Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDelMethod = "DELETE";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.ApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfInventoryManagement10";
exports.labelBodyText = "CfInventoryManagement10 Body";
exports.base_url =
  "https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/";
exports.apiPostMethod = "POST";
exports.btnExampleTitle = "CLICK ME";
exports.getStoreApiEndPoint = `account_block/accounts/store_accounts/get_store`;
exports.getOfcApiEndPoint = `account_block/accounts/ofc/get_all_ofcs`;
exports.getZoneApiEndPoint = `account_block/accounts/zone/list_of_zones`;
exports.addStoreApiEndPoint = `account_block/accounts/store_accounts`;
exports.addOfcApiEndPoint = `account_block/accounts/ofc`;
exports.addZoneApiEndPoint = `account_block/accounts/zone`;
exports.editStoreApiEndPoint = `account_block/accounts/store_accounts`;
exports.editOfcApiEndPoint = `account_block/accounts/ofc`;
exports.editZoneApiEndPoint = `account_block/accounts/zone`;
exports.getAllInventoryApiEndPoint = `bx_block_cfinventorymanagement11/inventories`; //get
exports.addInventoryApiEndPoint = `bx_block_cfinventorymanagement11/inventories`; //post
exports.editInventoryApiEndPoint = `bx_block_cfinventorymanagement11/inventories/`; //${id}`;
exports.getInventoryByIdApiEndPoint = `bx_block_cfinventorymanagement11/inventories/`; //${id}`;
exports.getInventoryByIdApiEndPoint = `bx_block_cfinventorymanagement11/inventories/`; //${id}`;
exports.getInventoryByStoreId = `/bx_block_cfinventorymanagement11/inventories?store_account_id=`;
exports.getInventoryByPaginationApiEndPoint = `/bx_block_cfinventorymanagement11/inventories?page=`; //${id}`;
exports.getStoreByIdApiEndPoint = `account_block/accounts/store_accounts?id=`; //${id}`;
exports.getZoneByIdApiEndPoint = `account_block/accounts/zone?id=`; //${id}`;
exports.getOfcByIdApiEndPoint = `account_block/accounts/ofc?id=`; //${id}`;
exports.EditStoreTimingsApiEndPoint = `account_block/accounts/store_timing/edit_timing`;
exports.EditMulipleStoreTimingsApiEndPoint = `account_block/accounts/store_timing/multiple_timing_edits`;
exports.getAllOfcByZoneIdApiEndPoint = `account_block/accounts/ofc/get_all_ofcs?zone_id=`;
exports.getClosingStoreTimeApiEndPoint = `account_block/accounts/store_timing/closing_time/?store_id=`;
exports.deleteParticularStoreClosingTimeApiEndPoint = `account_block/accounts/store_timing/destroy_store_time?id=`;
exports.StoreImportCsvApiEndPoint = `/account_block/accounts/store_accounts/csv_import`;
exports.OfcImportCsvApiEndPoint = `/account_block/accounts/ofc/csv_import`;
exports.ZoneImportCsvApiEndPoint = `/account_block/accounts/zone/csv_import`;
// exports.InventoryImportCsvApiEndPoint = `bx_block_cfinventorymanagement11/inventories/csv_import`;
exports.InventoryImportCsvApiEndPoint = `bx_block_cfinventorymanagement11/inventories/import`;
exports.StoreSearchApiEndPoint = `account_block/accounts/store_accounts/get_store?search=`;
exports.InventorySearchApiEndPoint = `bx_block_cfinventorymanagement11/inventories?search=`;
exports.OfcSearchApiEndPoint = `account_block/accounts/ofc/get_all_ofcs?search=`;
exports.ZoneSearchApiEndPoint = `account_block/accounts/zone/list_of_zones?search=`;
exports.FTPLocationApiEndPoint = `bx_block_cfftp2/ftp`;
exports.UpdateFTPLocationApiEndPoint = `bx_block_cfftp2/ftp`;
exports.UpdateStorestatusApiEndPoint = `account_block/accounts/store_accounts`;
exports.UpdateOfcStatusApiEndPoint = `account_block/accounts/ofc`;
exports.UpdateZoneStatusApiEndPoint = `account_block/accounts/zone`;
exports.StoreProfileChangeApiEndPoint = `account_block/accounts/store_accounts`;

// Customizable Area End
