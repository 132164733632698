import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

// Customizable Area Start
import { message as AntMessage } from "antd";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  orderList: any;
  newOrderBlock: boolean;
  acceptedOrdersBlock: boolean,
  rejectedOrdersBlock: boolean,
  partiallyAcceptedOrdersBlock: boolean,
  partiallyRejectedOrdersBlock: boolean,
  ordersOnHoldBlock: boolean,
  orderFullfilledBlock: boolean,
  selectedRowKeys: any;
  adminDashbaordData: any;
  orderApiData: any;
  selectedOrderId: any;
  selectedOrderDetails: any;
  orderConfirmationState: any;
  selectedFilterId: String;
  DropDownValue: string;
  orderDetails: any;
  RejectedReasonBlock: boolean;
  variablePriceArray: any;
  variableQuantity: any;
  testState: any;
  filterOrderData: any;
  selectedFilterString: any;
  Order_Id:any

  // Customizable Area End
}
interface SS {
  id: any;
}
export default class DashboardController extends BlockComponent<Props, S, SS> {
  //Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllOrderInformation: string;
  getsearchOrderInformation: string;
  getStoreDashbaordOrderInformation: string;
  searchOrderInformation: string;
  getProfileInformation: string;
  getOfcInformation: string;
  getZoneInformation: string;
  getStoreInformation: string;
  OrdersInformation: string;
  orderConfirmationState: string;
  SelectedOrderDetails: string;
  orderStatusUpdateInformation: string;
  getAllOrdersWithFilter: string;
  getAllDropDownIds: string;
  searchDashboardDataWithZoneOfcStoreFilters: string
  searchOrderItemsDeatils: string
  apiDungoOrderStatusAccepted: string
  apiDungoOrderStatusPacked: string
  // Customizable Area End
  constructor(props: Props) {

    super(props);
    console.log(props, 'props');

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      selectedRowKeys: [],
      orderList: [],
      newOrderBlock: false,
      acceptedOrdersBlock: false,
      rejectedOrdersBlock: false,
      partiallyAcceptedOrdersBlock: false,
      partiallyRejectedOrdersBlock: false,
      ordersOnHoldBlock: false,
      orderFullfilledBlock: false,
      adminDashbaordData: [],
      orderApiData: [],
      testState: [],
      selectedOrderId: '',
      selectedOrderDetails: {},
      orderConfirmationState: '',
      selectedFilterId: "",
      DropDownValue: "",
      orderDetails: [],
      RejectedReasonBlock: false,
      variablePriceArray: [],
      variableQuantity: [],
      filterOrderData: [4],
      selectedFilterString: `?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`,
      Order_Id:""

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  //========================================== componentDidMount ==========================================================
  async componentDidMount() {
    // Customizable Area Start
    let Dashboard: boolean = false;
    let StoreDashboard: boolean = false;
    let decodedString = atob(localStorage.getItem('permissions') || '{}');
    //@ts-ignore
    JSON.parse(decodedString).filter((d) => {

      if (d.attributes.access_module.name == "Dashboard") {
        Dashboard = true
        return
      }
      if (d.attributes.access_module.name == "Store Dashboard") {
        StoreDashboard = true
        return
      }

    });

    if (Dashboard) {
      this.getAllOrders();
      this.getAllDropDownId();
    }
    if (StoreDashboard) {
      // this.onGetOrders();


      //To Fetch Order from Last 14 days instead of fetching all Orders
      let reqPayload = `?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`
      this.FilterStoreDashboard(reqPayload);
    }
    this.getProfileData();
    // Customizable Area End
  }

  thiscallisFromAppJs() {
    console.log("this call is From App Js", this.props);
    // this.forceUpdate();
    this.FilterStoreDashboard(`/?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`);

  }

  getAllOrders = async () => {
    this.setState({ loading: true })
    this.getAllOrderInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllOrdersEndPoint,
    });
  }
  ongetOrdersinGivenInterval = async (mins: any) => {
    setInterval(() => {
      //@ts-ignore
      this.onGetOrders();
    }, mins)
  }

  searchOrdersDetails = async (searchedText: any) => {
    this.setState({ loading: true })
    this.searchOrderInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.searchOrdersApiEndPoint + searchedText,
    });
  }

  getProfileData = async () => {
    let id = localStorage.getItem('userId');
    this.getProfileInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getProfileDataApiEndPoint + id,
    });
  };

  ongetOfc = async () => {
    this.getOfcInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getOfcApiEndPoint,
    });
  };

  ongetZone = async () => {
    this.getZoneInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getZoneApiEndPoint,
    });
  };

  ongetStore = async () => {
    this.getStoreInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getStoreApiEndPoint,
    });
  };

  onGetOrders = async () => {
    this.setState({ loading: true })
    this.OrdersInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getOrdersApiEndPoint,
    });
  };

  getSelectedOrderDetails = async (id: any) => {
    this.SelectedOrderDetails = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSelectedOrdersDetailsApiEndPoint + id,
    });
  };

  updatedOrderStatus = async (data: any) => {
    this.orderStatusUpdateInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.orderStatusUpdatedApiEndPoint,
      body: JSON.stringify(data)
    });
  };

  dashboardSeachOrdersDetails = async (searchedText: any) => {
    this.setState({ loading: true })
    this.getsearchOrderInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dashboardSearchAccordingToOfcOrZoneApiEndPoint + searchedText,
    });
  }

  searchDashboardDataWithZoneOfcStoreFilter = async (searchedId: any) => {
    this.setState({ loading: true })
    this.getsearchOrderInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dashboardSearchAccordingToOfcOrZoneApiEndPoint + searchedId,
    });
  }

  getAllOrdersWithFilters = async (searchedId: any) => {
    this.getsearchOrderInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dashboardSearchAccordingToOfcOrZoneApiEndPoint + searchedId,
    });
  }
  getAllDropDownId = async () => {
    this.getAllDropDownIds = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dashboardSearchDropDownIdsApiEndPoint,
    });
  }
  searchOrdersItems = async (searchedText: any, orderId: any) => {
    this.searchOrderItemsDeatils = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSelectedOrdersDetailsApiEndPoint + `${orderId}` + `&search=${searchedText}`,
    });
  }

  FilterStoreDashboard = async (data: any) => {
    this.setState({ loading: true, selectedFilterString: data });
    this.getStoreDashbaordOrderInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getOrdersApiEndPoint + data
    });
  }
  handleCallDunzoOrderStatusACCEPTED=async()=>{
    let postMethod = {
      "state": 'ACCEPTED',
      "dunzo_order_id": this.state.Order_Id !=="" ? localStorage.getItem("Order_Id") : this.state.Order_Id,
      "reason": ''
    } 
    this.apiDungoOrderStatusAccepted = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.AcceptedDunzoOrderStatusApiEndPoint,
      body: JSON.stringify(postMethod),
    });
  }

  handleCallDunzoOrderStatusPACKED=async()=>{
    if(localStorage.getItem("Order_Id")){
      let postMethod = {
        "state": 'PACKED',
        "dunzo_order_id": this.state.Order_Id !== "" ? localStorage.getItem("Order_Id") : this.state.Order_Id,
        "reason": ''
      } 
      localStorage.removeItem("Order_Id")
      this.apiDungoOrderStatusPacked = await this.apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.AcceptedDunzoOrderStatusApiEndPoint,
        body: JSON.stringify(postMethod),
      });
    }
  }
  //========================================= API CALL BLOCK ===================================================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  //========================================== RECIVE BLOCK ==========================================================
  receive = (from: string, message: Message) => {

    // runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.errors && responseJson?.errors[0]?.token || responseJson == undefined) {
        if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
          localStorage.clear();
          window.location.href = '/';
        };
        if (responseJson == undefined) {
          // AntMessage.error('Something went wrong')
          return
        }
      }
      //============================ SUCCESS BLOCK =============================================
      if (responseJson || responseJson.data || responseJson.message) {
        if (apiRequestCallId === this.getAllOrderInformation) {
          this._handelGetAllOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.searchOrderInformation) {
          this._handelGetAllOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.getsearchOrderInformation) {
          this._handelGetAllOrderSuccessResponse(responseJson)
        }

        if (apiRequestCallId === this.getProfileInformation) {
          this._handelProfileDataSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.getOfcInformation) {
          this._handelGetOfcSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.getZoneInformation) {
          this._handelGetZoneSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.getStoreInformation) {
          this._handelGetStoreSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.OrdersInformation) {
          this._handelGetOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.SelectedOrderDetails) {
          this._handelGetSelectedOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.orderStatusUpdateInformation) {
            // this.onGetOrders();
            localStorage.setItem("Order_Id",responseJson?.data?.partner_order_id)
            this.setState({
              Order_Id:responseJson?.data?.partner_order_id
            },()=>this.handleCallDunzoOrderStatusACCEPTED())
            //@ts-ignore
            this.FilterStoreDashboard(this.props?.filterData);

            AntMessage
              .loading('Updating.......', 1)
              .then(() => AntMessage.success(responseJson.message))
        }
        if (apiRequestCallId === this.searchDashboardDataWithZoneOfcStoreFilters) {
          this._handelGetAllOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.getAllDropDownIds) {
          this._handelgetAllDropDownIdsSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.getAllOrdersWithFilter) {
          this._handelGetAllOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.searchOrderItemsDeatils) {
          this._handelGetSelectedOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.getStoreDashbaordOrderInformation) {
          this._handelGetOrderSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.apiDungoOrderStatusAccepted) {
          // this._handelDungoOrderPlacedSuccessResponse(responseJson)
             //@ts-ignore
            // this.FilterStoreDashboard(this.props?.filterData);
            AntMessage
            .loading('Updating.......', 1)
            .then(() => AntMessage.success(responseJson.message))
        }else{
          if(responseJson?.response?.code === 500){
            this.handleCallDunzoOrderStatusPACKED()
          }
        }
        if (apiRequestCallId === this.apiDungoOrderStatusPacked) {
          this._handelDungoOrderPlacedPackedSuccessResponse(responseJson)
        } 
        //============================ ERROR BLOCK ==============================================
      } else if (responseJson && responseJson.error || responseJson.errors) {

        if (responseJson?.errors[0]?.token) {
          if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
            localStorage.clear();
            window.location.href = '/';
          };
        }
        if (apiRequestCallId === this.SelectedOrderDetails) {
          this._handelGetSelectedOrderErrorResponse(responseJson)
        }
        if (apiRequestCallId === this.searchOrderItemsDeatils) {
          this._handelGetSelectedOrderErrorResponse(responseJson)
        }
      } else if (errorReponse) {

      }
    }
  }

  //===================================================================================================================
  //=========================================== SUCCESS BLOCK =========================================================
  // _handelGetAllOrderSuccessResponse
  _handelGetAllOrderSuccessResponse = async (response: any) => {
    this.setState({ loading: false })
    let orderListDataTobeDisplayed = response?.data?.length > 0 ?
      response.data.map((sd: any, index: any) => {
        return {
          id: sd.id,
          sno: index + 1,
          orderId: sd.partner_order_id ? sd.partner_order_id : '-',
          partner: sd.catalogue_name,
          store_id: sd.storeId,
          store_name: sd.store_name,
          amount: sd.total_price ? `Rs. ${sd.total_price}` : '-',
          order_time: sd.created_at ? `${moment(sd.created_at).format(`D MMM YY`)}  ,  ${moment(sd.created_at).format(`h:mm A`)}  ` : '-',
          status: sd.status,

        }
      }) : []


    let orderPartnerData = [];
    let totalPaymnet = response.total_order_price ? `INR ${response.total_order_price}` : `INR${0}`;
    let totalOrders = response.total_order_count ? response.total_order_count : 0

    // let totalOrderStatus = [];

    let count1 = 0
    let count2 = 0
    let count3 = 0
    for (var i in response.total_amount_of_partners) {
      orderPartnerData.push(
        {
          key: count1 + 1,
          orderPartner: i,
          amount: response.total_amount_of_partners[i],
        });
      count1++
    }

    //totalBillingRate

    let totalOrderStatus: any = []
    for (let i in response.total_order_status) {
      totalOrderStatus.push(
        {
          key: count2 + 1,
          status: i,
          noOfOrder: response.total_order_status[i],
        });
      count2++
    }

    let orderFulfilmentRateColumns = response?.order_fullfilment_rate?.map((data: any, index: any) => {
      return {
        key: index + 1,
        time: Object.keys(data)[0],
        percentage: Object.values(data)[0],
      }
    })

    let totalBillingRate = response?.total_billing_rate?.map((data: any, index: any) => {
      return {
        key: index + 1,
        month: Object.keys(data)[0],
        amount: Object.values(data)[0],
      }
    })
    this.setState({
      orderList: orderListDataTobeDisplayed, adminDashbaordData: {
        ...this.state.adminDashbaordData,
        orderPartnerData,
        totalOrderStatus,
        totalBillingRate,
        totalPaymnet,
        totalOrders,
        orderFulfilmentRateColumns
      },
    })
  }
  //===================================================================================================================
  // _handelProfileDataSuccessResponse
  _handelProfileDataSuccessResponse = async (response: any) => {
    let profileData = response.data.attributes
    this.setState({ dashboardData: { ...this.state.dashboardData, profileData } })
  }
  //===================================================================================================================
  // _handelGetOfcSuccessResponse
  _handelGetOfcSuccessResponse = async (response: any) => {
    let OfcData = response.data
    this.setState({ dashboardData: { ...this.state.dashboardData, OfcData } })
  }
  //===================================================================================================================
  // _handelGetZoneSuccessResponse
  _handelGetZoneSuccessResponse = async (response: any) => {
    let ZoneData = response.data
    this.setState({ dashboardData: { ...this.state.dashboardData, ZoneData } })
  }
  //===================================================================================================================
  // _handelGetZoneSuccessResponse
  _handelGetStoreSuccessResponse = async (response: any) => {
    // console.log(response)
    let StoreData = response.data
    this.setState({ dashboardData: { ...this.state.dashboardData, StoreData } })
  }
  //===================================================================================================================

  // _handelGetOrderSuccessResponse
  _handelGetOrderSuccessResponse = async (response: any) => {
    this.setState({ orderApiData: response, loading: false });
    // const { seState } = this.props
    //@ts-ignore
    if (this.props?.seState != undefined) {
      //@ts-ignore
      console.log(this.props?.seState);
      //@ts-ignore
      this.props?.seState({
        newOrderBlock: false,
        acceptedOrdersBlock: false,
        rejectedOrdersBlock: false,
        partiallyAcceptedOrdersBlock: false,
        partiallyRejectedOrdersBlock: false,
        ordersOnHoldBlock: false,
        orderFullfilledBlock: false,
        RejectedReasonBlock: false,
        orderApiData: response,
        loading: false,
        selectedFilterString: this.state?.selectedFilterString
      });
    }


  }
  //===================================================================================================================

  _handelGetSelectedOrderSuccessResponse = async (response: any) => {
    this.setState({ selectedOrderDetails: response?.data?.attributes ? response?.data?.attributes : {}, loading: false })

    if (response?.data?.attributes?.order_items.length > 0 && (response?.data?.attributes?.status == 'On hold')) {
      let selectedOrderIds: any = [];
      let selectedOrdersifAny = response?.data?.attributes?.order_items.map((items: any, index: any) => {
        if (items.status == 'Accepted' || items.id == 300046 || items.id == 300074) {
          selectedOrderIds.push(items);
          // selectedOrderIds.push(items.id);
          // console.log(items.status,items.item_name);
          return index + 1
        }
      });

      // console.log(selectedOrdersifAny,selectedOrderIds);
      this.setState({ selectedRowKeys: selectedOrdersifAny, orderConfirmationState: selectedOrderIds });
    }
    if (response?.data?.attributes?.order_items.length > 0 && (response?.data?.attributes?.status == 'Partially accepted')) {
      let selectedOrderIds: any = [];
      let selectedOrdersifAny = response?.data?.attributes?.order_items.map((items: any, index: any) => {
        if (items.status == 'Accepted' || items.id == 300046 || items.id == 300074) {
          selectedOrderIds.push(items);
          // selectedOrderIds.push(items.id);
          return index + 1
        }
      });

      this.setState({ selectedRowKeys: selectedOrdersifAny, orderConfirmationState: selectedOrderIds });
    }

    if (response?.data?.attributes?.partner?.toLowerCase() == 'phygital' && response?.data?.attributes?.order_items.length > 0) {

      const vPriceArray: any = [];
      response?.data?.attributes?.order_items.map((item: any) => {
        if (item?.variable_prices?.length > 0) {
          vPriceArray.push(item?.variable_prices[0])
        } else {
          vPriceArray.push(
            {
              article_code: item?.id,
              price: item?.price
            })

        }
      })
      // console.log(vPriceArray, "vPriceArray");
      // this.setState({ variablePriceArray: vPriceArray })

    }
  }

  //===================================================================================================================
  // _handelDungoOrderPlacedPackedSuccessResponse
  _handelDungoOrderPlacedPackedSuccessResponse = async (response: any) => {
    //@ts-ignore
    // this.FilterStoreDashboard(this.props?.filterData);
    localStorage.removeItem("Order_Id")
    AntMessage
      .loading('Updating.......', 1)
      .then(() => AntMessage.success(response.message))

  }

  //===================================================================================================================
  // _handelgetAllDropDownIdsSuccessResponse
  _handelgetAllDropDownIdsSuccessResponse = async (response: any) => {
    this.setState({
      adminDashbaordData: {
        ...this.state.adminDashbaordData,
        DropdownData: response?.data
      },
    });

  }
  //===================================================================================================================
  //===================================================================================================================
  //========================================== ERROR BLOCK  =========================================================
  //===================================================================================================================
  //===================================================================================================================
  //===================================================================================================================
  //===================================================================================================================
  //===================================================================================================================
  //===================================================================================================================


  // Customizable Area Start
  _handelGetSelectedOrderErrorResponse = async (response: any) => {
  }
  // Customizable Area End
}
