// Customizable Area Start
import React, { createRef, useState } from "react";
import CfInventoryManagement10Controller, {
  Props,
  configJSON,
} from "../CfInventoryManagement10Controller";
import "antd/dist/antd.css";
import {
  Table,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Row,
  Col,
  Form,
  Switch,
  Typography,
  TimePicker,
  DatePicker,
  Radio,
  Space,
  Avatar,
  Tabs,
  Anchor,
  Spin,
  Popover,
  Collapse,
  Tooltip,
} from "antd";
import { Link as LINK } from 'react-router-dom'
import { CloseCircleFilled, CloseOutlined, DownCircleFilled, EditOutlined, InfoCircleOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import "./Store.css";
import { FormInstance } from "antd/es/form";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  EditFilled,
  DeleteFilled,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
const { TabPane } = Tabs;
const { Title, Paragraph, Text, Link } = Typography;
import { message as AntMessage } from "antd";
//@ts-ignore
import GlobalLoader from "../../../../web/src/GlobalLoader"
//@ts-ignore
import { baseURL } from "../../../../framework/src/config.js"

/**
 * @author
 * @class @StoreTimings
 **/

class Store extends CfInventoryManagement10Controller {
  public phoneNoError: any;
  timerId: any = null
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      txtInputValue: "",
      selectedStoreTimingData: {},
      txtSavedValue: "",
      enableField: false,
      selectedRowKeys: [],
      addModal: false,
      days: [
        { name: "Monday", value: "Mon", id: 1 },
        { name: "Tuesday", value: "Tue", id: 2 },
        { name: "Wednesday", value: "Wed", id: 3 },
        { name: "Thursday", value: "Thu", id: 4 },
        { name: "Friday", value: "Fri", id: 5 },
        { name: "Saturday", value: "Sat", id: 6 },
      ],
      repeats: [
        { name: "Never", value: "never" },
        { name: "Daily", value: "daily" },
        { name: "Weekly", value: "weekly" },
        { name: "Monthly", value: "monthly" },
      ],
      selectedTab: 1,
      editModal: false,
      addOfcModal: false,
      editOfcModal: false,
      editZoneModal: false,
      addZoneModal: false,
      Storelist: [],
      OfcList: [],
      ZoneList: [],
      ofcIds: [],
      zoneIds: [],
      selectedStoreData: {},
      selectedOfcData: {},
      selectedZoneData: {},
      Inventorylist: [],
      selectedInventoryData: {},
      Errors: {},
      apiLoading: false,
      InventoryApiLoading: false,
      storeTimingsData: {
        startDate: '',
        EndDate: '',
        StartTime: '',
        EndTime: '',
        Days: [],
        repeats: [],

      },
      closingStoreTimings: {},
      selectedCheckBoxData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        TotalInventoryCount: 0,
        TotalStoreCount: 0,
      },
      modaLoader: false,
      selectedProfilePic: "",
      addItemUnavailable: [],
      getItemUnavailable: {},
      FtpModal: false,
      uploadingLoading: false,
      searchValue: '',

    };
    document.title = `Stores | 24SevenDashboard`
    // Customizable Area End


  }





  formRef = createRef<FormInstance>();
  selectRow = (record: any) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys });
  };
  onSelectedRowKeysChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  handelTabChange = (key: any) => {
    this.setState({ selectedTab: key });
  }

  onFinish = (values: any) => {
    console.log("Success:", values);
  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  onReset = () => {
    this.formRef.current!.resetFields();
  };

  handleSwitch = (e: any, data: any) => {
    console.log(e, data);
    let requestPayload = {
      id: data.id,
      data: {
        status: e == true ? true : false
      }
    }
    this.setState({ apiLoading: true })
    this.updateStoreStatus(requestPayload)
  };

  handleOfcSwitch = (e: any, OfcId: any) => {
    console.log(e, OfcId);
    let requestPayload = {
      id: OfcId,
      data: {
        status: e == true ? true : false
      }

    }
    this.setState({ apiLoading: true })
    this.updateOfcStatus(requestPayload)
  };

  handleZoneSwitch = (e: any, zoneId: any) => {
    console.log(e, zoneId);
    let requestPayload = {
      id: zoneId,
      data: {
        status: e == true ? true : false
      }
    }
    this.setState({ apiLoading: true })
    this.updateZoneStatus(requestPayload)
  };

  handleTimeChange = (e: any) => {
    console.log("handleTimeChange", e);
  };

  handleDateChange = (e: any) => {
    console.log("handleDateChange", e);
  };

  handleCheckBox = (e: any, index: any) => {
    console.log("handleCheckBox", e);
  };

  handleRadioChange = (e: any) => {
    console.log("handleRadioChange", e);
  };

  handelEditStore = (itemId: any) => {
    console.log(itemId);
    this.handelGetStoreDataByID(itemId)

  }
  handelEditOfc = (itemId: any) => {
    console.log(itemId);
    this.handelGetOfcDataByID(itemId)

  }
  handelEditZone = (itemId: any) => {
    console.log(itemId);
    this.handelGetZoneDataByID(itemId)

  }

  //===================================================================================

  //call add Store API
  handelAddStoreData = async (data: any) => {
    this.addStoreData(data);
  }
  //handelEditStoreData
  handelEditStoreData = async (data: any) => {
    // console.log(data);

    let dataToBeUpdated = {
      id: data.id,
      data: {
        store_name: data.store_name,
        password: data.password,
        address: data.address,
        phone_no: data.phone_no,
        name: data.manager,
        zone_id: data.zone_id,
        ofc_id: data.ofc_id,
        phygital_store_id: data.phygital_store_id,
        dunzo_store_id: data.dunzo_store_id,
        zomato_store_id: data.zomato_store_id,

      }
    }
    this.editStoreData(dataToBeUpdated);

  }

  //call add Zone API
  handelAddZoneData = async (data: any) => {
    this.addZoneData({ data: data })
  }

  //handelEditZoneData
  handelEditZoneData = async (data: any) => {
    let dataToBeUpdated = {
      id: data.zone_id,
      data: {
        zone_name: data.zone_name,
        zone_description: data.zone_description,
      }
    }
    console.log(dataToBeUpdated);
    this.editZoneData(dataToBeUpdated)

  }

  //handelEditOfcData
  handelEditOfcData = async (data: any) => {
    // console.log(data);

    let dataToBeUpdated = {
      id: data.ofc_id,
      data: {
        zone_id: data.zone_id,
        ofc_unnique_number: data.ofc_unnique_number,
        clusture_name: data.ofc_name,
        clusture_discription: data.ofc_description,
      }
    }
    this.editOfcData(dataToBeUpdated)

  }

  //handelAddOfcData
  handelAddOfcData = async (data: any) => {
    console.log('Calling add Ofc API', data);
    let _data = {
      data: {
        zone_id: data.zone_id,
        ofc_unnique_number: data.ofc_id,
        clusture_name: data.ofc_name,
        clusture_discription: data.ofc_description
      }

    }
    this.addOfcData(_data)

  }

  //handelGetStoreDataByID
  handelGetStoreDataByID = async (storeId: any) => {
    this.getStoreById(storeId)
  }
  //handelGetOfcDataByID
  handelGetOfcDataByID = async (ofcId: any) => {
    this.getOfcDataByID(ofcId);
  }
  //handelGetZoneDataByID
  handelGetZoneDataByID = async (zoneId: any) => {
    this.getZoneDataByID(zoneId);

  }

  selectedZoneId(zoneId: any) {
    this.formRef.current?.setFieldsValue({ ofc_id: null })
    this.getAllOfcsRealtedtoZoneId(zoneId);
  }

  editSelectedZoneId(zoneId: any) {
    this.formRef.current?.setFieldsValue({ ofc_id: null })
    this.getAllOfcsRealtedtoZoneId(zoneId);
  }

  // uploadStoreCsv
  uploadCsv = async (e: any) => {
    let isFileCSV = await this.beforeStoreFileUpload(e.file)
    if (isFileCSV) {
      this.setState({ apiLoading: true })
      const formData = new FormData();
      formData.append("file", e.file);
      console.log(formData);
      if (this.state.selectedTab == 1) { this.uploadStoreCsvFile(formData) }
      if (this.state.selectedTab == 2) { this.uploadOfcCsvFile(formData) }
      if (this.state.selectedTab == 3) { this.uploadZoneCsvFile(formData) }

    } else {
      AntMessage.error('You can only upload csv/xls file!');
    }
  }


  beforeStoreFileUpload(file: any) {
    if (file.type == 'text/csv' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return true;
    } else {
      return false
    }
  }

  searchStore = async (e: any) => {
    clearTimeout(this.timerId);
    this.setState({ txtInputValue: e.target.value, apiLoading: true })
    this.timerId = setTimeout(() => {
      this.searchStoreDetails(this.state?.txtInputValue)
      // this.searchStoreDetails(e.target.value)

    }, 1000)
  }
  searchOfc = async (e: any) => {
    clearTimeout(this.timerId);
    this.setState({ txtInputValue: e.target.value, apiLoading: true })
    this.timerId = setTimeout(() => {
      this.searchOfcDetails(this.state?.txtInputValue)
    }, 1000)
  }
  searchZone = async (e: any) => {
    clearTimeout(this.timerId);
    this.setState({ txtInputValue: e.target.value, apiLoading: true })
    this.timerId = setTimeout(() => {
      this.searchZoneDetails(this.state?.txtInputValue)
    }, 1000)
  }

  uploadStoreProfilePicture = async (e: any) => {
    console.log(this.state.selectedStoreData);

    const data = new FormData();
    data.append("data[avatar]", e.file);
    this.uploadStoreProfile(data)
  }

  handelSelectProfilePicture = async (e: any) => {
    this.setState({ selectedProfilePic: e.file })
  }
  //=====================================================================
  render() {
    const { modaLoader, selectedStoreData, selectedRowKeys, addModal, days, repeats, editModal, addOfcModal, editOfcModal, addZoneModal, editZoneModal, txtInputValue, pagination } = this.state;

    // const antIcon = <LoadingOutlined translate={{}} style={{ fontSize: '35px', color: 'black', top: '60% !important' }} spin />;
    const antIcon = <GlobalLoader />;

    let permissions: any;
    let decodedString = atob(localStorage.getItem('permissions') || '{}');

    JSON.parse(decodedString).filter((d: any) => {

      if (d.attributes.access_module.name == "Store") {
        permissions = d.attributes
        return
      }
    });

    let StoreTableObj = {
      title: "",
      dataIndex: "",
      key: "",
      render: (item: any, fullObj: any) => (
        <div className="editIconBox">
          <Space size="large">
            <span className="icon">
              <EditFilled
                translate
                onClick={() => {
                  this.handelEditStore(item.id);
                  this.setState({ editModal: true, modaLoader: true });

                }}
              />
            </span>
            <span>EDIT</span>
          </Space>
        </div>
      )

    }

    let OfcTableObj = {
      title: "",
      dataIndex: "",
      key: "",
      render: (item: any, fullObj: any) => {
        return (

          <div className="editIconBox">
            <Space size="large">
              <span className="icon">
                <EditFilled
                  translate
                  onClick={() => {
                    this.handelEditOfc(item.id)
                    this.setState({ editOfcModal: true, modaLoader: true })
                  }
                  }
                />
              </span>
              <span>Edit</span>
            </Space>
          </div>
        )
      }

    }
    let ZoneTableObj = {
      title: "",
      dataIndex: "",
      key: "",
      render: (item: any, fullObj: any) => {
        return (

          <div className="editIconBox">
            <Space size="large">
              <span className="icon">
                <EditFilled
                  translate
                  onClick={() => {
                    this.handelEditZone(item.zoneId)
                    this.setState({ editZoneModal: true, modaLoader: true })
                  }}
                />
              </span>
              <span>Edit</span>
            </Space>
          </div>
        )
      }
    }

    //Store Columns
    const columns = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "Store ID",
        dataIndex: "store_id",
        key: "store_id",
      },
      {
        title: "Store Name",
        dataIndex: "store_name",
        key: "store_name",
      },
      {
        title: "Store Address",
        dataIndex: "store_address",
        key: "store_address",
      },
      {
        title: "Store Manager",
        dataIndex: "store_manager",
        key: "store_manager",
      },
      {
        title: "Store Contact",
        dataIndex: "store_contact",
        key: "store_contact",
      },
      {
        title: "Store Username",
        dataIndex: "store_username",
        key: "store_username",
      },
      {
        title: "Password",
        dataIndex: "store_password",
        key: "store_password",
      },
      {
        title: "Zone Id",
        dataIndex: "store_zone_id",
        key: "store_zone_id",
      },
      {
        title: "Ofc Id",
        dataIndex: "store_ofc_id",
        key: "store_ofc_id",
      },
      {
        title: "Status",
        dataIndex: "store_status",
        key: "store_status",
        render: (data: any, record: any, index: any) => {
          return (
            <Space size="large">
              {/* <Text className="act-status">{data == true?'Open':'Close'}</Text> */}
              <Switch
                // loading={this.state.apiLoading}
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                checked={data == true ? true : false}
                onChange={(e) => this.handleSwitch(e, record)}
                title={data == true ? 'Open' : 'Close'}
              />
            </Space>
          );
        },
      },
      {
        title: "Outlet Id",
        dataIndex: "outlet_id",
        key: "outlet_id",

        render: (data: any, record: any, index: any) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Popover
                placement="leftBottom"
                content={
                  <>
                    {data.length > 0
                      ?
                      <div className="outer-outlet-block">
                        {
                          data.map((item: any) => {
                            return (
                              <div className="inner-outlet-block">
                                <label>{item?.name}:-</label>
                                <span>{item?.id}</span>
                              </div>
                            )
                          })
                        }

                      </div>
                      : <div>No Data Found !</div>}

                  </>
                }
                trigger="click"

              >
                <InfoCircleOutlined translate={{}} title='Outlet Id' />
              </Popover>

            </div>
          );
        },
      },
      // {
      //   title: "",
      //   dataIndex: "",
      //   key: "",
      //   render: (item: any, fullObj: any) => {
      //     return (
      //       <>
      //         {permissions.update_action ? <div className="editIconBox">
      //           <Space size="large">
      //             <span className="icon">
      //               <EditFilled
      //                 translate
      //                 onClick={() => {
      //                   this.handelEditStore(item.id);
      //                   this.setState({ editModal: true });
      //                 }}
      //               />
      //             </span>
      //             <span>EDIT</span>
      //           </Space>
      //         </div> : null}
      //       </>
      //     )

      //   }

      //   // className: permissions.update_action ? '' : 'c-none',

      // },
    ];

    //OFC Columns
    const OfcColumns = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "OFC ID",
        dataIndex: "ofc_unnique_number",
        key: "ofc_unnique_number",
      },
      {
        title: "OFC Name",
        dataIndex: "ofcName",
        key: "ofcName",
      },
      {
        title: "OFC Description",
        dataIndex: "ofcDescription",
        key: "ofcDescription",
      },
      {
        title: "Zone ID",
        dataIndex: "ofcZoneId",
        key: "ofcZoneId",
      },
      {
        title: "Status",
        dataIndex: "ofcStatus",
        key: "ofcStatus",
        render: (data: any, record: any, index: any) => {
          return (
            <Space size="large">
              {/* <Text className="act-status">Active</Text> */}
              <Switch
                // loading={this.state.apiLoading}
                defaultChecked={data == true ? data : false}
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                onChange={(e) => this.handleOfcSwitch(e, record.id)}
                title="Active"
              />
            </Space>
          );
        },
      },
      // {
      //   title: "",
      //   dataIndex: "",
      //   key: "",
      //   render: (item: any, fullObj: any) => {
      //     return (
      //       <>
      //         {permissions.update_action ?
      //           <div className="editIconBox">
      //             <Space size="large">
      //               <span className="icon">
      //                 <EditFilled
      //                   translate
      //                   onClick={() => {
      //                     this.handelEditOfc(item.id)
      //                     this.setState({ editOfcModal: true })
      //                   }
      //                   }
      //                 />
      //               </span>
      //               <span>Edit</span>
      //             </Space>
      //           </div>
      //           : null}
      //       </>
      //     )
      //   }

      // }
    ];

    //Zone Columns
    const zoneColumns = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "Zone ID",
        dataIndex: "zone_unique_id",
        key: "zone_unique_id",
      },
      {
        title: "Zone Name",
        dataIndex: "zoneName",
        key: "zoneName",
      },
      {
        title: "Zone Description",
        dataIndex: "zoneDescription",
        key: "zoneDescription",
      },
      {
        title: "Status",
        dataIndex: "zoneStatus",
        key: "zoneStatus",
        render: (data: any, record: any, index: any) => {
          return (
            <Space size="large">
              {/* <Text className="act-status">Active</Text> */}
              <Switch
                // loading={this.state.apiLoading}
                defaultChecked={data == true ? true : false}
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                onChange={(e) => this.handleZoneSwitch(e, record.zoneId)}
                title={data == true ? 'Active' : 'Inactive'}
              />
            </Space>
          );
        },
      },
      // {
      //   title: "",
      //   dataIndex: "",
      //   key: "",
      //   render: (item: any, fullObj: any) => {
      //     return (
      //       <>
      //         {permissions.update_action ?
      //           <div className="editIconBox">
      //             <Space size="large">
      //               <span className="icon">
      //                 <EditFilled
      //                   translate
      //                   onClick={() => {
      //                     this.handelEditZone(item.zoneId)
      //                     this.setState({ editZoneModal: true })
      //                   }}
      //                 />
      //               </span>
      //               <span>Edit</span>
      //             </Space>
      //           </div>
      //           : null}
      //       </>
      //     )
      //   }
      // }
    ];
    //

    if (permissions?.update_action) {
      //@ts-ignore
      columns.splice(11, 0, StoreTableObj); OfcColumns.splice(6, 0, OfcTableObj); zoneColumns.splice(5, 0, ZoneTableObj)
    }

    //----------------------------------------------------------------------------------------------------
    // PAGINATION OPTIONS
    const paginationOptions = {
      onChange: (page: any) => {
        this.setState({ pagination: { ...pagination, currentPage: page } });
        this.onGetStoreByPagination(page, this.state?.pagination.pageSize)
      },
      showSizeChanger: false,
      current: pagination.currentPage,
      pageSize: pagination.pageSize,
      total: this.state?.pagination?.TotalStoreCount,
    };
    //---------------------------------------------------------------------------------------------------

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    this.phoneNoError = false;

    return (
      <>
        <div className="StoreSection">

          {/* ADD STORE MODAL */}
          <Modal
            className="addstoreModalBox"
            visible={addModal}
            title={null}
            centered
            width="75vw"
            onCancel={() => {
              this.setState({ addModal: false });
              this.onReset();
              // this.componentDidMount()

            }
            }
            closeIcon={
              <CloseCircleFilled
                style={{ color: "#000", fontSize: "20px" }}
                translate={{}}
              />
            }
            footer={null}
          >
            <div className="AddStoreTimingsSection" style={{ padding: '0px' }}>
              <Row className="header">
                <Col span={24}>
                  <h1 className="mb-0 modal-heading">Add store</h1>
                </Col>
                <Col span={24}>
                  <p className="mb-0 modal-subtext">Add the store Details</p>
                </Col>
              </Row>
              <Row className="imgBoxBlock">
                <div className="imgBox" >
                  <img height='100px' width="100px" src="https://www.24-seven.in/img/logo/X-mas-Logo.png" />
                  <Upload
                    customRequest={(e) => { this.handelSelectProfilePicture(e) }}
                    showUploadList={false}
                  >
                    <Button color="transparent" className="edit_btn" ><EditOutlined translate={{}} /></Button>
                  </Upload>

                </div>
              </Row>
              {this.state.Errors.addStoreError !== "" ? <Row style={{ marginLeft: '20px', color: 'red' }}>{this.state.Errors.addStoreError}</Row> : ''}

              <Form
                onFinish={this.handelAddStoreData}
                // onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
                initialValues={
                  {
                    // zone_id: this.state?.zoneIds[0]?.id,
                    ofc_id: this.state?.ofcIds[0]?.id,
                  }
                }
                name="content-ref"
                autoComplete="off"
                layout="vertical"
              >
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={6}>
                    <Form.Item name="storeId" label="Store Id" rules={[{ required: true, message: "Unique Store id is required" }]}>
                      <Input type="text" placeholder="Store Id" />
                    </Form.Item>
                  </Col>
                  <Col className="rowCol" span={6}>
                    <Form.Item name="store_name" label="Store name" rules={[{ required: true, message: "Store name is required" }]} >
                      <Input type="text" placeholder="Enter store name" />
                    </Form.Item>
                  </Col>

                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={6}>
                    <Form.Item name="address" label="Store address" rules={[{ required: true, message: "Address is required" }]}>
                      <Input type="text" placeholder="Enter address" />
                    </Form.Item>
                  </Col>
                  <Col className="rowCol" span={6}>
                    <Form.Item
                      name="phone_no"
                      label="Phone number"
                      rules={
                        [
                          {
                            required: true, message: "Phone number is required"
                          },
                          {
                            pattern: new RegExp(/^(?:\d*)$/),
                            message: "Phone no must contain just number",
                          },
                          {
                            max: 10,
                            message: "Phone no should be less than 10 digits",
                          },
                          {
                            min: 10,
                            message: "Phone no should consist 10 digits",
                          },
                        ]
                      }>
                      <Input type="number" placeholder="Enter number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={6}>
                    <Form.Item
                      label="Store manager"
                      name="manager"
                      rules={
                        [
                          {
                            required: true,
                            message: "Manger name is required"
                          },
                          {
                            pattern: new RegExp(/[^0-9]/g),
                            message: "Manger name Should be in characters"
                          }
                        ]
                      } >
                      <Input type="text" placeholder="Enter manager name" />
                    </Form.Item>
                  </Col>
                  <Col className="rowCol" span={6}>
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[{ required: true, message: "Username is required" }]} >
                      <Input
                        type="text"
                        name="username"
                        autoComplete="new-username"
                        placeholder="Enter username" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={6}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={
                        [
                          {
                            required: true,
                            message: "Password is required"
                          },
                          {
                            pattern: new RegExp(/^(?=).{8,}$/),
                            message: "Password atlesat 8 digits long"
                          }
                        ]
                      } >
                      <Input.Password
                        name="password"
                        autoComplete="new-password"
                        placeholder="Enter password" />
                    </Form.Item>
                  </Col>
                  <Col className="rowCol" span={6}>
                    <Row className="modal-rows fieldBox selectdropBox">
                      <Col span={6}>
                        <Form.Item
                          name="zone_id"
                          label="Zone Id"
                          rules={[{ required: true, message: "Zone Id is required" }]}>

                          <Select placeholder="Zone Id" className="modal-role-text" onChange={(value) => {
                            this.selectedZoneId(value)
                          }} >
                            {
                              this.state.zoneIds.map((option: any) => (
                                <Select.Option value={option.id} >{option.zone_name}</Select.Option>
                              ))}
                          </Select>

                        </Form.Item>

                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="ofc_id"
                          label="Ofc Id"
                          rules={[{ required: true, message: "Ofc Id is required" }]}>{/**  rules={[{ required: true }]} */}

                          <Select placeholder="Ofc  Id" className="modal-role-text" value={this.state?.ofcIds[0]?.id}>
                            {
                              this.state.ofcIds.map((option: any) => (
                                <Select.Option value={option.id}>{option.clusture_name}</Select.Option>
                              ))}
                          </Select>

                          {/* <Input placeholder="Ofc Id" className="modal-role-text"/> */}

                        </Form.Item>
                      </Col>
                    </Row>

                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Collapse ghost>
                      <Collapse.Panel header="Outlet Id's" key="1" >
                        <div className="forms-Collapse-item">
                          <Row className="">
                            <Col className="rowCol" span={6}>
                              <label>Phygital :- </label>
                            </Col>
                            <Col className="rowCol" span={18}>
                              <Form.Item
                                name="phygital_store_id"
                              >
                                <Input
                                  type="text"
                                  placeholder="Enter Phygital Outlet Id" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className="forms-Collapse-item">
                          <Row className="">
                            <Col className="rowCol" span={6}>
                              <label>Dunzo :- </label>
                            </Col>
                            <Col className="rowCol" span={18}>
                              <Form.Item
                                name="dunzo_store_id"
                              >
                                <Input
                                  type="text"
                                  placeholder="Enter Dunzo Outlet Id" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className="forms-Collapse-item">
                          <Row className="">
                            <Col className="rowCol" span={6}>
                              <label>Zomato :- </label>
                            </Col>
                            <Col className="rowCol" span={18}>
                              <Form.Item name="zomato_store_id"   >
                                <Input
                                  type="text"
                                  placeholder="Enter Zomato Outlet Id" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Collapse.Panel>

                    </Collapse>

                  </Col>
                </Row>

                <Row className="saveBtnbox">
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="content-add-btn saveBtn"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>


          {/* EDIT STORE MODAL */}
          <Modal
            className="addstoreModalBox"
            visible={editModal}
            title={null}
            centered
            width="45vw"
            onCancel={() => {
              this.setState({ editModal: false })
              this.onReset();
            }}
            closeIcon={
              <CloseCircleFilled
                style={{ color: "#000", fontSize: "20px" }}
                translate={{}}
              />
            }
            footer={null}
          >
            <Row className="AddStoreTimingsSection">
              <Row className="header">
                <Col span={24}>
                  <h1 className="mb-0 modal-heading">Edit Store</h1>
                </Col>
                <Col span={24}>
                  <p className="mb-0 modal-subtext">
                    Edit the stores details
                  </p>
                </Col>
                <Col className="imgBoxBlock">
                  <div className="imgBox" >
                    <img height='100px' width="100px" style={{ borderRadius: 50 }} src={selectedStoreData?.attributes?.avatar ? selectedStoreData?.attributes?.avatar.url : "https://www.24-seven.in/img/logo/X-mas-Logo.png"} />
                    <Upload
                      customRequest={(e) => { this.uploadStoreProfilePicture(e) }}
                      showUploadList={false}
                    >
                      <Button color="transparent" className="edit_btn" ><EditOutlined translate={{}} /></Button>
                    </Upload>
                  </div>
                </Col>
              </Row>

              <Form
                onFinish={this.handelEditStoreData}
                // onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
                name="content-ref"
                autoComplete="false"
                layout="vertical"

              >
                <Spin
                  spinning={modaLoader}
                  indicator={antIcon}
                >
                  <Row className="modal-rows fieldBox">
                    <Col className="rowCol" span={6}>
                      <Form.Item name="storeId" label="Store Id" >
                        <Input type="text" placeholder="store Id" />
                      </Form.Item>
                      <Form.Item name="id" noStyle>
                        <Input type="hidden" placeholder="store Id" readOnly />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={6}>
                      <Form.Item name="store_name" label="Store name"  >
                        <Input type="text" placeholder="Enter store name" />
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row className="modal-rows fieldBox">
                    <Col className="rowCol" span={6}>
                      <Form.Item name="address" label="Address" >
                        <Input type="text" placeholder="Enter address" />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={6}>
                      <Form.Item name="phone_no" label="Phone number"  >
                        <Input type="text" placeholder="Enter Phone Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows fieldBox">
                    <Col className="rowCol" span={6}>
                      <Form.Item name="manager" label="Store manager" >
                        <Input type="text" placeholder="Enter manager name" />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={6}>
                      <Form.Item name="username" label="Username" >
                        <Input type="text" placeholder="Enter username" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows fieldBox">
                    <Col className="rowCol" span={6}>
                      <Form.Item name="password" label="Password" >
                        <Input type="text" placeholder="Enter password" />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={6}>
                      <Row className="modal-rows fieldBox selectdropBox">
                        <Col span={6}>
                          <Form.Item name="zone_id" label="Zone Id" rules={[{ required: true, message: "Zone id is required" }]}>
                            {/* <Select
                          className="modal-role-text"
                          placeholder="All Stores"
                        /> */}
                            <Select placeholder="Zone Id" className="modal-role-text" onChange={(value) => {
                              this.editSelectedZoneId(value)
                            }}>
                              {this.state.zoneIds.map((option: any) => (
                                //@ts-ignore
                                <Option value={option.id}>{option.zone_name}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name="ofc_id" label="Ofc Id" rules={[{ required: true, message: "Ofc id is required" }]}>{/**  rules={[{ required: true }]} */}

                            <Select placeholder="Ofc Id" className="modal-role-text">
                              {
                                this.state.ofcIds.map((option: any) => (
                                  //@ts-ignore
                                  <Option value={option.id}>{option.clusture_name}</Option>
                                ))}
                            </Select>
                            {/* <Input className="modal-role-text" placeholder="#DUWJ7R" /> */}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Collapse ghost>
                    <Collapse.Panel header="Outlet Id's" key="1">
                      <div className="forms-Collapse-item">
                        <Row className="">
                          <Col className="rowCol" span={6}>
                            <label>Phygital :- </label>
                          </Col>
                          <Col className="rowCol" span={18}>
                            <Form.Item
                              name="phygital_store_id"
                            >
                              <Input
                                type="text"
                                name="phygitalOutletId"
                                autoComplete="newpassword"
                                placeholder="Enter Phygital Outlet Id" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>

                      <div className="forms-Collapse-item">
                        <Row className="">
                          <Col className="rowCol" span={6}>
                            <label>Dunzo :- </label>
                          </Col>
                          <Col className="rowCol" span={18}>
                            <Form.Item
                              name="dunzo_store_id"
                            >
                              <Input
                                type="text"
                                name="dunzoOutletId"
                                autoComplete="off"
                                placeholder="Enter Dunzo Outlet Id" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>

                      <div className="forms-Collapse-item">
                        <Row className="">
                          <Col className="rowCol" span={6}>
                            <label>Zomato :- </label>
                          </Col>
                          <Col className="rowCol" span={18}>
                            <Form.Item
                              name="zomato_store_id"
                            >
                              <Input
                                type="text"
                                name="zomatoOutletId"
                                autoComplete="off"
                                placeholder="Enter Zomato Outlet Id" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Collapse.Panel>

                  </Collapse>

                  <Row className="saveBtnbox">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn saveBtn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>

              </Form>
            </Row>
          </Modal>
          {/* ADD OFC MODAL */}
          <Modal
            className="addstoreModalBox"
            visible={addOfcModal}
            title={null}
            centered
            width="45vw"
            onCancel={() => {
              this.setState({ addOfcModal: false })
              this.onReset();
              // this.componentDidMount();
            }}
            closeIcon={
              <CloseCircleFilled
                style={{ color: "#000", fontSize: "20px" }}
                translate={{}}
              />
            }
            footer={null}
          >
            <div className="AddStoreTimingsSection">
              <Row className="header">
                <Col span={24}>
                  <h1 className="mb-0 modal-heading">Add Ofc</h1>
                </Col>
                <Col span={24}>
                  <p className="mb-0 modal-subtext">Add Ofc store Details</p>
                </Col>
              </Row>
              {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
              <Form
                onFinish={this.handelAddOfcData}
                onFinishFailed={this.onFinishFailed}
                initialValues={{ zone_id: this.state?.zoneIds[0]?.id }}
                ref={this.formRef}
                name="content-ref"
                autoComplete="off"
                layout="vertical"
              >
                <div className="modal-rows fieldBox">
                  <div className="rowCol">
                    <Form.Item label="Zone Id" name="zone_id" rules={[{ required: true, message: "Select ZoneId" }]}>

                      <Select className="modal-role-text">
                        {this.state.zoneIds.map((option: any) => (
                          //@ts-ignore
                          <Option value={option.id}>{option.zone_name}</Option>
                        ))}
                      </Select>
                      {/* <Input className="modal-role-text" placeholder="#DUWJ7R" /> */}
                    </Form.Item>
                  </div>
                </div>
                <div className="modal-rows fieldBox">
                  <div className="rowCol">
                    <Form.Item label="Ofc Id" name="ofc_id" rules={[{ required: true, message: "Select OfcId" }]}>
                      <Input type="text" placeholder="Ofc Id" />
                    </Form.Item>
                  </div>
                </div>
                <div className="modal-rows fieldBox">
                  <div className="rowCol">
                    <Form.Item label="Ofc name" name="ofc_name" rules={[{ required: true, message: "Enter Ofc name" }]}>
                      <Input type="text" placeholder="Ofc name" />
                    </Form.Item>
                  </div>
                </div>
                <div className="modal-rows fieldBox">
                  <div className="rowCol">
                    <Form.Item label="Ofc description" name="ofc_description" rules={[{ required: true, message: "Enter Ofc description" }]}>
                      <Input type="text" placeholder="Ofc Description" />
                    </Form.Item>
                  </div>

                </div>
                <Row className="saveBtnbox">
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="content-add-btn saveBtn"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>

          {/* EDIT OFC MODAL */}
          <Modal
            className="addstoreModalBox"
            visible={editOfcModal}
            title={null}
            centered
            width="45vw"
            onCancel={() => {
              this.setState({ editOfcModal: false })
              this.onReset();
              // this.componentDidMount();
            }}
            closeIcon={
              <CloseCircleFilled
                style={{ color: "#000", fontSize: "20px" }}
                translate={{}}
              />
            }
            footer={null}
          >
            <div className="AddStoreTimingsSection">
              <Row className="header">
                <Col span={24}>
                  <h1 className="mb-0 modal-heading">Edit Ofc</h1>
                </Col>
                <Col span={24}>
                  <p className="mb-0 modal-subtext">Edit Ofc store Details</p>
                </Col>
              </Row>
              {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
              <Form
                onFinish={this.handelEditOfcData}
                onFinishFailed={this.onFinishFailed}
                initialValues={{ zone_id: this.state?.zoneIds[0]?.id }}
                ref={this.formRef}
                name="content-ref"
                autoComplete="off"
                layout="vertical"
              >
                <Spin
                  spinning={modaLoader}
                  indicator={antIcon}
                >
                  <div className="modal-rows fieldBox">
                    <div className="rowCol">
                      <Form.Item label="Zone Id" name="zone_id" rules={[{ required: true, message: "Select zoneId" }]}>

                        <Select className="modal-role-text">
                          {this.state.zoneIds.map((option: any) => (
                            //@ts-ignore
                            <Option value={option.id}>{option.zone_name}</Option>
                          ))}
                        </Select>
                        {/* <Input className="modal-role-text" placeholder="#DUWJ7R" /> */}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="modal-rows fieldBox">
                    <div className="rowCol">
                      <Form.Item label="Ofc Id" name="ofc_unnique_number">
                        <Input type="text" placeholder="Ofc Id" readOnly />
                      </Form.Item>
                      <Form.Item name="ofc_id" noStyle>
                        <Input type="hidden" placeholder="Ofc Id" readOnly />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="modal-rows fieldBox">
                    <div className="rowCol">
                      <Form.Item label="Ofc name" name="ofc_name" rules={[{ required: true, message: "Enter Ofc name" }]}>
                        <Input type="text" placeholder="Ofc name" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="modal-rows fieldBox">
                    <div className="rowCol">
                      <Form.Item label="Ofc description" name="ofc_description" rules={[{ required: true, message: "Enter ofc description" }]}>
                        <Input type="text" placeholder="Ofc Description" />
                      </Form.Item>
                    </div>

                  </div>
                  <Row className="saveBtnbox">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn saveBtn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>
              </Form>
            </div>
          </Modal>

          {/* ADD ZONE MODAL */}
          <Modal
            className="addstoreModalBox"
            visible={addZoneModal}
            title={null}
            centered
            width="45vw"
            onCancel={() => {
              this.setState({ addZoneModal: false });
              this.onReset();
              // this.componentDidMount();
            }}
            closeIcon={
              <CloseCircleFilled
                style={{ color: "#000", fontSize: "20px" }}
                translate={{}}
              />
            }
            footer={null}
          >
            <div className="AddStoreTimingsSection">
              <Row className="header">
                <Col span={24}>
                  <h1 className="mb-0 modal-heading">Add Zone</h1>
                </Col>
                <Col span={24}>
                  <p className="mb-0 modal-subtext">Add Zone Details</p>
                </Col>
              </Row>
              {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
              <Form
                onFinish={this.handelAddZoneData}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
                name="content-ref"
                autoComplete="off"
                layout="vertical"

              >
                <div className="modal-rows fieldBox">
                  <div className="rowCol">
                    <Form.Item label="Zone Id" name="zone_unique_id" rules={[{ required: true, message: "Enter ZoneId" }]}>
                      <Input type="text" placeholder="Zone Id" />
                    </Form.Item>
                  </div>
                </div>
                <div className="modal-rows fieldBox">
                  <div className="rowCol">
                    <Form.Item label="Zone name" name="zone_name" rules={[{ required: true, message: "Enter Zone name" }]}>
                      <Input type="text" placeholder="Zone name" />
                    </Form.Item>
                  </div>
                </div>
                <div className="modal-rows fieldBox">
                  <div className="rowCol">
                    <Form.Item label="Zone description" name="zone_description" rules={[{ required: true, message: "Enter Zone description" }]}>
                      <Input type="text" placeholder="Zone Description" />
                    </Form.Item>
                  </div>

                </div>
                <Row className="saveBtnbox">
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="content-add-btn saveBtn"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>

          {/* EDIT ZONE MODAL */}
          <Modal
            className="addstoreModalBox"
            visible={editZoneModal}
            title={null}
            centered
            width="45vw"
            onCancel={() => {
              this.setState({ editZoneModal: false })
              this.onReset();
            }
            }
            closeIcon={
              <CloseCircleFilled
                style={{ color: "#000", fontSize: "20px" }}
                translate={{}}
              />
            }
            footer={null}
          >
            <div className="AddStoreTimingsSection">
              <Row className="header">
                <Col span={24}>
                  <h1 className="mb-0 modal-heading">Edit Zone</h1>
                </Col>
                <Col span={24}>
                  <p className="mb-0 modal-subtext">Edit Zone Details</p>
                </Col>
              </Row>
              {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
              <Form
                onFinish={this.handelEditZoneData}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
                name="content-ref"
                autoComplete="off"
                layout="vertical"

              >
                <Spin
                  spinning={modaLoader}
                  indicator={antIcon}
                >
                  <div className="modal-rows fieldBox">
                    <div className="rowCol">
                      <Form.Item label="Zone Id" name="zone_unique_id">
                        <Input type="text" placeholder="Zone Id" readOnly />
                      </Form.Item>
                    </div>

                    <Form.Item name="zone_id" noStyle>
                      <Input type="hidden" placeholder="Zone Id" readOnly />
                    </Form.Item>

                  </div>
                  <div className="modal-rows fieldBox">
                    <div className="rowCol">
                      <Form.Item label="Zone name" name="zone_name">
                        <Input type="text" placeholder="Zone name" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="modal-rows fieldBox">
                    <div className="rowCol">
                      <Form.Item label="Zone description" name="zone_description">
                        <Input type="text" placeholder="Zone Description" />
                      </Form.Item>
                    </div>

                  </div>
                  <Row className="saveBtnbox">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn saveBtn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>
              </Form>
            </div>
          </Modal>




          <div className="StoreTimingsHeaderSection store-heading">
            <h1 className="store-search" >Store Management</h1>
            <div className="StoreTimingsActions store-heading">
              <div className="searchBox store-search">
                {this.state.selectedTab == 1 ? <Input placeholder="Search" onChange={(e) => { this.searchStore(e) }} /> : ''}
                {this.state.selectedTab == 2 ? <Input placeholder="Search" onChange={(e) => { this.searchOfc(e) }} /> : ''}
                {this.state.selectedTab == 3 ? <Input placeholder="Search" onChange={(e) => { this.searchZone(e) }} /> : ''}

              </div>


              <Upload
                className="importBtn store-col"
                accept={".csv,.xls,.xlsx"}
                customRequest={(e) => { this.uploadCsv(e) }}
                showUploadList={false}>
                <Button icon={<UploadOutlined translate />}>Import</Button>
              </Upload>

              <Button className="Btn store-col">
                <DownloadOutlined translate={''} style={{ marginRight: 7 }} />
                {this.state.selectedTab == 1 ? <a href={`${baseURL}/account_block/accounts/store_accounts/csv_export.csv?token=${localStorage.getItem('token')}&search=${txtInputValue}`} >Export </a> : null}
                {this.state.selectedTab == 2 ? <a href={`${baseURL}/account_block/accounts/ofc/csv_export.csv?token=${localStorage.getItem('token')}&search=${txtInputValue}`} >Export </a> : null}
                {this.state.selectedTab == 3 ? <a href={`${baseURL}/account_block/accounts/zone/csv_export.csv?token=${localStorage.getItem('token')}&search=${txtInputValue} `} >Export </a> : null}
              </Button>


              {this.state.selectedTab == 1 && (permissions ? permissions.create_action : false) ? <Button className="Btn addstoreBtn store-col" onClick={() => this.setState({ addModal: true })}>ADD STORE</Button> : ''}
              {this.state.selectedTab == 2 && (permissions ? permissions.create_action : false) ? <Button className="Btn addstoreBtn store-col" onClick={() => this.setState({ addOfcModal: true })}>ADD OFC</Button> : ''}
              {this.state.selectedTab == 3 && (permissions ? permissions.create_action : false) ? <Button className="Btn addstoreBtn store-col" onClick={() => this.setState({ addZoneModal: true })}>ADD ZONE</Button> : ''}



            </div>
          </div>
          <div className="sample-file">
            <Popover
              placement="bottom"
              content={
                <div>
                  <p>
                    {this.state.selectedTab == 1 ? <a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=store`}>Download</a>
                      : null}
                    {this.state.selectedTab == 2 ? <a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=ofc`}>Download</a>
                      : null}
                    {this.state.selectedTab == 3 ? <a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=zone`}>Download</a>
                      : null}

                  </p>
                </div>
              }
              title="Download Sample File"
            >
              <DownCircleFilled translate={{}} /> &nbsp;Sample file

            </Popover>
          </div>

          <div className="storeTimingTableBlock storeTabSection">
            <Tabs defaultActiveKey="1" onChange={this.handelTabChange}>
              <TabPane tab="Store" key="1">
                <Table
                  // rowSelection={rowSelection}
                  title={() => 'Stores'}
                  columns={columns}
                  dataSource={this.state.Storelist}
                  loading={{ spinning: this.state.apiLoading, indicator: antIcon }}
                  scroll={{ x: 'max-content' }}//1500
                  size={'small'}
                  pagination={paginationOptions}

                />
              </TabPane>
              <TabPane tab="OFC" key="2">
                <Table
                  // rowSelection={rowSelection}
                  columns={OfcColumns}
                  dataSource={this.state.OfcList}
                  loading={this.state.apiLoading}
                  scroll={{ x: 'max-content' }}//1500
                  size={'small'}
                // pagination={false}
                />
              </TabPane>
              <TabPane tab="Zone" key="3">
                <Table
                  // rowSelection={rowSelection}
                  columns={zoneColumns}
                  dataSource={this.state.ZoneList}
                  loading={this.state.apiLoading}
                  scroll={{ x: 'max-content' }}//1500
                  size={'small'}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

export default Store;
// Customizable Area End