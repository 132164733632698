import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { message as AntMessage } from "antd";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  editRoleModal: boolean;
  addRoleModal: boolean;
  rolePermissionData: any
  selectedRoleWithPermission: any;
  checkBoxPermissionSelectedValue: any;
  formAttributes: any;
  selectedCheckBoxData: any;
  deletedPermissionArray: any;
  dataLoader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesPermissions2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getRolesAndPermissionInformation: string;
  updatedRolesAndPermissionStatusInformation: string;
  addRoleInformation: string;
  deleteRoleInformation: string;
  editRoleAndPermissionsInformation: string;
  SearchRoleAndPermissionsInformation: string;
  deleteRolePermission: string;


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    // Customizable Area Start
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      editRoleModal: false,
      addRoleModal: false,
      rolePermissionData: [],
      selectedRoleWithPermission: {},
      checkBoxPermissionSelectedValue: [],
      formAttributes: [],
      selectedCheckBoxData: [],
      deletedPermissionArray: [],
      dataLoader: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }


  //========================================== componentDidMount ==========================================================
  async componentDidMount() {
    // Customizable Area Start
    this.getRolesAndPermissions();
    // Customizable Area End
  }
  getRolesAndPermissions = async () => {
    this.setState({ dataLoader: true })
    this.getRolesAndPermissionInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getRolesApiEndPoint,
    });
  };


  updateStatus = async (data: any, id: any) => {
    this.updatedRolesAndPermissionStatusInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.updateStatusRolesApiEndPoint + id,
      body: JSON.stringify(data),

    });
  }

  addRoleData = async (data: any) => {
    this.addRoleInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.AddRolesApiEndPoint,
      body: JSON.stringify(data),

    });
  }

  deleteRoleById = async (roleId: any) => {
    this.deleteRoleInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpDelMethod,
      endPoint: configJSON.DeleteRoleByIdApiEndPoint + roleId,
      // body: JSON.stringify(data),

    });
  }

  editRolesAndPermissions = async (roleId: any, data: any) => {
    this.setState({ dataLoader: true });
    this.editRoleAndPermissionsInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.editRoleByIdApiEndPoint + roleId,
      body: JSON.stringify(data),

    });
  }

  searchRolesWithPermissionDetails = async (searchText: any) => {
    this.setState({ dataLoader: true })
    this.SearchRoleAndPermissionsInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.SearchRoleApiEndPoint + searchText,
    });
  };

  deleteParticularRoleById = async (id: any) => {
    this.deleteRolePermission = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpDelMethod,
      endPoint: configJSON.deleteRolePermissionApiEndPoint + id,
    });
  };
  //=================================================================================================================

  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //========================================= API CALL BLOCK ===================================================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //==========================================  RUN ENGINE BLOCK  =======================================================================
  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("responseJson>>>>>>>", responseJson)
      if (responseJson?.errors && responseJson?.errors[0]?.token || responseJson == undefined) {
        //@ts-ignore
        if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
          localStorage.clear();
          window.location.href = '/';
        };
        if (responseJson == undefined) {
          // AntMessage.error('Something went wrong')
          return
        }
      }
      //============================ SUCCESS BLOCK =============================================
      if (responseJson && responseJson.data || responseJson.message) {
        if (apiRequestCallId === this.getRolesAndPermissionInformation) {
          this._handelGetRolesAndPerMissionSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.updatedRolesAndPermissionStatusInformation) {
          this._handelupdateStatusSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.addRoleInformation) {
          this._handelAddRoleSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.deleteRoleInformation) {
          this._handelDeleteRoleSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.editRoleAndPermissionsInformation) {
          this._handelEditRoleSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.SearchRoleAndPermissionsInformation) {
          this._handelSearchRoleSuccessResponse(responseJson)
        }
        if (apiRequestCallId === this.deleteRolePermission) {
          this._handeldeleteRolePermissionSuccessResponse(responseJson)
        }





        //============================ ERROR BLOCK ==============================================
      } else if (responseJson && responseJson.error || responseJson.errors) {

        if (apiRequestCallId === this.getRolesAndPermissionInformation) {
          if (responseJson.errors) {
            responseJson.errors.map((key: any, index: any) => {
              if (Object.keys(key)[0] == 'token') { AntMessage.error('Session Expired Please Login Again!') }
            })
          }
          this._handelGetRolesAndPerMissionErrorResponse(responseJson)
        }
        if (apiRequestCallId === this.updatedRolesAndPermissionStatusInformation) {
          this._handelupdateStatusErrorResponse(responseJson)
        }
        if (apiRequestCallId === this.getRolesAndPermissionInformation) {
          this._handelGetRolesAndPerMissionErrorResponse(responseJson)
        }
        if (apiRequestCallId === this.addRoleInformation) {
          this._handelAddRoleErrorResponse(responseJson)
        }
        if (apiRequestCallId === this.deleteRoleInformation) {
          this._handelDeleteRoleErrorResponse(responseJson)
        }
        if (apiRequestCallId === this.editRoleAndPermissionsInformation) {
          this._handelEditRoleRoleErrorResponse(responseJson)
        }
        if (apiRequestCallId === this.deleteRolePermission) {
          this._handeldeleteRolePermissionErrorResponse(responseJson)
        }



      } else if (errorReponse) {

      }
    }
  }



  //=================================================================================================================
  // _handelGetRolesAndPerMissionSuccessResponse
  _handelGetRolesAndPerMissionSuccessResponse = async (response: any) => {
    let rolesAndPermissiontDataTobeDisplayed: any = [];
    let modules: any = [];
    response.data.map((d: any) => {
      modules = [];
      // loop through permissions
      d.attributes.permissions.data.map((s: any) => {
        if (d.attributes.permissions.data.length > 0) {
          let moduleObject = {}
          moduleObject = {
            name: s.attributes.access_module.name,
            id: s.attributes.id,
            accessPermission: {
              create: s.attributes.create_action,
              update: s.attributes.update_action,
              delete: s.attributes.delete_action,
              read: s.attributes.read_action,
            }
          }
          modules.push(moduleObject)
        }

      })

      let object = {
        name: d.attributes.name,
        status: d.attributes.status == "active" ? true : false,
        id: d.attributes.id,
        modules: modules
      };

      rolesAndPermissiontDataTobeDisplayed.push(object);
    })
    this.setState({ rolePermissionData: rolesAndPermissiontDataTobeDisplayed, dataLoader: false });
  }
  //=================================================================================================================
  // _handelupdateStatusSuccessResponse
  _handelupdateStatusSuccessResponse = async (response: any) => {

    AntMessage.success(response.meta.message)

  }
  //=================================================================================================================
  // _handelAddRoleSuccessResponse
  _handelAddRoleSuccessResponse = async (response: any) => {

    AntMessage.success(response.meta.message)
    this.setState({ addRoleModal: false });
    this.componentDidMount()

  }
  //=================================================================================================================
  // _handelDeleteRoleSuccessResponse
  _handelDeleteRoleSuccessResponse = async (response: any) => {
    AntMessage.success(response.message)
    this.getRolesAndPermissions();
  }
  //=================================================================================================================
  // _handelEditRoleSuccessResponse
  _handelEditRoleSuccessResponse = async (response: any) => {
    if (response.data) {
      AntMessage.success(`Roles Updated successfully `)
      this.setState({ editRoleModal: false, dataLoader: false });
      this.getRolesAndPermissions();
    }
  }
  //=================================================================================================================
  // SearchRoleAndPermissionsInformation
  _handelSearchRoleSuccessResponse = async (response: any) => {
    if (response.data) {
      this._handelGetRolesAndPerMissionSuccessResponse(response)
    }
  }
  //=================================================================================================================
  // _handeldeleteRolePermissionSuccessResponse
  _handeldeleteRolePermissionSuccessResponse = async (response: any) => {
    if (response.message) {
      AntMessage.success(response.message)

    }
  }
  //=================================================================================================================
  //=============================================== ==================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================


  //==================================== HANDEL ERROR BLOCK==========================================================
  // _handelGetRolesAndPerMissionErrorResponse
  _handelGetRolesAndPerMissionErrorResponse = async (data: any) => {

  }
  //=================================================================================================================
  // _handelGetRolesAndPerMissionErrorResponse
  _handelupdateStatusErrorResponse = async (data: any) => {

  }
  //=================================================================================================================
  // _handelAddRoleErrorResponse
  _handelAddRoleErrorResponse = async (data: any) => {

  }
  //=================================================================================================================
  // _handelDeleteRoleErrorResponse
  _handelDeleteRoleErrorResponse = async (data: any) => {
    AntMessage.error(`${data.error}`)
    console.log("_handelDeleteRoleErrorResponse", data);

  }
  //=================================================================================================================
  // _handelEditRoleRoleErrorResponse
  _handelEditRoleRoleErrorResponse = async (data: any) => {
    console.log("_handelEditRoleRoleErrorResponse", data);
    AntMessage.error(data.errors[0])

  }
  //=================================================================================================================
  // _handeldeleteRolePermissionErrorResponse
  _handeldeleteRolePermissionErrorResponse = async (response: any) => {
    console.log(response);

  }
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================
  //=================================================================================================================

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End

}
