import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Swal from 'sweetalert2'
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import { message as AntMessage } from "antd";
import { FormInstance } from "antd";
import { createRef } from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  addModal: boolean,
  editModal: boolean,
  employee_id: string
  employee_name: string,
  role: string,
  contact: string,
  username: string,
  password: string,
  ofc_id: string,
  zn_id: string,
  status: string,
  peopleList: [],
  ofcIds: any,
  zoneIds: any,
  StoreIds: any,
  storeUserInfo: any,
  selectedEmployeeData: any,
  selectedzoneId: any,
  selectedOfcId: any,
  selectedStoreId: any,
  Roles: any;
  selectedRoleId: any
  id: string,
  selectedRowKeys: any;
  errors: { email: string };
  email: string;
  employeeProfileImage: string;
  selectselectedProfilePic: string;
  disabledOfc: boolean;
  disabledStore: boolean;
  roleInfo: any;
  officeMandatory: boolean,
  zoneMandatory: boolean,
  storeMandatory: boolean,
  dataLoader: boolean,
  tableDataLoader: boolean,
  pagination: any,
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PeopleManagement2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End
  base_url = `https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/`;
  public token = localStorage.getItem('token')
  public config = {
    headers: {
      'token': this.token || ''//'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NSwiZXhwIjoxNjM3MDQwNjg0fQ.5O46lD5pWKpsslFKx2gKi667-YdKDiKEB937oHoi8-_yHBtuDi2DxrcBUdE3t7VcSalptY73DLHoQtwyZgm0eA'
    }
  }
  getOfcInformation: string;
  getPeopleById: string;
  getZoneInformation: string;
  addPeopleInformation: string;
  editPeopleInformation: string;
  getPeopleInformation: string;
  getStoreInformation: string;
  getSpecificStoreInformation: string;
  getPeopleByIdInformation: string;
  updateEmployeeInformation: string;
  updateEmployeeStatusInformation: string;
  importFileData: string;
  exportFileData: string;
  getAllOfcRealtedToZoneId: string;
  getAllStoresRealtedToOfcId: string;
  getAllRoles: string;
  uploadPeopleCsvFileInformation: string;
  PeopleSearchInformation: string
  uploadProfilePicture: string
  roleInformation: any



  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];



    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      addModal: false,
      editModal: false,
      employee_id: "",
      employee_name: "",
      role: "",
      contact: "",
      username: "",
      password: "",
      ofc_id: "",
      zn_id: "",
      status: "",
      peopleList: [],
      ofcIds: [],
      zoneIds: [],
      StoreIds: [],
      storeUserInfo: {},
      selectedEmployeeData: {},
      selectedzoneId: 1,
      selectedOfcId: 0,
      selectedStoreId: 0,
      Roles: [],
      selectedRoleId: 0,
      id: "",
      selectedRowKeys: [],
      errors: { email: "" },
      email: "",
      employeeProfileImage: '',
      selectselectedProfilePic: "",
      disabledOfc: false,
      disabledStore: false,
      roleInfo: {},
      officeMandatory: false,
      zoneMandatory: false,
      storeMandatory: false,
      dataLoader: false,
      tableDataLoader: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        Totalcount: 0,
        TotalPeoplescount: 0
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  formRef = createRef<FormInstance>();


  toast(msg: any) {
    Swal.fire({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      icon: 'success',
      timerProgressBar: false,
      timer: 4000,
      title: msg
    })
  }
  errorToast(msg: any) {
    Swal.fire({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      icon: 'error',
      timerProgressBar: false,
      timer: 3000,
      title: msg
    })
  }

  /**************************************API CALL */
  async componentDidMount() {
    // Customizable Area Start
    // this.ongetStore();
    // this.ongetOfc();
    this.ongetZone();
    // this.ongetPeople();
    this.onGetPeoplesByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize);
    this.getRoles();
    // this.ongetStores();
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area End
  }
  getRoles = async () => {
    this.getAllRoles = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllRoles,
    });
  }
  ongetStores = async () => {
    this.getStoreInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getStoreApiEndPoint,
    });
  };

  ongetPeople = async () => {
    this.setState({ tableDataLoader: true })
    this.getPeopleInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getPeopleApiEndPoint,
    });
  };

  onGetPeoplesByPagination = async (page: any, pageSize: any) => {
    this.setState({ tableDataLoader: true })
    this.getPeopleInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getPeopleApiEndPoint + `?page=${page}&per_page=${pageSize}`,
    });
  };
  ongetStore = async () => {
    this.getStoreInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getStoreApiEndPoint,
    });
  };

  onStoreInfo = async (id: number) => {
    this.getSpecificStoreInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getStoreInfoByStoreId + id
    })
  }
  ongetOfc = async () => {
    this.getOfcInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getOfcApiEndPoint,
    });
  };
  ongetZone = async () => {
    this.getZoneInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getZoneApiEndPoint,
    });
  };
  getPeopleId = async (id: number) => {
    this.getPeopleById = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getPeopleInformationById}${id}`,
    });
  }
  addPeopleData = async (data: any, username: string, password: string, user_email: string) => {
    console.log("add People Data email=>>>>>>>>>>>>>>>>>>:", user_email);
    if (this.state.selectselectedProfilePic !== "") {
      let add_emp_data = new FormData();
      add_emp_data.append("data[avatar]", this.state.selectselectedProfilePic);
      add_emp_data.append("data[full_name]", data.full_name);
      add_emp_data.append("data[user_name]", data.username);
      add_emp_data.append("data[password]", data.password);
      add_emp_data.append("data[full_phone_number]", data.full_phone_number);
      add_emp_data.append("data[email]", data.user_email);
      add_emp_data.append("data[employee_id]", data.employee_id);
      add_emp_data.append("data[zone_id]", data.zone_id);
      add_emp_data.append("data[ofc_id]", data.ofc_id);
      add_emp_data.append("data[store_account_id]", data.store_id);
      add_emp_data.append("data[role_id]", data.role_id);
      console.log('Calling add emplyee API', add_emp_data)
      this.addPeopleInformation = await this.apiCall({
        contentType: undefined,//configJSON.ApiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.addPeopleApiEndPoint,
        body: add_emp_data
      });

    } else {
      let add_emp_data = {
        data: {
          full_name: data.full_name,
          user_name: username,
          email: user_email,
          password: password,
          full_phone_number: data.full_phone_number,
          employee_id: data.employee_id,
          zone_id: data.zone_id,
          ofc_id: data.ofc_id,
          store_account_id: data.store_id,
          role_id: data.role_id,
        }
      }
      console.log('Calling add emplyee API', add_emp_data)
      this.addPeopleInformation = await this.apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.addPeopleApiEndPoint,
        body: JSON.stringify(add_emp_data),
      });
    }


  }


  // editStoreData = async (data: any) => { // not used
  //   this.editPeopleInformation = await this.apiCall({
  //       contentType: configJSON.ApiContentType,
  //       method: configJSON.httpPatchMethod,
  //       endPoint: configJSON.addPeopleApiEndPoint,
  //       body: JSON.stringify(data),
  //   });
  // }

  handelGetEmployeeDataByID = async (id: any) => {
    this.getPeopleByIdInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getPeopleByIdApiEndPoint + id
    });
  }


  updateEmployeeData = async (data: any, id: any) => {
    this.updateEmployeeStatusInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.updateEmployeeApiEndPoint + id,
      body: JSON.stringify(data),
    });
    this.setState({ editModal: false })
  }

  getAllOfcsRealtedtoZoneId = async (id: any) => {
    this.setState({ selectedzoneId: id });
    this.getAllOfcRealtedToZoneId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllOfcByZoneIdApiEndPoint + id,
      // body: JSON.stringify(data),

    });
  }
  getAllStoreRealtedtoOfcId = async (id: any) => {
    this.getAllStoresRealtedToOfcId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllStoreByOfcIdApiEndPoint + this.state.selectedzoneId + '&ofc_id=' + id,
      // body: JSON.stringify(data),

    });
  }

  uploadPeopleCsvFile = async (formData: any) => {
    this.uploadPeopleCsvFileInformation = await this.apiCall({
      contentType: undefined,//`multipart/form-data;boundary="boundary"`,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.PeopleImportCsvApiEndPoint,
      body: formData,

    });
  }
  searchPeoplesDetails = async (searchedText: any) => {
    this.PeopleSearchInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.PeopleSearchApiEndPoint + searchedText,
    });
  }

  updatePeopleStatus = async (data: any, id: any) => {
    this.updateEmployeeInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.PeopleStatusUpdatedApiEndPoint + id,
      body: JSON.stringify(data),
    });
  }

  uploadProfilePictureFile = async (data: any, id: any) => {
    this.uploadProfilePicture = await this.apiCall({
      contentType: undefined,//configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.uploadProfilePictureApiEndPoint + id,
      // body: JSON.stringify(data),
      body: data,
    });
  }

  getRoleInformation = async (id: any) => {
    this.roleInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getRoleInformation + id
    })
  }
  //============================= API CALL BLOCK ==========================================================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  //========================================= RECEIVE API CALL BLOCK ===================================================

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.errors && responseJson?.errors[0]?.token || responseJson == undefined) {
        //@ts-ignore
        if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
          localStorage.clear();
          window.location.href = '/';
        };
      }

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.getOfcInformation) {
          this.verifyGetOfcSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getZoneInformation) {
          this.verifyGetZoneSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getPeopleInformation) {
          this.verifyGetPeopleSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.addPeopleInformation) {
          this.verifyaddPeopleSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.editPeopleInformation) {
          this.verifyeditPeopleSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getStoreInformation) {
          this.verifyGetStoreSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getSpecificStoreInformation) {
          this.verifyGetStoreUserSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getPeopleByIdInformation) {
          this.verifyGetPeopleByIdSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.updateEmployeeInformation) {
          this.verifyUpdatePeopleSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.getAllOfcRealtedToZoneId) {
          this._handelgetAllOfcRealtedToZoneSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.getAllStoresRealtedToOfcId) {
          this._handelgetAllZoneRealtedToOfcSuccessResponse(responseJson);
        }
        if (apiRequestCallId == this.getAllRoles) {
          this.handlegetAllRoles(responseJson)
        }
        if (apiRequestCallId === this.uploadPeopleCsvFileInformation) {
          this._handelImportStoreCsvSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.PeopleSearchInformation) {
          this.verifyGetPeopleSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.updateEmployeeStatusInformation) {
          this.verifyUpdatePeopleStatusSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.uploadProfilePicture) {
          this.uploadProfileSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.roleInformation) {
          this.verifyFetchRoleInfo(responseJson);
        }
      } else if (responseJson && responseJson?.error || responseJson?.errors) {
        if (apiRequestCallId === this.getOfcInformation) {
          this.errorGetOfcSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getZoneInformation) {
          this.errorGetZoneSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getPeopleInformation) {

          this.errorGetPeopleSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.addPeopleInformation) {
          this.erroraddPeopleSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.editPeopleInformation) {
          this.erroreditPeopleSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.updateEmployeeInformation) {
          AntMessage.error(responseJson.errors)
        }
        if (apiRequestCallId === this.uploadPeopleCsvFileInformation) {
          this._handelImportInventoryCsvErrorResponse(responseJson);
        }
        if (apiRequestCallId === this.updateEmployeeStatusInformation) {
          this.verifyUpdatePeopleStatusErrorCallBack(responseJson);
        }
        if (apiRequestCallId === this.uploadProfilePicture) {
          this.uploadProfileErrorCallBack(responseJson);
        }
      } else if (errorReponse) {

      }
    }
  }

  /***************************************CallbackSuccss Or Error */
  erroraddPeopleSuccessCallBack = async (responseJson: any) => {
    console.log(responseJson.errors);

    AntMessage.error(responseJson.errors[0])
  }
  verifyGetOfcSuccessCallBack = async (response: any) => {
    // this.setState({ ofcIds: response.data })
  }
  verifyGetZoneSuccessCallBack = async (response: any) => {
    this.setState({ zoneIds: response.data });
    // this.getAllOfcsRealtedtoZoneId(response.data[0].id)
  }
  verifyGetPeopleSuccessCallBack = async (response: any) => {
    let DataTobeDisplayed = response.data.map((sd: any, index: any) => {
      return {
        id: sd.id,
        sno: (index + 1) + ((this.state.pagination.currentPage - 1) * 10),
        employee_id: sd.attributes.employee_id,
        store_id: sd.attributes.store?.storeId ? sd.attributes.store?.storeId : '-',
        employee_name: sd.attributes.full_name,
        role: sd.attributes.role.data ? sd.attributes.role.data.attributes ? sd.attributes.role.data.attributes.name : "-" : "-",
        username: sd.attributes.user_name ? sd.attributes.user_name : '-',
        contact: sd.attributes.full_phone_number ? sd.attributes.full_phone_number : '-',
        password: sd.attributes.password ? sd.attributes.password : '-',
        ofc_id: sd.attributes.ofc ? sd.attributes.ofc.ofc_unnique_number : '-',//sd.attributes.ofc_id,
        zn_id: sd.attributes.zone ? sd.attributes.zone.zone_unique_id : '-',//sd.attributes.zone_id,
        store_status: sd.attributes.status == "active" ? true : false,

      }
    })
    this.setState({ peopleList: DataTobeDisplayed, tableDataLoader: false });
    this.setState({ peopleList: DataTobeDisplayed, tableDataLoader: false, pagination: { ...this.state.pagination, TotalPeoplescount: response?.meta?.total_count ? response?.meta?.total_count : 0 } })

  }

  verifyFetchRoleInfo = async (response: any) => {

    this.setState({
      roleInfo: response
    }, () => {
      this.state.roleInfo
    });

    if (this.state?.roleInfo?.data?.attributes?.name?.toLowerCase() === 'ofc manager') {
      this.setState({ disabledOfc: false, disabledStore: true, officeMandatory: true, zoneMandatory: true, storeMandatory: false })
      this.formRef.current?.setFieldsValue({ ofc_id: "", zone_id: "" })

    } else if (this.state?.roleInfo?.data?.attributes?.name?.toLowerCase() === 'zone manager') {

      this.setState({ disabledOfc: true, disabledStore: true, ofcIds: [], officeMandatory: false, zoneMandatory: true, storeMandatory: false })
      this.formRef.current?.setFieldsValue({ ofc_id: "", zone_id: "" })

    } else if (this.state?.roleInfo?.data?.attributes?.name?.toLowerCase() === 'store manager') {
      this.setState({ storeMandatory: true, officeMandatory: true, zoneMandatory: true, disabledOfc: false, disabledStore: false })
    } else {
      this.setState({ disabledOfc: false, disabledStore: false, officeMandatory: false, zoneMandatory: false, storeMandatory: false })
    }
  }

  verifyaddPeopleSuccessCallBack = async (response: any) => {
    if (response.data) {
      AntMessage.success(response.meta.message);
      this.setState({ addModal: false })
      this.componentDidMount();
    }
  }
  verifyGetStoreUserSuccessCallBack = async (data: any) => {
    let response = data?.data;
    this.setState({ username: response?.user_name, password: response?.password });

    //@ts-ignore
    this.formRef.current.setFieldsValue({
      user_name: response.user_name ? response.user_name : "",
      password: response.password ? response.password : ""
    });
  }
  verifyGetPeopleByIdSuccessCallBack = async (data: any) => {
    let APIResponse = data?.data.attributes;
    this.setState({
      selectedEmployeeData: APIResponse, username: APIResponse.user_name ? APIResponse.user_name : '', password: APIResponse.password ? APIResponse.password : '',
      email: APIResponse.email ? APIResponse.email : '',
      employeeProfileImage: APIResponse.avatar ? APIResponse.avatar.url : "",
      dataLoader: false,
    });

    console.log(APIResponse)

    if (APIResponse?.role?.data?.attributes?.name?.toLowerCase() === 'ofc manager') {
      this.setState({ disabledOfc: false, disabledStore: true, officeMandatory: true, zoneMandatory: true })
      this.formRef.current?.setFieldsValue({ ofc_id: "", zone_id: "" })

    } else if (APIResponse?.role?.data?.attributes?.name?.toLowerCase() === 'zone manager') {

      this.setState({ disabledOfc: true, disabledStore: true, ofcIds: [], officeMandatory: false, zoneMandatory: true })
      this.formRef.current?.setFieldsValue({ ofc_id: "", zone_id: "" })

    } else if (APIResponse?.role?.data?.attributes?.name?.toLowerCase() === 'store manager') {
      this.setState({ storeMandatory: true, officeMandatory: false, zoneMandatory: false })
    } else {
      this.setState({ disabledOfc: false, disabledStore: false, officeMandatory: false, zoneMandatory: false, storeMandatory: false })
    }

    let _data = {
      id: data.data.id,
      employee_id: APIResponse.employee_id,
      full_name: APIResponse.full_name ? APIResponse.full_name : '',
      // full_name:APIResponse.full_name?APIResponse.full_name:'',
      role_id: APIResponse.role.data ? parseInt(APIResponse.role.data.id) : '',
      // email:APIResponse.email?APIResponse.email:'',
      store_id: APIResponse.store_account_id ? APIResponse.store_account_id : '',
      user_name: APIResponse.user_name ? APIResponse.user_name : '',
      password: APIResponse.password ? APIResponse.password : '',
      full_phone_number: APIResponse.full_phone_number,
      ofc_id: APIResponse.ofc_id ? APIResponse.ofc_id : '',
      zone_id: APIResponse.zone_id ? APIResponse.zone_id : '',
    }
    if (APIResponse.zone_id) {
      await this.getAllOfcsRealtedtoZoneId(APIResponse.zone_id);
      await this.getAllStoreRealtedtoOfcId(APIResponse.ofc_id)
    }

    //@ts-ignore
    this.formRef.current.setFieldsValue(_data)
  }

  verifyUpdatePeopleSuccessCallBack = async (response: any) => {
    if (response.data) {
      AntMessage.success(response.meta.message);
      this.setState({ editModal: false });
      this.componentDidMount();
    }

  }
  verifyGetStoreSuccessCallBack = async (response: any) => {
    this.setState({ StoreIds: response.data });

  }

  verifyeditPeopleSuccessCallBack = async (response: any) => {
    this.setState({ StoreIds: response.data });

  }
  _handelgetAllOfcRealtedToZoneSuccessResponse = async (data: any) => {

    this.setState({ ofcIds: data.data })
    //         if(data.data.length>0)
    // {
    //   // this.getAllStoreRealtedtoOfcId(data.data[0].id)
    // }

    //@ts-ignore
    // this.formRef.current?.setFieldsValue({ ofc_id:data.data})


  }
  _handelgetAllZoneRealtedToOfcSuccessResponse = async (data: any) => {

    this.setState({ StoreIds: data.data })
  }
  handlegetAllRoles = async (data: any) => {
    this.setState({
      Roles: data.data
    })

  }
  _handelImportStoreCsvSuccessResponse = async (data: any) => {
    if (data.data) {
      AntMessage.success('File Imported Successfully ');
      this.componentDidMount();
    }

  }
  verifyUpdatePeopleStatusSuccessCallBack = async (data: any) => {
    if (data.data) {
      AntMessage.success('Status updated Successfully ');
      this.setState({ editModal: false })
      this.onGetPeoplesByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize)
      // this.componentDidMount();
    }
  }

  uploadProfileSuccessCallBack = async (data: any) => {
    this.setState({ employeeProfileImage: data.data.attributes.avatar.url })
    AntMessage.success('Image Uploaded successfully');

  }

  /********************Error Callback ****************************************8*/

  uploadProfileErrorCallBack = async (response: any) => {
    AntMessage.error('Something went wrong')
  }

  errorGetOfcSuccessCallBack = async (response: any) => {

  }
  errorGetZoneSuccessCallBack = async (response: any) => {

  }
  errorGetPeopleSuccessCallBack = async (response: any) => {

  }
  // erroraddPeopleSuccessCallBack= async (response: any) => {
  //   AntMessage.error('Something Went Wrong');

  // }
  erroreditPeopleSuccessCallBack = async (response: any) => {

  }

  _handelImportInventoryCsvErrorResponse = async (data: any) => {
    console.log(data);
  }


  verifyUpdatePeopleStatusErrorCallBack = async (data: any) => {
    AntMessage.error(data.errors[0]);

  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End

}
