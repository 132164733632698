import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

import { message as AntMessage } from "antd";
import { FormInstance } from "antd/es/form";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  error: any;
  profileData: any
  profileImage: string;
  loading: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changePasswordInformation: string;
  getProfileIndormation: string;
  editProfileData: string;
  UpdateProfileImage: string;
  getNotifications: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      error: "",
      profileData: {},
      profileImage: "",
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  //=========================================================================================================================
  async componentDidMount() {
    // Customizable Area Start
    this.getProfileData();
    // Customizable Area End
  }

  async componentDidUpdate() {
    //@ts-ignore
    if (this.props.modalType == 'Profile') {
      //@ts-ignore
      this.formRef.current?.setFieldsValue(this.state.profileData)
    }
  }

  /**
   * @method getProfileData
   */
  getProfileData = async () => {
    this.setState({ loading: true });
    let id = localStorage.getItem('userId');
    this.getProfileIndormation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getProfileDataApiEndPoint + id,
    });
  };


  // changePassword
  changePassword = async (data: any) => {
    let change_password_data = {
      data: {
        current_password: data.current_password,
        new_password: data.new_password
      }
    }
    this.changePasswordInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.changePasswordApiEndPoint,
      body: JSON.stringify(change_password_data),
    });
  }
  //=========================================================================================================================
  // updateProfileData
  updateProfileData = async (data: any) => {
    let edit_profile_data = {
      data: {
        full_name: data.name,
        full_phone_number: data.password,
        zone_id: data.zone_id,
        status: "active",
        ofc_id: data.ofc_id,
        password: data.password,
        store_account_id: data.store_account_id,
      }
    }
    this.editProfileData = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.editProfileDataApiEndPoint + localStorage.getItem('userId'),
      body: JSON.stringify(edit_profile_data),
    });
  }
  //=========================================================================================================================
  uploadProfile = async (data: any) => {
    this.UpdateProfileImage = await this.apiCall({
      contentType: undefined,//configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.editProfileImageApiEndPoint,
      body: data
    });
  }


  //========================================= API CALL BLOCK ===================================================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //=========================================================================================================================
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson?.errors && responseJson?.errors[0]?.token || responseJson == undefined) {
        if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
          localStorage.clear();
          window.location.href = '/';
        };
        if (responseJson == undefined) {
          // AntMessage.error('Something went wrong')
          return
        }
      }
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.changePasswordInformation) {
          this.handelchangePasswordData(responseJson);
        }
        if (apiRequestCallId === this.getProfileIndormation) {
          this.handelProfileData(responseJson);
        }
        if (apiRequestCallId === this.editProfileData) {
          this.handelEditProfileData(responseJson);
        }
        if (apiRequestCallId === this.UpdateProfileImage) {
          this.handelEditProfileImageSuccessCallback(responseJson);
        }

      } else if (responseJson && responseJson.error || responseJson.errors) {
        if (apiRequestCallId === this.changePasswordInformation) {
          this.handelchangePasswordError(responseJson)

        }
        if (apiRequestCallId === this.editProfileData) {
          this.handelEditProfileDataError(responseJson)
        }
        if (apiRequestCallId === this.UpdateProfileImage) {
          this.handelEditProfileImageErrorCallback(responseJson);
        }
        else {
          // AntMessage.error('Something went wrong');
        }
      } else if (errorReponse) {

      }
    }
  }






  //============================================ SUCCESS  RESPONSE =============================================
  //=========================================================================================================================
  //handelchangePasswordData
  handelchangePasswordData = async (data: any) => {
    if (data.data) {
      AntMessage.success(data.meta.message)//antd
      //@ts-ignore
      this.props.handleCloseChangePasswordModal(false)

    }
  }
  //=========================================================================================================================
  //handelProfileData
  handelProfileData = async (data: any) => {
    let preSelectedData = {
      name: data.data.attributes?.full_name !== null ? data.data.attributes.full_name : '',
      password: data.data.attributes?.password !== null ? data.data.attributes.password : '',
      role: data.data.attributes?.role.data !== null ? data.data.attributes.role.data.attributes.name : '',
      phone_number: data.data.attributes?.full_phone_number,
      username: data.data.attributes?.user_name ? data.data.attributes.user_name : '',
      ofc_id: data.data.attributes?.ofc?.ofc_unnique_number !== null ? data.data.attributes?.ofc?.ofc_unnique_number : null,
      zone_id: data.data.attributes?.zone?.zone_unique_id !== null ? data.data.attributes?.zone?.zone_unique_id : null,
      store_account_id: data.attributes?.store_account_id !== null ? data.data.attributes.store_account_id : null,
    }
    this.setState({
      profileData: preSelectedData,
      loading: false,
      profileImage: data.data.attributes.avatar ? data.data.attributes.avatar.url : ""
    });
    //@ts-ignore
    this.props.profileImage(data.data.attributes.avatar ? data.data.attributes.avatar.url : null);

    //@ts-ignore
    // this.formRef.current.setFieldsValue(preSelectedData)

    // console.log("Admin", data.data.attributes?.zone == null && data.data.attributes?.ofc == null && data.data.attributes?.store === null);
    // console.log("Store Manager", data.data.attributes?.zone !== null && data.data.attributes?.ofc !== null && data.data.attributes?.store !== null);
    // console.log("Ofc Manager", data.data.attributes?.zone !== null && data.data.attributes?.ofc !== null && data.data.attributes?.store == null);
    // console.log("Zone Manager", data.data.attributes?.zone !== null && data.data.attributes?.ofc == null && data.data.attributes?.store === null);

  }
  //=========================================================================================================================
  // handelEditProfileData
  handelEditProfileData = async (data: any) => {

    if (data.data) {
      let preSelectedData = {
        name: data.data.attributes?.full_name !== null ? data.data.attributes.full_name : '',
        password: data.data.attributes?.password !== null ? data.data.attributes.password : '',
        role: data.data.attributes?.role.data.attributes.name ? data.data.attributes.role.data.attributes.name : '',
        phone_number: data.data.attributes?.full_phone_number,
        username: data.data.attributes?.user_name ? data.data.attributes.user_name : '',
        ofc_id: data.data.attributes?.ofc_id !== null ? data.data.attributes.ofc_id : '',
        zone_id: data.data.attributes?.zone_id !== null ? data.data.attributes.zone_id : '',
        store_account_id: data.attributes?.store_account_id !== null ? data.data.attributes.store_account_id : '',
      }
      localStorage.setItem('fullName', data.data.attributes.full_name)
      this.setState({ profileData: preSelectedData, loading: false });
      AntMessage.success('Profile Updated Successfully !')//antd

      //@ts-ignore
      this.props.handleprofileBioModal(false)
    }
  }
  //=========================================================================================================================
  handelEditProfileImageSuccessCallback = async (data: any) => {
    if (data.data) {
      this.setState({ profileImage: data.data.attributes.avatar ? data.data.attributes.avatar.url : "", loading: false });
      //@ts-ignore
      this.props.profileImage(data.data.attributes.avatar ? data.data.attributes.avatar.url : null);

      AntMessage.success(data.meta.message)
    }
  }
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //========================================= HANDEL ERRORS =================================================================
  handelchangePasswordError = async (data: any) => {
    if (data) {
      AntMessage.error(data.errors[0].profile)
    }
  }
  //=========================================================================================================================
  handelEditProfileDataError = async (data: any) => {
    AntMessage.error('Profile Not Updated please try again!')//antd
  }
  //=========================================================================================================================
  handelEditProfileImageErrorCallback = async (data: any) => {
    AntMessage.error('Profile Image Not Updated please try again!');
  }
  //=========================================================================================================================
  handelchangePasswordErrorData = async (data: any) => {
    AntMessage.error('Password');
  }
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================
  //=========================================================================================================================













  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End

}
