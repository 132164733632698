Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDelMethod = "DELETE";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.ApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfInventoryManagement10";
exports.labelBodyText = "CfInventoryManagement10 Body";
exports.base_url =
  "https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/";
exports.apiPostMethod = "POST";
exports.btnExampleTitle = "CLICK ME";

exports.getCategoryApiEndPoint = `bx_block_categories/categories?catalogue_id=`;
exports.getSubCategoryApiEndPoint = `bx_block_categories/sub_categories?catalogue_id=`;
exports.getGroupingsApiEndPoint = `bx_block_cfinventorymanagement11/inventories`; //get;
exports.getAllInventoryItemAndArticleCodeApiEndPoint = `/bx_block_cfinventorymanagement11/inventories/article_code`; //get
exports.getAllInventoryApiEndPoint = `bx_block_cfinventorymanagement11/inventories`; //get
exports.getAllGroupingApiEndPoint = `/bx_block_categories/groupings?catalogue_id=`; //get
exports.searchAllGroupingApiEndPoint = `/bx_block_categories/groupings?search=`; //get
exports.addInventoryApiEndPoint = `bx_block_cfinventorymanagement11/inventories`; //post
exports.editInventoryApiEndPoint = `bx_block_cfinventorymanagement11/inventories/`; //${id}`;
exports.getInventoryByIdApiEndPoint = `bx_block_cfinventorymanagement11/inventories/`; //${id}`;

exports.addCategoryApiEndPoint = `bx_block_categories/categories`;
exports.addSubCategoryApiEndPoint = `bx_block_categories/sub_categories`;
exports.addGroupingsApiEndPoint = `bx_block_catalogue/catalogues`;

exports.editCategoryApiEndPoint = `bx_block_categories/categories/`;
exports.editSubCategoryApiEndPoint = `bx_block_categories/sub_categories/`;
exports.editGroupingsApiEndPoint = `bx_block_catalogue/catalogues`;

exports.getCategoryByIdApiEndPoint = `bx_block_categories/categories/`; //${id}`;
exports.getSubCategoryByIdApiEndPoint = `/bx_block_categories/sub_categories/`; //${id}`;
exports.getGroupingsByIdApiEndPoint = `bx_block_catalogue/catalogues_id=`; //${id}`;
exports.InventorySearchApiEndPoint = `bx_block_cfinventorymanagement11/inventories/`;
exports.addGroupingApiEndPoint = `/bx_block_categories/groupings`;

exports.deleteCategorybyId = `bx_block_categories/categories/`;
exports.deleteSubCategorybyId = "bx_block_categories/sub_categories/";
exports.deleteGroupingsbyId = "/bx_block_cfinventorymanagement11/inventories/";
// exports.grouppingImportCsvApiEndPoint = `csv_export_bx_block_categories_groupings.csv`;
exports.grouppingImportCsvApiEndPoint = `bx_block_categories/groupings/csv_import`;

exports.ImportCsvApiEndPoint = `bx_block_categories/sub_categories/csv_import`;
exports.variablePriceApiEndPoint = `/bx_block_cfinventorymanagement11/inventories/variable_price_import`;
exports.getAllItemsForGrouppingsApiEndPoint = `bx_block_cfinventorymanagement11/inventories/inventory_article_code`;
exports.ImportCategoryCsvApiEndPoint = `bx_block_categories/categories/csv_import`;
exports.ImportSubCategoryCsvApiEndPoint = `bx_block_categories/sub_categories/csv_import`;
exports.searchCategoriesApiEndPoint = "bx_block_categories/categories?search=";
exports.createOffersApiEndPoint = "/bx_block_catalogue/offers/";
exports.getOffersApiEndPoint = "/bx_block_catalogue/offers/";
exports.searchInventoryApiEndPoint =
  "/bx_block_cfinventorymanagement11/inventories?search=";
exports.searchSubCategoriesApiEndPoint =
  "bx_block_categories/sub_categories?search=";
exports.searchGrouppingsApiEndPoint = "/bx_block_categories/groupings?search=";
exports.CategoryImportCsvApiEndPoint =
  "bx_block_categories/sub_categories/csv_import";
exports.getAllCatalougeApiEndPoint =
  "/bx_block_catalogue/catalogues?status=true";
exports.searchCatalougeApiEndPoint =
  "/bx_block_catalogue/catalogues?status=true&search=";

// Customizable Area End
