Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.httpPutMethod = "PUT";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Notifications";
exports.labelBodyText = "Notifications Body";
// exports.fetchNotification = `bx_block_notifications/notifications/get_user_notification`;
exports.fetchNotification = `bx_block_notifications/notifications/get_user_notification?page=1&per_page=10`;
exports.readSingleNotification = `bx_block_notifications/notifications`;
exports.unreadAllNotification = `/bx_block_notifications/notifications/mark_read_or_unread_notification_list?is_read=true`;
exports.searchNotification = `bx_block_notifications/notifications?search=`;
exports.FTPImportApiEndpoint = `bx_block_cfinventorymanagement11/inventories/ftp_import`;
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
