import React, { createRef } from "react";
import CfInventoryManagement10Controller, {
  Props,
  configJSON,
} from "./Inventory.web.Controller";
import "antd/dist/antd.css";
import {
  Table,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Row,
  Col,
  Form,
  Typography,
  Space,
  Popconfirm,
  DatePicker,
  TimePicker,
  Radio,
  Card,
  Popover,
  Progress,
  Spin
} from "antd";
import { CloseCircleFilled, DownCircleFilled, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import "./InventoryManagement.css";
import { FormInstance } from "antd/es/form";
import {
  EditFilled,
  DeleteFilled,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { message as AntMessage } from "antd";
import moment from "moment";
import Draggable from 'react-draggable';
//@ts-ignore
import GlobalLoader from "../../../../web/src/GlobalLoader"
//@ts-ignore
import { baseURL } from "../../../../framework/src/config.js"

const { Title, Paragraph, Text, Link } = Typography;

interface IProps { }

/**
 * @author
 * @class @InventoryManagement
 **/

class InventoryManagement extends CfInventoryManagement10Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      txtInputValue: "Enter FTP Location & Press Enter",
      txtSavedValue: "",
      enableField: false,
      selectedRowKeys: [],
      selectedStoreTimingData: {},
      addModal: false,
      days: [],
      repeats: [],
      selectedTab: 1,
      editModal: false,
      addOfcModal: false,
      editOfcModal: false,
      editZoneModal: false,
      addZoneModal: false,
      Storelist: [],
      OfcList: [],
      ZoneList: [],
      ofcIds: [],
      zoneIds: [],
      Errors: {},
      selectedStoreData: {},
      closingStoreTimings: {},
      selectedOfcData: {},
      selectedZoneData: {},
      Inventorylist: [],
      selectedInventoryData: {},
      apiLoading: false,
      InventoryApiLoading: false,
      storeTimingsData: {
        startDate: '',
        EndDate: '',
        StartTime: '',
        EndTime: '',
        Days: [],
        repeats: [],

      },
      selectedCheckBoxData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        TotalInventoryCount: 0,
        TotalStoreCount: 0,
      },
      modaLoader: false,
      selectedProfilePic: false,
      addItemUnavailable: {},
      getItemUnavailable: {},
      FtpModal: false,
      uploadingLoading: false,
      searchValue: '',
      selectedStoreId: null,
    };
    document.title = `Inventory | 24SevenDashboard`

    // Customizable Area End
  }
  timer: any = null
  formRef = createRef<FormInstance>();
  draggleRef = React.createRef();

  selectRow = (record: any) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys });
  };
  onSelectedRowKeysChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  onFinish = async (values: any) => {
    let datatoBeAdded = {
      data: {
        attributes: {
          store_account_id: values.storeId,
          article_code: values.articalCode,
          item_description: values.description,
          net_mrp: values.netmrp,
          gross_mrp: values.gmrp,
          item_name: values.title,
          volume: values.volume,
          weight: values.weight,
          stock_in_qty: values.stock
        }
      }

    }
    this.addInventoryData(datatoBeAdded);

  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  onReset = () => {
    this.formRef.current!.resetFields();
  };
  handelEditInventory = (item: any) => {
    this.handelGetInventoryDataByID(item.inv_id);
  }

  handelEditItemAvalablity = (item: any) => {
    if (!item) { AntMessage.error('Please Select Timing'); return }
    this.setState({ editZoneModal: true, editOfcModal: false })
    setTimeout(() => {
      // console.log(this.formRef);
      // console.log(item.attributes);

      let date: any = [];
      date.push(moment(item?.attributes?.start_date, 'YYYY/MM/DD'));
      date.push(moment(item?.attributes?.end_date, 'YYYY/MM/DD'));
      let time: any = [];
      time.push(moment(item?.attributes?.start_time, ["h:mm a"]).format("YYYY-MM-DD HH:mm"));
      time.push(moment(item?.attributes?.end_time, ["h:mm a"]).format("YYYY-MM-DD HH:mm"));
      // console.log('date', date);
      // console.log('timing', time);
      // console.log('reason', item.attributes.reason);
      this.formRef.current?.setFieldsValue({ id: item.id });
      this.formRef.current?.setFieldsValue({ editDate: date, });
      this.formRef.current?.setFieldsValue({ editTime: [moment(time[0]), moment(time[1])], });
      this.formRef.current?.setFieldsValue({ editReason: item.attributes.reason });
      // let SelecteditemAvaliblityData = {
      //   editDate: date,
      //   editTime: [moment(time[0]), moment(time[1])],
      //   editReason: item.attributes.reason

      // }
      // this.setState({ addItemUnavailable: { ...this.state.addItemUnavailable, SelecteditemAvaliblityData } })
      let itemDateData = {
        start_date: item?.attributes?.start_date,
        end_date: item?.attributes?.end_date
      }
      let itemTimeData = {
        start_time: item?.attributes?.start_time,
        end_time: item?.attributes?.end_time
      }
      this.setState({ addItemUnavailable: { ...this.state.addItemUnavailable, itemDateData, itemTimeData } })

    }, 10)
  }
  handelUpdateEditItemAvailiblity = (item: any) => {
    console.log(item);
    let { itemDateData, itemTimeData } = this.state.addItemUnavailable
    let reqPayload = {
      data: {
        attributes: {
          start_date: itemDateData?.start_date,
          end_date: itemDateData?.end_date,
          start_time: itemTimeData?.start_time,
          end_time: itemTimeData?.end_time,
          reason: item.editReason
        }
      }
    }
    console.log(reqPayload, item.id);
    this.updateItemUnavalablity(reqPayload, item.id)


  }
  //======================== API CALL =====================================


  //get Inventry Data By store ID
  handelGetInventoryDataByID = async (id: any) => {
    this.setState({ modaLoader: true })
    this.getAllInventoryById(id)
  }

  //handelupdateInventoryData
  handelupdateInventoryData = async (data: any) => {
    console.log(this.state?.selectedInventoryData);

    let datatobeUpdated = {
      data: {
        attributes: {
          store_account_id: data.storeId,
          article_code: data.articalCode,
          item_description: data.description,
          item_name: data.itemName,
          net_mrp: data.netmrp,
          gross_mrp: data.gmrp,
          volume: data.volume,
          status: this.state?.selectedInventoryData?.data?.attributes?.status,
          weight: data.weight,
          stock_in_qty: data.stock
        }
      }

    }
    console.log(datatobeUpdated);

    this.editInventoryData(datatobeUpdated, this.state.selectedInventoryData.data.id);
  }

  // uploadInventoryCsv
  uploadInventoryCsv = async (e: any) => {
    let isFileCSVorXLS = await this.beforeInventoryFileUpload(e.file)

    if (isFileCSVorXLS) {
      this.setState({ InventoryApiLoading: true, uploadingLoading: true });
      const formData = new FormData();
      formData.append("file", e.file);
      this.uploadInventoryCsvFile(formData)
    } else {
      AntMessage.error('You can only upload csv/xls file!');

    }
  }



  searchInventory = async (e: any) => {
    clearTimeout(this.timer);
    this.setState({ searchValue: e.target.value, InventoryApiLoading: true });
    this.timer = setTimeout(() => {
      this.searchInventoryDetails(this.state?.searchValue)
    }, 1000);


  }
  handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      clearTimeout(this.timer?._id);
      this.searchInventoryDetails(e.target.value)
    }

  }

  onDateChange = (dates: any, dateStrings: any) => {
    console.log('From: ', dates[0], ', to: ', dates[1]);
    // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    let itemDateData = {
      start_date: dateStrings[0],
      end_date: dateStrings[1]
    }
    this.setState({ addItemUnavailable: { ...this.state.addItemUnavailable, itemDateData } })

  }

  onTimeChange = (time: any, timeString: any) => {
    console.log(time, timeString);
    let itemTimeData = {
      start_time: timeString[0],
      end_time: timeString[1]
    }
    this.setState({ addItemUnavailable: { ...this.state.addItemUnavailable, itemTimeData } })


  }


  handelAddItemAvailiblity = async (values: any) => {
    console.log(values);

    let reqPayload = {
      data: {
        attributes: {
          start_date: this.state.addItemUnavailable?.itemDateData?.start_date,
          end_date: this.state.addItemUnavailable?.itemDateData?.end_date,
          start_time: this.state.addItemUnavailable?.itemTimeData?.start_time,
          end_time: this.state.addItemUnavailable?.itemTimeData?.end_time,
          reason: values.reason
        }
      }
    }

    this.addItemAvailiblity(reqPayload, values.inventoryId)

  };

  handelOpenAddItemAvailiblityModal = async (item: any) => {
    console.log(item);
    let itemDetails = {
      itemId: item.inv_id,
      itemName: item.itemName,
      storeId: item.store_id
    }
    this.setState({ addOfcModal: true, addItemUnavailable: { ...this.state.addItemUnavailable, itemDetails } }, () => {
      this.formRef.current?.setFieldsValue({ inventoryId: item.inv_id })
    });
  }

  handelOpenViewtemAvailiblityModal = async (item: any) => {
    let itemDetails = {
      itemId: item.inv_id,
      itemName: item.itemName,
      storeId: item.store_id
    }
    this.setState({ editOfcModal: true, addItemUnavailable: { ...this.state.addItemUnavailable, itemDetails } });
    this.handelGetInventoryAvailablity(item.inv_id);
  }
  //=====================================================================

  setSelectedStoreId = async (item: any) => {
    let ParsedData = JSON.parse(item)
    // console.log(ParsedData);
    this.formRef.current?.setFieldsValue({ name: ParsedData.store_name })
    this.formRef.current?.setFieldsValue({ storeId: ParsedData.id })

  }

  onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    //@ts-ignore
    const targetRect = this.draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
  }

  handelFTPLocation = async (values: any) => {
    let reqPayload = {
      address: values.Address,
      user_name: values.Username,
      password: values.Password,
      file_path: values.FilePath,
      file_name: values.FileName,
    }
    console.log(values);
    this.updateFTPLocation(reqPayload)

  }
  selectedStoreId(value: any) {
    this.setState({ InventoryApiLoading: true })
    if (value == '0') { this.ongetInventory(1, 10); }
    else {
      this.setState({ selectedStoreId: value })
      this.getInventoryDataWithStoreId(value)
    }
    console.log(value);
  }
  //============================= beforefileUpload =================================
  beforeInventoryFileUpload(file: any) {
    if (file.type == 'text/csv' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return true;
    } else {
      return false
    }
  }

  //=========================== RENDER =============================================
  render() {
    const { addItemUnavailable, selectedInventoryData, editZoneModal } = this.state;
    const antIcon = <SyncOutlined translate={{}} style={{ fontSize: '40px', color: 'black' }} spin />;

    let permissions: any;
    let decodedString = atob(localStorage.getItem('permissions') || '{}');

    //@ts-ignore
    JSON.parse(decodedString).filter((d) => {

      if (d.attributes.access_module.name == "Inventory") {
        permissions = d.attributes
        return
      }
    });

    // console.log(permissions, "permissions");
    let TabObj = {
      title: "",
      dataIndex: "",
      key: "",
      render: (item: any, fullObj: any) => {
        return (
          <div className="editIconBox">
            <Space size="large">

              <span className="icon">
                {/* Admin || Super Admin condition */}
                {
                  localStorage.getItem("role") == 'Admin' || localStorage.getItem("role") == 'Super Admin'
                    ?
                    <EditFilled
                      translate
                      onClick={() => {
                        this.handelEditInventory(item)
                        this.setState({ editModal: true })
                      }
                      }
                    />
                    /* Other Than Admin and Super Admin */
                    :
                    <EditFilled
                      translate
                      onClick={() => {
                        this.handelOpenViewtemAvailiblityModal(item)
                      }
                      }
                    />

                }



              </span>
              <span>EDIT</span>
            </Space>
          </div>
        )
      }
    }
    let AddItemAvailiblityButton = {
      title: "",
      dataIndex: "",
      key: "",
      render: (item: any, fullObj: any) => {
        return (
          <div className="editIconBox">
            <Space size="large">

              <span className="icon">
                {/* Admin || Super Admin condition */}
                <PlusOutlined
                  translate
                  onClick={() => {
                    this.handelOpenAddItemAvailiblityModal(item)
                  }
                  }
                />
              </span>
              <span>Add</span>
            </Space>
          </div>
        )
      }
    }

    const columns = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
        // render: (text: any, record: any, index: any) => index + 1,
        render: (text: any, record: any, index: any) => (this.state?.searchValue == '' ? (this.state?.pagination?.currentPage - 1) * 10 + index + 1 : text)

      },
      {
        title: "Store ID",
        dataIndex: "store_id",
        key: "store_id",
        filters: this.state?.Storelist.length > 0 && localStorage.getItem("role") == ('Admin') ? this.state?.Storelist.map((item: any) => {
          return {
            text: item.store_name,
            value: item.store_name
          }
        }) : false,
        onFilter: (value: string, record: any) => record.store_name.indexOf(value) === 0,
        // sorter: {
        //   compare: (a: any, b: any) => a?.store_id.localeCompare(b?.store_id),
        //   multiple: 1,
        // },
      },
      {
        title: "Store Name",
        dataIndex: "store_name",
        key: "store_name",
      },
      {
        title: "Article Code",
        dataIndex: "articleCode",
        key: "articleCode",
      },
      {
        title: "Item Name",
        dataIndex: "itemName",
        key: "itemName",
      },
      {
        title: "Item Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Net MRP",
        dataIndex: "netMrp",
        key: "netMrp",
        sorter: (a: any, b: any) => a.netMrp - b.netMrp,
      },
      {
        title: "Gross MRP",
        dataIndex: "grossMrp",
        key: "grossMrp",
        sorter: (a: any, b: any) => a.grossMrp - b.grossMrp,

      },
      {
        title: "Volume",
        dataIndex: "volume",
        key: "volume",
        sorter: (a: any, b: any) => a.volume - b.volume,

      },
      {
        title: "Weight",
        dataIndex: "weight",
        key: "weight",
        sorter: (a: any, b: any) => a.weight - b.weight,

      },
      {
        title: "Last synced on",
        dataIndex: "syncedOn",
        key: "syncedOn",
        sorter: (a: any, b: any) => a.syncedOn - b.syncedOn,

      },
      {
        title: "Stock in Qty",
        dataIndex: "stockInQty",
        key: "stockInQty",
        // align: 'center',
        // sorter: (a: any, b: any) => a.stockInQty - b.stockInQty,
        render(text: any, record: any) {
          return (
            <div>
              {
                localStorage.getItem('role') != 'Admin' ?
                  <span
                    title={record.availabile == true ? 'Available' : 'Unavailablity exist'}
                    className={record.availabile == true ? "green-dot" : "red-dot"}></span>
                  : null
              }
              {text}
            </div>
          )

        }
      },
    ];

    permissions != undefined && permissions.update_action ? columns.splice(12, 0, TabObj) : '';
    permissions != undefined && permissions.create_action && localStorage.getItem("role") !== ('Admin' || 'Super Admin') ? columns.splice(12, 0, AddItemAvailiblityButton) : ''


    const { apiLoading, uploadingLoading, selectedRowKeys, addModal, editModal, pagination, editOfcModal, addOfcModal } = this.state;

    // PAGINATION OPTIONS
    const paginationOptions = {
      onChange: (page: any) => {
        this.setState({ pagination: { ...pagination, currentPage: page } })
        this.ongetInventory(page, pagination.pageSize)
      },
      total: this.state?.pagination?.TotalInventoryCount,
      showSizeChanger: false,
      current: pagination.currentPage,
      pageSize: pagination.pageSize
    };





    const props = {
      name: "file",
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      headers: {
        authorization: "authorization-text",
      },
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    return (
      <Row className="Reports-main">
        <Col span={24} className="content-container">
          <div className="InventoryManagementSection">


            {/* ADD INVENTORY MODAL */}
            <Modal
              className="addInventoryModal"
              bodyStyle={{ height: '850px' }}
              visible={addModal}
              title={null}
              centered
              width="45vw"
              onCancel={() => {
                this.onReset();
                this.setState({ addModal: false });
              }
              }
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              <div className="AddInventorySection">
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">Add Inventory</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">Add the inventory details</p>
                  </Col>
                </Row>
                <Form
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  ref={this.formRef}
                  name="content-ref"
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row className="modal-rows fieldBox selectdropBox ">
                    <Form.Item
                      name="storeId"
                    >
                      <Input type="hidden" />
                    </Form.Item>
                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="store"
                        label="Store"
                        rules={[
                          { required: true, message: "Store is required." },
                        ]}
                      >
                        <Select
                          // value={this.state?.Storelist[0]?.id}
                          className="modal-role-text"
                          placeholder="Select Store"
                          onChange={(value) => {
                            this.setSelectedStoreId(value)
                          }}>
                          {this.state.Storelist.map((option: any) => (
                            //@ts-ignore
                            <Option value={JSON.stringify(option)}>{`${option.store_name}(${option.store_id})`}</Option>
                          ))}
                        </Select>
                        {/* <Input className="modal-role-text" placeholder="Store Id" /> */}
                      </Form.Item>
                    </Col>

                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="name"
                        label="Store name"
                        rules={[
                          { required: true, message: "Store name is required." },
                        ]}
                      >
                        <Input placeholder="Store name" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={24}>
                      <Form.Item
                        name="description"
                        label="Description"
                        rules={[
                          { required: true, message: "description is required." },
                        ]}
                      >
                        <Input placeholder="Description" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="articalCode"
                        label="Article code"
                        rules={[
                          { required: true, message: "Article Code is required." },
                        ]}
                      >
                        <Input placeholder="Article Code" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="title"
                        label="Item title"

                        rules={[{ required: true, message: "Title is required." }]}
                      >
                        <Input
                          placeholder="Title"
                          className="modal-role-text"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="netmrp"
                        label="Net MRP"
                        rules={[
                          { required: true, message: "Net MRP is required." },
                          { pattern: /^(?:\d*)$/, message: 'value must be in digits' }
                        ]}
                      >
                        <Input placeholder="Net MRP" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="gmrp"
                        label="Gross MRP"
                        rules={[
                          { required: true, message: "Gross MRP is required." },
                          { pattern: /^(?:\d*)$/, message: "value must be in digits", },
                        ]}
                      >
                        <Input placeholder="Gross MRP" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="volume"
                        label="Volume"
                        rules={[
                          { required: true, message: "Volume is required." },
                        ]}>
                        <Input placeholder="Volume" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="weight"
                        label="Weight"
                        rules={[
                          { required: true, message: "Weight is required." },
                        ]} >
                        <Input placeholder="Weight" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col span={12} className="rowCol">
                      <Form.Item
                        name="stock"
                        label="Stock Quantity"
                        rules={[
                          { required: true, message: "Stock is required" },
                          { pattern: /^(?:\d*)$/, message: "value must be in digits" }
                        ]}
                      >
                        <Input placeholder="Stock" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="modal-rows">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>


            {/* Edit Inventory Modal */}
            <Modal
              className="addInventoryModal"
              visible={editModal}
              title={null}
              centered
              width="65vw"
              onCancel={() => this.setState({ editModal: false })}
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              {this.state.modaLoader ? <Spin indicator={antIcon} /> : null}

              <div className="AddInventorySection">
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">Edit Item Details</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">Edit the item details</p>
                  </Col>
                </Row>
                <Form
                  onFinish={this.handelupdateInventoryData}
                  onFinishFailed={this.onFinishFailed}
                  ref={this.formRef}
                  name="content-ref"
                  autoComplete="off"
                  layout="vertical"

                >
                  <Row className="modal-rows">
                    <Col className="rowCol" span={12}>

                      <div className="inv-item">
                        <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{this.state?.selectedInventoryData?.data?.attributes?.article_code ? this.state?.selectedInventoryData?.data?.attributes?.article_code : '-'}</p>
                        <p style={{ fontWeight: 'bold' }}>{this.state?.selectedInventoryData?.data?.attributes?.item_name ? this.state?.selectedInventoryData?.data?.attributes?.item_name : '-'}</p>
                      </div>
                    </Col>
                    <Col>
                      <Form.Item
                        name="storeId"
                        rules={[
                          { required: true, message: "Store id is required." },
                        ]}
                      >
                        <Input type="hidden" placeholder="Store Id" readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="storeUniqueId"
                        label="Store Id"
                        rules={[
                          { required: true, message: "Store id is required." },
                        ]}
                      >
                        <Input type="text" placeholder="Store Id" className="modal-role-text" readOnly />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="name"
                        label="Store name"
                        rules={[
                          { required: true, message: "Store name is required." },
                        ]}
                      >
                        <Input placeholder="Store name" className="modal-role-text" readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="description"
                        label="Description"
                        rules={[
                          { required: true, message: "description is required." },
                        ]}
                      >
                        <Input placeholder="Description" className="modal-role-text" />
                      </Form.Item>
                    </Col>

                    <Col className="rowCol" span={12}>
                      <Form.Item
                        name="itemName"
                        label="Item name"
                        rules={[
                          { required: true, message: "Item name is required." },
                        ]}
                      >
                        <Input placeholder="Item name" className="modal-role-text" />
                      </Form.Item>
                      <Form.Item
                        name="articalCode"
                        label="Article Code"
                        noStyle
                      >
                        <Input type="hidden" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col className="rowCol" span={12}>

                    </Col>
                    <Col className="rowCol" span={12}>
                      {/* <Form.Item
                    name="item"
                  // rules={[{ required: true, message: "Title is required." }]}
                  >
                    <Input
                      placeholder="Pepsi Pet 750ml"
                      className="modal-role-text"
                    />
                  </Form.Item> */}
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="netmrp"
                        label="Net MRP"
                        rules={[
                          { required: true, message: "Net mrp is required." },
                        ]}
                      >
                        <Input placeholder="Enter net MRP" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="gmrp"
                        label="Gross MRP"
                        rules={[
                          { required: true, message: "Gross mrp is required." },
                        ]}
                      >
                        <Input placeholder="Enter gross MRP" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="volume"
                        label="Volume"
                      >
                        <Input placeholder="Enter volume" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="rowCol">
                      <Form.Item
                        name="weight"
                        label="Weight"
                      >
                        <Input placeholder="Enter weight" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows">
                    <Col span={12} className="rowCol">
                      <Form.Item
                        name="stock"
                        label="Stock in quantity"
                        rules={[{ required: true, message: "Stock is required." }]}
                      >
                        <Input placeholder="Enter Stock" className="modal-role-text" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="modal-rows">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>



            {/* View Inventory Availablity Modal */}
            <Modal
              className="editInventoryModal sev_editInventoryModal"
              visible={editOfcModal}
              title={null}
              centered
              width="65vw"
              onCancel={() => this.setState({ editOfcModal: false })}
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              <div className="AddInventorySection">
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">View item unavilable</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">View the item unavailable</p>
                  </Col>
                </Row>

                <Row>
                  <label>Item Description</label>
                  <div className="item-description sev_item_description">
                    <div className="item-info">
                      <Row>
                        <Col span={12}>
                          <label className="label-text">{addItemUnavailable?.itemDetails?.itemId}</label>
                          <label className="label-text1">{addItemUnavailable?.itemDetails?.itemName}</label>
                        </Col>

                        <Col span={12}>
                          <label className="label-text">Store ID: {addItemUnavailable?.itemDetails?.storeId}</label>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Row>
                <Row>
                  {
                    selectedInventoryData?.data?.length > 0 ?
                      selectedInventoryData?.data?.map((item: any) => {
                        return <div className="un_items_block">
                          <Row>
                            <Col>
                              <div style={{
                                marginTop: '15px',
                                marginRight: '10px'
                              }}>
                                <input type="radio" value={1} name="item_avaliblity" onClick={() => {
                                  let editSelecteditemAvaliblityData = item
                                  this.setState({ addItemUnavailable: { ...this.state.addItemUnavailable, editSelecteditemAvaliblityData } })
                                }} />
                              </div>
                            </Col>
                            <Col>
                              <label className="label-text">
                                <span className="text">{moment(item?.attributes.start_date).format('Do MMMM YYYY')}</span>
                                <span className="text-saprate">&nbsp;to&nbsp;&nbsp;</span>
                                <span className="text">{moment(item?.attributes.end_date).format('Do MMMM YYYY')}</span>
                              </label>
                              <label className="label-text">
                                <span className="text">{item?.attributes.start_time} </span>
                                <span className="text-saprate">&nbsp;to&nbsp;&nbsp; </span>
                                <span className="text">{item?.attributes.end_time}</span>
                              </label>
                            </Col>
                          </Row>

                        </div>
                      })
                      : <div>
                        <span>No data found</span>
                      </div>
                  }

                </Row>
                <Row className="modal-rows mt-2">
                  <Col span={6}>

                    <Button
                      type="primary"
                      disabled={selectedInventoryData?.data?.length == 0}
                      size="large"
                      className="content-add-btn"
                      onClick={() => {
                        this.handelEditItemAvalablity(this.state?.addItemUnavailable?.editSelecteditemAvaliblityData)
                      }}
                    >
                      Edit
                    </Button>

                  </Col>
                </Row>
              </div>
            </Modal>


            {/* Add Inventory Availablity Modal */}
            <Modal
              className="editInventoryModal sev_editInventoryModal"
              visible={addOfcModal}
              title={null}
              centered
              width="65vw"
              onCancel={() => this.setState({ addOfcModal: false })}
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              <div className="AddInventorySection">
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">Add item unavailable</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">Mark the item unavailable</p>
                  </Col>
                </Row>
                <Form
                  onFinish={this.handelAddItemAvailiblity}
                  onFinishFailed={this.onFinishFailed}
                  ref={this.formRef}
                  name="content-ref"
                  autoComplete="off"
                >
                  <Row>
                    <label>Item Description</label>
                    <div className="item-description sev_item_description">
                      <div className="item-info">
                        <Row>
                          <Col span={12}>
                            <label className="label-text">{addItemUnavailable?.itemDetails?.itemId}</label>
                            <label className="label-text1">{addItemUnavailable?.itemDetails?.itemName}</label>
                          </Col>

                          <Col span={12}>
                            <label className="label-text">Store ID: {addItemUnavailable?.itemDetails?.storeId}</label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Form.Item name='inventoryId' noStyle ><Input type="hidden" /></Form.Item>
                    <Form.Item name='date' label="Date" className="form-group" >
                      <div className="custome-date-control">
                        <DatePicker.RangePicker

                          ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                          }}
                          onChange={this.onDateChange}
                        />
                      </div>
                    </Form.Item>

                  </Row>
                  <Row>
                    <Form.Item name='time' label="Time" className="form-group" >
                      <div className="custome-date-control">
                        <TimePicker.RangePicker
                          use12Hours format="h:mm a"
                          onChange={this.onTimeChange}
                        />
                      </div>

                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item name='reason' label="Reason" className="form-group" rules={[{ required: true, message: "Reason is required" }]}>
                      <Input placeholder="Enter Reason" />
                    </Form.Item>
                  </Row>
                  <Row className="modal-rows">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>

                </Form>

              </div>
            </Modal>


            {/* Edit Inventory Availablity Modal */}
            <Modal
              className="editInventoryModal sev_editInventoryModal"
              visible={editZoneModal}
              title={null}
              centered
              width="65vw"
              onCancel={() => this.setState({ editZoneModal: false })}
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              <div className="AddInventorySection">
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">Edit item unavailable</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">Edit the item unavailable</p>
                  </Col>
                </Row>
                <Form
                  onFinish={this.handelUpdateEditItemAvailiblity}
                  onFinishFailed={this.onFinishFailed}
                  ref={this.formRef}
                  name="content-ref"
                  autoComplete="off"
                >
                  <Row>
                    <label>Item Description</label>
                    <div className="item-description sev_item_description">
                      <div className="item-info">
                        <Row>
                          <Col span={12}>
                            <label className="label-text">{addItemUnavailable?.itemDetails?.itemId}</label>
                            <label className="label-text1">{addItemUnavailable?.itemDetails?.itemName}</label>
                          </Col>

                          <Col span={12}>
                            <label className="label-text">Store ID: {addItemUnavailable?.itemDetails?.storeId}</label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Form.Item name='id'>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name='editDate' label="Date" className="form-group custome-date-control" >
                      {/* <div className="custome-date-control"> */}
                      <DatePicker.RangePicker
                        value={addItemUnavailable?.SelecteditemAvaliblityData?.editDate}
                        format={'YYYY/MM/DD'}
                        onChange={this.onDateChange}
                      />
                      {/* </div> */}
                    </Form.Item>

                  </Row>
                  <Row>
                    <Form.Item name='editTime' label="Time" className="form-group custome-date-control" >
                      {/* <div className="custome-date-control"> */}
                      <TimePicker.RangePicker
                        value={addItemUnavailable?.SelecteditemAvaliblityData?.editTime}
                        use12Hours={true}
                        format="h:mm a"
                        onChange={this.onTimeChange}
                      />
                      {/* </div> */}

                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item name='editReason' label="Reason" className="form-group" rules={[{ required: true, message: "Reason is required" }]}>
                      <Input placeholder="Enter Reason" />
                    </Form.Item>
                  </Row>
                  <Row className="modal-rows">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>

                </Form>

              </div>
            </Modal>

            {/*  FTP Location Draggable Modal  */}
            <Modal
              className="addInventoryModal"
              visible={this.state.FtpModal}
              title={null}
              centered
              width="30vw"
              onCancel={() => {
                this.onReset();
                this.setState({ FtpModal: false });
              }
              }
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
              modalRender={modal => (
                <Draggable
                  onStart={(event, uiData) => { this.onStart(event, uiData) }}
                >
                  {/* @ts-ignore */}
                  <div ref={this.draggleRef}>{modal}</div>
                </Draggable>
              )}
            >
              <div className="AddInventorySection">
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">FTP Location</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">FTP Location details</p>
                  </Col>
                </Row>
                <Form
                  onFinish={this.handelFTPLocation}
                  onFinishFailed={this.onFinishFailed}
                  ref={this.formRef}
                  name="content-ref"
                  autoComplete="off"
                  layout="vertical"

                >
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    spinning={apiLoading}
                    indicator={<GlobalLoader />}
                  >
                    <Row className="modal-rows">
                      <Col className="rowCol" span={24}>
                        <Form.Item
                          name="Address"
                          label="Address"   >
                          <Input
                            placeholder="FTP Address"
                            className="modal-role-text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="modal-rows">
                      <Col span={12} className="rowCol">
                        <Form.Item name="FilePath" label="File Path"  >
                          <Input placeholder="FTP FilePath" className="modal-role-text" />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="rowCol">
                        <Form.Item name="FileName" label="File name"  >
                          <Input placeholder="FTP FileName" className="modal-role-text" />
                        </Form.Item>
                      </Col>
                    </Row>


                    <Row className="modal-rows">
                      <Col span={12} className="rowCol">
                        <Form.Item name="Username" label="Username"  >
                          <Input placeholder="FTP username" className="modal-role-text" />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="rowCol">
                        <Form.Item name="Password" label="Password"  >
                          <Input placeholder="FTP password" className="modal-role-text" />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <Row className="modal-rows">
                <Col span={24} className="rowCol">
                  
                </Col>
              </Row> */}

                    <Row className="modal-rows">
                      <Col span={6}>
                        <Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            className="content-add-btn"
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Spin>
                </Form>
              </div>
            </Modal>

            {/* Importing loafing API */}

            {/* {uploadingLoading == true ?
            <Progress percent={50} showInfo={false} />
            : null} */}

            {/* Other Divisions */}
            <div className="InventoryHeaderSection inv-row">
              <Col className="inv-title" span={5} style={{ textAlign: 'left' }}>
                <Title className="title">Inventory </Title>
              </Col>
              {/* <h1>Inventory</h1> */}

              <div className="InventoryActions inv-row">
                {/* <div>
              <Input
                title={'Enter the FTP location and press enter'}
                value={this.state.txtSavedValue}
                type='search'
                className="searchBox"
                style={{ fontSize: '13px', minWidth: '230px' }}
                placeholder="Enter FTP Location & Press Enter"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    //@ts-ignore
                    if (e.target.value == '') {
                      AntMessage.error('Enter FTP Path')

                    } else {
                      this.setState({ enableField: true })
                    }
                  }
                }}
                onChange={(e) => {
                  this.setState({ txtSavedValue: e.target.value })
                }}
              />

            </div> */}

                {/* <Popconfirm
              visible={this.state.enableField}
              title="Sure you want to change the FTP Location path ?"
              okText="Okay"
              cancelText="Cancel"
              onConfirm={() => {
                this.updateFTPLocation(this.state.txtSavedValue)
              }}
              // okButtonProps={{ loading: true }}
              onCancel={(e) => {
                this.setState({ enableField: false, txtSavedValue: this.state.txtInputValue != '' ? this.state.txtInputValue : '' })
              }}>
            </Popconfirm> */}
                {localStorage.getItem('role') == 'Admin' ?
                  <Row className="modal-rows fieldBox selectdropBox  inv-col">

                    <Select placeholder="Select Store"
                      className="modal-role-text"
                      loading={this.state.Storelist.length == 0}
                      onChange={(value) => {
                        this.selectedStoreId(value)
                      }} >
                      <Select.Option value="0">All Stores</Select.Option>
                      {
                        this.state.Storelist.map((option: any) => (
                          <Select.Option value={option.id} >{option.store_name}({option.store_id})</Select.Option>
                        ))}
                    </Select>



                  </Row>
                  : null}

                <Input className="searchBox inv-col"
                  placeholder="Search"
                  onKeyDown={this.handleKeyDown}
                  onChange={(e) => { this.searchInventory(e) }}
                />


                <Upload className="Inventor-btn  inv-col"
                  accept={".csv,.xls,.xlsx"}
                  customRequest={(e) => { this.uploadInventoryCsv(e) }}
                  showUploadList={false}
                ><UploadOutlined translate />Import
                </Upload>

                <Button className="Inventor-btn inv-col"><DownloadOutlined translate={''} />
                  <a href={`${baseURL}/bx_block_cfinventorymanagement11/inventories/csv_export.csv?token=${localStorage.getItem('token')}`}>Export</a>
                </Button>

                {permissions != undefined && permissions.create_action && (localStorage.getItem("role") == 'Admin' || localStorage.getItem("role") == 'Super Admin') ?
                  <>
                    <Button className="Inventor-btn  inv-col" onClick={() => {
                      this.setState({ FtpModal: true });
                      this.getFtpLocation()

                    }
                    }>
                      FTP
                    </Button>

                    <Button className="Inventor-btn inv-col" onClick={() => {
                      this.setState({ addModal: true })
                    }
                    }>
                      Add Inventory
                    </Button>
                  </>
                  : ''}

              </div>
            </div>
            <div className="sample-file">
              <Popover
                placement="bottom"
                content={
                  <div>
                    <p><a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=Inventory`}>Download</a>
                    </p>
                  </div>
                }
                title="Download Sample File"
              >
                <DownCircleFilled translate={{}} style={{ marginRight: 5 }} />Sample file

              </Popover>
            </div>
            <Table
              //@ts-ignore
              columns={columns}
              title={() => 'Item Listing'}
              size={'small'}
              dataSource={this.state.Inventorylist}
              // dataSource={permissions.read_action ? this.state.Inventorylist:[]}
              loading={{ spinning: this.state.InventoryApiLoading, indicator: <GlobalLoader /> }}
              scroll={{ x: 'max-content' }}//1300
              //@ts-ignore
              pagination={this.state?.searchValue !== '' ? true : paginationOptions}
            />
          </div >
        </Col>
      </Row>


    );
  }
}

export default InventoryManagement;
