// Customizable Area Start
import React, { createRef } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import {
    Table,
    Select,
    Button,
    Modal,
    Upload,
    Input,
    Row,
    Col,
    Form,
    Typography,
    Divider,
    Space,
    Card,
    DatePicker,
    Switch,
    Spin,
} from "antd";

import { BarChartOutlined, CloseCircleFilled, ExportOutlined, EyeOutlined, SyncOutlined } from "@ant-design/icons";
import "../../../blocks/RequestManagement/src/Reports/Reports.css";
import { FormInstance } from "antd/es/form";
const { Title, Paragraph, Text, Link } = Typography;
import {
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
    EditFilled,
    SignalFilled
} from "@ant-design/icons";
import dashboardController, { Props, configJSON } from "./DashboardController";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import "./Dashboard.css";
//@ts-ignore
import sandClock from '../assets/sand-clock.png'
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js"
//@ts-ignore
import GlobalLoader from "../../../web/src/GlobalLoader"

export default class Dashboard extends dashboardController {
    cardArray: any;
    timer: any = null
    constructor(props: Props) {
        super(props);
        console.log("Constructor Loaded");
        this.state = {
            dashboardData: [],
            errorMsg: "",
            token: "",
            loading: false,
            orderList: [],
            newOrderBlock: false,
            acceptedOrdersBlock: false,
            rejectedOrdersBlock: false,
            partiallyAcceptedOrdersBlock: false,
            partiallyRejectedOrdersBlock: false,
            ordersOnHoldBlock: false,
            orderFullfilledBlock: false,
            selectedRowKeys: [],
            adminDashbaordData: [],
            orderApiData: [],
            selectedOrderId: '',
            selectedOrderDetails: {},
            orderConfirmationState: '',
            testState: [],
            selectedFilterId: "",
            DropDownValue: "Search",
            orderDetails: [],
            RejectedReasonBlock: false,
            variablePriceArray: [],
            variableQuantity: [],
            filterOrderData: [4],
            selectedFilterString: `?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`,
            Order_Id:0

        };
        document.title = `Dashboard | 24SevenDashboard`
    }

    formRef = createRef<FormInstance>();

    searchOrders = (e: any) => {
        clearTimeout(this.timer);
        this.setState({ token: e.target.value })
        let value = e.target.value
        this.timer = setTimeout(() => {
            this.searchOrdersDetails(value)
        }, 1000)

    }

    dashboardSeachAccordingToStore = (storeId: any) => {
        let queryString = `store_id=${storeId}`
        this.dashboardSeachOrdersDetails(queryString)

    }
    dashboardSeachAccordingToOFC = (OfcId: any) => {
        let queryString = `ofc=${OfcId}`
        this.dashboardSeachOrdersDetails(queryString)

    }

    onChange = (dates: any, dateStrings: any) => {
        // console.log('From: ', dates[0], ', to: ', dates[1]+'From: ', dateStrings[0], ', to: ', dateStrings[1]);
        if (dates && dateStrings) {
            this.setState({ testState: [dates[0], dates[1]] });
            let objectValue: any = this.state?.selectedFilterId;
            let filterId = {}
            let reqPayload: any
            reqPayload = `start_date=${moment(dates[0]).format('D MMMM YYYY')}&end_date=${moment(dates[1]).format('D MMMM YYYY')}`
            this.setState({ selectedOrderId: reqPayload });

            if (objectValue !== '' || objectValue == null) {
                if ('ofc_id' in objectValue) {
                    reqPayload = reqPayload + '&ofc_id=' + objectValue.ofc_id;
                    this.setState({ selectedOrderId: reqPayload });
                }
                if ('zone_id' in objectValue) {
                    reqPayload = reqPayload + '&zone_id=' + objectValue.zone_id;
                    this.setState({ selectedOrderId: reqPayload });
                }
                if ('store_id' in objectValue) {
                    reqPayload = reqPayload + '&store_id=' + objectValue.store_id;
                    this.setState({ selectedOrderId: reqPayload });
                }
            }

            this.getAllOrdersWithFilters(reqPayload);
        } else {
            this.setState({ testState: null, DropDownValue: "Search" });

        }
    }

    searchAccordingToZoneOfcStoreId = (object: any) => {
        this.setState({ testState: null, token: '', selectedFilterId: '', selectedOrderId: '' });
        let objectValue = JSON.parse(object);
        let reqPayload = null;
        if ('ofc_id' in objectValue) {
            reqPayload = `ofc_id=${objectValue.ofc_id}`;
            this.setState({ selectedFilterId: objectValue, DropDownValue: objectValue.unique_id, selectedOrderId: reqPayload })
        }
        if ('zone_id' in objectValue) {
            reqPayload = `zone_id=${objectValue.zone_id}`;
            this.setState({ selectedFilterId: objectValue, DropDownValue: objectValue.unique_id, selectedOrderId: reqPayload })
        }
        if ('store_id' in objectValue) {
            reqPayload = `store_id=${objectValue.store_id}`;
            this.setState({ selectedFilterId: objectValue, DropDownValue: objectValue.unique_id, selectedOrderId: reqPayload })
        }
        if (reqPayload !== null)
            this.searchDashboardDataWithZoneOfcStoreFilter(reqPayload)

    }

    clearFilter = () => {
        this.setState({ testState: null, DropDownValue: "Search", token: '', selectedFilterId: '', selectedOrderId: '' });
        this.getAllOrders();
    }

    viewIndividualOrderDetails = (item: any, fullObj: any) => {
        this.setState({ loading: true, })
        this.getSelectedOrderDetails(fullObj.id);
    }

    //======================================  RENDER  ================================================
    render() {
        //order Columns
        const { DropDownValue, newOrderBlock, selectedOrderDetails, loading } = this.state

        const individualOrderColumns = [
            {
                title: "Order Id",
                dataIndex: "id",
                key: "id",
            },
            {
                title: "Name",
                dataIndex: "item_name",
                key: "item_name",
            },
            {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
            },
            {
                title: "Discount",
                dataIndex: "discount",
                key: "discount",
            },
        ]
        const orderColumns = [
            {
                title: "SNo",
                dataIndex: "sno",
                key: "sno",
            },
            {
                title: "order ID",
                dataIndex: "orderId",
                key: "orderId",
                render(text: any, record: any) {
                    return {
                        props: {
                            style: { fontWeight: 'bold' }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "Partner",
                dataIndex: "partner",
                key: "partner",
                render(text: any, record: any) {
                    return {
                        props: {
                            style: { fontWeight: 'bold' }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "Store ID",
                dataIndex: "store_id",
                key: "store_id",
                // width: 200,

            },
            {
                title: "Store name",
                dataIndex: "store_name",
                key: "store_name",
            },
            {
                title: "Amount",
                dataIndex: "amount",
                key: "amount",
                sorter: {
                    compare: (a: any, b: any) => a?.amount.localeCompare(b?.amount),
                    multiple: 1,
                },

            },
            {
                title: "Order Time",
                dataIndex: "order_time",
                key: "order_time",
                // width: 110,
                align: 'center',
                render(text: any, record: any) {
                    let dataData = text.split(',')
                    return {
                        props: {
                            style: { fontWeight: 'bold', lineHeight: '7px' }
                        },
                        children: <div><p>{dataData[0]}</p><p>{dataData[1]}</p></div>
                    };
                }
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                align: 'center',
                filters: [
                    { text: 'New orders', value: 'New orders' },
                    { text: 'Orders fulfilled', value: 'Orders fulfilled' },
                    { text: 'Accepted', value: 'Accepted' },
                    { text: 'Rejected', value: 'Rejected' },
                    { text: 'On hold', value: 'On hold' },
                    { text: 'Partially rejected', value: 'Partially rejected' },
                    { text: 'Partially accepted', value: 'Partially accepted' },
                ],
                filterMode: 'menu',
                filterSearch: true,
                onFilter: (value: string, record: any) => record.status.includes(value),
                render(text: any, record: any) {
                    return {
                        props: {
                            style: { fontWeight: 'bold', lineHeight: '7px' }
                        },
                        children: <div>
                            {
                                text == 'New orders' ? <p style={{ color: 'LimeGreen' }}>{text}</p> :
                                    text == 'Orders fulfilled' ? <p style={{ color: 'Green' }}>{text}</p> :
                                        text == 'Accepted' ? <p style={{ color: 'Green' }}>{text}</p> :
                                            text == 'Rejected' ? <p style={{ color: 'Red' }}>{text}</p> :
                                                text == 'On hold' ? <p style={{ color: 'Orange' }}>{text}</p> :
                                                    text == 'Partially rejected' ? <p style={{ color: 'Red' }}>{text}</p> :
                                                        text == 'Partially accepted' ? <p style={{ color: 'Gold' }}>{text}</p> :
                                                            <p>{text}</p>
                            }
                        </div>
                    };
                }
            },
            {
                title: "",
                dataIndex: "view",
                key: "view",
                width: 40,
                align: 'center',
                render: (item: any, fullObj: any) => {
                    return (
                        <div className="editIconBox">
                            <Space size="large">

                                <span className="icon">
                                    {/* Admin || Super Admin condition */}
                                    <EyeOutlined
                                        translate={''}
                                        onClick={() => {
                                            this.viewIndividualOrderDetails(item, fullObj)
                                            this.setState({ newOrderBlock: true })
                                        }
                                        }
                                    />
                                </span>
                            </Space>
                        </div>
                    )
                }
            }
        ];


        const totalOrderColumn = [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'No.of order',
                dataIndex: 'noOfOrder',
                key: 'noOfOrder',
                align: 'center',
            }]


        const partnerColumn = [
            {
                title: 'Order partner',
                dataIndex: 'orderPartner',
                key: 'orderPartner',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
            }]

        const orderFulfilmentRateRow = [
            {
                title: 'Time',
                dataIndex: 'time',
                key: 'time',
            },
            {
                title: 'Percentage',
                dataIndex: 'percentage',
                key: 'percentage',
            }
        ];

        const BillingRateRow = [
            {
                title: 'Month',
                dataIndex: 'month',
                key: 'month',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
            }];


        return (
            <>
                <div className="Reports-main se-dashboard-block" key={'a'} >
                    <div className="content-container" key={'b'}>
                        <Row className="header topElement-row" key={`content1`}>
                            <Col className="topElement-col" key={`content11`} span={9} style={{ textAlign: "left" }}>
                                <Title className="title">Dashboard</Title>
                            </Col>

                            <Col className="topElement-col" key={`content12`} span={15} style={{ textAlign: "left" }}>
                                <div className="is_filteralignmnet">

                                    {
                                        this.state?.adminDashbaordData?.DropdownData
                                            ?


                                            <Select
                                                showSearch
                                                value={DropDownValue}
                                                placeholder="Search "
                                                optionFilterProp="children"
                                                filterOption={(input: any, option: any) =>
                                                    option?.children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }

                                                filterSort={(optionA, optionB) =>
                                                    optionA?.children[0]?.toLowerCase().localeCompare(optionB?.children[0]?.toLowerCase())
                                                }
                                                className=" select-width mr-2"
                                                onChange={(object: any) => { this.searchAccordingToZoneOfcStoreId(object) }}
                                            >
                                                {this.state?.adminDashbaordData?.DropdownData?.map((data: any) => {
                                                    //@ts-ignore
                                                    return <Select.Option
                                                        key={`${data.unique_id}${data.name}`}
                                                        value={JSON.stringify(data)}
                                                    >
                                                        {data.name}({data.unique_id})
                                                    </Select.Option>
                                                })
                                                }
                                            </Select>
                                            : null
                                    }


                                    <DatePicker.RangePicker
                                        className="mr-2"
                                        inputReadOnly={true}
                                        value={this.state.testState}
                                        format={'DD/MM/YYYY'}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        onChange={this.onChange}

                                    />
                                    <Button
                                        title="Clear Filters"
                                        className="claerFilter"
                                        onClick={() => { this.clearFilter() }}
                                    >Clear filters</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mainContentRow" key={`content2`} gutter={[16, 48]}>
                            <Col className="mainContentCol" key={`content21`} span={10}>
                                <Row key={`CR1`} gutter={[16, 48]}>
                                    <Col key={`RC11`} span={12} title={`Total Payments`}>
                                        <Card key={`RCARD11`} className="se-card se-card-total">
                                            <Avatar size={32} style={{ backgroundColor: '#000000' }} icon={<SignalFilled translate="translate={{}}" />} />
                                            <p>Total Payments</p>
                                            <h3><b>
                                                {this.state?.adminDashbaordData?.totalPaymnet ? this.state.adminDashbaordData.totalPaymnet : '-'
                                                }</b></h3>
                                        </Card>
                                    </Col>
                                    <Col key={`RC12`} span={12} title={`Total orders`}>
                                        <Card key={`RC1111`} className="se-card se-card-total">
                                            <Avatar size={32}
                                                src={sandClock}
                                            // style={{ backgroundColor: '#000000' }}
                                            />
                                            <p>Total Orders</p>
                                            <h3><b>{this.state?.adminDashbaordData?.totalOrders ? this.state?.adminDashbaordData?.totalOrders : '-'}</b></h3>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row key={`1R-1R`} gutter={[16, 48]}>
                                    <Col key={`1C-1C`} span={12} title={`Total orders`}>
                                        <Card key={`1R-1Card`} className="se-card se-cartotal">
                                            <h4 className="se-title">
                                                Total Orders
                                            </h4>
                                            <Table
                                                size={'small'}
                                                loading={this.state.loading}
                                                bordered={false}
                                                pagination={false}
                                                dataSource={this.state?.adminDashbaordData?.totalOrderStatus}
                                                //@ts-ignore
                                                columns={totalOrderColumn} />
                                        </Card>
                                    </Col>
                                    <Col key={`2C-2C`} span={12} title={`Order Partners`}>
                                        <Card key={`1C-2Card`} className="se-card se-cardfulfilment">
                                            <h4 className="se-title">
                                                Order Partners
                                            </h4>
                                            <Table
                                                size={'small'}
                                                loading={this.state.loading}
                                                bordered={false}
                                                pagination={false}
                                                dataSource={this.state.adminDashbaordData.orderPartnerData}
                                                columns={partnerColumn} />
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mainContentCol" key={`content22`} span={7} title={`Order fulfilment rate`}>
                                <Card className="se-card se-cardorder">
                                    <Row gutter={[16, 48]}>
                                        <Col key={`#$%C`} span={16}>
                                            <h4 className="se-title">
                                                Order fulfilment rate
                                            </h4>
                                        </Col>

                                    </Row>
                                    <Row gutter={[16, 48]}>
                                        <Col key={`1C-!@C`} span={24}>
                                            <Table
                                                size={'small'}
                                                loading={this.state.loading}
                                                bordered={false}
                                                pagination={false}
                                                columns={orderFulfilmentRateRow}
                                                dataSource={this.state?.adminDashbaordData?.orderFulfilmentRateColumns} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className="mainContentCol" key={`content23`} span={7} title={`Billing rate`}>
                                <Card className="se-card se-cardorder">
                                    <Row gutter={[16, 48]}>
                                        <Col span={16}>
                                            <h4 className="se-title">
                                                Billing rate
                                            </h4>
                                        </Col>

                                    </Row>
                                    <Row gutter={[16, 48]}>
                                        <Col span={24}>
                                            <Table
                                                size={'small'}
                                                loading={this.state.loading}
                                                bordered={false}
                                                pagination={false}
                                                columns={BillingRateRow}
                                                dataSource={this.state?.adminDashbaordData?.totalBillingRate} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                        {/*ORDER BLOCK  */}

                        <Row key={`OBRow1`} gutter={[16, 48]}>
                            <Col key={`orderBlockCol1`} span={24} style={{ textAlign: "left" }}>
                                <Card key={`Card1`} className="orderBlock">
                                    <Row key={`R1`}
                                        style={{ paddingBottom: "20px" }}
                                        className="item-order-list-row"

                                    >
                                        <Col
                                            flex="1 0 25%"
                                            key={`orderBlockRow11`}
                                            span={9}
                                            style={{ textAlign: "left" }}
                                            className="item-order-list-col"
                                        >
                                            <Title className="title item-order-list-col">Orders</Title>
                                        </Col>
                                        <Col
                                            flex="1 0 25%"
                                            key={`orderBlockRow12`} span={15} style={{ textAlign: "end" }}>
                                            <Button
                                                className="search-box"
                                                style={{ marginRight: '30px', height: '40px' }}
                                            >
                                                <a
                                                    href={`${baseURL}/bx_block_orderdetailview/orders/order_export.csv?search=${this.state.token}&${this.state.selectedOrderId}`}
                                                >
                                                    Export Orders</a></Button>
                                            <Space size="middle" direction="horizontal">
                                                <Input
                                                    placeholder="Search orders"
                                                    className="search-box"
                                                    onChange={(e) => { this.searchOrders(e) }}
                                                />

                                            </Space>
                                        </Col>
                                    </Row>

                                    <Row key={`R2`} style={{ paddingBottom: "20px" }}>
                                        <Col key={`C1`} span={24} style={{ textAlign: "left" }} >
                                            <Table
                                                key={'order-table'}
                                                //@ts-ignore
                                                columns={orderColumns}
                                                dataSource={this.state.orderList}
                                                loading={this.state.loading}
                                                scroll={{ x: 1300 }}
                                                size="small"
                                                onRow={(fullObj) => {
                                                    return {
                                                        onDoubleClick: (e) => {
                                                            this.viewIndividualOrderDetails(null, fullObj)
                                                            this.setState({ newOrderBlock: true })
                                                        },
                                                    };
                                                }}
                                                rowKey={`order-table-block`}

                                            // pagination={false}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* Individual Order View Modal */}

                <Modal
                    title="Order Details"
                    centered
                    visible={newOrderBlock}
                    footer={null}
                    width={1000}
                    onCancel={() => this.setState({ newOrderBlock: false, selectedOrderDetails: {}, loading: false })}
                >
                    {loading ?
                        // <SyncOutlined style={{ fontSize: "25px", display: "flex", justifyContent: "center" }} translate="" spin /> 
                        <GlobalLoader />
                        :
                        <div>
                            <Row gutter={[16, 48]} className="order-row">
                                <Col className="order-col" key={`orderDetialsCol1`} span={6}>
                                    <div className={`individual-order 
                                 ${selectedOrderDetails?.status == "New orders" ? ' newOrderStatus' :
                                            selectedOrderDetails?.status == "Accepted" ? ' AcceptedStatus' :
                                                selectedOrderDetails?.status == "Rejected" ? ' RejectedStatus' :
                                                    selectedOrderDetails?.status == "On hold" ? ' onHoldStatus' :
                                                        selectedOrderDetails?.status == "Partially accepted" ? ' partiallyAcceptedStatus' :
                                                            selectedOrderDetails?.status == "Partially rejected" ? ' PartiallyRejected' :
                                                                selectedOrderDetails?.status == "Orders fulfilled" ? ' OrderFulfilledstatus' : ''}`
                                    }>
                                        <div> Status </div>
                                        <div >{selectedOrderDetails?.status ? selectedOrderDetails?.status : '-'}</div>
                                    </div>
                                </Col>
                                <Col className="order-col" key={`orderDetialsCol2`} span={6}>
                                    <div className="individual-order">
                                        <div> Order Id</div>
                                        <div style={{ fontSize: '14px' }}>{selectedOrderDetails?.partner_order_id ? selectedOrderDetails?.partner_order_id : '-'}</div>
                                    </div>
                                </Col>
                                <Col className="order-col" key={`orderDetialsCol3`} span={6}>
                                    <div className="individual-order"><div>Partner name</div>
                                        <div>
                                            <img className="imgbox" src={selectedOrderDetails?.catalogue_url?.url} alt="not found" />
                                            {selectedOrderDetails?.partner ? selectedOrderDetails?.partner : '-'}</div>

                                    </div>
                                </Col>
                                {selectedOrderDetails?.status == "Orders fulfilled" ?
                                    <Col key={`orderDetialsCol4`} span={6} >
                                        <div className="individual-order " >
                                            <div> Bill no </div>
                                            <div>{selectedOrderDetails?.bill_no ? selectedOrderDetails?.bill_no : '-'}</div>
                                        </div>
                                    </Col>
                                    : null}
                                {selectedOrderDetails?.status == "Rejected" ?
                                    <Col className="order-col" key={`orderDetialsCol5`} span={6} >
                                        <div className="individual-order ">
                                            <div> Reason </div>
                                            <div>{selectedOrderDetails?.reason ? selectedOrderDetails?.reason : '-'}</div>
                                        </div>
                                    </Col>
                                    : null}
                            </Row>
                            <Divider />
                            <div>
                                <div className="list_orders_details order-details-row" >
                                    <div className="list_left_details order-details-col">
                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Order Id :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.partner_order_id ? selectedOrderDetails?.partner_order_id : '-'} </span>

                                        </div>

                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Total Price :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.total_price ? selectedOrderDetails?.total_price : '-'} </span>


                                        </div>

                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Total Items :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.partner ? selectedOrderDetails?.partner : '-'} </span>


                                        </div>
                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Total Discount :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.discount ? selectedOrderDetails?.discount : '-'} </span>

                                        </div>
                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Total Item Quantity :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.item_quantity ? selectedOrderDetails?.item_quantity : '-'} </span>

                                        </div>
                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Store Id :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.store_id ? selectedOrderDetails?.store_id : '-'} </span>

                                        </div>
                                    </div>
                                    <div className="list_right_details order-details-col">
                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Customer name :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.customer_name ? selectedOrderDetails?.customer_name : '-'} </span>
                                        </div>

                                        <div className="item-text order-row">
                                            <span className="texts1 order-details-items-col">Contact :</span>
                                            <span className="texts2 order-details-items-col">{selectedOrderDetails?.customer_phoneno ? selectedOrderDetails?.customer_phoneno : '-'} </span>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div>
                                <Table
                                    columns={individualOrderColumns}
                                    size="small"
                                    dataSource={selectedOrderDetails?.order_items ? selectedOrderDetails?.order_items : []}
                                    scroll={{ x: 900 }}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    }
                </Modal>
            </>
        );
    }
}
// Customizable Area End