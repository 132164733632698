// Customizable Area Start
import React, { createRef } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { withRouter } from "react-router";
import "antd/dist/antd.css";
import {
  Table,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Row,
  Col,
  Form,
  Typography,
  Divider,
  Space,
  Card,
  Empty,
  Spin,
  Skeleton,
} from "antd";
import "../../../blocks/RequestManagement/src/Reports/Reports.css";
const { Title, Paragraph, Text, Link } = Typography;
import catalogueController, {
  Props,
  configJSON,
} from "./CatalogueController";
import Item from "antd/lib/list/Item";
import { LoadingOutlined } from "@ant-design/icons";
//@ts-ignore
import GlobalLoader from "../../../web/src/GlobalLoader"

export default class Catalogue extends catalogueController {
  cardArray: any;
  mainCatVariable: any;
  timer: any = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      arrayHolder: [],
      token: "",
      addCategoryModal: false,
      addSubCategoryModal: false,
      addGroupingModal: false,
      editCategoryModal: false,
      editSubCategoryModal: false,
      editGroupingModal: false,
      selectedTab: 1,
      CategoryList: [],
      SubCategoryList: [],
      GroupingList: [],
      apiLoading: false,
      selectedCategoryData: {},
      selectedSubCategoryData: {},
      selectedGroupingsData: {},
      Errors: {},
      selectedRowKeys: [],
      catalogueArray: [],
      InventoryDataList: [],
      selectedSubCategoryArray: [],
      displayInventoryList: [],
      selectedOfferData: {},
      OfferListData: [],
      buyItem: {},
      freeItems: {},
      offerModal: false,
      timingsData: {
        startDate: '',
        EndDate: '',
        StartTime: '',
        EndTime: '',
      },
      editOfferModal: false,
      selectedOfferDataForUpdate: {},
      searchInventoryItemsBlock: false,
      pagination: {
        currentPage: 1,
        pageSize: 25,
        Totalcount: 0,
        TotalInventorycount: 0
      },
      itemCode: 'Item Code',
      articleCodeLoader: false,
      selectedCatalogueId: 0,
      DataLoader: false

    }
    document.title = `Catalouge | 24SevenDashboard`;
  }

  searchCatalouge = async (e: any) => {
    clearTimeout(this.timer);
    let value = e.target.value;
    this.timer = setTimeout(() => {
      this.searchCatalougeDetails(value)
    }, 1000)
  }

  navigation = (item: any) => {
    localStorage.setItem("catalogue_id", item.id);
    localStorage.setItem("catalogue_name", item.attributes.name);
    this.setState({ selectedCatalogueId: item.id })
    //  window.location.href='/CatalogueList';


    // @ts-ignore
    this.props.history.push({
      pathname: '/CatalogueList',
      state: { catalogueId: item.id, catalogueDetails: { image: item.attributes?.avatar?.url, name: item?.attributes?.name } }
    })
  };
  render() {
    const { catalogueArray, apiLoading } = this.state;
    return (
      <>
        <Row className="catalouge-main">
          <Col span={24} className="content-container">
            <Row className="header catalouge-heading">
              <Col className="catalouge-search" span={9} >
                <Title className="title">Catalogue</Title>
              </Col>

              <Col className="catalouge-search" span={15} >
                <Space size="middle">
                  <Input placeholder="Search" className="search-box" onChange={(e) => { this.searchCatalouge(e) }} />
                </Space>
              </Col>
            </Row>
            <Row className="CardRowSection catalouge-row">
              {
                catalogueArray?.length > 0
                  ?
                  catalogueArray?.map((item: any) => {
                    if (item.attributes.status == true) {
                      return (
                        <Col
                          key={item.id}
                          className="cardColSection catalouge-col"
                          title={`${item?.attributes?.name ? item.attributes.name : `Catalouge`}`}

                        >
                          <Card
                            key={item.id}
                            onClick={() => this.navigation(item)}
                            className="cardSection"
                          >
                            <div className="circle" >
                              <img
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: '50px'
                                }}
                                src={item.attributes?.avatar?.url} alt="" />
                            </div>
                            <p className="data">{item.attributes.name}</p>
                          </Card>
                        </Col>
                      );
                    }
                  })
                  :
                  <>
                    {
                      apiLoading ?
                        <GlobalLoader />
                        : <Empty
                          style={{ marginLeft: '40%' }}
                          description={
                            <span>
                              No catalogue found
                            </span>
                          }
                        />}
                  </>
              }




            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
// Customizable Area End