Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PeopleManagement2";
exports.labelBodyText = "PeopleManagement2 Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
exports.validationApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDelMethod = "DEL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.ApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.base_url =
  "https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/";
exports.apiPostMethod = "POST";
exports.getStoreApiEndPoint = `account_block/accounts/store_accounts/get_store`;
exports.getOfcApiEndPoint = `account_block/accounts/ofc/get_all_ofcs`;
exports.getZoneApiEndPoint = `account_block/accounts/zone/list_of_zones`;
exports.addPeopleApiEndPoint = `bx_block_peoplemanagement2/people_accounts`;
exports.getPeopleByIdApiEndPoint = `bx_block_peoplemanagement2/people_accounts/?id=`;
exports.editPeopleApiEndPoint = `bx_block_peoplemanagement2/people_accounts?id=`;
exports.getPeopleApiEndPoint = `bx_block_peoplemanagement2/people_accounts/list_of_peoples`;
exports.updateEmployeeApiEndPoint = `bx_block_peoplemanagement2/people_accounts?id=`;
exports.getZoneByIdApiEndPoint = `account_block/accounts/zone?id=`; //${id}`;
exports.getOfcByIdApiEndPoint = `account_block/accounts/ofc?id=`; //${id}`;
exports.exportFileData =
  "bx_block_peoplemanagement2/people_accounts/csv_export.csv";
exports.ImportFileData =
  "bx_block_peoplemanagement2/people_accounts/csv_import";
exports.getAllOfcByZoneIdApiEndPoint = `account_block/accounts/ofc/get_all_ofcs?zone_id=`;
exports.getAllStoreByOfcIdApiEndPoint = `account_block/accounts/store_accounts/get_store?zone_id=`;
exports.getStoreInfoByStoreId = `account_block/accounts/store_accounts?id=`;
exports.getAllRoles = `bx_block_roles_permissions/roles/role_list`; //&ofc_id=
exports.PeopleImportCsvApiEndPoint = `bx_block_peoplemanagement2/people_accounts/csv_import`;
exports.PeopleSearchApiEndPoint = `bx_block_peoplemanagement2/people_accounts/list_of_peoples?search=`;
exports.PeopleStatusUpdatedApiEndPoint = `bx_block_peoplemanagement2/people_accounts?id=`;
exports.uploadProfilePictureApiEndPoint = `bx_block_peoplemanagement2/people_accounts?id=`;
exports.getRoleInformation = `bx_block_roles_permissions/roles?id=`