// Customizable Area Start
import React, { createRef } from "react";
import NotificationsController, {
    Props
} from "./NotificationsController";
import "antd/dist/antd.css";
import {
    Modal,
    Input,
    Row,
    Col,
    Card,
    Spin,
    Pagination,
    Divider,
    Alert,
    Popconfirm
} from "antd";
import { CloseCircleFilled, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import './notification.css';
import moment from 'moment';


export default class NotificationsList extends NotificationsController {
    constructor(props: Props) {
        super(props)
        this.state = {
            listing: [],
            value: '',
            apiLoading: false,
            notificationCount: 0,
            totalNotificationLength: 0,
            pagination: {
                currentPage: 1,
                pageSize: 25
            },

        }
    }

    timer: any = null

    onSingleClick = (value: any) => {
        if (value.attributes?.is_read == true) {
            return
        }
        this.setState({ apiLoading: true })
        this.readSingleNotificationFunction(value.id);
    }

    onHandleUnreadNotifications = () => {
        this.readAllNotification()
    }

    static getDerivedStateFromProps(props: Props, state: any) {
        // console.log(state, '#######');

        // @ts-expect-error
        props.setNotificationCount(state.notificationCount);
    }

    onHandleSearchNotification = (e: any) => {
        clearTimeout(this.timer);
        this.setState({ value: e.target.value })
        this.timer = setTimeout(() => {
            console.log(this.state.value);
            this.triggerChange
        }, 1000)
    }

    handleKeyDown = (e: any) => {

        if (e.keyCode === 13) {
            clearTimeout(this.timer)
            this.triggerChange()
        }
    }

    triggerChange = () => {
        const { value } = this.state;
        if (value == "") {
            this.getNotificationList()
        } else {
            this.searchNotification(value)
        }

    }

    onPageChange = (page: any, pageSize: any) => {
        this.setState({
            apiLoading: true,
            pagination: {
                ... this.state.pagination,
                currentPage: page,
                pageSize: pageSize
            }
        });
        this.fetchNotificationAccordingToPagination(page, pageSize)


    }

    render() {
        //@ts-ignore
        const { openNotificationModal, handleCloseNotificationModal } = this.props;
        const { notificationCount, apiLoading, totalNotificationLength, listing } = this.state;


        return (
            <Modal
                className="addstoreModalBox notcardmodal"
                visible={openNotificationModal}
                title={null}
                centered
                width="45vw"
                onCancel={() => {
                    handleCloseNotificationModal(false)
                }}
                closeIcon={
                    <CloseCircleFilled
                        style={{ color: '#000', fontSize: '20px' }}
                        translate={{}}
                    />
                }
                footer={null}
            >
                <div className="AddStoreTimingsSection">



                    <Row className="header">
                        <Col span={12}>
                            <div >
                                <h1 className="mb-0 modal-heading">Notifications</h1>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div
                                title="Mark as read"
                                onClick={this.onHandleUnreadNotifications}
                                style={{ marginTop: '15px', fontWeight: 'bold', textAlign: 'right', cursor: 'pointer' }}>
                                <u>Mark as read</u>
                            </div>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                            <div
                                title="Refresh"
                                onClick={this.getNotificationList}
                                style={{ marginTop: '15px', fontWeight: 'bold', textAlign: 'right', cursor: 'pointer' }}>
                                <ReloadOutlined translate={{}} />
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Input
                            type="text"
                            placeholder="Search"
                            style={{
                                height: '50px',

                                padding: 'auto',
                                marginBottom: '10px'
                            }}
                            onChange={(e) => this.onHandleSearchNotification(e)}
                            onKeyDown={this.handleKeyDown}
                            allowClear
                            value={this.state.value}
                        />
                    </Row>
                    <Row
                        className={notificationCount == 0 ? 'd-none' : ''}

                    >
                        <Col span={24}>
                            <div className="notification-alert">
                                <Alert
                                    style={{ borderRadius: 10 }}
                                    message={`${notificationCount} unread of ${totalNotificationLength} notifications.`}
                                    action={

                                        <div
                                            title="Mark as read"
                                            style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            <Popconfirm
                                                title="Are you sure?"
                                                onConfirm={() => {
                                                    this.onHandleUnreadNotifications();
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <u>Mark as read</u>
                                            </Popconfirm>
                                        </div>}
                                    type="warning"
                                    closable />
                            </div>
                        </Col>
                    </Row>
                    <Spin
                        spinning={apiLoading}
                        indicator={<LoadingOutlined translate={{}}
                            className="notify-loader" spin />} >
                        <Row className="notcardlist">
                            {/* {this.state.notificationCount > 0 ? */}
                            {this.state?.listing?.length > 0 ?
                                this.state?.listing?.map((value: any, i: any) => (
                                    <Card
                                        style={{
                                            backgroundColor: '#edeef4',
                                            height: '60px',
                                            padding: 'auto',
                                            marginTop: '10px',
                                            cursor: 'pointer'
                                        }}
                                        key={i}
                                        className={`single-notification ${value.attributes?.is_read == true ? 'seen' : 'unseen'}`}
                                        onClick={() => this.onSingleClick(value)}
                                    >
                                        <b>{value.attributes.contents}   </b>
                                        <div className="notification-time">{moment(value.attributes.created_at).fromNow()}</div>
                                    </Card>
                                ))
                                : (
                                    <p>No Notification</p>
                                )}

                        </Row>
                    </Spin>
                    <div >
                        {listing?.length > 0 ?
                            <>
                                <Divider />
                                <Pagination
                                    className="notification_pagination"
                                    size="small"
                                    total={totalNotificationLength}
                                    showSizeChanger={false}
                                    showQuickJumper={false}
                                    defaultPageSize={listing.length}
                                    onChange={this.onPageChange}
                                />
                            </>
                            : null
                        }
                    </div>

                </div>
            </Modal >
        )
    }
}
// Customizable Area End