import React, { createRef, useState } from 'react';
require('./loader.css');

export default class GlobalLoader extends React.Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <div className="spinner">
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </div>
    );
  }
}
