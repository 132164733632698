import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Swal from "sweetalert2";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { string } from "yup";
import { message as AntMessage } from "antd";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  errors: { email: string; password: string };
  message: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End
  verifyLoginApiCallId: String;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      errors: { email: "", password: "" },
      message: "",
      loading: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.callGetValidationApi();
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    color: "#6200EE",
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  // async receive(from: string, message: Message) {
  //   // Customizable Area Start

  //   if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
  //     const userName = message.getData(getName(MessageEnum.LoginUserName));

  //     const password = message.getData(getName(MessageEnum.LoginPassword));

  //     const countryCode = message.getData(
  //       getName(MessageEnum.LoginCountryCode)
  //     );

  //     if (!countryCode && userName && password) {
  //       this.setState({
  //         email: userName,
  //         password: password,
  //         checkedRememberMe: true,
  //       });

  //       //@ts-ignore
  //       this.txtInputEmailProps.value = userName;

  //       //@ts-ignore
  //       this.txtInputPasswordProps.value = password;

  //       this.CustomCheckBoxProps.isChecked = true;
  //     }
  //   } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  //     const apiRequestCallId = message.getData(
  //       getName(MessageEnum.RestAPIResponceDataMessage)
  //     );

  //     var responseJson = message.getData(
  //       getName(MessageEnum.RestAPIResponceSuccessMessage)
  //     );

  //     var errorReponse = message.getData(
  //       getName(MessageEnum.RestAPIResponceErrorMessage)
  //     );

  //     if (apiRequestCallId != null) {
  //       if (
  //         apiRequestCallId === this.validationApiCallId &&
  //         responseJson !== undefined
  //       ) {
  //         var arrayholder = responseJson.data;

  //         if (arrayholder && arrayholder.length !== 0) {
  //           let regexData = arrayholder[0];

  //           if (regexData && regexData.email_validation_regexp) {
  //             this.emailReg = new RegExp(regexData.email_validation_regexp);
  //           }
  //         }
  //       }

  //       if (apiRequestCallId === this.apiEmailLoginCallId) {
  //         if (responseJson && responseJson.meta && responseJson.meta.token) {
  //           runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  //           this.saveLoggedInUserData(responseJson);
  //           this.sendLoginSuccessMessage();
  //           this.openInfoPage();
  //         } else {
  //           //Check Error Response
  //           this.parseApiErrorResponse(responseJson);
  //           this.sendLoginFailMessage();
  //         }

  //         this.parseApiCatchErrorResponse(errorReponse);
  //       }
  //     }
  //   }
  //   // Customizable Area End
  // }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Api
  onPressverifyLogin = async (data: any) => {
    this.verifyLoginApiCallId = await this.apiCall({
      contentType: configJSON.loginApiContentType,
      method: configJSON.loginAPiMethod,
      endPoint: configJSON.loginAPiEndPoint,
      body: JSON.stringify(data),
    });
    // }
  };

  //getApiCall
  // this.getSurveyListId = await this.apiCall({
  //   contentType: configJSON.exampleApiContentType,
  //   method: configJSON.validationApiMethodType,
  //   endPoint: configJSON.exampleAPiEndPoint
  // })

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    // const token = (await StorageProvider.get("Userdata")) || "";
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log("Otppppppppppppppppppppp", responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.verifyLoginApiCallId) {

          this.verifyLoginSuccessCallBack(responseJson);
        }
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.verifyLoginApiCallId) {
          this.verifyLoginFailureCallBack(responseJson);
        }
      } else if (errorReponse) {
        this.setState({
          message: "",
          // isFetching: false,
        });
      }
    }
  }
  verifyLoginSuccessCallBack = async (response: any) => {
    if (response?.data?.attributes?.role?.data == null) {
      Swal.fire({
        text: "Login Failed ! Role is not assigned to this user.",
        icon: 'error',
        showCloseButton: true
      });
      return;
    }
    localStorage.setItem("token", response.meta.token);
    localStorage.setItem("fullName", response.data.attributes.full_name);
    localStorage.setItem("userId", response.data.id);
    localStorage.setItem("store_id", response?.data?.attributes?.store ? response?.data?.attributes?.store?.id : null);
    localStorage.setItem("role", response?.data?.attributes?.role?.data?.attributes?.name);
    /*
    Getting the Permission in Json Storing  Permission JSOn into Storage by Encyptions.
    Using atob and bto a function to encryt the JSON
    */
    let encodedString = btoa(JSON.stringify(response.data.attributes.role.data.attributes.permissions.data));
    localStorage.setItem("permissions", encodedString);

    this.setState({ loading: false });

    let data = response.data.attributes.role.data.attributes.permissions.data;
    let permissions = data && data[0];
    if (permissions.attributes.access_module.name == "Dashboard") {
      window.location.href = '/Dashboard'
    }
    if (permissions.attributes.access_module.name == "Catalogue") {
      window.location.href = '/Catalogue'
    }
    if (permissions.attributes.access_module.name == "Roles and Permission") {
      window.location.href = '/Roles&Permission'
    }
    if (permissions.attributes.access_module.name == "Store") {
      window.location.href = '/Store'
    }
    if (permissions.attributes.access_module.name == "People") {
      window.location.href = '/People'
    }
    if (permissions.attributes.access_module.name == "Store Timings") {
      window.location.href = '/StoreTimings'
    }
    if (permissions.attributes.access_module.name == "Inventory") {
      window.location.href = '/Inventory'
    }
    if (permissions.attributes.access_module.name == "Reporting") {
      window.location.href = '/Reporting'
    }
    if (permissions.attributes.access_module.name == "Store Dashboard") {
      window.location.href = '/StoreDashboard'
    }
  };

  verifyLoginFailureCallBack = (error: any) => {
    this.setState({ loading: false })
    Swal.fire({
      text: error ? error?.errors[0]?.failed_login : "Login Failed !",
      icon: 'error',
      showCloseButton: true
    })
  };

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
      device_id: localStorage.getItem('notification')
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
