import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { message as AntMessage } from "antd";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  listing: any;
  notificationCount: number;
  value: string;
  apiLoading: boolean;
  totalNotificationLength: any;
  pagination: any;
}

interface SS {
  id: any;
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {

  getNotifications: string;
  FTPImportInformation: string;
  readNotification: any;
  readAllNotifications: any;
  search: any;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      listing: [],
      notificationCount: 0,
      value: '',
      apiLoading: false,
      totalNotificationLength: 0,
      pagination: {
        currentPage: 1,
        pageSize: 25
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getNotificationList();
    let Role = localStorage.getItem('role');
    // if (Role?.toLowerCase() == 'admin') {
    //   this.FTPImportAPI();
    // }
  }

  getNotificationList = async () => {
    this.setState({ apiLoading: true });
    this.getNotifications = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.fetchNotification,
    })
  }

  fetchNotificationAccordingToPagination = async (page: any, pagesize: any) => {
    this.getNotifications = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.fetchNotification + `?page=${page}&per_page=${pagesize}`,
    })
  }

  FTPImportAPI = async () => {
    setInterval(async () => {
      console.log("FTP Import Called");
      this.FTPImportInformation = await this.apiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.FTPImportApiEndpoint,
      })
    }, 900000) // For Every 15 miniuts  900000
  }

  readSingleNotificationFunction = async (id: any) => {
    this.readNotification = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.readSingleNotification + `/${id}`,
    })
  }

  readAllNotification = async () => {
    this.setState({ apiLoading: true })
    this.readAllNotifications = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.unreadAllNotification
    })
  }

  searchNotification = async (value: string) => {
    this.setState({ apiLoading: true });
    this.search = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.searchNotification + value
    })
  }


  //============================= API CALL BLOCK ==========================================================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      // console.log('responseJson>>>>>>>>>', responseJson);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.getNotifications) {
          this.verifyGetNotifications(responseJson);
        }
        if (apiRequestCallId === this.FTPImportInformation) {
          this.FTPImportSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.readNotification) {
          this.verifySingleNotification(responseJson);
        }
        if (apiRequestCallId === this.readAllNotifications) {
          this.verifyUnreadNotifications(responseJson)
        }
        if (apiRequestCallId === this.search) {
          this.verifySearchNotificationList(responseJson)
        }
      }
      else if (responseJson && responseJson.errors || responseJson == undefined) {
        if (apiRequestCallId === this.FTPImportInformation) {
          this.FTPImportErrorResponse(responseJson);
        }
        if (apiRequestCallId === this.getNotifications) {
          this.setState({ listing: [] })
        }
      }


    }
  }

  verifyGetNotifications = async (response: any) => {
    // console.log(response, 'verifyGetNotifications');

    this.setState({
      listing: response.data,
      totalNotificationLength: response.meta?.total_page * 25,
      apiLoading: false,
      notificationCount: response?.meta?.count
    });
    // const isRead = false;
    // const unread = this.state.listing.filter((val: any) => { if (val.attributes.is_read == false) { return val } });
    // console.log(unread, 'unread');
    // this.setState({ notificationCount: unread?.length })
    // this.setState({ notificationCount: response?.meta?.count })

  }

  verifySingleNotification = async (response: any) => {
    // this.componentDidMount();
    this.setState({ apiLoading: true })
    this.fetchNotificationAccordingToPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize)
    // this.getNotificationList();

  }

  FTPImportSuccessResponse = async (response: any) => {
    // console.log("FTP Import Synced Successfully");
  }

  verifyUnreadNotifications = async (response: any) => {
    console.log(response);
    this.setState({ apiLoading: false });
    AntMessage.success(response?.meta?.message)
    // this.setState({ listing: response.data == null || response.data.length == 0 ? [] : response.data });
    this.getNotificationList();
    //@ts-ignore
    // this.props.setNotificationCount(0);

  }

  verifySearchNotificationList = async (response: any) => {
    this.setState({ listing: response.data, apiLoading: false })
    // this.setState({ listing: response.data, notificationCount: response.data.length, apiLoading: false })
  }

  //============================= ERROR BLOCK =======================================
  FTPImportErrorResponse = async (response: any) => {
    console.log("FTP Import Unsuccessfull");
    AntMessage.success("FTP Import Successfull");
  }

}
