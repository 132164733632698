// Customizable Area Start
import EmailAccountLoginController, {
  Props,
  configJSON,
} from "../EmailAccountLoginController";
import React from "react";
import "./Login.css";
import { Alert, Input, Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

export default class Login extends EmailAccountLoginController {

  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: "",
      placeHolderPassword: "",
      imgPasswordVisible: "",
      imgPasswordInVisible: "",
      labelHeader: "",
      btnTxtLogin: "",
      labelRememberMe: "",
      btnTxtSocialLogin: "",
      labelOr: "",
      errors: { email: "", password: "" },
      message: "",
      loading: false,
    };
  }
  handleEmailField = (e: any) => {
    const { value } = e.target;
    const { errors } = this.state;
    if (value) {
      errors.email = "";
    }
    this.setState(
      {
        email: value,
      },
      () => this.validateEmail()
    );
  };
  handlePasswordField = (e: any) => {
    const { value } = e.target;
    const { errors } = this.state;
    if (value) {
      errors.password = "";
    }
    this.setState(
      {
        password: value,
        errors: errors,
      },
      () => this.validatePassword()
    );
  };
  validateEmail = () => {
    let errors = { email: "", password: this.state.errors.password };
    const { email } = this.state;
    let isValid = true;
    if (!email) {
      isValid = false;

      errors["email"] = "Please Enter Username.";
    }

    // if (email) {
    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );

    //   if (!pattern.test(email)) {
    //     isValid = false;

    //     errors["email"] = "Please enter valid email address.";
    //   }
    // }
    this.setState({
      errors: { email: errors.email, password: errors.password },
    });

    return isValid;
  };
  validatePassword() {
    let errors = { email: this.state.errors.email, password: "" };
    const { password } = this.state;
    let isValid = true;

    if (!password) {
      isValid = false;

      errors["password"] = "Please Enter Password.";
    }
    this.setState({
      errors: {
        email: errors.email,
        password: errors.password,
      },
    });

    return isValid;
  }
  validate = () => {
    let errors = { email: "", password: "" };
    const { password, email } = this.state;
    let isValid = true;
    if (!email) {
      isValid = false;

      errors["email"] = "Please Enter Username.";
    }

    // if (email) {
    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );

    //   if (!pattern.test(email)) {
    //     isValid = false;

    //     errors["email"] = "Please enter valid email address.";
    //   }
    // }
    if (!password) {
      isValid = false;

      errors["password"] = "Please Enter Password.";
    }
    this.setState({
      errors: {
        email: errors.email,
        password: errors.password,
      },
    });

    return isValid;
  };
  onClose = () => { };
  handleSubmit = async (event: any) => {
    this.setState({ loading: true })
    event.preventDefault();
    let isValid = this.validate();
    if (!isValid) {
      this.setState({ loading: false })
      // Swal.fire({
      //   text: "Please enter a valid details",
      //   icon: 'error',
      //   showCloseButton: true
      // })
    } else {
      const verifyData = {
        data: {
          type: "email_account",
          attributes: {
            user_name: this.state.email,
            password: this.state.password,
          },
        },
      };
      this.onPressverifyLogin(verifyData);
    }
  };
  render() {
    const { errors, loading } = this.state;
    return (
      <div className="LoginMainSection">
        <div className="imgBlock">
          <img src="https://www.24-seven.in/img/logo/X-mas-Logo.png" />
        </div>
        <div className="loginfieldBox">
          <div className="login-information">
            <h1 className="head">Login</h1>
            <h2 className="title">Enter your login details</h2>
            <form>
              <div>
                <label className="loginId" htmlFor="loginfield">
                  Login/Store ID
                </label>

                <Input
                  type="text"
                  prefix={<UserOutlined translate={{}} />}
                  value={this.state.email}
                  onChange={this.handleEmailField}
                  placeholder="Username"
                  id="loginfield"
                />
                {errors.email ? (
                  <span className="error" style={{ color: "red" }}>
                    {errors.email}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Input.Password
                  prefix={<LockOutlined translate={{}} />}
                  value={this.state.password}
                  type="password"
                  onChange={this.handlePasswordField}
                  placeholder="Password"
                  name="pwd" autoComplete="new-password"
                />
                {errors.password ? (
                  <span className="error" style={{ color: "red" }}>
                    {errors.password}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Button
                  htmlType="submit"
                  loading={loading}
                  onClick={this.handleSubmit}
                  className="signInBtn"
                >
                  Sign In
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
// Customizable Area End
