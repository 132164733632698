Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RequestManagement";
exports.labelBodyText = "RequestManagement Body";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDelMethod = "DELETE";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.getReportTypeApiEndPoint =
  "/bx_block_admin/admin/order_report?order_type=";
exports.outletWiseStatictsApiEndPoint =
  "/bx_block_adhocreporting/adhoc_reports/outlet_statistics?store_id=";
exports.dailyGocercySalesReportApiEndPoint =
  "/bx_block_adhocreporting/adhoc_reports/grocery_report?store_id=";
exports.getStoreClosingTimeLogReportApiEndPoint = `/bx_block_adhocreporting/adhoc_reports/store_closing_report`;
exports.getInventoryReportDataApiEndPoint = `/bx_block_adhocreporting/adhoc_reports/inventory_unavailability`;
exports.getSalesreportInformationByStoreId = `/bx_block_adhocreporting/adhoc_reports/sales_report`;
exports.getSalesreportInformation = `/bx_block_adhocreporting/adhoc_reports/sales_report`;
exports.getStoreApiEndPoint = `account_block/accounts/store_accounts/get_store`;
exports.getCategoryApiEndPoint = `/bx_block_categories/categories`;
exports.getAllCatalougeApiEndPoint =
  "/bx_block_catalogue/catalogues?status=true";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
