import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from ".././assets";
import Swal from 'sweetalert2'
import { message as AntMessage } from "antd";
import moment from 'moment';

// Customizable Area End

export const configJSON = require(".././config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    selectedRowKeys: any;
    addModal: boolean;
    days: any;
    Errors: any
    repeats: any;
    selectedTab: number;
    editModal: boolean;
    addOfcModal: boolean;
    editOfcModal: boolean;
    editZoneModal: boolean,
    addZoneModal: boolean,
    Storelist: any,
    OfcList: any,
    ZoneList: any,
    ofcIds: any
    zoneIds: any
    selectedStoreData: any,
    selectedOfcData: any,
    selectedZoneData: any,
    Inventorylist: any,
    selectedInventoryData: any,
    apiLoading: boolean,
    storeTimingsData: any,
    selectedStoreTimingData: any,
    closingStoreTimings: any,
    selectedCheckBoxData: any,
    pagination: any,
    InventoryApiLoading: boolean,
    modaLoader: boolean,
    selectedProfilePic: any,
    addItemUnavailable: any,
    getItemUnavailable: any,
    FtpModal: boolean,
    uploadingLoading: boolean,
    searchValue: any,
    selectedStoreId: any,

    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CfInventoryManagement10Controller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    base_url = `https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/`;
    public token = localStorage.getItem('token')
    public config = {
        headers: {
            'token': this.token || ''//'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NSwiZXhwIjoxNjM3MDQwNjg0fQ.5O46lD5pWKpsslFKx2gKi667-YdKDiKEB937oHoi8-_yHBtuDi2DxrcBUdE3t7VcSalptY73DLHoQtwyZgm0eA'
        }
    }
    getStoreInformation: string;
    getOfcInformation: string;
    getZoneInformation: string;
    addStoreInformation: string;
    addOfcInformation: string
    addZoneInformation: string
    editStoreInformation: string
    editOfcInformation: string
    editZoneInformation: string
    getAllInventoriesInformation: string
    addInventoriesInformation: string
    getInventoryByIdInformation: string
    getStoreByIdInformation: string
    getOfcByIdInformation: string
    getZoneByIdInformation: string
    editInventoryInformation: string
    editStoreTimingsInformation: string
    editMultipleStoreTimingsInformation: string
    getAllOfcRealtedToZoneId: string
    getselectedStoreClosingTime: string
    deleteParticularStoreClosingTime: string
    uploadStoreCsvFileInformation: string
    uploadOfcCsvFileInformation: string
    uploadZoneCsvFileInformation: string
    uploadInventoryCsvFileInformation: string
    storeSearchInformation: string
    inventorySearchInformation: string
    OfcSearchInformation: string
    ZoneSearchInformation: string
    FtpLocationInformation: string
    UpdateFtpLocationInformation: string
    UpdateStoreStatusInformation: string
    UpdateOfcStatusInformation: string
    UpdateZoneStatusInformation: string
    UpdateStoreProfileImage: string
    addItemAvailiblityInformation: string
    getItemAvailiblityInformation: string
    updateItemAvailiblityInformation: string
    getInventoryByStoreIdInformation: string

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            // Customizable Area Start
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            selectedRowKeys: [],
            addModal: false,
            days: [],
            Errors: {},
            repeats: [],
            selectedTab: 1,
            editModal: false,
            addOfcModal: false,
            editOfcModal: false,
            editZoneModal: false,
            addZoneModal: false,
            Storelist: [],
            OfcList: [],
            ZoneList: [],
            ofcIds: [],
            zoneIds: [],
            selectedStoreTimingData: {},
            closingStoreTimings: [],
            selectedStoreData: {},
            selectedOfcData: {},
            selectedZoneData: {},
            Inventorylist: [],
            selectedInventoryData: {},
            apiLoading: false,
            InventoryApiLoading: false,
            storeTimingsData: {
                startDate: '',
                EndDate: '',
                StartTime: '',
                EndTime: '',
                Days: [],
                repeats: [],

            },
            selectedCheckBoxData: [],
            pagination: {
                currentPage: 1,
                pageSize: 10,
                TotalInventoryCount: 0,
                TotalStoreCount: 0,
            },
            modaLoader: false,
            selectedProfilePic: "",
            addItemUnavailable: [],
            getItemUnavailable: {},
            FtpModal: false,
            uploadingLoading: false,
            searchValue: '',
            selectedStoreId: null,

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    toast(msg: any) {
        Swal.fire({
            toast: true,
            position: 'top-right',
            showConfirmButton: false,
            icon: 'success',
            timerProgressBar: false,
            timer: 4000,
            title: msg
        })
    }
    errorToast(msg: any) {
        Swal.fire({
            toast: true,
            position: 'top-right',
            showConfirmButton: false,
            icon: 'error',
            timerProgressBar: false,
            timer: 3000,
            title: msg
        })
    }





    //====================================================================================================
    async componentDidMount() {
        // Customizable Area Start
        this.ongetStore();
        this.ongetInventory(1, 10);
        // Customizable Area End
    }

    ongetStore = async () => {
        // this.setState({ apiLoading: true });
        this.getStoreInformation = await this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getStoreApiEndPoint,
        });
    };
    onGetStoreByPagination = async (page: any, pagesize: any) => {
        this.setState({ apiLoading: true });
        this.getStoreInformation = await this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getStoreApiEndPoint + `?page=${page}&per_page=${pagesize}`,
        });
    };


    ongetInventory = async (page: any, per_page: any) => {
        this.setState({ InventoryApiLoading: true, searchValue: '' });
        let store_id = localStorage.getItem('store_id');
        let ENDPOINT = configJSON.getInventoryByPaginationApiEndPoint + page + '&per_page=' + per_page + `${this.state?.selectedStoreId ? `&store_account_id=${this.state?.selectedStoreId}` : ''}`;
        if (store_id !== 'null') {
            ENDPOINT = configJSON.getInventoryByPaginationApiEndPoint + page + '&per_page=' + per_page + '&store_account_id=' + store_id
        }
        this.getAllInventoriesInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: ENDPOINT,
        });
    }

    getFtpLocation = async () => {
        this.setState({ apiLoading: true });
        this.FtpLocationInformation = await this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.FTPLocationApiEndPoint,
        });
    }



    addInventoryData = async (data: any) => {
        this.addInventoriesInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.addInventoryApiEndPoint,
            body: JSON.stringify(data),
        });
    }

    getAllInventoryById = async (id: number) => {
        this.getInventoryByIdInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.getInventoryByIdApiEndPoint}${id}`,
        });
    }

    editInventoryData = async (data: any, id: number) => {
        this.editInventoryInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.editInventoryApiEndPoint + id,
            body: JSON.stringify(data),
        });
    }




    deleteClosingTime = async (id: any, store_account_id: any) => {
        this.deleteParticularStoreClosingTime = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpDelMethod,
            endPoint: configJSON.deleteParticularStoreClosingTimeApiEndPoint + id + '&store_id=' + store_account_id, //4&store_id=5
            // body: JSON.stringify(data),

        });
    }







    uploadInventoryCsvFile = async (formData: any) => {
        this.uploadInventoryCsvFileInformation = await this.apiCall({
            contentType: undefined,//`multipart/form-data;boundary="boundary"`,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.InventoryImportCsvApiEndPoint,
            body: formData,

        });
    }


    searchInventoryDetails = async (searchedText: any) => {
        let store_id = localStorage.getItem('store_id');
        let ENDPOINT = configJSON.InventorySearchApiEndPoint + searchedText;
        if (store_id !== 'null') {
            ENDPOINT = configJSON.InventorySearchApiEndPoint + searchedText + '&store_account_id=' + store_id
        }
        if (this.state?.selectedStoreId) {
            ENDPOINT = configJSON.InventorySearchApiEndPoint + searchedText + '&store_account_id=' + this.state?.selectedStoreId
        }
        this.inventorySearchInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: ENDPOINT,
        });
    }



    updateFTPLocation = async (FTPLocation: any) => {
        this.UpdateFtpLocationInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.UpdateFTPLocationApiEndPoint,
            body: JSON.stringify(FTPLocation)
        });
    }




    addItemAvailiblity = async (data: any, id: any) => {
        this.addItemAvailiblityInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.getInventoryByIdApiEndPoint + id + '/add_unavailability',
            body: JSON.stringify(data)
        });
    }
    handelGetInventoryAvailablity = async (id: number) => {
        this.getItemAvailiblityInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.getInventoryByIdApiEndPoint}${id}`,
        });
    }
    updateItemUnavalablity = async (data: any, id: any) => {
        this.updateItemAvailiblityInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.getInventoryByIdApiEndPoint + id + '/edit_unavailability',
            body: JSON.stringify(data)
        });
    }
    getInventoryDataWithStoreId = async (id: any) => {
        this.getInventoryByStoreIdInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getInventoryByStoreId + id + `&page=1&per_page=10`,
        });
    }
    //=============== API CALL BLOCK ==============================
    apiCall = async (data: any) => {
        const { contentType, method, endPoint, body } = data;
        const token = localStorage.getItem('token')
        const header = {
            "Content-Type": contentType,
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };


    //========================================= RECEIVE API CALL BLOCK ===================================================

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("responseJson>>>>>>>", responseJson)
            if (responseJson?.errors && responseJson?.errors[0]?.token) {
                if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
                    localStorage.clear();
                    window.location.href = '/';
                };
                if (responseJson == undefined) {
                    // AntMessage.error('Something went wrong')
                    return
                }
            }
            if (responseJson && responseJson.data || responseJson?.message) {
                if (apiRequestCallId === this.getStoreInformation) {
                    // this.setState({ apiLoading: false });
                    this.handelGetStoreData(responseJson);
                }
                if (apiRequestCallId === this.getAllInventoriesInformation) {
                    this._handelGetInventoryData(responseJson);
                }
                if (apiRequestCallId === this.getInventoryByIdInformation) {
                    this._handelGetInventoryDataById(responseJson);
                }
                if (apiRequestCallId === this.editInventoryInformation) {
                    this._handelEditInventoryData(responseJson);
                }
                if (apiRequestCallId === this.getAllOfcRealtedToZoneId) {
                    this._handelgetAllOfcRealtedToZoneSuccessResponse(responseJson);
                }
                if (apiRequestCallId === this.uploadInventoryCsvFileInformation) {
                    this._handelImportInventoryCsvSuccessResponse(responseJson);
                }
                if (apiRequestCallId === this.FtpLocationInformation) {
                    this._handelFtpLocation(responseJson)
                }
                if (apiRequestCallId === this.UpdateFtpLocationInformation) {
                    this._handelUpdateFtpLocation(responseJson)
                }

                if (apiRequestCallId === this.addItemAvailiblityInformation) {
                    this._handeladdItemAvailiblityInformationSuccessResponse(responseJson)
                }
                if (apiRequestCallId === this.getItemAvailiblityInformation) {
                    this.setState({ selectedInventoryData: responseJson.data.attributes?.unavailabilities })
                }
                if (apiRequestCallId === this.updateItemAvailiblityInformation) {
                    this._handelupdateItemUnavailiblityInformationSuccessResponse(responseJson)
                }
                if (apiRequestCallId === this.getInventoryByStoreIdInformation) {
                    this._handelGetInventoryData(responseJson);
                }
                if (apiRequestCallId === this.inventorySearchInformation) {
                    this._handelGetInventoryData(responseJson);
                }

            } else if (responseJson && responseJson.error || responseJson?.errors || responseJson == undefined) {
                if (apiRequestCallId === this.getStoreInformation) {
                    // AntMessage.error('Something Went Wrong')//antd
                }
                if (apiRequestCallId === this.editStoreInformation) {
                    responseJson.errors.password ?
                        AntMessage.error(responseJson.errors.password) : null;
                    // AntMessage.error('Something went wrong')
                }
                if (apiRequestCallId === this.addInventoriesInformation) {
                    this._handelAddInventoryErrorResponse(responseJson);
                }
                if (apiRequestCallId === this.getselectedStoreClosingTime) {
                    console.log(responseJson);
                }

                if (apiRequestCallId === this.uploadInventoryCsvFileInformation) {
                    this._handelImportInventoryCsvErrorResponse(responseJson);
                }
                if (apiRequestCallId === this.addItemAvailiblityInformation) {
                    this._handeladdItemAvailiblityInformationErrorResponse(responseJson)
                }
                if (apiRequestCallId === this.updateItemAvailiblityInformation) {
                    this._handelupdateItemUnavailiblityInformationErrorResponse(responseJson)
                }
                if (apiRequestCallId === this.editInventoryInformation) {
                    this._handelEditInventoryErrorData(responseJson);
                }
                if (apiRequestCallId === this.editInventoryInformation) {
                    this._handelGetInventoryErrorData(responseJson);
                }
                else {
                    // AntMessage.error('Something went wrong');
                }
            } else if (errorReponse) {

            }
        }
    }
    //===================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //handelGetStoreData
    handelGetStoreData = async (data: any) => {

        let storeListDataTobeDisplayed = data.data.map((sd: any) => {
            return {
                id: sd.id,
                store_id: sd.storeId ? sd.storeId : '-',
                store_name: sd.store_name ? sd.store_name : '-',
                store_address: sd.address ? sd.address : '-',
                store_manager: sd.name ? sd.name : '-',
                store_contact: sd.phone_no ? sd.phone_no : '-',
                store_username: sd.user_name ? sd.user_name : '-',
                store_status: sd?.status ? sd.status : false,
                store_password: sd.password ? sd.password : '-',
                store_ofc_id: sd.ofc_unnique_number,//sd.ofc_id,
                store_zone_id: sd.zone_unique_id,
                outlet_id: sd?.phygital_store_id || sd?.dunzo_store_id || sd?.zomato_store_id ? [{ name: 'Phygital', id: sd?.phygital_store_id ? sd?.phygital_store_id : 'NA' }, { name: 'Dunzo', id: sd?.dunzo_store_id ? sd?.dunzo_store_id : 'NA' }, { name: 'Zomato', id: sd?.zomato_store_id ? sd?.zomato_store_id : 'NA' }] : [],

            }
        })
        this.setState({ Storelist: storeListDataTobeDisplayed, }
        )

    }
    //====================================================================================================
    _handelGetInventoryData = async (data: any) => {
        let inventoryListDataTobeDisplayed = data.data.map((sd: any, index: any) => {
            return {

                sno: (index + 1) + ((this.state.pagination.currentPage - 1) * 10),
                id: sd.attributes.store_account_id,
                inv_id: sd.id,
                store_name: sd.attributes.store_name,
                store_id: sd.attributes.store_id,
                itemName: sd.attributes?.item_name ? sd.attributes?.item_name : '-',
                articleCode: sd.attributes.article_code,
                description: sd.attributes.item_description,
                netMrp: sd.attributes.net_mrp,
                grossMrp: sd.attributes.gross_mrp,
                volume: sd.attributes.volume,
                weight: sd.attributes.weight,
                stockInQty: sd.attributes.stock_in_qty,
                syncedOn: sd.attributes.last_synced,
                availabile: sd.attributes.unavailabilities.data.length == 0 ? true : false

            }
        })
        this.setState({ Inventorylist: inventoryListDataTobeDisplayed, InventoryApiLoading: false, pagination: { ...this.state.pagination, TotalInventoryCount: data?.meta?.total_count ? data?.meta?.total_count : 40 } }
        )

    }
    //====================================================================================================
    _handelAddInventoryData = async (data: any) => {
        console.log(data);

        if (data.data.attributes) {
            this.setState({ addModal: false })
            this.componentDidMount();
            this.toast('Inventory Added Successfully!')
        } else {
            this.errorToast('Something went wrong!')
        }
    }
    //====================================================================================================
    _handelGetInventoryDataById = async (data: any) => {
        this.setState({ selectedInventoryData: data, modaLoader: false })
        let _data = {
            storeId: data.data.attributes.store_account_id,
            storeUniqueId: data.data.attributes.store_id,
            name: data.data.attributes.store_name,
            articleCode: data.data.attributes.article_code,
            itemName: data.data.attributes?.item_name ? data.data.attributes?.item_name : '-',
            description: data.data.attributes.item_description,
            netmrp: data.data.attributes.net_mrp,
            gmrp: data.data.attributes.gross_mrp,
            volume: data.data.attributes.volume,
            weight: data.data.attributes.weight,
            stock: data.data.attributes.stock_in_qty,
        }
        //@ts-ignore
        this.formRef.current.setFieldsValue(_data)
    }
    //====================================================================================================
    _handelEditInventoryData = async (data: any) => {
        if (data.data) {
            this.setState({ editModal: false })
            // this.toast('Inventory data updated successfully!')
            AntMessage.success('Inventory data updated successfully!')
            this.componentDidMount();
        }
    }
    //====================================================================================================
    //====================================================================================================
    // _handelgetAllOfcRealtedToZoneSuccessResponse
    _handelgetAllOfcRealtedToZoneSuccessResponse = async (data: any) => {
        console.log(data);
        this.setState({ ofcIds: data.data })
        //@ts-ignore
        // this.formRef.current?.setFieldsValue({ ofc_id:data.data})


    }
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    // _handelImportInventoryCsvSuccessResponse
    _handelImportInventoryCsvSuccessResponse = async (data: any) => {
        if (data.status == 200) {
            this.setState({ InventoryApiLoading: false, uploadingLoading: false })
            AntMessage.success('File Imported Successfully ');
            this.componentDidMount();
        }
    }
    //====================================================================================================
    // _handelFtpLocation
    _handelFtpLocation = async (data: any) => {
        if (data.data) {
            this.setState({ txtSavedValue: data.data.attributes.address, txtInputValue: data.data.attributes.address, apiLoading: false });
            let _data = {
                Username: data.data.attributes.user_name,
                Password: data.data.attributes.password,
                Address: data.data.attributes.address,
                FilePath: data.data.attributes.file_path,
                FileName: data.data.attributes.file_name,
            }
            setTimeout(() => {
                console.log(_data);

                //@ts-ignore
                this.formRef.current?.setFieldsValue(_data)
            }, 100)

        }

    }
    //====================================================================================================
    // _handelUpdateFtpLocation
    _handelUpdateFtpLocation = async (data: any) => {
        if (data.data) {
            AntMessage.success("FTP Loaction Successfully Updated.")
            // this.setState({ enableField: false, txtSavedValue: data.data.attributes.address, txtInputValue: data.data.attributes.address })
            this.setState({ FtpModal: false })
        }

    }
    //====================================================================================================
    // _handeladdItemAvailiblityInformationErrorResponse
    _handeladdItemAvailiblityInformationSuccessResponse = async (data: any) => {
        // console.log(data);
        if (data.data) {
            AntMessage.success('Item updated');
            this.setState({ addOfcModal: false });
            this.ongetInventory(1, 10);
        }

    }
    //====================================================================================================
    // _handelupdateItemUnavailiblityInformationErrorResponse
    _handelupdateItemUnavailiblityInformationSuccessResponse = async (data: any) => {
        console.log(data);
        if (data.data) {
            AntMessage.success('Item updated');
            this.ongetInventory(1, 10);
        }
        this.setState({ editZoneModal: false })
    }
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================




    //ERROR BLOCK
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    // _handelAddInventoryErrorResponse
    _handelAddInventoryErrorResponse = async (data: any) => {
        console.log('_handelAddInventoryErrorResponse', data);
        // AntMessage.error('Something went wrong!')
    }
    //====================================================================================================
    //====================================================================================================
    // _handelImportInventoryCsvErrorResponse
    _handelImportInventoryCsvErrorResponse = async (data: any) => {
        console.log(data, "FTP Import Unsuccessfull Error");
        this.setState({ InventoryApiLoading: false, uploadingLoading: false })
        AntMessage.success('File Imported Successfully ');
        this.componentDidMount();

    }
    //====================================================================================================

    //====================================================================================================
    //====================================================================================================
    // _handeladdItemAvailiblityInformationErrorResponse
    _handeladdItemAvailiblityInformationErrorResponse = async (data: any) => {
        console.log(data);
    }
    //====================================================================================================
    // _handelupdateItemUnavailiblityInformationErrorResponse
    _handelupdateItemUnavailiblityInformationErrorResponse = async (data: any) => {
        console.log(data);
    }
    //====================================================================================================
    // _handelEditInventoryErrorData
    _handelEditInventoryErrorData = async (data: any) => {
        if (data.message) {
            AntMessage.success(data.message)
        }
    }
    //====================================================================================================
    //====================================================================================================
    // _handelGetInventoryErrorData
    _handelGetInventoryErrorData = async (data: any) => {
        console.log(data);
        data.error ? AntMessage.error(data.error) : '';
    }
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================


    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        },
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible,
    };

    btnExampleProps = {
        onPress: () => this.doButtonPressed(),
    };

    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }

    // Customizable Area Start
    // Customizable Area End
}
    // //===================================== API CALLING BLOCK ============================================
