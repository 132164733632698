// Customizable Area Start
import React, { createRef, useState } from "react";
import Settings2Controller, {
    Props,
    configJSON,
} from "../src/Settings2Controller";
import "antd/dist/antd.css";
import {
    Table,
    Select,
    Button,
    Modal,
    Upload,
    Input,
    Row,
    Col,
    Form,
    Switch,
    Typography,
    TimePicker,
    DatePicker,
    Radio,
    Space,
    Avatar,
    Tabs,
    Card,
    Spin
} from "antd";
import { CloseCircleFilled, EditOutlined, LockFilled, SyncOutlined } from "@ant-design/icons";
// import "./Store.css";
import { FormInstance } from "antd/es/form";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
    EditFilled,
    DeleteFilled,
    UploadOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
const { TabPane } = Tabs;
const { Title, Paragraph, Text, Link } = Typography;


class Settings extends Settings2Controller {
    public phoneNoError: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            error: "",
            profileData: {},
            profileImage: "",
            loading: false,

            // Customizable Area End

        };

    }

    formRef = createRef<FormInstance>();
    onFinish = (values: any) => {
        console.log("Success:", values);
    };

    onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    onReset = () => {
        this.formRef.current!.resetFields();
    };

    handleSwitch = (e: any, data: any) => {
        console.log(e);
    };

    handelChangePassword = async (values: any) => {
        this.setState({ error: "" });
        console.log("Success:", values);
        this.changePassword(values);

    };
    handelprofileEdit = async (values: any) => {
        this.setState({ loading: true });
        console.log("Success:", values);
        this.updateProfileData(values)

    };

    uploadProfilePicture = async (e: any) => {
        const data = new FormData();
        data.append("data[avatar]", e.file);
        this.uploadProfile(data)
    }
    //=====================================================================

    render() {
        //@ts-ignore
        const { modalType, openChangePasswordModal, handleCloseChangePasswordModal, openProfileBioModal, handleprofileBioModal } = this.props

        const { error, loading } = this.state;
        const closeModal = async () => {
            handleCloseChangePasswordModal(false)
        }
        // console.log(this.state?.profileData?.zone !== null && this.state?.profileData?.ofc !== null && this.state?.profileData?.store_account_id !== null);

        return (
            <>
                {

                    modalType == "ChangePassword" ?

                        <Modal
                            className="addstoreModalBox"
                            visible={openChangePasswordModal}
                            title={null}
                            centered
                            width="45vw"
                            onCancel={() => {
                                this.onReset();
                                this.setState({ error: "" })
                                handleCloseChangePasswordModal(false);
                            }}
                            closeIcon={
                                <CloseCircleFilled
                                    style={{ color: '#000', fontSize: '20px' }}
                                    translate={{}}
                                />
                            }
                            footer={null}
                        >
                            <div className="AddStoreTimingsSection">
                                <Row className="header">
                                    <Col span={24}>
                                        <h1 className="mb-0 modal-heading">Change Password</h1>
                                    </Col>
                                    <Col span={24}>
                                        <p className="mb-0 modal-subtext">Enter new password</p>
                                    </Col>
                                </Row>
                                <Row>

                                    {error != "" ? <span style={{ color: 'red' }}>{error}</span> : ''}
                                </Row>
                                <Form
                                    onFinish={this.handelChangePassword}
                                    onFinishFailed={this.onFinishFailed}
                                    ref={this.formRef}
                                    name="content-ref"
                                    autoComplete="false"
                                    size='large'
                                >
                                    <div className="modal-rows ">
                                        <div className="rowCol">
                                            <Form.Item name="current_password" rules={[{ required: true, message: 'Current Password is required' }]} >
                                                <Input
                                                    id="current_password"
                                                    name="cpwd"
                                                    autoComplete="new-password"
                                                    placeholder="Current Password" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="modal-rows ">
                                        <div className="rowCol">
                                            <Form.Item
                                                name="new_password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your new password!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {

                                                            if (value.length < 8) {
                                                                return Promise.reject("Password can't be less than 8 characters");
                                                            } else {
                                                                return Promise.resolve();
                                                            }


                                                        },
                                                    }),
                                                ]}

                                            >
                                                <Input.Password
                                                    name="ccpwd"
                                                    autoComplete="new-password"
                                                    placeholder="New Password" />
                                            </Form.Item>

                                        </div>
                                        <div className="modal-rows ">
                                            <div className="rowCol">
                                                <Form.Item
                                                    name="confirm_password"
                                                    dependencies={['new_password']}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please confirm your password!',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('new_password') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Passwords do not match!'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password placeholder="Confirm Password" />
                                                </Form.Item>


                                            </div>
                                        </div>
                                    </div>
                                    <Row className="saveBtnbox">
                                        <Col span={6}>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    htmlType="submit"
                                                    className="content-add-btn saveBtn"
                                                >
                                                    Save
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Modal>

                        : modalType == "Profile" ?

                            <Modal
                                className="addstoreModalBox"
                                visible={openProfileBioModal}
                                title={null}
                                centered
                                width="45vw"
                                onCancel={() => {
                                    handleprofileBioModal(false)

                                }
                                }
                                closeIcon={
                                    <CloseCircleFilled
                                        style={{ color: "#000", fontSize: "20px" }}
                                        translate={{}}
                                    />
                                }
                                footer={null}
                            >
                                <div className="AddStoreTimingsSection">
                                    <Row className="header">
                                        <Col span={24}>
                                            <h1 className="mb-0 modal-heading">Profile</h1>
                                        </Col>
                                        <Col span={24}>
                                            <p className="mb-0 modal-subtext">View Your Profile Details</p>
                                        </Col>
                                    </Row>
                                    <Row className="imgBoxBlock">
                                        <div className="imgBox" title={`Edit Profile Picture`}>
                                            <img
                                                className="img-fluid"
                                                height='100px'
                                                width="100px"
                                                style={{ borderRadius: 50 }}
                                                src={this.state.profileImage !== "" ? this.state.profileImage : "https://www.24-seven.in/img/logo/X-mas-Logo.png"} />
                                            <Upload
                                                customRequest={(e) => { this.uploadProfilePicture(e) }}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    color="transparent"
                                                    className="edit_btn"
                                                >
                                                    <EditOutlined translate={{}} />
                                                </Button>
                                            </Upload>

                                        </div>
                                    </Row>

                                    <Form
                                        onFinish={this.handelprofileEdit}
                                        // onFinish={this.onFinish}
                                        onFinishFailed={this.onFinishFailed}
                                        ref={this.formRef}
                                        name="content-ref"
                                        autoComplete="off"
                                    >

                                        <Spin
                                            spinning={loading}
                                            indicator={<SyncOutlined translate={{}} spin style={{ color: "#000" }} />}
                                        >

                                            <Row className="modal-rows fieldBox">
                                                <Col className="rowCol" span={6}>
                                                    <label>Name:-</label>
                                                    <Form.Item name="name">
                                                        <Input type="text" placeholder="Name"
                                                            disabled={this.state?.profileData?.zone !== null && this.state?.profileData?.ofc !== null && this.state?.profileData?.store_account_id !== null}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="store_account_id" noStyle >
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                </Col>
                                                <Col className="rowCol" span={6}>
                                                    <label>Password:-</label>
                                                    <Form.Item name="password"
                                                    >
                                                        <Input type="text" placeholder="Password"
                                                            disabled={this.state?.profileData?.zone !== null && this.state?.profileData?.ofc !== null && this.state?.profileData?.store_account_id !== null}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                            <Row className="modal-rows fieldBox">
                                                <Col className="rowCol" span={6}>
                                                    <label>Role:-</label>
                                                    <Form.Item name="role"  >
                                                        <Input type="text" placeholder="Role"
                                                            disabled={this.state?.profileData?.zone !== null && this.state?.profileData?.ofc !== null && this.state?.profileData?.store_account_id !== null}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col className="rowCol" span={6}>
                                                    <label>Contact:-</label>
                                                    <Form.Item name="phone_number">
                                                        <Input type="text" placeholder="Phone number"
                                                            disabled={this.state?.profileData?.zone !== null && this.state?.profileData?.ofc !== null && this.state?.profileData?.store_account_id !== null}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row className="modal-rows fieldBox">
                                                <Col className="rowCol" span={6}>
                                                    <label>Username:-</label>
                                                    <Form.Item name="username">
                                                        <Input placeholder="Username" disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col className="rowCol" span={6}>
                                                    <Row className="modal-rows fieldBox selectdropBox">
                                                        <Col span={6}>
                                                            <label>Ofc:-</label>
                                                            <Form.Item name="ofc_id">{/**  rules={[{ required: true }]} */}
                                                                <Input type="text" className="modal-role-text" placeholder="#OFC ID" disabled />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <label>Zone:-</label>
                                                            <Form.Item name="zone_id">
                                                                <Input type="text" className="modal-role-text" placeholder="#Zone Id" disabled />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="saveBtnbox">
                                                <Col span={6}>
                                                    <Form.Item>
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            htmlType="submit"
                                                            className="content-add-btn saveBtn"
                                                        >
                                                            Edit Profile
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Spin>

                                    </Form>
                                </div>
                            </Modal>
                            : ''


                }





            </>
        );
    }
}

export default Settings;
// Customizable Area End














