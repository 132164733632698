import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { message as AntMessage } from "antd";
import moment from 'moment';
import { EDOM } from "constants";
import { date } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  cardArray: any;
  selectedRowKeys: [];
  // Customizable Area Start
  selectedReport: any
  FiltercardArray: any
  ReportDataList: any
  Storelist: any
  apiLoading: boolean,
  selectedCategory: any;
  selectedStoreName: any;
  selectedStore: any;
  CategoryList: any;
  to_date: any;
  from_date: any;
  pagination: any;
  selectedCategoryName: any;
  catalogueList: any;
  selectedCatalougeId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReportInformation: string;
  getCategoryInformation: string;
  getStoreInformation: string;
  currentStockReportInformation: string;
  dailyGocercySalesReportInformation: string;
  outletWiseStatictsInformation: string;
  getInventoryReportDataInformation: string;
  getStoreClosingTimeLogReportInformation: string;
  getInventoryReportDataInformationByStoreId: string;
  getStoreClosingTimeLogReportInformationByStoreId: string;
  getSalesreportInformationByStoreId: string;
  getPartiallAcceptanceReport: string;
  getCatalougeInformation: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      cardArray: [
        { id: 1, title: "Sales Report", type: 'Orders fulfilled' },
        { id: 2, title: "Order Cancellation Reason Report", type: 'Rejected' },
        { id: 3, title: "Partial Order Acceptance Reason Report", type: 'Partially accepted' },
        { id: 4, title: "Inventory report", type: 'Orders fulfilled' },
        { id: 5, title: "Store Close and Open Logs", type: 'Orders fulfilled' },
        { id: 6, title: "Outlet Wise statistics", type: 'OutletStatistics' },
        { id: 7, title: "Current Stock Report", type: 'currentStock' },
        { id: 8, title: "Daily Grocery sales report ", type: 'DailyGrocery' },
        // { id: 9, title: "Merchnat Payout report ", type: 'MerchantPayout' },
      ],
      FiltercardArray: [],
      selectedRowKeys: [],
      // Customizable Area Start
      selectedReport: {},
      ReportDataList: [],
      Storelist: [],
      apiLoading: false,
      selectedCategory: null,
      selectedStore: null,
      selectedStoreName: 'Select Store',
      selectedCategoryName: 'Select Category',
      CategoryList: [],
      to_date: null,
      from_date: null,
      pagination: {
        current: 1,
        pageSize: 10,
        Totalcount: 0

      },
      catalogueList: [],
      selectedCatalougeId: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }



  async componentDidMount() {
    this.setState({ FiltercardArray: this.state.cardArray });
    //@ts-ignore
    const ID = this.props.history.location.state?.detail?.id;
    //@ts-ignore
    const TYPE = this.props.history.location.state?.detail?.type;

    if (ID == 1) {
      this.setState({ apiLoading: true })
      this.ongetStore();
      this.updateSalesReport();
    }
    if (ID == 2) {
      this.setState({ apiLoading: true })
      this.getReportData(TYPE);
    }
    if (ID == 3) {
      this.setState({ apiLoading: true })
      this.getPartiallyAcceptanceReportData(TYPE, 1, 10);
    }

    if (ID == 4) {
      this.setState({ apiLoading: true })
      this.ongetStore();
      this.getInventoryReportData(TYPE, 1, 10)

    }
    if (ID == 5) {
      this.setState({ apiLoading: true })
      this.ongetStore();
      this.getStoreClosingTimeLogReport(TYPE)
    }
    if (ID == 6 || ID == 7 || ID == 8) {
      this.setState({ apiLoading: false })
      this.ongetStore();
      this.getAllCatargories();
    }
    if (ID == 9) {

    }
    this.getCatalouge();

  }

  getCatalouge = async () => {
    // this.setState({ apiLoading: true })
    this.getCatalougeInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllCatalougeApiEndPoint,
    });
  }

  ongetStore = async () => {
    this.getStoreInformation = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getStoreApiEndPoint,
    });
  };

  getReportData = async (type: any,) => {
    this.getReportInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getReportTypeApiEndPoint + type,
      // body: JSON.stringify(data)
    });
  }
  getReportDataByCatalougeId = async (type: any, catalouge_id: any) => {
    this.getReportInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getReportTypeApiEndPoint + type + `&catalogue_id=${catalouge_id}`,
      // body: JSON.stringify(data)
    });
  }
  getPartiallyAcceptanceReportData = async (type: any, page: any, per_page: any) => {
    this.getPartiallAcceptanceReport = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `/bx_block_admin/admin/order_partially_accepted?token=${localStorage.getItem('token')}&order_type=${type}&page=${page}&per_page=${per_page}`,
      // body: JSON.stringify(data)
    });
  }
  getPartiallyAcceptanceReportDataByCatalougeId = async (type: any, page: any, per_page: any, catalouge_id: any) => {
    this.getPartiallAcceptanceReport = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `/bx_block_admin/admin/order_partially_accepted?token=${localStorage.getItem('token')}&order_type=${type}&page=${page}&per_page=${per_page}` + `&catalogue_id=${catalouge_id}`,
      // body: JSON.stringify(data)
    });
  }

  getAllCatargories = async () => {
    this.getCategoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getCategoryApiEndPoint,
      // body: JSON.stringify(data)
    });
  }

  updateCurrentStockReport = async (storeId: any, categoryId: any) => {
    this.setState({ ReportDataList: [], apiLoading: true });
    this.currentStockReportInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `/account_block/accounts/store_accounts/get_storewise_inventory?id=${storeId}&category_id=${categoryId}`,
    });
  }

  updateDailyGocercySalesReport = async (storeId: any) => {
    this.setState({ ReportDataList: [], apiLoading: true });
    this.dailyGocercySalesReportInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dailyGocercySalesReportApiEndPoint + `${storeId}`,
    });
  }
  updateDailyGocercySalesReportDateFilter = async (storeId: any, date: any) => {
    this.setState({ ReportDataList: [], apiLoading: true });
    this.dailyGocercySalesReportInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dailyGocercySalesReportApiEndPoint + `${storeId}${date}`,
    });
  }

  updateOutletStataictsByStoreId = async (storeId: any) => {
    this.setState({ ReportDataList: [], apiLoading: true });
    this.outletWiseStatictsInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.outletWiseStatictsApiEndPoint + `${storeId}`,
    });
  }

  updateOutletStataictReportDateFilter = async (storeId: any, date: any) => {
    this.setState({ ReportDataList: [] });
    this.outletWiseStatictsInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.outletWiseStatictsApiEndPoint + `${storeId}${date}`,

    });
  }
  getInventoryReportData = async (type: any, page: any, totalNoData: any) => {
    this.getInventoryReportDataInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getInventoryReportDataApiEndPoint + `?page=${page}&per_page=${totalNoData}`,
      // body: JSON.stringify(data)
    });
  }

  getStoreClosingTimeLogReport = async (type: any,) => {
    this.getStoreClosingTimeLogReportInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getStoreClosingTimeLogReportApiEndPoint,
      // body: JSON.stringify(data)
    });
  }

  updateStoreClosingOpeningTimeByStoreId = async (storeId: any) => {
    this.setState({ ReportDataList: [], apiLoading: true });
    this.getStoreClosingTimeLogReportInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getStoreClosingTimeLogReportApiEndPoint + `?store_id=${storeId}`,

    });
  }

  updateInventoryItemUnavalablityByStoreId = async (storeId: any) => {
    this.setState({ ReportDataList: [], apiLoading: true });
    this.getInventoryReportDataInformationByStoreId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getInventoryReportDataApiEndPoint + `?store_id=${storeId}`,
    });
  }

  updateSalesReportByStoreId = async (storeId: any) => {
    this.setState({ ReportDataList: [], apiLoading: true });
    this.getSalesreportInformationByStoreId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSalesreportInformationByStoreId + `?store_id=${storeId}`,
    });
  }

  updateSalesReport = async () => {
    this.setState({ ReportDataList: [] });
    this.getSalesreportInformationByStoreId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSalesreportInformationByStoreId,
    });
  }
  updateSalesReportByCatalogeId = async (catalouge_id: any) => {
    this.setState({ ReportDataList: [] });
    this.getSalesreportInformationByStoreId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSalesreportInformation + `?catalogue_id=${catalouge_id}`,
    });
  }

  //=============== API CALL BLOCK ==============================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  //===================================== RECIVE =====================================================
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.errors && responseJson?.errors[0]?.token || responseJson == undefined) {
        if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
          localStorage.clear();
          window.location.href = '/';
        };
        // if (responseJson == undefined) {
        //   // AntMessage.error('Something went wrong')
        //   return
        // }
      }
      if (responseJson && responseJson.data || responseJson.message) {
        if (apiRequestCallId === this.getStoreInformation) {
          // this.setState({ apiLoading: false });
          this.handelGetStoreData(responseJson);
        }

        if (apiRequestCallId === this.getReportInformation) {
          // this.setState({ apiLoading: false });
          this._handelGetReportSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.getPartiallAcceptanceReport) {
          this._handelGetPartiallAcceptanceReportSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.getCategoryInformation) {
          this._handelGetAllCategorySuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.currentStockReportInformation) {
          this._handelcurrentStockReportInformation(responseJson);
        }
        if (apiRequestCallId === this.dailyGocercySalesReportInformation) {
          this._handeldailyGocercySalesReportInformation(responseJson);
        }
        if (apiRequestCallId === this.outletWiseStatictsInformation) {
          this._handeloutletWiseStatictsInformation(responseJson);
        }
        if (apiRequestCallId === this.getInventoryReportDataInformation) {
          this._handelgetInventoryReportDataSuccessInformation(responseJson);
        }
        if (apiRequestCallId === this.getInventoryReportDataInformationByStoreId) {
          this._handelgetInventoryReportDataSuccessInformation(responseJson);
        }

        if (apiRequestCallId === this.getStoreClosingTimeLogReportInformation) {
          this._handelgetStoreClosingTimeLogReportSuccessInformation(responseJson);
        }
        if (apiRequestCallId === this.getSalesreportInformationByStoreId) {
          this._handelSalesReportSuccessInformation(responseJson);
        }
        if (apiRequestCallId === this.getCatalougeInformation) {
          this._handelgetCatalougeSuccessData(responseJson);
        }




      } else if (responseJson && responseJson.error || responseJson.errors || responseJson == undefined) {
        if (apiRequestCallId === this.getStoreInformation) {
          // AntMessage.error('Something Went Wrong')//antd
        }

        if (apiRequestCallId === this.getReportInformation) {
          this.setState({ apiLoading: false });
          this._handelGetReportErrorResponse(responseJson);
        }
        if (apiRequestCallId === this.getInventoryReportDataInformation) {
          this._handelgetInventoryReportDataErrorInformation(responseJson);
        }
        if (apiRequestCallId === this.getSalesreportInformationByStoreId) {
          this._handelSalesReportErrorInformation(responseJson);
        }
        if (apiRequestCallId === this.getStoreClosingTimeLogReportInformation) {
          this._handelgetStoreClosingTimeLogReportErrorInformation(responseJson);
        }
        if (apiRequestCallId === this.dailyGocercySalesReportInformation) {
          this._handeldailyGocercySalesReportInformationError(responseJson);
        }
        if (apiRequestCallId === this.currentStockReportInformation) {
          this._handelcurrentStockReportInformationError(responseJson);
        }
        if (apiRequestCallId === this.getPartiallAcceptanceReport) {
          this._handelGetPartiallAcceptanceReportErrorResponse(responseJson);
        }
      }
    }
  }


  //================================= SUCCESS RESPONSE ============================================
  _handelgetCatalougeSuccessData = async (data: any) => {
    this.setState({ catalogueList: data.data, });//apiLoading: false 
  }
  //========================================================================================================

  // _handelGetReportSuccessResponse
  _handelGetReportSuccessResponse = async (data: any) => {
    let dataToDisplay = data.data?.map((d: any, index: any) => {
      return {
        key: index,
        storeId: d.storeId ? d.storeId : '-',
        storeName: d.store_name ? d.store_name : '-',
        itemNumber: d.item_code ? d.item_code : '-',
        itemDescription: d.item_description ? d.item_description : '-',
        brandDescription: d.item_description ? d.item_description : '-',
        mrp: d.total_price ? d.total_price : '-',
        stockQty: d.stockQty ? d.stockQty : '-',
        reason: d?.reason ? d.reason : '-',
        orderId: d.partner_order_id ? d.partner_order_id : '-',
        orderPartner: d.catalogue_name,
        orderDate: d.updated_at ? moment(d.updated_at).format('DD-MM-YYYY') : '-',
        customerName: `${d.customer?.first_name} ${d.customer?.last_name}`,
        customerInfo: d.customer?.phone ? d.customer?.phone : '-',
        articleStatus: '-',
        orderStatus: d?.status ? d?.status : '-',
      }
    });
    this.setState({ ReportDataList: dataToDisplay, apiLoading: false })
  }
  //========================================================================================================
  _handelGetPartiallAcceptanceReportSuccessResponse = async (data: any) => {
    let dataToDisplay = data.data?.map((d: any, index: any) => {
      return {
        key: index,
        storeId: d.Store_ID ? d.Store_ID : '-',
        storeName: d.Store_Name ? d.Store_Name : '-',
        itemCode: d.Item_Code ? d.Item_Code : '-',
        itemName: d.Item_Name ? d.Item_Name : '-',
        reason: d?.Reason ? d.Reason : '-',
        orderId: d.Order_ID ? d.Order_ID : '-',
        orderPartner: d.Order_Partner ? d.Order_Partner : '-',
        orderDate: d.Order_Date ? moment(d.Order_Date).format('DD-MM-YYYY') : '-',
        customerName: d.Customer_Name && d.Customer_Name !== " " ? d.Customer_Name : '-',
        customerInfo: d.Customer_Information ? d.Customer_Information : '-',
        articleStatus: d.Article_Status ? d.Article_Status : '-',
      }
    });
    this.setState({ ReportDataList: dataToDisplay, apiLoading: false, pagination: { ...this.state.pagination, Totalcount: data?.total_count ? data?.total_count : 40 } }
    )
  }
  //========================================================================================================
  handelGetStoreData = async (data: any) => {
    let storeListDataTobeDisplayed = data.data.map((sd: any) => {
      return {
        id: sd.id,
        store_id: sd.storeId,
        store_name: sd.store_name,
        store_address: sd.address,
        store_manager: sd.name ? sd.name : '',
        store_contact: sd.phone_no,
        store_username: sd.user_name ? sd.user_name : '',
        store_status: sd?.status ? sd.status : false,
        store_password: sd.password ? sd.password : '',
        store_ofc_id: sd.ofc_unnique_number,//sd.ofc_id,
        store_zone_id: sd.zone_unique_id,

      }
    })
    this.setState({ Storelist: storeListDataTobeDisplayed });//apiLoading: false
  }
  //========================================================================================================
  _handelGetAllCategorySuccessResponse = async (data: any) => {
    this.setState({ CategoryList: data?.data })
  }
  //========================================================================================================
  _handelcurrentStockReportInformation = async (data: any) => {
    let currentReportData = data?.data?.map((d: any, index: any) => {
      return {
        key: index,
        itemName: d.attributes?.item_name ? d.attributes?.item_name : '-',
        itemQuantity: d.attributes?.item_quantity ? d.attributes?.item_quantity : '-',
        itemPrice: d.attributes?.net_price ? d.attributes?.net_price : '-',
      }

    })
    // console.log(currentReportData);
    this.setState({ ReportDataList: currentReportData, apiLoading: false });
  }
  //========================================================================================================
  _handeldailyGocercySalesReportInformation = async (data: any) => {
    let currentReportData = data?.data?.map((d: any, index: any) => {
      return {
        key: index,
        date: d?.Date ? d.Date : '-',
        storeCode: d?.store_code ? d.store_code : '-',
        orderNo: d?.order_no ? d.order_no : '-',
        BillNo: d?.bill_no ? d.bill_no : '-',
        mrp: d?.rate ? d.rate : '-',
        rate: d?.rate ? d.rate : '-',
        quantity: d?.quantity ? d.quantity : '-',
        paymentMode: d?.payment_mode ? d.payment_mode : '-',
      }

    })
    // console.log(currentReportData);
    this.setState({ ReportDataList: currentReportData, apiLoading: false });
  }
  //========================================================================================================
  _handeloutletWiseStatictsInformation = async (data: any) => {
    let currentReportData = data?.data?.map((d: any, index: any) => {
      return {
        key: index,
        storeId: d?.storeId ? d.storeId : '-',
        store_name: d?.store_name ? d.store_name : '-',
        store_manager: d?.store_manager ? d.store_manager : '-',
        zone: d?.zone_unique_id ? d.zone_unique_id : '-',
        ofc: d?.ofc_unnique_number ? d.ofc_unnique_number : '-',
        address: d?.address ? d.address : '-',
      }

    })
    this.setState({ ReportDataList: currentReportData, apiLoading: false });


  }
  //========================================================================================================
  _handelgetInventoryReportDataSuccessInformation = async (data: any) => {
    console.log("_handelgetInventoryReportDataSuccessInformation", data);
    let currentReportData = data?.data?.map((d: any, index: any) => {
      return {
        key: index,
        storeId: d?.store_id ? d.store_id : '-',
        storeName: d?.store_name ? d.store_name : '-',
        itemCode: d?.item_code ? d.item_code : '-',
        itemName: d?.zone_unique_id ? d.zone_unique_id : '-',
        unStartDate: d?.unavailability_start_date ? moment(d.unavailability_start_date).format('DD-MM-YYYY') : '-',
        unEndDate: d?.unavailability_end_date ? moment(d.unavailability_end_date).format('DD-MM-YYYY') : '-',
        unStartTime: d?.unavailability_start_time ? d.unavailability_start_time : '-',
        unEndTime: d?.unavailability_end_time ? d.unavailability_end_time : '-',
        repeat: d?.repeat ? d.repeat : '-',
        reason: d?.reason ? d.reason : '-',
        RecordBy: d?.record_by ? d.record_by : '-',
      }

    })
    console.log("currentReportData", currentReportData);

    this.setState({ ReportDataList: currentReportData, apiLoading: false, pagination: { ...this.state.pagination, Totalcount: data?.total_count ? data?.total_count : 40 } });

  }
  //========================================================================================================
  _handelgetStoreClosingTimeLogReportSuccessInformation = async (data: any) => {
    // console.log("_handelgetStoreClosingTimeLogReportSuccessInformation", data);
    let currentReportData = data?.data?.map((d: any, index: any) => {
      return {
        key: index,
        storeId: d?.store_id ? d.store_id : '-',
        storeName: d?.store_name ? d.store_name : '-',
        CloseStartDate: d?.close_start_date ? d.close_start_date : '-',
        closeStartTime: d?.close_start_time ? d.close_start_time : '-',
        closeEndDate: d?.close_end_date ? d.close_end_date : '-',
        closeEndTime: d?.close_end_time ? d.close_end_time : '-',
        repeat: d?.repeat ? d.repeat : '-',
        reason: d?.reason ? d.reason : '-',
        RecordBy: d?.recorded_by ? d.recorded_by : '-',
      }

    })
    // console.log("currentReportData", currentReportData);
    this.setState({ ReportDataList: currentReportData, apiLoading: false });


  }
  //========================================================================================================
  _handelgetSalesReportInformation = async (data: any) => {
    // console.log("_handelgetStoreClosingTimeLogReportSuccessInformation", data);
    let currentReportData = data?.data?.map((d: any, index: any) => {
      return {
        key: index,
        storeId: d.storeId ? d.storeId : '-',
        site_id: d.site_id ? d.site_id : '-',
        storeName: d.storeName ? d.storeName : '-',
        order_date: d.order_date ? d.order_date : '-',
        order_id: d.order_id ? d.order_id : '-',
        txn_id: d.txn_id ? d.txn_id : '-',
        bill_no: d.bill_no ? d.bill_no : '-',
        amount: d.amount ? d.amount : '-',
        Famount: d.Famount ? d.Famount : '-',
        order_status: d.order_status ? d.order_status : '-',
        product_status: d.product_status ? d.product_status : '-',
        product_name: d.product_name ? d.product_name : '-',
        product_quantity: d.product_quantity ? d.product_quantity : '-',
        packaging_charge: d.packaging_charge ? d.packaging_charge : '-',
        action: d.action ? d.action : '-',
        action_reason: d.action_reason ? d.action_reason : '-',
        totalTCS: d.totalTCS ? d.totalTCS : '-',
        totalTDS: d.totalTDS ? d.totalTDS : '-',
        total_gst: d.total_gst ? d.total_gst : '-',
        base_amoount: d.base_amoount ? d.base_amoount : '-',
        product_total_amount: d.product_total_amount ? d.product_total_amount : '-',
        product_amount_per_unit: d.product_amount_per_unit ? d.product_amount_per_unit : '-',
        product_discount: d.product_discount ? d.product_discount : '-',
      }

    })
    console.log("currentReportData", currentReportData);
    this.setState({ ReportDataList: currentReportData, apiLoading: false });
  }
  //========================================================================================================
  _handelSalesReportSuccessInformation = async (data: any) => {
    // console.log("_handelSalesReportSuccessInformation", data.data);
    let currentReportData = data?.data?.map((d: any, index: any) => {

      return {
        key: index,
        storeId: d.store_id ? d.store_id : '-',
        site_id: d.site_id ? d.site_id : '-',
        storeName: d.store_name ? d.store_name : '-',
        order_date: d.order_date ? moment(d.order_date).format('DD-MM-YYYY') : '-',
        order_id: d.order_id ? d.order_id : '-',
        txn_id: d.txn_id ? d.txn_id : '-',
        bill_no: d.bill_no ? d.bill_no : '-',
        amountwithDiscount: d.amount_with_discount ? d.amount_with_discount : '-',
        amountWithoutDiscount: d.amount_without_discount ? d.amount_without_discount : '-',
        order_status: d.order_status ? d.order_status : '-',
        product_status: d.product_status ? d.product_status : '-',
        product_name: d.product_name ? d.product_name : '-',
        product_quantity: d.product_quantity ? d.product_quantity : '-',
        packaging_charge: d.packaging_charge ? d.packaging_charge : '--',
        action: d.action ? d.action : '-',
        action_reason: d.action_reason ? d.action_reason : '-',
        totalTCS: d.total_tcs ? d.total_tcs : '-',
        totalTDS: d.totalTDS ? d.totalTDS : '-',
        total_gst: d.total_gst ? d.total_gst : '-',
        base_amoount: d.base_amoount ? d.base_amoount : '-',
        product_total_amount: d.product_total_amount ? d.product_total_amount : '-',
        product_amount_per_unit: d.product_amount_per_unit ? d.product_amount_per_unit : '-',
        product_discount: d.product_discount ? d.product_discount : '-',
        item_base_amount: d.item_base_amount ? d.item_base_amount : '-',
      }

    })

    // console.log("currentReportData", currentReportData);
    this.setState({ ReportDataList: currentReportData, apiLoading: false });
  }
  //========================================================================================================

  //================================= ERROR RESPONSE ==============================================
  // _handelGetReportErrorResponse
  _handelGetReportErrorResponse = async (data: any) => {
    console.log("_handelGetReportErrorResponse", data);
    AntMessage.error(`No Data found!`)

  }
  _handelgetInventoryReportDataErrorInformation = async (data: any) => {
    console.log("E_handelgetInventoryReportDataErrorInformationrror", data);
  }
  _handelgetStoreClosingTimeLogReportErrorInformation = async (data: any) => {
    console.log("_handelgetStoreClosingTimeLogReportErrorInformation", data);
  }
  _handelSalesReportErrorInformation = async (data: any) => {
    console.log("_handelSalesReportErrorInformation", data);
    this.setState({ apiLoading: false });
    AntMessage.error(`No Data found!`)
  }
  _handeldailyGocercySalesReportInformationError = async (data: any) => {
    console.log("_handeldailyGocercySalesReportInformationError", data);
    this.setState({ apiLoading: false });
    AntMessage.error(`No Data found!`)
  }
  _handelcurrentStockReportInformationError = async (data: any) => {
    console.log("_handelcurrentStockReportInformationError", data);
    this.setState({ apiLoading: false });
    AntMessage.error(`No Data found!`)
  }
  _handelGetPartiallAcceptanceReportErrorResponse = async (data: any) => {
    console.log("_handelGetPartiallAcceptanceReportErrorResponse", data);
    this.setState({ apiLoading: false });
    AntMessage.error(`No Data found!`)
  }



  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End
}
