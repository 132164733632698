// Customizable Area Start
import React, { createRef } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { NewOrder } from "./NewOrder.web"
import { OrderFullfilled } from "./OrderFullfilled.web"
import { OrderOnHold } from "./OrdersOnHold.web"
import { PartiallyRejectedOrders } from "./PartiallyRejectedOrders.web"
import { PartiallyAcceptedOrders } from "./PartiallyAcceptedOrders.web"
import { RejectedOrder } from "./RejectedOrder.web"
import { AcceptedOrder } from "./AcceptedOrder.web"
import "antd/dist/antd.css";
import {
    Table,
    Select,
    Button,
    Modal,
    Upload,
    Input,
    Row,
    Col,
    Form,
    Typography,
    Divider,
    Space,
    Card,
    DatePicker,
    Switch,
    TimePicker,
    Spin,
    Popover,
    Checkbox,
} from "antd";
import { ArrowLeftOutlined, BarChartOutlined, CloseCircleFilled, FilterFilled, LoadingOutlined, ShoppingCartOutlined, SyncOutlined } from "@ant-design/icons";
import "../../../blocks/RequestManagement/src/Reports/Reports.css";
import { FormInstance } from "antd/es/form";
const { Title, Paragraph, Text, Link } = Typography;
import {
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
    EditFilled,
} from "@ant-design/icons";
import dashboardController, { Props, configJSON } from "./DashboardController";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import "./Dashboard.css";
export default class StoreDashboard extends dashboardController {
    cardArray: any;
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.state = {
            dashboardData: [],
            errorMsg: "",
            token: "",
            loading: false,
            orderList: [],
            newOrderBlock: false,
            acceptedOrdersBlock: false,
            rejectedOrdersBlock: false,
            partiallyAcceptedOrdersBlock: false,
            partiallyRejectedOrdersBlock: false,
            ordersOnHoldBlock: false,
            orderFullfilledBlock: false,
            selectedRowKeys: [],
            adminDashbaordData: [],
            orderApiData: [],
            selectedOrderId: '',
            selectedOrderDetails: {},
            orderConfirmationState: '',
            testState: [],
            selectedFilterId: "",
            DropDownValue: "",
            orderDetails: [],
            RejectedReasonBlock: false,
            variablePriceArray: [],
            variableQuantity: [],
            filterOrderData: [4],
            selectedFilterString: `?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`,
            Order_Id: 0,
        };
        document.title = `Store Dashbaord | 24SevenDashboard`;
        // Customizable Area End
    }
    onDateChange = (dates: any, dateStrings: any) => {
        if (dates && dateStrings) {
            let reqPayload = `?start_date=${moment(dates[0]).format('D MMMM YYYY')}&end_date=${moment(dates[1]).format('D MMMM YYYY')}`
            this.FilterStoreDashboard(reqPayload);
        } else {
            this.onGetOrders();
            
        }
    }

    handleChange(e: any) {
        if (e.target.checked == true) {
            let data = [];
            data.push(e.target.value)
            this.setState({ filterOrderData: data });
        }

    }

    // thiscallisFromAppJs() {
    //     console.log("this call is From App Js");
    //     // this.componentDidMount()
    // }



    getDateFilterParamatersForGettingOrders(id: any) {
        if (id == 1) {
            //Today
            return `?start_date=${moment().format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`;
        }
        else if (id == 2) {
            //Yesterday
            return `?start_date=${moment().subtract(1, 'd').format('D MMMM YYYY')}&end_date=${moment().subtract(1, 'd').format('D MMMM YYYY')}`;
        }
        else if (id == 3) {
            //Last Week
            return `?start_date=${moment().subtract(7, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`;
        }
        else if (id == 4) {
            //Last 2 Week
            return `?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`;
        }
        else if (id == 5) {
            //Last Month
            return `?start_date=${moment().subtract(30, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`;
        }
        else if (id == 6) {
            //All
            return '/';
        }

    }


    filterStoreDashboard = () => {

        let filterPayload = this.getDateFilterParamatersForGettingOrders(this.state?.filterOrderData?.[0]);
        this.setState({ selectedFilterString: filterPayload }, () => {
            this.FilterStoreDashboard(this.state?.selectedFilterString);

        })
    }

    clearFilterStoreDashboard = () => {
        this.setState({ filterOrderData: [] });
        this.onGetOrders();
    }

    //====================================== RENDER ========================================

    render() {

        //@ts-ignore
        // const FilterDataValue = this.props?.history?.location?.state?.value;
        // console.log(FilterDataValue);

        let permissions: any;
        let decodedString = atob(localStorage.getItem('permissions') || '{}');

        //@ts-ignore
        JSON.parse(decodedString).filter((d) => {

            if (d.attributes.access_module.name == "Store Dashboard") {
                permissions = d.attributes
                return
            }
        });

        const antIcon = <LoadingOutlined translate={{}} style={{
            fontSize: '30px', color: 'black'
        }
        } spin />;

        const orderDatFilter = [
            { id: 1, label: 'Today', value: 'Today' },
            { id: 2, label: 'Yesterday', value: 'Yesterday' },
            { id: 3, label: 'Last Week', value: 'Last Week' },
            { id: 4, label: 'Last 2 Week', value: 'Last 2 Week' },
            { id: 5, label: 'Last Month', value: 'Last Month' },
            { id: 6, label: 'All', value: 'All' },
        ]

        const { filterOrderData, orderApiData, newOrderBlock, acceptedOrdersBlock, rejectedOrdersBlock, partiallyAcceptedOrdersBlock, partiallyRejectedOrdersBlock, ordersOnHoldBlock, orderFullfilledBlock, loading } = this.state
        // console.log('xyz', orderApiData);

        return (
            <div>
                <div className="StoreSection">
                    <div className="store-dashboard-block">
                        {!newOrderBlock && !acceptedOrdersBlock && !rejectedOrdersBlock && !partiallyAcceptedOrdersBlock && !partiallyRejectedOrdersBlock && !ordersOnHoldBlock && !orderFullfilledBlock ?
                            <div className="content-container">
                                <div className="date-picker-placement">
                                    <Row>
                                        <Col key={'00001'}>
                                            <div className="custome-date-control">
                                                <DatePicker.RangePicker

                                                    ranges={{
                                                        Today: [moment(), moment()],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                    }}
                                                    onChange={this.onDateChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="filter-block">
                                            <div
                                                style={{ marginLeft: 'buttonWidth', clear: 'both', whiteSpace: 'nowrap' }}>
                                                <Popover placement="bottomRight" title={`Filters`}
                                                    content={
                                                        <div>
                                                            <div>
                                                                {
                                                                    orderDatFilter.map((item: any) => {
                                                                        return <div>
                                                                            <Checkbox
                                                                                key={item.label}
                                                                                onChange={(e) => { this.handleChange(e) }}
                                                                                checked={this.state?.filterOrderData?.[0] == item.id}
                                                                                // checked={item.value == value}
                                                                                value={item.id}
                                                                            >
                                                                                {item.value}
                                                                            </Checkbox>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>

                                                            <div className="filter-btn-block" >
                                                                <div
                                                                    className="filter-button"
                                                                    onClick={() => { this.filterStoreDashboard() }}  >
                                                                    Filter
                                                                </div>
                                                                <div style={{ width: 10 }}></div>
                                                                {
                                                                    filterOrderData?.length > 0 ?
                                                                        <div
                                                                            className="filter-button"
                                                                            onClick={() => { this.clearFilterStoreDashboard() }}
                                                                        >
                                                                            Clear
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>

                                                        </div>}
                                                    trigger="click">
                                                    <div className="filter-icon-block">
                                                        <FilterFilled translate={{}} title="Filters"
                                                            style={{ padding: '5px' }}
                                                        />
                                                        Filters
                                                    </div>
                                                </Popover>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Spin
                                    indicator={antIcon}
                                    spinning={loading}>

                                    <div className="store-information store-topinfo">
                                        <div className="title-information">
                                            <Row gutter={[16, 48]} className="header">
                                                <Col key={'00002'} span={9} style={{ textAlign: "left" }}>
                                                    {/* <Title className="title">Order Fulfilled</Title> */}
                                                </Col>
                                                <Col key={'00003'} span={15} style={{ textAlign: "end" }}>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="store-litemist">
                                            {/* {loading ?
                                            <Spin indicator={antIcon} />
                                            : null} */}
                                            <Row className="item-order-list-row" gutter={[16, 48]} >
                                                <Col className="item-order-list-col" key={1} span={6} style={{ textAlign: "left" }}>
                                                    <div className="store-container" >
                                                        <div className="store-action newBg" >
                                                            New orders
                                                        </div>
                                                        {
                                                            orderApiData?.new_orders?.length > 0 ?
                                                                orderApiData?.new_orders?.map((newOrders: any, index: any) => {
                                                                    return <>
                                                                        <div
                                                                            className="store-items"
                                                                            key={`${index}-${newOrders.id}`}
                                                                            onClick={() => {
                                                                                this.setState({ newOrderBlock: true, selectedOrderId: newOrders.id })
                                                                            }}
                                                                            title={newOrders?.catalogue_name && newOrders.partner_order_id ? `${newOrders?.catalogue_name}(${newOrders.partner_order_id}) ${moment(newOrders?.created_at).format('D MMMM YYYY')}` : 'New Order'}
                                                                        >
                                                                            <h5>{newOrders.partner_order_id ? newOrders.partner_order_id : '-'}</h5>
                                                                            <h6>
                                                                                <span className="icon">
                                                                                    <img
                                                                                        src={newOrders?.catalogue_url?.url ? newOrders?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                                        className="partner-img"
                                                                                    ></img>
                                                                                </span>
                                                                                <span className="tetx">{newOrders?.catalogue_name ? newOrders.catalogue_name : '-'}</span>
                                                                            </h6>
                                                                        </div> </>
                                                                }) : <p>No new orders...!!!</p>
                                                        }

                                                    </div>
                                                </Col>
                                                <Col className="item-order-list-col" key={2} span={6} style={{ textAlign: "left" }}>
                                                    <div className="store-container">
                                                        <div className="store-action acceptBg">
                                                            Accepted orders
                                                        </div>
                                                        {
                                                            orderApiData?.accepted_orders?.length > 0 ?
                                                                orderApiData?.accepted_orders?.map((acceptedOrder: any, index: any) => {
                                                                    return <>
                                                                        <div
                                                                            className="store-items"
                                                                            key={`${index}-${acceptedOrder.id}`}
                                                                            onClick={() => {
                                                                                this.setState({ acceptedOrdersBlock: true, selectedOrderId: acceptedOrder.id })
                                                                            }}
                                                                            title={acceptedOrder?.catalogue_name && acceptedOrder.partner_order_id ? `${acceptedOrder?.catalogue_name}(${acceptedOrder.partner_order_id}) ${moment(acceptedOrder?.created_at).format('D MMMM YYYY')}` : 'Accepted Order'}
                                                                        >

                                                                            <h5>{acceptedOrder.partner_order_id ? acceptedOrder.partner_order_id : ''}</h5>
                                                                            <h6>
                                                                                <span className="icon">
                                                                                    <img
                                                                                        src={acceptedOrder?.catalogue_url?.url ? acceptedOrder?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                                        className="partner-img"
                                                                                    ></img>
                                                                                </span>
                                                                                <span className="tetx">{acceptedOrder.catalogue_name}</span>
                                                                            </h6>
                                                                        </div>
                                                                    </>
                                                                }) : <p>No orders...!!!</p>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className="item-order-list-col" key={3} span={6} style={{ textAlign: "left" }}>
                                                    <div className="store-container">
                                                        <div className="store-action rejectBg">
                                                            Rejected orders
                                                        </div>
                                                        {
                                                            orderApiData?.rejected_orders?.length > 0 ?
                                                                orderApiData?.rejected_orders?.map((rejectData: any, index: any) => {
                                                                    return <>
                                                                        <div
                                                                            className="store-items"
                                                                            key={`${index}-${rejectData.id}`}
                                                                            onClick={() => { this.setState({ rejectedOrdersBlock: true, selectedOrderId: rejectData.id }) }}
                                                                            title={rejectData?.catalogue_name && rejectData.partner_order_id ? `${rejectData?.catalogue_name}(${rejectData.partner_order_id}) ${moment(rejectData?.created_at).format('D MMMM YYYY')}` : 'Rejected Order'}
                                                                        >

                                                                            <h5>{rejectData.partner_order_id ? rejectData.partner_order_id : ''}</h5>
                                                                            <h6>
                                                                                <span className="icon">
                                                                                    <img
                                                                                        src={rejectData?.catalogue_url?.url ? rejectData?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                                        className="partner-img"
                                                                                    ></img>
                                                                                </span>
                                                                                <span className="tetx">{rejectData.catalogue_name}</span>
                                                                            </h6>
                                                                        </div>
                                                                    </>
                                                                }) : <span>No orders...!!!</span>
                                                        }

                                                    </div>
                                                </Col>
                                                <Col className="item-order-list-col" key={4} span={6} style={{ textAlign: "left" }} >
                                                    <div className="store-container">
                                                        <div className="store-action acceptBg1" >
                                                            Partially accepted orders
                                                        </div>
                                                        {
                                                            orderApiData?.partially_accepted_orders?.length > 0 ?
                                                                orderApiData?.partially_accepted_orders?.map((partiallyAcceptedOrder: any, index: any) => {
                                                                    return <>
                                                                        <div
                                                                            className="store-items"
                                                                            key={`${index}-${partiallyAcceptedOrder.id}`}
                                                                            onClick={() => { this.setState({ partiallyAcceptedOrdersBlock: true, selectedOrderId: partiallyAcceptedOrder.id }) }}
                                                                            title={partiallyAcceptedOrder?.catalogue_name && partiallyAcceptedOrder.partner_order_id ? `${partiallyAcceptedOrder?.catalogue_name}(${partiallyAcceptedOrder.partner_order_id}) ${moment(partiallyAcceptedOrder?.created_at).format('D MMMM YYYY')}` : 'Partially Accepted Order'}
                                                                        >

                                                                            <h5>{partiallyAcceptedOrder.partner_order_id ? partiallyAcceptedOrder.partner_order_id : ''}</h5>
                                                                            <h6>
                                                                                <span className="icon">
                                                                                    <img
                                                                                        src={partiallyAcceptedOrder?.catalogue_url?.url ? partiallyAcceptedOrder?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                                        className="partner-img"
                                                                                    ></img>
                                                                                </span>
                                                                                <span className="tetx">{partiallyAcceptedOrder.catalogue_name}</span>
                                                                            </h6>
                                                                        </div>
                                                                    </>
                                                                }) : <span>No orders...!!!</span>
                                                        }

                                                    </div>
                                                </Col>
                                                <Col className="item-order-list-col" key={5} span={4} style={{ textAlign: "left", display: 'none' }}>
                                                    <div className="store-container">
                                                        <div className="store-action rejectBg1" >
                                                            Partially rejected orders
                                                        </div>
                                                        {
                                                            orderApiData?.partially_rejected_orders?.length > 0 ?
                                                                orderApiData?.partially_rejected_orders?.map((partiallyRejectedOrder: any, index: any) => {
                                                                    return <>
                                                                        <div
                                                                            className="store-items"
                                                                            key={`${index}-${partiallyRejectedOrder.id}`}
                                                                            onClick={() => { this.setState({ partiallyRejectedOrdersBlock: true, selectedOrderId: partiallyRejectedOrder.id }) }}
                                                                            title={partiallyRejectedOrder?.catalogue_name && partiallyRejectedOrder.partner_order_id ? `${partiallyRejectedOrder?.catalogue_name}(${partiallyRejectedOrder.partner_order_id}) ${moment(partiallyRejectedOrder?.created_at).format('D MMMM YYYY')}` : 'Partially Rejected Order'}
                                                                        >

                                                                            <h5>{partiallyRejectedOrder.partner_order_id ? partiallyRejectedOrder.partner_order_id : ''}</h5>
                                                                            <h6>
                                                                                <span className="icon">
                                                                                    <img
                                                                                        src={partiallyRejectedOrder?.catalogue_url?.url ? partiallyRejectedOrder?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                                        className="partner-img"
                                                                                    ></img>
                                                                                </span>
                                                                                <span className="tetx">{partiallyRejectedOrder.catalogue_name}</span>
                                                                            </h6>
                                                                        </div>
                                                                    </>
                                                                }) : <span>No orders...!!!</span>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className="item-order-list-col" key={6} span={4} style={{ textAlign: "left", display: 'none' }} >
                                                    <div className="store-container">
                                                        <div className="store-action holdBg" >
                                                            Orders on hold
                                                        </div>
                                                        {
                                                            orderApiData?.on_hold_orders?.length > 0 ?
                                                                orderApiData?.on_hold_orders?.map((onHoldOrders: any, index: any) => {
                                                                    return <>
                                                                        <div
                                                                            className="store-items"
                                                                            key={`${index}-${onHoldOrders.id}`}
                                                                            onClick={() => { this.setState({ ordersOnHoldBlock: true, selectedOrderId: onHoldOrders.id }) }}
                                                                            title={onHoldOrders?.catalogue_name && onHoldOrders.partner_order_id ? `${onHoldOrders?.catalogue_name}(${onHoldOrders.partner_order_id}) ${moment(onHoldOrders?.created_at).format('D MMMM YYYY')}` : 'On Hold Orders'}
                                                                        >

                                                                            <h5>{onHoldOrders.partner_order_id ? onHoldOrders.partner_order_id : ''}</h5>
                                                                            <h6>
                                                                                <span className="icon">
                                                                                    <img
                                                                                        src={onHoldOrders?.catalogue_url?.url ? onHoldOrders?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                                        className="partner-img"
                                                                                    ></img>
                                                                                </span>
                                                                                <span className="tetx">{onHoldOrders.catalogue_name}</span>
                                                                            </h6>
                                                                        </div>
                                                                    </>
                                                                }) : <span>No orders</span>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="store-information">
                                        <div className="title-information">
                                            <Row gutter={[16, 48]} className="header responsive">
                                                <Title className="title">Order Fulfilled</Title>

                                                {/* <Col key={11} span={9} style={{ textAlign: "left" }}>
                                                <Title className="title">Order Fulfilled</Title>
                                            </Col>
                                            <Col key={12} span={15} style={{ textAlign: "end" }}>

                                            </Col> */}
                                            </Row>
                                        </div>
                                        <div className="store-litemist">
                                            <Row gutter={[16, 48]} className="order-rows" style={{ overflowX: "auto" }}>

                                                {
                                                    orderApiData?.order_fullfiled?.length > 0 ?
                                                        orderApiData?.order_fullfiled?.map((orderFulfilled: any, index: any) => {
                                                            return <>
                                                                <Col
                                                                    span={4}
                                                                    key={`${index}-${orderFulfilled.id}`}
                                                                    style={{ textAlign: "left" }}
                                                                    onClick={() => { this.setState({ orderFullfilledBlock: true, selectedOrderId: orderFulfilled.id }) }}>
                                                                    <div
                                                                        className="store-items"
                                                                        title={orderFulfilled?.catalogue_name && orderFulfilled.partner_order_id ? `${orderFulfilled?.catalogue_name} ${moment(orderFulfilled?.created_at).format('D MMMM YYYY')}` : 'Order Fulfilled'}
                                                                    >

                                                                        <h5>{orderFulfilled?.partner_order_id ? orderFulfilled.partner_order_id : '-'}</h5>
                                                                        <h6>
                                                                            <span className="icon">
                                                                                <img
                                                                                    src={orderFulfilled?.catalogue_url?.url ? orderFulfilled?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                                    className="partner-img"
                                                                                ></img>
                                                                            </span>
                                                                            <span className="tetx">{orderFulfilled.catalogue_name}</span>
                                                                        </h6>
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        }) : <p>No orders ...!!!</p>
                                                }


                                            </Row>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className="store-information">
                                        <div className="title-information ">
                                            <Row gutter={[16, 48]} className="header responsvie">
                                                <Title className="title">Order summary view</Title>
                                            </Row>
                                        </div>
                                        <div className="store-litemist">
                                            <Row className="store-order-list-row" gutter={[16, 10]} >
                                                <Col className="store-order-list-col" key={'0001'} span={4} style={{ textAlign: "left" }}>
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.new_orders ? orderApiData?.order_summary_view?.new_orders : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#5ee823" }}
                                                                />
                                                            </span>
                                                            <span className="tetx">New orders</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col className="store-order-list-col" key={'0002'} span={4} style={{ textAlign: "left" }}>
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.accepted_orders_count ? orderApiData?.order_summary_view?.accepted_orders_count : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#7dad23" }}
                                                                />
                                                            </span>
                                                            <span className="tetx">Accepted</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col className="store-order-list-col" key={'0003'} span={4} style={{ textAlign: "left" }}>
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.rejected_orders_count ? orderApiData?.order_summary_view?.rejected_orders_count : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#f70505" }}
                                                                />
                                                            </span>
                                                            <span className="tetx">Rejected</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col className="store-order-list-col" key={'0004'} span={4} style={{ textAlign: "left" }}>
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.partially_accepted_orders_count ? orderApiData?.order_summary_view?.partially_accepted_orders_count : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#e3950e" }}
                                                                />
                                                            </span>
                                                            <span className="tetx">Partially accepted</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col className="store-order-list-col" key={'0005'} span={4} style={{ textAlign: "left" }}>
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.partially_rejected_orders_count ? orderApiData?.order_summary_view?.partially_rejected_orders_count : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#FFBB00" }}
                                                                />
                                                            </span>
                                                            <span className="tetx">Partially rejected</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col className="store-order-list-col" key={'0006'} span={4} style={{ textAlign: "left" }}>
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.on_hold_orders_count ? orderApiData?.order_summary_view?.on_hold_orders_count : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#e3950e" }}

                                                                />
                                                            </span>
                                                            <span className="tetx">On Hold Orders</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col className="store-order-list-col" key={'0007'} span={4} style={{ textAlign: "left" }}>
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.order_fullfiled_count ? orderApiData?.order_summary_view?.order_fullfiled_count : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#1AEE30" }}
                                                                />
                                                            </span>
                                                            <span className="tetx">Orders fulfilled</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="store-order-list-col"
                                                    key={'0008'} span={4}
                                                    style={{ textAlign: "left" }}
                                                    title={`Today's Order amount`}
                                                >
                                                    <div className="store-items">
                                                        <h5>{orderApiData?.order_summary_view?.total_order_amount ? orderApiData?.order_summary_view?.total_order_amount : '0'}</h5>
                                                        <h6>
                                                            <span className="icon">
                                                                <ShoppingCartOutlined translate={{}}
                                                                    style={{ color: "#1AEE30" }}
                                                                />
                                                            </span>
                                                            <span className="tetx">Total Order Amount</span>
                                                        </h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                            : null}
                        {/* New orders Block */}
                        {newOrderBlock ?
                            //@ts-ignore
                            <NewOrder seState={(obj) => { this.setState(obj) }} orderid={this.state?.selectedOrderId} filterData={this.state?.selectedFilterString} />
                            : null
                        }
                        {/* Accepted orders */}
                        {acceptedOrdersBlock ?
                            //@ts-ignore
                            <AcceptedOrder seState={(obj) => { this.setState(obj) }} orderid={this.state?.selectedOrderId} filterData={this.state?.selectedFilterString} />
                            : null
                        }
                        {/* Rejected orders */}
                        {rejectedOrdersBlock ?
                            //@ts-ignore
                            <RejectedOrder seState={(obj) => { this.setState(obj) }} orderid={this.state?.selectedOrderId} filterData={this.state?.selectedFilterString} />
                            : null
                        }
                        {/* Partially accepted orders */}
                        {partiallyAcceptedOrdersBlock ?
                            //@ts-ignore
                            <PartiallyAcceptedOrders seState={(obj) => { this.setState(obj) }} orderid={this.state?.selectedOrderId} filterData={this.state?.selectedFilterString} />
                            : null
                        }
                        {/* Partially rejected orders */}
                        {partiallyRejectedOrdersBlock ?
                            //@ts-ignore
                            <PartiallyRejectedOrders seState={(obj) => { this.setState(obj) }} orderid={this.state?.selectedOrderId} filterData={this.state?.selectedFilterString} />
                            : null
                        }
                        {/* Orders on hold */}
                        {ordersOnHoldBlock ?
                            //@ts-ignore
                            <OrderOnHold seState={(obj) => { this.setState(obj) }} orderid={this.state?.selectedOrderId} filterData={this.state?.selectedFilterString} />
                            : null
                        }


                        {/* orderFullfilledBlock */}
                        {orderFullfilledBlock ?
                            //@ts-ignore
                            <OrderFullfilled seState={(obj) => { this.setState(obj) }} orderid={this.state?.selectedOrderId} filterData={this.state?.selectedFilterString} /> : null
                        }


                    </div>
                </div>
            </div>
        )
    }
}
// Customizable Area End
