// Customizable Area Start
import React, { createRef } from "react";
import CfInventoryManagement10Controller, {
  Props,
  configJSON,
} from "../CfInventoryManagement10Controller";
import "antd/dist/antd.css";
import {
  Table,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Row,
  Col,
  Form,
  Switch,
  Typography,
  TimePicker,
  DatePicker,
  Radio,
  Space,
  Avatar,
  Checkbox
} from "antd";
import { CloseCircleFilled, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./StoreTimings.css";
import { FormInstance } from "antd/es/form";
//@ts-ignore
import GlobalLoader from "../../../../web/src/GlobalLoader"
import {
  EditFilled,
  DeleteFilled,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import moment from 'moment';

const { Title, Paragraph, Text, Link } = Typography;

interface IProps { }

/**
 * @author
 * @class @StoreTimings
 **/

class StoreTimings extends CfInventoryManagement10Controller {
  timer: any = null
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      selectedRowKeys: [],
      addModal: false,
      days: [
        { name: "Monday", value: "monday", id: 1 },
        { name: "Tuesday", value: "tuesday", id: 2 },
        { name: "Wednesday", value: "wednesday", id: 3 },
        { name: "Thursday", value: "thursday", id: 4 },
        { name: "Friday", value: "friday", id: 5 },
        { name: "Saturday", value: "saturday", id: 6 },
        // { name: "Sunday", value: "sunday", id: 7 },
      ],
      repeats: [
        { name: "Never", value: 0 },
        { name: "Daily", value: 1 },
        { name: "Weekly", value: 2 },
        { name: "Monthly", value: 3 },
      ],
      selectedTab: 1,
      editModal: false,
      addOfcModal: false,
      editOfcModal: false,
      editZoneModal: false,
      addZoneModal: false,
      Storelist: [],
      OfcList: [],
      ZoneList: [],
      ofcIds: [],
      zoneIds: [],
      selectedStoreData: {},
      selectedOfcData: {},
      selectedZoneData: {},
      Inventorylist: [],
      selectedInventoryData: {},
      selectedStoreTimingData: {},
      closingStoreTimings: [],
      Errors: {},
      apiLoading: false,
      InventoryApiLoading: false,
      storeTimingsData: {
        startDate: '',
        EndDate: '',
        StartTime: '',
        EndTime: '',
        Days: [],
        repeats: [],

      },
      selectedCheckBoxData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        TotalInventoryCount: 0,
        TotalStoreCount: 0,
      },
      modaLoader: false,
      selectedProfilePic: false,
      addItemUnavailable: [],
      getItemUnavailable: {},
      FtpModal: false,
      uploadingLoading: false,
      searchValue: '',


    };
    document.title = `Store Timings | 24SevenDashboard`
    // Customizable Area End
  }
  formRef = createRef<FormInstance>();

  async componentDidMount() {
    this.ongetStore();
  }

  selectRow = (record: any) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys });
  };
  onSelectedRowKeysChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  onFinish = (values: any) => {
    console.log(values);

    let _editStoreTimings =
    {
      store_id: values.store_account_id,
      data: {
        start_time: this.state.storeTimingsData.StartTime,
        end_time: this.state.storeTimingsData.EndTime,
        start_date: this.state.storeTimingsData.startDate,
        end_date: this.state.storeTimingsData.EndDate,
        days: { week_days: this.state.storeTimingsData.Days },
        repeats: Number(values.repeats)
      }
    }
    console.log("Success:", _editStoreTimings);
    this.onReset();
    setTimeout(() => {
      this.EditStoreTimings(_editStoreTimings)
      console.log(this.state);
    }, 0)

  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  onReset = () => {
    this.formRef.current!.resetFields();
  };

  handleSwitch = (e: any, data: any) => {
    console.log(e, data);
    let requestPayload = {
      id: data.id,
      data: {
        status: e == true ? 'active' : 'inactive'
      }
    }

    this.updateStoreStatus(requestPayload)

  };

  handleTimeChange = (e: any, timeString: any) => {
    // console.log("handleTimeChange", e, timeString);
    // console.log(timeString[0]);
    // console.log(timeString[1]);
    this.setState({
      storeTimingsData:
      {
        ...this.state.storeTimingsData,
        StartTime: timeString[0],
        EndTime: timeString[1],
      }
    })

  };

  handleDateChange = (e: any, DateString: any) => {
    console.log("handleDateChange", e, DateString);
    this.setState({
      storeTimingsData:
      {
        ...this.state.storeTimingsData,
        startDate: DateString[0],
        EndDate: DateString[1],

      }
    })
  };

  handleCheckBox = (e: any, index: any) => {
    console.log("handleCheckBox", e, index);
  };

  handleRadioChange = (e: any) => {
    // console.log("handleRadioChange", e);
  };
  handelOnChange(checkedValues: any) {
    this.setState({ storeTimingsData: { ...this.state.storeTimingsData, Days: checkedValues } })
    setTimeout(() => {
      console.log(this.state);
    }, 0)


  }
  editSelectedStoreTiming = (item: any) => {

    setTimeout(() => {
      this.onReset();
      this.formRef.current?.setFieldsValue({ store_account_id: item.id, })

      console.log(this.state);
    }, 0)
  };

  onEditMultipleStoreTimings = (values: any) => {
    let _editStoreTimings =
    {
      store_list: values.storeId,
      data: {
        start_time: this.state.storeTimingsData.StartTime,
        end_time: this.state.storeTimingsData.EndTime,
        start_date: this.state.storeTimingsData.startDate,
        end_date: this.state.storeTimingsData.EndDate,
        days: { week_days: values.days },
        repeats: values.repeats
      }
    }
    console.log("onEditMultipleStoreTimings:", _editStoreTimings);
    this.onReset();
    this.EditMultipleStoreTimings(_editStoreTimings)

  };

  selectedStoreDetails = (values: any) => {
    console.log(values);
    this.setState({ selectedStoreTimingData: values })
    // let data = {store_id:values.id} 
    this.getStoreClosingTimings(values.id)

  };

  populatedEditTimingsModalForEditTimings = (data: any) => {
    this.setState({ selectedCheckBoxData: data?.days?.week_days.length > 0 ? data?.days?.week_days : '' })

    setTimeout(() => {
      this.formRef.current?.setFieldsValue({ days: data?.days?.week_days });
      let date = [];
      date.push(moment(data.start_date, 'YYYY/MM/DD'));
      date.push(moment(data.end_date, 'YYYY/MM/DD'));
      let time = [];
      time.push(moment(data.start_time, ["h:mm a"]).format("YYYY-MM-DD HH:mm"));
      time.push(moment(data.end_time, ["h:mm a"]).format("YYYY-MM-DD HH:mm"));
      console.log('date', date);
      console.log('timing', time);
      this.formRef.current?.setFieldsValue({ store_account_id: data.store_account_id });
      this.formRef.current?.setFieldsValue({ startEndDate: date });
      this.formRef.current?.setFieldsValue({ startEndTime: [moment(time[0]), moment(time[1])] });
      this.formRef.current?.setFieldsValue({ days: data?.days?.week_days });
      // this.setState({ selectedCheckBoxData: data.days.week_days.length > 0 ? data.days.week_days : '' })
      let repeatId: any;
      if (data.repeats == 'Monthly') { repeatId = 3 }
      else if (data.repeats == 'Weekly') { repeatId = 2 }
      else if (data.repeats == 'Daily') { repeatId = 1 }
      else if (data.repeats == 'Never') { repeatId = 0 }
      this.formRef.current?.setFieldsValue({ repeats: repeatId });

    }, 0)



  };

  deleteParticularStoreCloseTime = (data: any) => {
    console.log(data);
    this.deleteClosingTime(data.id, data.store_account_id)
  };

  searchStore = async (e: any) => {
    clearTimeout(this.timer);
    let searchedText = e.target.value;
    this.timer = setTimeout(() => {
      this.setState({ apiLoading: true });
      this.searchStoreDetails(searchedText)
    }, 1000)
  }
  //========================================= RENDER  ============================================================
  render() {

    const antIcon = <GlobalLoader />;
    //Permission Block

    let permissions: any;
    let decodedString = atob(localStorage.getItem('permissions') || '{}');

    //@ts-ignore
    JSON.parse(decodedString).filter((d) => {

      if (d.attributes.access_module.name == "Store Timings") {
        permissions = d.attributes
        return
      }
    });

    console.log(permissions, "permissions");





    const columns = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "Store ID",
        dataIndex: "store_id",
        key: "store_id",
      },
      {
        title: "Store Name",
        dataIndex: "store_name",
        key: "store_name",
      },
      {
        title: "Location",
        dataIndex: "store_address",
        key: "store_address",
      },
      {
        title: "Contact Information",
        dataIndex: "store_contact",
        key: "store_contact",
      },
      {
        title: "Status",
        dataIndex: "store_status",
        key: "store_status",
        render: (data: any, record: any, index: any) => {
          return (
            <Space size="large">
              {/* <Text className="act-status">Open</Text> */}
              <Switch
                checkedChildren={"Open"}
                unCheckedChildren={"Close"}
                defaultChecked={data == true ? data : false}
                onChange={(e) => this.handleSwitch(e, record)}
                title={data == true ? "Open" : "Close"}
              />
            </Space>
          );
        },
      },
      // {
      //   title: "",
      //   dataIndex: "",
      //   key: "",
      //   render: (item: any, fullObj: any) =>{
      //     return (

      //       <div className="editIconBox">
      //       <Space size="large">
      //         <span className="icon">
      //           <EditFilled
      //             translate
      //             onClick={() => {
      //               this.setState({ editOfcModal: true });
      //               this.editSelectedStoreTiming(item)
      //             }}
      //           />
      //         </span>
      //         <span>EDIT TIMINGS</span>
      //       </Space>
      //     </div>
      //     )
      //   } 
      // },
      // {
      //   title: "",
      //   dataIndex: "",
      //   key: "",
      //   render: (item: any, fullObj: any) =>{
      //     return (
      //       <>
      //       {permissions != undefined && permissions.update_action ?
      //       <div className="editIconBox">
      //       <Space size="large">
      //         <span className="icon">
      //           <EditFilled
      //             translate
      //             onClick={() => {
      //               this.setState({ editOfcModal: true });
      //               this.editSelectedStoreTiming(item)
      //             }}
      //           />
      //         </span>
      //         <span>EDIT TIMINGS</span>
      //       </Space>
      //     </div>
      //     :null}
      //       </>
      //     )
      //   } 
      // },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="closTimingBox">
            <span className="icon">
              <svg
                onClick={() => {
                  this.setState({ editModal: true })
                  this.selectedStoreDetails(item);
                  // this.selectedStoreDetails(this.state.selectedStoreTimingData)
                }

                }
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 455 455"
                // style="enable-background:new 0 0 455 455"
                xmlSpace="preserve"
              >
                <path d="m332.229 90.04 14.238-27.159-26.57-13.93-14.227 27.136a196.32 196.32 0 0 0-63.17-15.523V30h48.269V0H164.231v30H212.5v30.563a196.352 196.352 0 0 0-63.171 15.523L135.103 48.95l-26.57 13.93 14.239 27.16C67.055 124.958 30 186.897 30 257.5 30 366.576 118.424 455 227.5 455S425 366.576 425 257.5c0-70.604-37.056-132.542-92.771-167.46zM355 272.5H212.5V130h30v112.5H355v30z" />
              </svg>
            </span>
            <span>CLOSING TIMINGS</span>
          </div>
        ),
      },
    ];
    let obj = {
      title: "",
      dataIndex: "",
      key: "",
      render: (item: any, fullObj: any) => {
        return (

          <div className="editIconBox">
            <span className="icon">
              <EditFilled
                translate
                onClick={() => {
                  this.setState({ editOfcModal: true });
                  this.editSelectedStoreTiming(item)
                }}
              />
            </span>
            EDIT TIMINGS
          </div>
        )
      }
    }

    permissions != undefined && permissions.update_action ? columns.splice(5, 0, obj) : '';


    const { selectedRowKeys, addModal, days, repeats, editModal, editOfcModal, closingStoreTimings, selectedCheckBoxData, selectedStoreTimingData } = this.state;
    const props = {
      name: "file",
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      headers: {
        authorization: "authorization-text",
      },
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };


    return (
      <div className="StoreTimingsSection">



        {/* Edit  Timings */}
        <Modal
          className="editMultipleTimeModal"
          visible={editOfcModal}
          title={null}
          centered
          width="500px"
          onCancel={() => {
            this.onReset();
            this.setState({ editOfcModal: false, selectedCheckBoxData: [] })
          }
          }
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
              onClick={() => {
                this.onReset();
                this.setState({ editOfcModal: false, selectedCheckBoxData: [] })


              }}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Edit  Timings</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">
                  Set the store closing timings
                </p>
              </Col>
            </Row>
            <Form
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              name="content-ref"
              // initialValues={{ days: ['monday', 'friday'] }}
              autoComplete="off"
              layout="vertical"
            >
              <Row className="modal-rows allstoreSelectBox">
                <Col className="rowCol">
                  <Form.Item name="store_account_id" noStyle ><Input type="hidden" /></Form.Item>
                </Col>
              </Row>
              <Row className="startEndDatePicker">
                <Col>
                  <Form.Item label="Date" name="startEndDate" rules={[{ required: true, message: "Start and End date is required" }]} >
                    <DatePicker.RangePicker format={'YYYY/MM/DD'} onChange={this.handleDateChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="startEndTimePicker">
                <Col>
                  <Form.Item label="Time" name="startEndTime" rules={[{ required: true, message: "Start and End time is required" }]} >
                    <TimePicker.RangePicker
                      onChange={this.handleTimeChange}
                      use12Hours={true}
                      format="h:mm a"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="chooseDayFlexBox">
                <Row className="title">OR CHOOSE DAYS</Row>
                <Row className="flexBox">
                  <Form.Item name="days" valuePropName="checked" >

                    <Checkbox.Group onChange={(e) => this.handelOnChange(e)} defaultValue={this.state?.selectedCheckBoxData.length > 0 ? this.state?.selectedCheckBoxData : []} >
                      {days.map((item: any, index: any) => (
                        <Checkbox
                          onChange={(e) => this.handleCheckBox(e, index)}
                          name={item.name}
                          value={item.value}
                        >
                          {item.name}
                        </Checkbox>

                      ))}
                    </Checkbox.Group>
                  </Form.Item>

                </Row>
              </div>
              <div className="repeatDayFlexBox">
                <Row className="title">
                  {/* <h2>Repeats</h2> */}
                </Row>
                <Row className="flexBox">
                  <Form.Item label="Repeats" name="repeats" >
                    <Col className="repeats-row">

                      <Radio.Group
                        className="checkbox-flex"
                        onChange={this.handleRadioChange}>
                        {repeats.map((item: any, index: any) => (
                          <Radio.Button value={item.value}>
                            {item.name}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Col>
                  </Form.Item>
                </Row>
              </div>
              <Row className="saveBtnbox">
                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="content-add-btn saveBtn"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>

        {/* Closing Timings */}
        <Modal
          className="closeTimingModal cltime-model "
          visible={editModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.onReset();
            this.setState({ editModal: false })
            this.setState({ closingStoreTimings: [] })
          }
          }
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
              onClick={() => {
                this.onReset();
                this.setState({ editModal: false })
                this.setState({ closingStoreTimings: [] })
              }}
            />
          }
          footer={null}
        >
          <Row className="RowsStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Closing Timing</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">
                  View the stores closing timings
                </p>
              </Col>
            </Row>


            <div className="item-description sev_item_description">
              <div className="item-info">
                <Row>
                  <Col span={12}>
                    <label className="label-text1"><u>{selectedStoreTimingData?.store_id}</u></label>
                    <label className="label-text">
                      {selectedStoreTimingData?.store_name}{selectedStoreTimingData?.store_address ? `-${selectedStoreTimingData?.store_address}` : null}</label>
                  </Col>
                </Row>
              </div>
            </div>

            <Form
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              name="content-ref"
              autoComplete="off"
            >

              {closingStoreTimings.length > 0 ?
                closingStoreTimings?.map((d: any) => {
                  return (

                    <>

                      <Row className="timeDaypickerFlexBox">
                        <Col className="timePicker">
                          <Form.Item name='datePicker' >
                            {
                              d.start_time && d.end_time ? `${d.start_time}-${d.end_time}` : <p>No data</p>
                            }
                          </Form.Item>
                        </Col>

                        <Col className="selectBox">
                          <Form.Item name='days'>

                            {d.days != null && Object.keys(d.days).length !== 0 && d.days.week_days.length > 0 ? d.days.week_days.toString() : d.start_date && d.end_date ? `${moment(d.start_date).format("Do MMM YY")}-${moment(d.end_date).format("Do MMM YY")}` : <p>No data</p>}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="editTimingBlock">
                        <Col>
                          <span className="icon" />
                          <span><EditOutlined translate={{}} onClick={() => {
                            this.setState({ editOfcModal: true, editModal: false });
                            this.populatedEditTimingsModalForEditTimings(d)

                          }} />EDIT TIMINGS</span>
                        </Col>
                        <Col className="ml-5">
                          <span><DeleteOutlined translate={{}} onClick={() => {
                            this.deleteParticularStoreCloseTime(d)

                          }} />Delete</span>
                        </Col>
                      </Row>
                    </>

                  )
                }) :
                <h3><br />No Records Found</h3>
              }


              {/* <Row>
                {closingStoreTimings?.length > 0 ?
                  closingStoreTimings?.map((d: any) => {
                    <>
                     

                    </>
                  }) : ''}

              </Row> */}

            </Form>
          </Row>

        </Modal>


        {/* Edit Multiple Timings */}
        <Modal
          className="editMultipleTimeModal"
          visible={addModal}
          title={null}
          centered
          width="700px"
          onCancel={() => {
            this.onReset()
            this.setState({ addModal: false })
          }
          }
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
              onClick={() => {
                this.onReset()
                this.setState({ addModal: false })
              }}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection" style={{ padding: '0px' }}>
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Edit Multiple Timings</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">
                  Set the multiple stores closing timings
                </p>
              </Col>
            </Row>
            <Form
              onFinish={this.onEditMultipleStoreTimings}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              initialValues={
                { zone_id: this.state?.Storelist[0]?.id }
              }
              name="content-ref"
              autoComplete="off"
              layout="vertical"
            >
              <Row className="modal-rows allstoreSelectBox">
                <Col className="rowCol">
                  <Form.Item label="Select Store" name="storeId" rules={[{ required: true, message: 'Store is required' }]}>
                    <Select
                      mode='multiple'
                      maxTagCount='responsive'
                      // className="modal-role-text"
                      placeholder="All Stores"
                    >
                      {this.state.Storelist.map((option: any) => (
                        <Select.Option value={option.id}>{option.store_name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="startEndDatePicker">
                <Col>
                  <Form.Item label="Date" name="startEndDate" rules={[{ required: true, message: "Start and End date is required" }]} >
                    <DatePicker.RangePicker format={'YYYY/MM/DD'} onChange={this.handleDateChange} />
                  </Form.Item>

                </Col>
              </Row>
              <Row className="startEndTimePicker ">
                <Col>
                  <Form.Item label="Time" name="startEndTime" rules={[{ required: true, message: "Start and End time is required" }]} >
                    <TimePicker.RangePicker
                      onChange={this.handleTimeChange}
                      use12Hours={true}
                      format="h:mm a"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="chooseDayFlexBox">
                <Row className="title">OR CHOOSE DAYS</Row>
                <Row className="flexBox">
                  <Form.Item name="days" valuePropName="checked" > {/* rules={[{ required: true, message: "Day/s is required" }]} */}
                    <Checkbox.Group>
                      {days.map((item: any, index: any) => (
                        <Checkbox
                          onChange={(e) => this.handleCheckBox(e, index)}
                          name={item.name}
                          value={item.value}
                        >
                          {item.name}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>

                  </Form.Item>
                </Row>
              </div>
              <div className="repeatDayFlexBox">
                <Row className="flexBox">
                  <Form.Item label="Repeats" name="repeats"> {/* rules={[{ required: true, message: "required" }]} */}
                    <Radio.Group
                      className="checkbox-flex"
                      onChange={this.handleRadioChange}>
                      {repeats.map((item: any, index: any) => (
                        <Radio.Button
                          value={item.value}>
                          {item.name}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Row>
              </div>
              <Row className="saveBtnbox">
                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="content-add-btn saveBtn"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>




        <div className="StoreTimingsHeaderSection store-timings-heading">
          <h1 className="title store-timings-search">Store Timings</h1>
          <div className="StoreTimingsActions store-timings-heading">
            <div className="searchBox">
              <Input placeholder="Search" onChange={(e) => { this.searchStore(e) }} />
            </div>
            {permissions.update_action?
            <Button
              className="editStoreBtn store-timings-search"
              onClick={() => {
                this.setState({ addModal: true })
              }}
            >
              EDIT MULTIPLE STORES
            </Button>
            :null}
          </div>
        </div>
        <div className="storeTimingTableBlock">
          <Table
            // rowSelection={rowSelection}
            title={() => 'Store Timings'}
            size="small"
            scroll={{ x: 'max-content' }}//1100
            columns={columns}
            dataSource={this.state.Storelist}
            loading={{ spinning: this.state.apiLoading, indicator: antIcon }}
          // pagination={true}
          />
        </div>
      </div>
    );
  }
}

export default StoreTimings;

// Customizable Area End