import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ImageRounded } from "@material-ui/icons";
import Axios from 'axios'
import Swal from 'sweetalert2'
import { message as AntMessage } from "antd";
import moment from 'moment';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  addCategoryModal: boolean,
  addSubCategoryModal: boolean,
  addGroupingModal: boolean,
  editCategoryModal: boolean,
  editSubCategoryModal: boolean,
  editGroupingModal: boolean,
  selectedTab: Number,
  CategoryList: any,
  SubCategoryList: any,
  GroupingList: any,
  InventoryDataList: any,
  apiLoading: boolean,
  selectedCategoryData: any,
  selectedSubCategoryData: any,
  selectedGroupingsData: any,
  Errors: any,
  selectedRowKeys: any,
  catalogueArray: any,
  selectedSubCategoryArray: any,
  displayInventoryList: any,
  selectedOfferData: any,
  OfferListData: any,
  buyItem: any,
  freeItems: any,
  offerModal: boolean,
  editOfferModal: boolean,
  timingsData: any,
  selectedOfferDataForUpdate: any,
  searchInventoryItemsBlock: any,
  pagination: any
  itemCode: any,
  articleCodeLoader: any,
  selectedCatalogueId: any,
  DataLoader: any
  // Customizable Area End
}

interface SS {
  id: any;


}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  base_url = `https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/`;
  public token = localStorage.getItem('token')
  public config = {
    headers: {
      'token': this.token || ''//'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NSwiZXhwIjoxNjM3MDQwNjg0fQ.5O46lD5pWKpsslFKx2gKi667-YdKDiKEB937oHoi8-_yHBtuDi2DxrcBUdE3t7VcSalptY73DLHoQtwyZgm0eA'
    }
  }
  getCategoryInformation: string;
  getSubCategoryInformation: string;
  getGroupingsInformation: string;
  addCategoryInformation: string;
  addSubCategoryInformation: string
  addGroupingsInformation: string
  getCategoryByIdInformation: string;
  getSubCategoryByIdInformation: string;
  searchCategoriesInformation: string;
  searchSubCategoriesInformation: string;
  editCategoryInformation: string
  editSubCategoryInformation: string
  editGroupingsInformation: string;
  editGroupingsInventoryInformation: string;
  deleteCategoryInformation: string;
  deleteSubCategoryInformation: string;
  getGroupingsInformationById: string;
  uploadCatalogueCsvFileInformation: string;
  uploadVariablePriceCsvFileInformation: string;
  uploadCategoryCsvFileInformation: string;
  uploadSubCategoryCsvFileInformation: string;
  uploadGrouppingCsvFileInformation: string;
  getCatalougeInformation: string;
  catalougeSearchInformation: string;
  deleteGroupingInformation: string;
  inventorySearchInformation: string;
  addGroupingsInventoryInformation: string
  getAllGroupingsInformation: string;
  searchGroupingsInformation: string;
  searchInventoryInformation: string;
  createOffersInformation: string;
  getOffersInformation: string;
  updateOffersInformation: string;
  deleteeOffersInformation: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      addCategoryModal: false,
      addSubCategoryModal: false,
      addGroupingModal: false,
      editCategoryModal: false,
      editSubCategoryModal: false,
      editGroupingModal: false,
      selectedTab: 1,
      CategoryList: [],
      SubCategoryList: [],
      GroupingList: [],
      InventoryDataList: [],
      apiLoading: false,
      selectedCategoryData: {},
      selectedSubCategoryData: {},
      selectedGroupingsData: {},
      Errors: {},
      selectedRowKeys: [],
      catalogueArray: [],
      selectedSubCategoryArray: [],
      displayInventoryList: [],
      selectedOfferData: {},
      OfferListData: [],
      buyItem: {},
      freeItems: [],
      offerModal: false,
      editOfferModal: false,
      timingsData: {
        startDate: '',
        EndDate: '',
        StartTime: '',
        EndTime: '',
      },
      selectedOfferDataForUpdate: {},
      searchInventoryItemsBlock: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        Totalcount: 0,
        TotalInventorycount: 0
      },
      itemCode: 'Item Code',
      articleCodeLoader: false,
      selectedCatalogueId: 0,
      DataLoader: false

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  toast(msg: any) {
    Swal.fire({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      icon: 'success',
      timerProgressBar: false,
      timer: 4000,
      title: msg
    })
  }
  errorToast(msg: any) {
    Swal.fire({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      icon: 'error',
      timerProgressBar: false,
      timer: 3000,
      title: msg
    })
  }
  async componentDidMount() {
    this.getCatalouge();
    // this.getCatgory();
    // this.getSubCategory();
    // this.getGroupings();
    // this.getAllGroupingsData();
    // this.getAllOffers();

    // super.componentDidMount();
    // this.getToken();
    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener("willFocus", () => {
    //     this.getToken();
    //   });
    // }
  }

  getCatalouge = async () => {
    this.setState({ apiLoading: true })
    this.getCatalougeInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllCatalougeApiEndPoint,
    });
  }


  getallarticlecodes = async () => {
    this.setState({ articleCodeLoader: true })
    this.getGroupingsInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllItemsForGrouppingsApiEndPoint,
      // endPoint: configJSON.getAllInventoryItemAndArticleCodeApiEndPoint + `?catalogue_id=${localStorage.getItem('catalogue_id')}`,
    });
  }
  getallarticlecodesByPagination = async (page: any, pageSize: any) => {
    this.setState({ articleCodeLoader: true })
    this.getGroupingsInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllItemsForGrouppingsApiEndPoint + `?page=${page}&per_page=${pageSize}`,
      // endPoint: configJSON.getAllInventoryItemAndArticleCodeApiEndPoint + `?catalogue_id=${localStorage.getItem('catalogue_id')}`,
    });
  }

  searchArticleCodeAPI = async (searchedText: any) => {
    this.setState({ articleCodeLoader: true })
    this.getGroupingsInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllItemsForGrouppingsApiEndPoint + `?search=${searchedText}`,
    });
  }


  addCategoryData = async (data: any) => {
    let add_Category_data = {
      "name": data.name,
      "priority": 2,
      "display_name": data.display_name,
      "catalogue_id": localStorage.getItem("catalogue_id"),
      "description": data.description,
      "status": true,
    }
    console.log('Calling add Category API', add_Category_data)
    this.addCategoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.addCategoryApiEndPoint,
      body: JSON.stringify(add_Category_data),
    });
  }
  addSubCategoryData = async (data: any) => {
    let add_SubCategory_data = {
      name: data.name,
      category_id: parseInt(data.category_id),
      display_name: data.display_name,
      description: data.description,
      status: true
    }
    console.log('Calling add Category API', add_SubCategory_data)
    this.addSubCategoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.addSubCategoryApiEndPoint,
      body: JSON.stringify(add_SubCategory_data),
    });
  }
  getCatgory = async () => {
    this.setState({ apiLoading: true });
    let id = localStorage.getItem('catalogue_id')
    if (id !== null) {
      this.getCategoryInformation = await this.apiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getCategoryApiEndPoint + id,
      });
    }

  };
  getSubCategory = async () => {
    let id = localStorage.getItem('catalogue_id')
    if (id !== null) {
      this.getSubCategoryInformation = await this.apiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getSubCategoryApiEndPoint + id,
      });
    }
  }
  getCategoryById = async (id: any) => {
    this.getCategoryByIdInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getCategoryByIdApiEndPoint}${id}`,
    });
  }
  getSubCategoryById = async (id: any) => {
    this.getSubCategoryByIdInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getSubCategoryByIdApiEndPoint}${id}`,
    });
  }
  getAllInventoryById = async (id: number) => {
    this.getGroupingsInformationById = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.addGroupingApiEndPoint + `/${id}`,
    });
  }
  getAllOffers = async () => {
    this.getOffersInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getOffersApiEndPoint,
    });
  }

  editInventoryData = async (data: any, id: number) => {
    this.editGroupingsInventoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.addGroupingApiEndPoint + `/${id}`,
      body: JSON.stringify(data),
    });
  }

  editCategoryData = async (data: any, id: any) => {
    this.editCategoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.editCategoryApiEndPoint + id,
      body: JSON.stringify(data),
    });
  }
  editSubCategoryData = async (data: any, id: any) => {
    this.editSubCategoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPatchMethod,
      endPoint: configJSON.editSubCategoryApiEndPoint + id,
      body: JSON.stringify(data),
    });
  }

  handleDeleteCategoryById = async (id: any) => {
    this.deleteCategoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpDelMethod,
      endPoint: configJSON.deleteCategorybyId + id,
      // body: JSON.stringify(data),

    });
  }
  handleDeleteSubCategoryById = async (id: any) => {
    this.deleteSubCategoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpDelMethod,
      endPoint: configJSON.deleteSubCategorybyId + id,
      // body: JSON.stringify(data),

    });
  }
  uploadCatalogueCsvFile = async (formData: any) => {
    this.uploadCatalogueCsvFileInformation = await this.apiCall({
      contentType: undefined,//`multipart/form-data;boundary="boundary"`,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.ImportCsvApiEndPoint,
      body: formData,

    });
  }
  uploadVariablePriceFile = async (formData: any) => {
    this.uploadVariablePriceCsvFileInformation = await this.apiCall({
      contentType: undefined,//`multipart/form-data;boundary="boundary"`,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.variablePriceApiEndPoint,
      body: formData,

    });
  }
  uploadCategoryCsvFile = async (formData: any) => {
    this.uploadCategoryCsvFileInformation = await this.apiCall({
      contentType: undefined,//`multipart/form-data;boundary="boundary"`,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.ImportCategoryCsvApiEndPoint,
      body: formData,

    });
  }
  uploadSubCategoryCsvFile = async (formData: any) => {
    this.uploadSubCategoryCsvFileInformation = await this.apiCall({
      contentType: undefined,//`multipart/form-data;boundary="boundary"`,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.ImportSubCategoryCsvApiEndPoint,
      body: formData,

    });
  }



  uploadGroupingsCsvFile = async (formData: any) => {
    this.uploadGrouppingCsvFileInformation = await this.apiCall({
      contentType: undefined,//`multipart/form-data;boundary="boundary"`,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.grouppingImportCsvApiEndPoint,
      body: formData,

    });
  }

  searchCategoriesDetails = async (searchedText: any) => {
    this.searchCategoriesInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.searchCategoriesApiEndPoint + searchedText,
    });
  }

  searchSubCategoriesDetails = async (searchedText: any) => {
    this.searchSubCategoriesInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.searchSubCategoriesApiEndPoint + searchedText,
    });
  }

  searchCatalougeDetails = async (searchedText: any) => {
    this.catalougeSearchInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.searchCatalougeApiEndPoint + searchedText,
    });
  }

  deleteGroupingsbyId = async (id: any) => {
    this.deleteGroupingInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpDelMethod,
      endPoint: configJSON.addGroupingApiEndPoint + `/${id}`,
    });
  }
  searchInventoryDetails = async (id: any) => {
    this.inventorySearchInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.InventorySearchApiEndPoint + id,
    });
  }

  addGroupingsData = async (data: any) => {
    this.addGroupingsInventoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.addGroupingApiEndPoint,
      body: JSON.stringify(data),
    });
  }

  getAllGroupingsData = async () => {
    let id = localStorage.getItem('catalogue_id')
    if (id !== null) {
      this.getAllGroupingsInformation = await this.apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getAllGroupingApiEndPoint + id,
      });
    }
  }
  getGroupingsInformationByPagination = async (page: any, pagesize: any) => {
    let id = localStorage.getItem('catalogue_id')
    if (id !== null) {
      this.getAllGroupingsInformation = await this.apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getAllGroupingApiEndPoint + id + `&page=${page}&per_page=${pagesize}`,
      });
    }
  }

  searchGroupingsDetails = async (searchedText: any) => {
    this.searchGroupingsInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.searchGrouppingsApiEndPoint + searchedText,
    });
  }


  inventoryItemSearchByName = async (searchedText: any) => {
    this.searchInventoryInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.searchAllGroupingApiEndPoint + searchedText + `&catalogue_id=${localStorage.getItem('catalogue_id') ? localStorage.getItem('catalogue_id') : ''}`,
    });
  }

  createOffer = async (payLoad: any) => {
    this.createOffersInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.createOffersApiEndPoint,
      body: JSON.stringify(payLoad),

    });
  }
  updateOffer = async (payLoad: any,) => {
    this.updateOffersInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.getOffersApiEndPoint}${this.state?.selectedOfferDataForUpdate.id}`,
      body: JSON.stringify(payLoad),

    });
  }

  deleteOfferById = async (id: any,) => {
    this.deleteeOffersInformation = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpDelMethod,
      endPoint: `${configJSON.getOffersApiEndPoint}${id}`,
      // body: JSON.stringify(payLoad),

    });
  }
  //===================================================================================================================
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  //========================================= API CALL BLOCK ===================================================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  //========================================= RECEIVE API CALL BLOCK ===================================================

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("responseJson>>>>>>>", responseJson);
      if (responseJson?.errors && responseJson?.errors[0]?.token) {
        //@ts-ignore
        if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
          localStorage.clear();
          window.location.href = '/';
        };
        if (responseJson == undefined) {
          // AntMessage.error('Something went wrong')
          return
        }
      }
      if (responseJson && responseJson.data || responseJson?.message || responseJson?.meta || responseJson?.success) {
        if (apiRequestCallId === this.getCategoryInformation) {
          this.handleGetCategoryData(responseJson);
        }
        if (apiRequestCallId == this.getSubCategoryInformation) {
          this._handelGetSubCategoryData(responseJson);
        }
        if (apiRequestCallId === this.addCategoryInformation) {
          this._handelAddCategoryData(responseJson);
        }
        if (apiRequestCallId === this.addSubCategoryInformation) {
          this._handelAddSubCategoryData(responseJson);
        }
        if (apiRequestCallId === this.addGroupingsInformation) {
          this._handelAddSubGroupingsData(responseJson);
        }
        if (apiRequestCallId === this.getCategoryByIdInformation) {
          this._handleGetCategoryDataById(responseJson);
        }
        if (apiRequestCallId === this.getSubCategoryByIdInformation) {
          this._handelGetSubCategoryDataById(responseJson);
        }
        if (apiRequestCallId === this.editCategoryInformation) {
          this._handelEditCategoryData(responseJson);
        }
        if (apiRequestCallId === this.editSubCategoryInformation) {
          this._handelEditSubCategoryData(responseJson);
        }
        if (apiRequestCallId === this.editGroupingsInformation) {
          this._handelEditSubGroupingsData(responseJson);
        }
        if (apiRequestCallId === this.deleteCategoryInformation) {
          this.handleDeleteCategoryData(responseJson);
        }
        if (apiRequestCallId === this.deleteSubCategoryInformation) {
          this.handleDeleteSubCategoryData(responseJson);
        }
        if (apiRequestCallId === this.uploadCatalogueCsvFileInformation) {
          this._handelImportCsvSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.uploadVariablePriceCsvFileInformation) {
          this._handelImportCsvSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.uploadCategoryCsvFileInformation) {
          this._handelImportCsvSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.uploadSubCategoryCsvFileInformation) {
          this._handelImportCsvSuccessResponse(responseJson);
        }


        if (apiRequestCallId === this.uploadGrouppingCsvFileInformation) {
          this._handelGrouppingImportCsvSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.getGroupingsInformation) {
          this._handelGetInventoryData(responseJson);
        }
        if (apiRequestCallId === this.getGroupingsInformationById) {
          this._handelGetInventoryDataById(responseJson);
        }
        if (apiRequestCallId === this.searchCategoriesInformation) {
          this.handleGetCategoryData(responseJson);
        }
        if (apiRequestCallId === this.searchSubCategoriesInformation) {
          this._handelGetSubCategoryData(responseJson);
        }
        if (apiRequestCallId === this.getCatalougeInformation) {
          this._handelgetCatalougeSuccessData(responseJson);
        }
        if (apiRequestCallId === this.catalougeSearchInformation) {
          this._handelgetCatalougeSuccessData(responseJson);
        }
        if (apiRequestCallId === this.deleteGroupingInformation) {
          this._handeldeleteGroupingSuccessData(responseJson);
        }
        if (apiRequestCallId === this.inventorySearchInformation) {
          this._handelinventorySearchSuccessData(responseJson);
        }
        if (apiRequestCallId === this.addGroupingsInventoryInformation) {
          this._handeladdGroupingsInventorySuccessData(responseJson);
        }
        if (apiRequestCallId === this.editGroupingsInventoryInformation) {
          this._handeleditGroupingsInventoryInformationSuccessData(responseJson);
        }
        if (apiRequestCallId === this.getAllGroupingsInformation) {
          this._handelGetGroupingSuccessData(responseJson);
        }
        if (apiRequestCallId === this.searchGroupingsInformation) {
          this._handelGetGroupingSuccessData(responseJson);
        }
        if (apiRequestCallId === this.searchInventoryInformation) {
          this._handelInventorySearchDataSuccessData(responseJson);
        }
        if (apiRequestCallId === this.createOffersInformation) {
          this._handelCreateOffersSuccessData(responseJson);
        }
        if (apiRequestCallId === this.getOffersInformation) {
          this._handelGetOffersSuccessData(responseJson);
        }
        if (apiRequestCallId === this.updateOffersInformation) {
          this._handelUpdateOffersSuccessData(responseJson);
        }
        if (apiRequestCallId === this.deleteeOffersInformation) {
          this._handelDeleteOffersSuccessData(responseJson);
        }












      } else if (responseJson && responseJson.error || responseJson?.errors) {
        // if(responseJson.errors){
        //     AntMessage.error(responseJson.errors)
        // if(responseJson.errors[0]?.token){
        //     AntMessage.error('Session timed out please login again');
        //     localStorage.clear(); 

        // }
        // }
        if (apiRequestCallId === this.getCategoryInformation) {
          this._handelgetCatalougeErrorData(responseJson);

        }
        if (apiRequestCallId === this.addCategoryInformation) {
          this.handelErrorAddCategoryData(responseJson);
        }
        if (apiRequestCallId === this.editCategoryInformation) {
          console.log('edit Category Error', responseJson)

        }
        if (apiRequestCallId === this.catalougeSearchInformation) {
          AntMessage.error('No data found')
        }
        if (apiRequestCallId === this.deleteGroupingInformation) {
          this._handeldeleteGroupingErrorData(responseJson);
        }
        if (apiRequestCallId === this.editGroupingsInformation) {
          this._handelEditSubGroupingsErrorData(responseJson);
        }
        if (apiRequestCallId === this.addGroupingsInventoryInformation) {
          this._handeladdGroupingsInventoryErrorData(responseJson);
        }
        if (apiRequestCallId === this.editGroupingsInventoryInformation) {
          this._handeleditGroupingsInventoryInformationErrorData(responseJson);
        }
        if (apiRequestCallId === this.getAllGroupingsInformation) {
          this._handelGetGroupingErrorData(responseJson);
        }
        if (apiRequestCallId === this.searchGroupingsInformation) {
          this._handelGetGroupingErrorData(responseJson);
        }
        if (apiRequestCallId === this.createOffersInformation) {
          this._handelCreateOffersErrorData(responseJson);
        }
        if (apiRequestCallId === this.getOffersInformation) {
          this._handelGetOffersErrorData(responseJson);
        }
        if (apiRequestCallId === this.getGroupingsInformation) {
          this.setState({ apiLoading: false, articleCodeLoader: false, InventoryDataList: [] });
        }

        else {
          // AntMessage.error('Something went wrong');
        }
      } else if (errorReponse) {

      }
    }
  }
  //===================================================================================================

  //====================================================================================================
  handleGetCategoryData = async (data: any) => {
    let categoryListDataTobeDisplayed = data.data.map((sd: any,index:any) => {
      return {
        id: sd.id,
        sno: (index + 1) + ((this.state.pagination.currentPage - 1) * 10),
        name: sd.attributes.name,
        priority: sd.attributes.priority,
        display_name: sd.attributes.display_name,
        description: sd.attributes.description,
        status: sd.attributes.status ? true : false,
        catalogue_id: sd.attributes.catalogue_id
      }
    })
    this.setState({ CategoryList: categoryListDataTobeDisplayed, apiLoading: false })
  }

  _handelGetSubCategoryData = async (data: any) => {
    let subCategoryListDataTobeDisplayed = data.data.map((sd: any,index:any) => {
      return {
        id: sd.id,
        sno: (index + 1) + ((this.state.pagination.currentPage - 1) * 10),
        name: sd.attributes.name,
        priority: sd.attributes.priority,
        display_name: sd.attributes.display_name,
        description: sd.attributes.description,
        category_name: sd.attributes.category_name,
        status: sd.attributes.status,
        catalogue_id: sd.attributes.catalogue_id,
        category_id: sd.attributes.category_id
      }
    })
    this.setState({ SubCategoryList: subCategoryListDataTobeDisplayed })
  }


  _handelGetInventoryData = async (data: any) => {
    this.setState({ InventoryDataList: data.data, displayInventoryList: data.data, apiLoading: false, articleCodeLoader: false, pagination: { ...this.state.pagination, TotalInventorycount: data?.meta?.total_count ? data?.meta?.total_count : 0 } }
    )

  }
  //====================================================================================================
  _handelGetGroupingSuccessData = async (data: any) => {
    let groupingDataTobeDisplayed = data.data.map((sd: any,index:any) => {
      return {

        id: sd.id,
        sno: (index + 1) + ((this.state.pagination.currentPage - 1) * 10),
        name: sd.attributes.item_name,
        displayName: sd.attributes.display_name,
        itemName: sd.attributes.item_name,
        status: sd.attributes.status == true ? true : false,
        articalCode: sd.attributes.item_code,
        description: sd.attributes.description,
        netMrp: sd.attributes.net_price,
        grossMrp: sd.attributes.gross_price,
        subCategoryId: sd.attributes.sub_category_names.length == 0 ? sd.attributes.sub_category_names[0] : sd.attributes.sub_category_names.join(',')

      }
    })
    this.setState({ GroupingList: groupingDataTobeDisplayed, apiLoading: false, pagination: { ...this.state.pagination, Totalcount: data?.meta?.total_count ? data?.meta?.total_count : 40 }
      }
    )

  }
  //======================================== ===========================================================
  _handelInventorySearchDataSuccessData = async (data: any) => {
    console.log(data);
  }
  //====================================================================================================
  //call add Store API
  _handelAddCategoryData = async (data: any) => {
    console.log(data.data);
    if (data.data) {
      // this.toast(data.message)
      this.setState({ addCategoryModal: false })
      AntMessage.success("Category added Successfully")//antd
      this.getCatgory();
      // this.componentDidMount();
    }
  }
  //====================================================================================================
  _handelAddSubCategoryData = async (data: any) => {
    console.log(data.data);
    if (data?.data?.attributes?.errors) {
      AntMessage.error(data?.data?.attributes?.errors?.name[0]);
      return;
    }

    this.setState({ addSubCategoryModal: false });
    AntMessage.success("SubCategory added Successfully");
    //@ts-ignore
    this.formRef.current!.resetFields();
    this.getSubCategory();


  }
  //====================================================================================================
  _handelAddSubGroupingsData = async (data: any) => {
    console.log(data.data);
    if (data.data) {
      this.setState({ addGroupingModal: false })
      // this.toast(data.message)
      AntMessage.success(data.message)//antd
      this.componentDidMount();
    }
  }
  //====================================================================================================
  _handelEditSubCategoryData = async (data: any) => {
    console.log(data.data);
    if (data?.data?.attributes?.errors) {
      AntMessage.error(data?.data?.attributes?.errors?.name[0]);
      return;
    }
    this.setState({ editSubCategoryModal: false })
    AntMessage.success("SubCategory Updated Successfully");
    this.getSubCategory();

  }
  //====================================================================================================
  _handelEditSubGroupingsData = async (data: any) => {
    if (data.data) {
      this.setState({ editCategoryModal: false });
      AntMessage.success('Updated successfully!')
      this.componentDidMount();
    }
  }
  //====================================================================================================
  _handelEditCategoryData = async (data: any) => {
    console.log(data.data);
    if (data.data?.attributes.errors?.name.length > 0) {
      data?.data.attributes.errors?.name.map((error: any) => {
        AntMessage.error(error)
      })
    }
    else {
      this.setState({ editCategoryModal: false })
      AntMessage.success("Category Updated Successfully")
      this.getCatgory();
      // this.componentDidMount();
    }
  }

  //====================================================================================================
  _handleGetCategoryDataById = async (data: any) => {
    console.log(data);
    let APIResponse = data?.data.attributes;
    this.setState({ selectedCategoryData: APIResponse, DataLoader: false })
    // console.log(this.formRef)
    let _data = {
      id: data.data?.id,
      name: APIResponse.name,
      priority: APIResponse.priority,
      display_name: APIResponse.display_name,
      description: APIResponse.description,
      status: APIResponse.status,
      catalogue_id: APIResponse.catalogue_id,
    }
    //@ts-ignore
    this.formRef.current.setFieldsValue(_data)
  }
  //====================================================================================================
  _handelGetSubCategoryDataById = async (data: any) => {
    console.log(data);
    let APIResponse = data?.data;
    this.setState({ selectedSubCategoryData: APIResponse, DataLoader: false });
    let _data = {
      id: APIResponse?.id,
      name: APIResponse?.attributes?.name,
      priority: APIResponse?.attributes?.priority,
      display_name: APIResponse?.attributes?.display_name,
      description: APIResponse?.attributes?.description,
      status: APIResponse?.attributes?.status,
      catalogue_id: APIResponse?.attributes?.catalogue_id,
      category_id: APIResponse?.attributes?.category_id?.toString()
    }
    //@ts-ignore
    this.formRef.current.setFieldsValue(_data)
  }

  //====================================================================================================
  _handelGetInventoryDataById = async (data: any) => {
    this.setState({ selectedGroupingsData: data, selectedSubCategoryArray: data.data.attributes.sub_category_ids, DataLoader: false })
    let _data = {
      itemName: data.data.attributes.item_name,
      name: data.data.attributes.store_name,
      itemCode: data.data.attributes.item_code,
      item_description: data.data.attributes.description,
      netmrp: data.data.attributes.net_price,
      gmrp: data.data.attributes.gross_price ? data.data.attributes.gross_price : 0,
      itemDisplayName: data.data.attributes.display_name,
      subCatego: data.data.attributes.sub_category_names,
    }
    //@ts-ignore
    this.formRef.current.setFieldsValue(_data)

  }
  handleDeleteCategoryData = (data: any) => {

    AntMessage.success('Category Deleted successfully!')
    this.componentDidMount();
  }
  handleDeleteSubCategoryData = (data: any) => {
    AntMessage.success('SubCategory Deleted successfully!')
    this.componentDidMount();
  }
  _handelImportCsvSuccessResponse = async (data: any) => {
    if (data.data || data.status == 200) {
      this.setState({ apiLoading: false })
      AntMessage.success('File Imported Successfully ');
      this.componentDidMount();
    }
  }
  _handelGrouppingImportCsvSuccessResponse = async (data: any) => {
    if (data.status == 200) {
      AntMessage.success('File Imported Successfully ');
      this.setState({ apiLoading: false });
      this.getAllGroupingsData();
      // this.componentDidMount();
    }
  }

  _handelgetCatalougeSuccessData = async (data: any) => {
    this.setState({ catalogueArray: data.data, apiLoading: false })
  }
  //====================================================================================================
  // _handeldeleteGroupingSuccessData
  _handeldeleteGroupingSuccessData = async (data: any) => {
    console.log(data);

    if (data.meta) {
      AntMessage.success(data.meta.message);
      this.getAllGroupingsData();

    }
  }
  //====================================================================================================
  // _handelinventorySearchSuccessData
  _handelinventorySearchSuccessData = async (data: any) => {
    console.log(data);
    let _data = {
      itemName: data.data.attributes.item_name,
      itemCode: data.data.attributes.article_code,
      netmrp: data.data.attributes.net_mrp,
      item_description: data.data.attributes.item_description,
      gmrp: data.data.attributes.gross_mrp ? data.data.attributes.gross_mrp : 0,
      itemDisplayName: data.data.attributes.display_name,
      subCatego: data.data.attributes.sub_category_names,
    }
    //@ts-ignore
    this.formRef.current.setFieldsValue(_data)

  }
  //====================================================================================================
  // _handeladdGroupingsInventorySuccessData
  _handeladdGroupingsInventorySuccessData = async (data: any) => {
    // console.log(data);
    if (data.data) {
      if (data.data.type == "grouping") { AntMessage.success(" Grouping Added successfully"); }
      if (data.data.type == "error") {
        AntMessage.warning(data.data.attributes?.errors?.item_code[0] == 'has already been taken' ? 'Item/Itemcode has already been taken' : 'something went wrong');
        return;
      }
      this.getAllGroupingsData();
      //@ts-ignore
      this.formRef.current!.resetFields();
      this.setState({ addGroupingModal: false });
    }
  }
  //====================================================================================================
  // _handeleditGroupingsInventoryInformationSuccessData
  _handeleditGroupingsInventoryInformationSuccessData = async (data: any) => {
    if (data.data) {
      AntMessage.success(" Grouping updated successfully");
      this.setState({ editGroupingModal: false });
      // this.componentDidMount();
      this.getAllGroupingsData();
    }
  }
  //====================================================================================================
  _handelCreateOffersSuccessData = async (data: any) => {
    console.log(data);
    if (data.data) {
      AntMessage.success(`${data?.data?.attributes?.offer_type} Offer is Created Successfully !`);
      this.getAllOffers();
    }
  }
  //====================================================================================================
  _handelGetOffersSuccessData = async (data: any) => {
    console.log(data.data);
    let tableDataToBeDisplayed = data?.data?.map((d: any, index: any) => {
      return {
        sno: index + 1,
        id: d?.id,
        offerType: d?.attributes?.offer_type ? d?.attributes?.offer_type : '-',
        start_time: d?.attributes?.start_time ? `${moment(d?.attributes?.start_time).format("h:mm a")}` : '-',
        end_time: d?.attributes?.end_time ? `${moment(d?.attributes?.end_time).format("h:mm a")}` : '-',
        start_date: d?.attributes?.start_date ? moment(d?.attributes?.start_date).format('DD-MM-YYYY') : '',
        end_date: d?.attributes?.end_date ? moment(d?.attributes?.end_date).format('DD-MM-YYYY') : '',
        // start_date: d?.attributes?.start_date ? d?.attributes?.start_date.split('-').reverse().join('-') : '-',
        // end_date: d?.attributes?.end_date ? d?.attributes?.end_date.split('-').reverse().join('-') : '-',
        // end_time: d?.attributes?.end_time ? d?.attributes?.end_time : '-',
        // itemCode: d?.attributes?.offer_type,
        // itemName: d?.attributes?.offer_type,
        amount: d?.attributes?.min_order_amount ? d?.attributes?.min_order_amount : "NA",

      }
    })
    this.setState({ OfferListData: tableDataToBeDisplayed });
  }
  //====================================================================================================
  _handelUpdateOffersSuccessData = async (data: any) => {
    // console.log(data.data);
    if (data.data) {
      AntMessage.success(`Offer Updated Successfully`)
      this.setState({ editOfferModal: false })
      this.getAllOffers();
    }
  }
  //====================================================================================================
  _handelDeleteOffersSuccessData = async (data: any) => {
    console.log(data.success);
    if (data.success) {
      AntMessage.success(`Offer Deleted Successfully`)
      this.getAllOffers();
    }
  }
  //====================================================================================================
  //ERROR BLOCK
  //====================================================================sssssss================================
  _handelgetCatalougeErrorData = async (data: any) => {
    console.log('_handelgetCatalougeErrorData', data);
    this.setState({ Errors: { apiLoading: false } });
  }
  //=======================================================


  handelErrorAddCategoryData = async (data: any) => {
    this.setState({ Errors: { addCategoryError: data.error } });
    console.log(this.state);
  }
  //=======================================================
  // _handeldeleteGroupingErrorData
  _handeldeleteGroupingErrorData = async (data: any) => {
    if (data.meta) {
      AntMessage.success(data.meta.message)
    }
  }
  //=======================================================
  // _handelEditSubGroupingsErrorData
  _handelEditSubGroupingsErrorData = async (data: any) => {
    if (data.message) {
      AntMessage.success(data.message)
    }
  }
  //=======================================================
  // _handeladdGroupingsInventoryErrorData
  _handeladdGroupingsInventoryErrorData = async (data: any) => {
    console.log(data);
    AntMessage.error(data.errors[0])
    return;
  }
  //=======================================================
  _handeleditGroupingsInventoryInformationErrorData = async (data: any) => {
    if (data) {
      console.log("Edit grouping Error", data);
      AntMessage.success(data.message)
    }
  }
  //=======================================================
  // _handelGetGroupingErrorData
  _handelGetGroupingErrorData = async (data: any) => {
    console.log('Get Groupings Error', data)
  }
  //=======================================================
  _handelCreateOffersErrorData = async (data: any) => {
    console.log(data);
  }
  //=======================================================
  _handelGetOffersErrorData = async (data: any) => {
    console.log(data);
  }
  //=======================================================
  //=======================================================
  // txtInputWebProps = {
  //   onChangeText: (text: string) => {
  //       this.setState({ txtInputValue: text });
  //   },
  //   secureTextEntry: false,
  // };

  // txtInputMobileProps = {
  //   ...this.txtInputWebProps,
  //   autoCompleteType: "email",
  //   keyboardType: "email-address",
  // };

  // txtInputProps = this.isPlatformWeb()
  //   ? this.txtInputWebProps
  //   : this.txtInputMobileProps;

  // btnShowHideProps = {
  //   onPress: () => {
  //       this.setState({ enableField: !this.state.enableField });
  //       this.txtInputProps.secureTextEntry = !this.state.enableField;
  //       this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
  //           ? imgPasswordVisible
  //           : imgPasswordInVisible;
  //   },
  // };

  // btnShowHideImageProps = {
  //   source: this.txtInputProps.secureTextEntry
  //       ? imgPasswordVisible
  //       : imgPasswordInVisible,
  // };

  // btnExampleProps = {
  //   onPress: () => this.doButtonPressed(),
  // };

  // doButtonPressed() {
  //   let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
  //   msg.addData(
  //       getName(MessageEnum.AuthTokenDataMessage),
  //       this.state.txtInputValue
  //   );
  //   this.send(msg);
  // }

  // Customizable Area Start
  // Customizable Area End
  // Customizable Area Start
  // Customizable Area End
}
