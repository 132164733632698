Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.base_url =
  "https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings2";
exports.labelBodyText = "Settings2 Body";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDelMethod = "DELETE";
exports.ApiContentType = "application/json";
exports.btnExampleTitle = "CLICK ME";
exports.changePasswordApiEndPoint = `bx_block_profile/passwords`;
exports.getProfileDataApiEndPoint = `bx_block_peoplemanagement2/people_accounts?id=`;
exports.editProfileDataApiEndPoint = `bx_block_profile/profile_update?id=`;
exports.editProfileImageApiEndPoint = `bx_block_profile/profile_update`;



// Customizable Area End
