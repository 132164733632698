// Customizable Area Start
import React, { createRef } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "antd/dist/antd.css";
import {
    Table,
    Select,
    Button,
    Modal,
    Upload,
    Input,
    Row,
    Col,
    Form,
    Typography,
    Divider,
    Space,
    Card,
    DatePicker,
    Switch,
    Skeleton,
    List,
} from "antd";
import { ArrowLeftOutlined, BarChartOutlined, CloseCircleFilled, SyncOutlined } from "@ant-design/icons";
import "../../../blocks/RequestManagement/src/Reports/Reports.css";
import { FormInstance } from "antd/es/form";
const { Title, Paragraph, Text, Link } = Typography;
import {
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
    EditFilled,
} from "@ant-design/icons";
import dashboardController, { Props, configJSON } from "./DashboardController";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import "./Dashboard.css";

export class OrderFullfilled extends dashboardController {
    cardArray: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            dashboardData: [],
            errorMsg: "",
            token: "", 
            loading: false,
            orderList: [],
            newOrderBlock: false,
            acceptedOrdersBlock: false,
            rejectedOrdersBlock: false,
            partiallyAcceptedOrdersBlock: false,
            partiallyRejectedOrdersBlock: false,
            ordersOnHoldBlock: false,
            orderFullfilledBlock: false,
            selectedRowKeys: [],
            adminDashbaordData: [],
            orderApiData: [],
            selectedOrderId: '',
            selectedOrderDetails: {},
            orderConfirmationState: '',
            testState: [],
            selectedFilterId: "",
            DropDownValue: "",
            orderDetails: [],
            RejectedReasonBlock: false,
            variablePriceArray: [],
            variableQuantity: [],
            filterOrderData: [4],
            selectedFilterString: `?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`,
            Order_Id:0
        };
        // Customizable Area End
    }


    async componentDidMount() {
        //@ts-ignore
        this.getSelectedOrderDetails(this.props?.orderid)
    }

    handelItemSearch = async (searchedText: any, orderId: any) => {
        console.log(searchedText, orderId);
        this.searchOrdersItems(searchedText, orderId)
    }
    render() {
        let permissions: any;
        let decodedString = atob(localStorage.getItem('permissions') || '{}');

        //@ts-ignore
        JSON.parse(decodedString).filter((d) => {

            if (d.attributes.access_module.name == "Store Dashboard") {
                permissions = d.attributes
                return
            }
        });

        console.log(permissions, "permissions");
        console.log(this.props, "props");
        const { selectedOrderDetails, newOrderBlock, acceptedOrdersBlock, rejectedOrdersBlock, partiallyAcceptedOrdersBlock, partiallyRejectedOrdersBlock, ordersOnHoldBlock, orderFullfilledBlock } = this.state
        const totalOrderColumn = [
            {
                title: 'order Id',
                dataIndex: 'order_id',
                key: 'order_id',
            },
            {
                title: 'Bill no',
                dataIndex: 'bill_no',
                key: 'bill_no',
            },
            {
                title: 'Customer name',
                dataIndex: 'customer_name',
                key: 'customer_name',
            },
            {
                title: 'customer Phoneno',
                dataIndex: 'customer_phoneno',
                key: 'customer_phoneno',
            },
            {
                title: 'Store Id',
                dataIndex: 'store_id',
                key: 'store_id',
            },
            {
                title: 'Item code',
                dataIndex: 'item_code',
                key: 'item_code',
            },
            {
                title: 'Item name',
                dataIndex: 'item_name',
                key: 'item_name',
            },
            {
                title: 'Item quantity ',
                dataIndex: 'item_quantity',
                key: 'item_quantity',
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'discount',
                dataIndex: 'discount',
                key: 'discount',
            },
            {
                title: 'date & time',
                dataIndex: 'date_time',
                key: 'date_time',
                render(text: any, record: any) {
                    let dataData = text.split(',')
                    return {
                        props: {
                            style: { fontWeight: 'bold', lineHeight: '7px' }
                        },
                        children: <div><p>{dataData[0]}</p><p>{dataData[1]}</p></div>
                    };
                }
            },
        ]
        const totalOrderOrder = selectedOrderDetails?.order_items?.map((data: any, index: any) => {
            return {
                key: index + 1,
                order_id: index + 1,
                customer_name: selectedOrderDetails.customer_name ? selectedOrderDetails.customer_name : '-',
                bill_no: selectedOrderDetails.bill_no ? selectedOrderDetails.bill_no : '-',
                customer_phoneno: selectedOrderDetails.customer_phoneno ? selectedOrderDetails.customer_phoneno : '-',
                store_id: selectedOrderDetails.store_id ? selectedOrderDetails.store_id : '-',
                item_code: data.id,
                item_name: data.item_name ? data.item_name : '-',
                item_quantity: data.quantity ? data.quantity : '-',
                price: data.price ? data.price : '-',
                discount: data.discount,
                date_time: selectedOrderDetails.created_at ? ` ${moment(selectedOrderDetails.created_at).format(`D MMM YY`)},${moment(selectedOrderDetails.created_at).format(`h:mm A`)}  ` : '-',
            }
        })
        return (
            <div>
                <ArrowLeftOutlined translate={{}} style={{ fontSize: '35px', paddingBottom: 20 }} onClick={() => {
                    //@ts-ignore
                    this.props.seState({
                        newOrderBlock: false,
                        acceptedOrdersBlock: false,
                        rejectedOrdersBlock: false,
                        partiallyAcceptedOrdersBlock: false,
                        partiallyRejectedOrdersBlock: false,
                        ordersOnHoldBlock: false,
                        orderFullfilledBlock: false,
                    })
                }} />
                <Row >
                    <Col>
                        <Space>
                            <div>
                                <p className="layout-order-id">{selectedOrderDetails?.partner_order_id ? selectedOrderDetails?.partner_order_id : ''}</p>
                            </div>
                        </Space>
                    </Col>
                    <Col className="SearchPlacement">
                        <Space>
                            <Input type="text"
                                placeholder="Search"
                                className="inputTpye"
                                onChange={(e) => { this.handelItemSearch(e.target.value, selectedOrderDetails?.id) }} />
                        </Space>
                    </Col>
                </Row>
                <div>
                    {!selectedOrderDetails.partner_order_id ?
                        <>
                            {/* <SyncOutlined style={{ fontSize: "25px", display: "flex", justifyContent: "center" }} translate="" spin /> */}
                            <Skeleton loading={!selectedOrderDetails.partner_order_id} active avatar>
                                <List.Item.Meta
                                    avatar={<Avatar src={""} />}
                                    title={""}
                                    description=""
                                />
                            </Skeleton>
                        </>
                        :
                        <div className="store-order-informations">
                            <Card className="order-sortinfo">
                                <div className="order-title-block">
                                    <div className="order-top-block">
                                        <Row gutter={[16, 48]} style={{ width: '100%' }}>
                                            <Col span={10} style={{ textAlign: "left" }}>
                                                <h2 className="ordres-title">
                                                    <span className="icon">
                                                        <img
                                                            src={selectedOrderDetails?.catalogue_url ? selectedOrderDetails?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                            className="partner-img2"
                                                        ></img>
                                                    </span>
                                                    {selectedOrderDetails?.partner}
                                                </h2>
                                            </Col>
                                            <Col span={14} style={{ textAlign: "right" }}>
                                                <div className="ordre-actoions">
                                                    <Button className="btn btn-submit" style={{ pointerEvents: 'none', backgroundColor: '#c3da90' }}>Order Fullfilled</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="order-bottom-block">
                                        <Row gutter={[16, 48]} className="n-order-row">
                                            <Col className="gutter-row n-order-col" span={3} style={{ textAlign: "left" }}>
                                                <div className="ordre--info">
                                                    <h4>Order Amount</h4>
                                                    <p>Rs. {selectedOrderDetails?.total_price}</p>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row n-order-col" span={3} style={{ textAlign: "left" }}>
                                                <div className="ordre--info">
                                                    <h4>Total Item </h4>
                                                    <p>{selectedOrderDetails?.total_item}</p>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row n-order-col" span={3} style={{ textAlign: "left" }}>
                                                <div className="ordre--info">
                                                    <h4>Total Quantity </h4>
                                                    <p>{selectedOrderDetails?.item_quantity}</p>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row n-order-col" span={3} style={{ textAlign: "left" }}>
                                                <div className="ordre--info">
                                                    <h4>Total Discount</h4>
                                                    <p>{selectedOrderDetails?.discount}</p>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row n-order-col" span={12} style={{ textAlign: "left" }}>
                                                <div className="order-action">
                                                    {/* <Button className="btn-submit">
                                                    Accept
                                                </Button>
                                                <Button className="btn-submit btn-submit1">
                                                    Reject
                                                </Button> */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="deleivery-add-block">
                                                    <div className="ordre--info">
                                                        <h4>Delivery Address</h4>
                                                        <p>{selectedOrderDetails?.address ? `${selectedOrderDetails?.address?.maps_address ? selectedOrderDetails?.address?.maps_address : `${selectedOrderDetails?.address?.address1},${selectedOrderDetails?.address?.city}`}` : '-'}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col style={{paddingRight:'20px', position:"absolute",right:'150px'}}>
                                                <div className="deleivery-add-block">
                                                    <div className="ordre--info">
                                                        <h4>Driver Name</h4>
                                                        <p>{selectedOrderDetails?.dunzo_rider_name ? selectedOrderDetails?.dunzo_rider_name : "-" }</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col style={{paddingRight:'20px', position:"absolute",right:'0'}}>
                                                <div className="deleivery-add-block">
                                                    <div className="ordre--info">
                                                        <h4>Driver Mobile No.</h4>
                                                        <p>{selectedOrderDetails?.dunzo_rider_phone ? selectedOrderDetails?.dunzo_rider_phone : "-" }</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card>
                            <div className="order-listinfo-block">
                                <div className="order-filter-block">
                                    <Row gutter={[16, 48]} >
                                        <Col span={12} style={{ textAlign: "left" }}>

                                        </Col>
                                        <Col span={12} style={{ textAlign: "left" }}>

                                        </Col>
                                    </Row>
                                </div>
                                <Card className="order-tablelist-block">
                                    <Row gutter={[16, 48]} >
                                        <Col span={24} style={{ textAlign: "left" }}>
                                            <Table
                                                size={'small'}
                                                scroll={{ x: 1100 }}
                                                bordered={false}
                                                pagination={false}
                                                dataSource={totalOrderOrder} columns={totalOrderColumn} />
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </div>
                    }
                </div>
            </div>

        )
    }
}
// Customizable Area End
