// App.js - WEB
import React, { useEffect, useState, useRef } from 'react';
import { View } from 'react-native';
import Swal from 'sweetalert2';
import { baseURL } from '../../framework/src/config';
import firebase from 'firebase';
import 'firebase/messaging';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/PrivacyPolicy.web';
import TermConditionPage from '../../blocks/info-page/src/termAndCondition.web';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import DesktopNotifications2 from '../../blocks/DesktopNotifications2/src/DesktopNotifications2';
import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import DotpayApiIntegration3 from '../../blocks/DotpayApiIntegration3/src/DotpayApiIntegration3';
import CfDunzoApiIntegration from '../../blocks/CfDunzoApiIntegration/src/CfDunzoApiIntegration';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import OrderDetailView from '../../blocks/OrderDetailView/src/OrderDetailView';
import CfAmazonApiIntegration3 from '../../blocks/CfAmazonApiIntegration3/src/CfAmazonApiIntegration3';
import OrderSummary from '../../blocks/OrderSummary/src/OrderSummary';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Settings2 from '../../blocks/Settings2/src/Settings2';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import CfFtp2 from '../../blocks/CfFtp2/src/CfFtp2';
import ZomatoGroceryApiIntegration2 from '../../blocks/ZomatoGroceryApiIntegration2/src/ZomatoGroceryApiIntegration2';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Notifications from '../../blocks/Notifications/src/Notifications';
import CfStoreOpencloseTimeManagement2 from '../../blocks/CfStoreOpencloseTimeManagement2/src/CfStoreOpencloseTimeManagement2';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import OpenApi from '../../blocks/OpenApi/src/OpenApi';
import Prioritise from '../../blocks/Prioritise/src/Prioritise';
import CfInventoryManagement10 from '../../blocks/CfInventoryManagement10/src/CfInventoryManagement10';
import ItemAvailability from '../../blocks/ItemAvailability/src/ItemAvailability';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import CfMagicpinApiIntegration from '../../blocks/CfMagicpinApiIntegration/src/CfMagicpinApiIntegration';
import CfInventoryManagement11 from '../../blocks/CfInventoryManagement11/src/CfInventoryManagement11';
import InventoryManagement from '../../blocks/CfInventoryManagement10/src/InventoryManagement/InventoryManagment.web';
import StoreTimings from '../../blocks/CfInventoryManagement10/src/StoreTimings/StoreTimings.web';
import { Home } from './components/Home/Home';
import Login from '../../blocks/email-account-login/src/Login/Login.web';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import People from '../../blocks/PeopleManagement2/src/People/People.web';
// import { Roles } from '../Roles&Permission/Roles';
import Roles from '../../blocks/RolesPermissions2/src/RolesPermission.web';
import DashboardManagement from '../../blocks/dashboard/src/Dashboard.web';
import StoreDashboard from '../../blocks/dashboard/src/StoreDashbaord.web';
import Reports from '../../blocks/RequestManagement/src/Reports/Reports.web';
import Store from '../../blocks/CfInventoryManagement10/src/Store/Store.web';
import CatalogueList from '../../blocks/catalogue/src/CatalogueList/CatalogueList';
import Reportlist from '../../blocks/RequestManagement/src/Reports/Reportlist.web';

import { createBrowserHistory } from 'history';

const preLoginComponents = [
  {
    path: '/',
    exact: true,
    main: (e, props) => <Login {...e} data={props} />
  }
];
const postLoginComponents = [
  {
    path: '/CatalogueList',
    name: 'Catalogue',
    exact: true,
    main: (e, props) => <CatalogueList {...e} data={props} />
  },
  {
    path: '/Reportlist',
    name: 'Reportlist',
    exact: true,
    main: (e, props) => <Reportlist {...e} data={props} />
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    exact: true,
    main: (e, props) => <InfoPage {...e} data={props} />
  },
  {
    path: '/term-and-condition',
    name: 'TermAndCondition',
    exact: true,
    main: (e, props) => <TermConditionPage {...e} data={props} />
  }
];

const routeMap = {
  CfMagicpinApiIntegration: {
    component: StoreTimings,
    path: '/CfMagicpinApiIntegration'
  },
  CfInventoryManagement11: {
    component: InventoryManagement,
    path: '/CfInventoryManagement11'
  },

  DesktopNotifications2: {
    component: DesktopNotifications2,
    path: '/DesktopNotifications2'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/PeopleManagement2'
  },
  DotpayApiIntegration3: {
    component: DotpayApiIntegration3,
    path: '/DotpayApiIntegration3'
  },
  CfDunzoApiIntegration: {
    component: CfDunzoApiIntegration,
    path: '/CfDunzoApiIntegration'
  },
  // core:{
  //  component:core,
  // path:"/core"},
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  CfAmazonApiIntegration3: {
    component: CfAmazonApiIntegration3,
    path: '/CfAmazonApiIntegration3'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  CfFtp2: {
    component: CfFtp2,
    path: '/CfFtp2'
  },
  ZomatoGroceryApiIntegration2: {
    component: ZomatoGroceryApiIntegration2,
    path: '/ZomatoGroceryApiIntegration2'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  CfStoreOpencloseTimeManagement2: {
    component: CfStoreOpencloseTimeManagement2,
    path: '/CfStoreOpencloseTimeManagement2'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  OpenApi: {
    component: OpenApi,
    path: '/OpenApi'
  },
  Prioritise: {
    component: Prioritise,
    path: '/Prioritise'
  },
  CfInventoryManagement10: {
    component: CfInventoryManagement10,
    path: '/CfInventoryManagement10'
  },
  ItemAvailability: {
    component: ItemAvailability,
    path: '/ItemAvailability'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  // Report: {
  //   component: ReportList,
  //   path: '/ReportingList'
  // },
  Login: {
    component: Login,
    path: '/Login'
  },
  Home: {
    component: Home,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/privacy-policy',
    exact: true
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  CatalogueList: {
    component: CatalogueList,
    path: '/CatalogueList'
  }
};

const SideBarArray = [];
const permissions = localStorage.getItem('permissions');
if (permissions) {
  var decodedString = window.atob(localStorage.getItem('permissions') || '{}');
  const permissions = JSON.parse(decodedString);
  permissions &&
    permissions.map((item, index) => {
      if (item.attributes.access_module.name == 'Dashboard') {
        SideBarArray.push({
          path: '/Dashboard',
          name: 'Dashboard',
          exact: true,
          main: (e, props) => <DashboardManagement {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'Catalogue') {
        SideBarArray.push({
          path: '/Catalogue',
          name: 'Catalogue',
          exact: true,
          main: (e, props) => <Catalogue {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'Roles and Permission') {
        SideBarArray.push({
          path: '/Roles&Permission',
          name: 'Roles&Permission',
          exact: true,
          main: (e, props) => <Roles {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'Store') {
        SideBarArray.push({
          path: '/Store',
          name: 'Store',
          exact: true,
          main: (e, props) => <Store {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'People') {
        SideBarArray.push({
          path: '/People',
          name: 'People',
          exact: true,
          main: (e, props) => <People {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'Store Timings') {
        SideBarArray.push({
          path: '/StoreTimings',
          name: 'StoreTimings',
          exact: true,
          main: (e, props) => <StoreTimings {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'Inventory') {
        SideBarArray.push({
          path: '/Inventory',
          name: 'Inventory',
          exact: true,
          main: (e, props) => <InventoryManagement {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'Reporting') {
        SideBarArray.push({
          path: '/Reporting',
          name: 'Reporting',
          exact: true,
          main: (e, props) => <Reports {...e} data={props} />
        });
      }
      if (item.attributes.access_module.name == 'Store Dashboard') {
        SideBarArray.push({
          path: '/StoreDashboard',
          name: 'Store Dashboard',
          exact: true,
          main: (e, props) => <StoreDashboard {...e} data={props} />
        });
      }
    });
}

const App = props => {
  const history = createBrowserHistory();

  const [notification, setNotification] = useState({
    title: '',
    body: '',
    time: ''
  });
  const [notifyAlert, setNotifyAlert] = useState(false);
  const [notificationToken, setNotificationToken] = useState();
  const [value, setValue] = useState(0); // integer state
  const [audio] = useState(new Audio('bell.ogg'));
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setPlaying(!playing);

  const useForceUpdate = () => {
    return () => setValue(value => value + 1);
  };

  const askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      const registration = await navigator.serviceWorker
        .register('firebase-message-sw.js', {
          scope: '/',
          updateViaCache: 'none'
        })
        .then(registration => {
          return registration;
        })
        .catch(e => {
          console.log(e);
        });

      if (Notification.permission !== 'denied') {
        Notification.requestPermission()
          .then(permission => {
            if (permission === 'granted') {
              setNotifyAlert(false);
              messaging
                .getToken({
                  vapidKey:
                    'BMjb-2IOD0fxDNAPWWRE3Z3A879ajyykVLR5ViBft-tBEGoaFxbVERebS19v2W33cfC5-_7Wb1KH_aC3mwEHZc0',
                  serviceWorkerRegistration: registration
                })
                .then(token => {
                  localStorage.setItem('notification', token);
                  setNotificationToken(token);
                });
            }
            if (permission === 'denied') {
              setNotifyAlert(true);
            }
          })
          .catch(e => {
            localStorage.removeItem('notification');
            setNotifyAlert(true);
          });
        localStorage.removeItem('notification');
        setNotificationToken();
        setNotifyAlert(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (notificationToken) {
      setNotification({
        title: message?.data?.data
          ? message?.data?.data.title
          : message?.data?.notification.title,
        body: message?.data?.data
          ? message?.data?.data.body
          : message?.data?.notification.body,
        time: message?.data?.data
          ? message?.data?.data.timestamp
          : message?.data?.notification.timestamp
      });
    }

    askForPermissioToReceiveNotifications();
    navigator.serviceWorker.addEventListener('message', message => {
      console.log(message), setPlaying(true);
      setNotification({
        title: message?.data?.data
          ? message?.data?.data.title
          : message?.data?.notification.title,
        body: message?.data?.data
          ? message?.data?.data.body
          : message?.data?.notification.body,
        time: message?.data?.data
          ? message?.data?.data.timestamp
          : message?.data?.notification.timestamp
      });
    });
    // console.log(new onGetOrders().onGetOrders())

    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    if (notificationToken) {
      const data = new FormData();
      data.append('data[device_id]', localStorage.getItem('notification'));
      fetch(`${baseURL}/bx_block_profile/profile_update`, {
        method: 'PUT',
        headers: {
          token: localStorage.getItem('token')
        },
        body: data
      });
    }
  }, [notificationToken]);

  useEffect(() => {
    if (notification.title) {
      Swal.fire({
        position: 'top-right',
        title: notification.title,
        text: notification.body,
        footer: notification.time,
        showCloseButton: true,
        confirmButtonText: 'Ok'
      }).then(function(isConfirm) {
        if (isConfirm) {
          if (history.location.pathname === '/StoreDashboard') {
            window.location.reload();

            //         setValue(value + 1)
            //     history.push({
            //   pathname: '/StoreDashboard',
            //   state: { value: value + 1 }
            // })
            //To call a function from other component
            // new StoreDashboard().thiscallisFromAppJs();
          }
        }
      });
    }
  }, [notification]);

  return (
    <div className="global-layout">
      {notifyAlert && !localStorage.getItem('token') && (
        <div className="notify-red">
          <p>
            Notification are disabled. To receive notifications please enable
            from your browser setting and reload.
          </p>
        </div>
      )}
      <BrowserRouter>
        {localStorage.getItem('token') ? (
          <Switch>
            <Route>
              <Home
                postLoginComponents={postLoginComponents}
                SideBarComponents={SideBarArray}
                notifyAlert={notifyAlert}
              />
            </Route>
          </Switch>
        ) : (
          <Switch>
            {preLoginComponents &&
              preLoginComponents.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))}
          </Switch>
        )}
      </BrowserRouter>
      {/* <TopNav /> */}
      {/* {WebRoutesGenerator({ routeMap })}
        <ModalContainer /> */}
      {/* <div className="notify">
          <p>{notification.title}</p>
          <p>{notification.body}</p>
        </div> */}
    </div>
  );
};

export default App;
