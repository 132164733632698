// Customizable Area Start
import React, { createRef } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
//@ts-ignore
import _ from 'lodash';
import {
    Table,
    Select,
    Button,
    Modal,
    Upload,
    Input,
    Row,
    Col,
    Form,
    Typography,
    Divider,
    Space,
    Card,
    DatePicker,
    Switch,
    Skeleton,
    List,
} from "antd";
import { ArrowLeftOutlined, BarChartOutlined, CloseCircleFilled, ExportOutlined, SyncOutlined } from "@ant-design/icons";
import "../../../blocks/RequestManagement/src/Reports/Reports.css";
import { FormInstance } from "antd/es/form";
const { Title, Paragraph, Text, Link } = Typography;
import {
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
    EditFilled,
} from "@ant-design/icons";
import dashboardController, { Props, configJSON } from "./DashboardController";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import { message as AntMessage } from "antd";
import "./Dashboard.css";
import { setTimeout } from "timers";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js"

export class NewOrder extends dashboardController {
    cardArray: any;
    totalOrderOrder: any
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            dashboardData: [],
            errorMsg: "",
            token: "",
            loading: false,
            selectedRowKeys: [],
            orderList: [],
            newOrderBlock: false,
            acceptedOrdersBlock: false,
            rejectedOrdersBlock: false,
            partiallyAcceptedOrdersBlock: false,
            partiallyRejectedOrdersBlock: false,
            ordersOnHoldBlock: false,
            orderFullfilledBlock: false,
            adminDashbaordData: [],
            orderApiData: [],
            selectedOrderId: '',
            selectedOrderDetails: {},
            orderConfirmationState: [],
            testState: [],
            selectedFilterId: "",
            DropDownValue: "",
            orderDetails: [],
            RejectedReasonBlock: false,
            variablePriceArray: [],
            variableQuantity: [],
            filterOrderData: [4],
            selectedFilterString: `?start_date=${moment().subtract(14, 'd').format('D MMMM YYYY')}&end_date=${moment().format('D MMMM YYYY')}`,
            Order_Id:0
        };

        // Customizable Area End
    }

    formRef = createRef<FormInstance>();
    onFinish = (values: any) => {
        console.log("Success:", values);
        this.rejectOrder(this.state?.selectedOrderDetails?.id, values.reason)
    };
    onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    onReset = () => {
        this.formRef.current!.resetFields();
    };
    selectRow = (record: any) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.order_id) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.order_id), 1);
        } else {
            selectedRowKeys.push(record.order_id);
        }
        this.setState({ selectedRowKeys });
    };

    onSelectedRowKeysChange = (selectedRow: any, selectedRowKeys: any) => {
        // console.log(selectedRowKeys)
        this.setState({ selectedRowKeys: selectedRow, orderConfirmationState: selectedRowKeys });
    };

    async componentDidMount() {
        //@ts-ignore
        this.getSelectedOrderDetails(this.props?.orderid);
        // this.onGetOrders();
    }

    handleVariablePriceChange = (value: any, record: any) => {
        let val = JSON.parse(value);
        // console.log(val);
        // console.log(val.price);
        let obj = {
            article_code: val.article_code,
            price: parseInt(val.price),
            // quantity: value
        }
        // let updatedVariablePriceArray = this.state?.variablePriceArray
        // this.state?.variablePriceArray.map((item: any, index: any) => {
        //     if (item.article_code == val.article_code) {
        //         updatedVariablePriceArray[index] = val;
        //     }
        // })

        // console.log(updatedVariablePriceArray);
        let updatedVariablePriceArray: any = []
        updatedVariablePriceArray = this.state?.variablePriceArray;
        console.log(updatedVariablePriceArray);

        if (updatedVariablePriceArray.length > 0) {
            // updatedVariablePriceArray.map((item: any, index: any) => {
            //     if (item.article_code == val.article_code) {
            //         updatedVariablePriceArray[index].price = val.price;
            //     }
            // })
            let array = this.state?.variablePriceArray.map((d: any) => {
                return d.article_code
            });
            if (array.includes(val.article_code)) {
                console.log("VP Include");
                // updatedVariablePriceArray[record.key].quantity = value;
                updatedVariablePriceArray[record.key].price = parseInt(val.price);

            } else {
                console.log(" VP Not include");
                updatedVariablePriceArray.push(obj)
            }
        } else {
            updatedVariablePriceArray.push(val)
        }


        console.log(updatedVariablePriceArray);
        this.setState({ variablePriceArray: updatedVariablePriceArray })


    }

    changeItemQuantity = (value: any, record: any) => {
        console.log(value, record, record.key)
        if (value > record.item_quantity) {
            AntMessage.info(`Item quantity can not be greater than ${record.item_quantity}`);
            return
        }
        if (value == 0 || value == null || !value) {
            value = record.item_quantity
            AntMessage.info(`Item quantity can not be 0 or null`);
            return
        }

        let obj = {
            article_code: record.item_code,
            // price: record.price,
            quantity: parseInt(value)
        }
        let updatedVariablePriceArray: any = []
        updatedVariablePriceArray = this.state?.variablePriceArray;
        console.log(updatedVariablePriceArray);

        if (updatedVariablePriceArray.length > 0) {
            let array = this.state?.variablePriceArray.map((d: any) => {
                return d.article_code
            });
            if (array.includes(record.item_code)) {
                console.log("Include");
                updatedVariablePriceArray[record.key].quantity = parseInt(value);
                // updatedVariablePriceArray[index].price = item.price;

            } else {
                console.log("Not include");
                updatedVariablePriceArray.push(obj)
            }

        } else {
            updatedVariablePriceArray.push(obj)
        }
        console.log(updatedVariablePriceArray);
        this.setState({ variablePriceArray: updatedVariablePriceArray })



    }


    acceptPhygitalOrder = async (orderId: any) => {
        console.log(orderId)
        let payloadData = {
            id: orderId,
            data: {
                status: "Accepted",
                variable_prices: this.state?.variablePriceArray.length > 0 ? this.state?.variablePriceArray : [],

            }
        }
        this.updatedOrderStatus(payloadData)

    }
    acceptOrder = async (orderId: any) => {
        console.log(orderId)
        let payloadData = {
            id: orderId,
            data: {
                status: "Accepted",
            }
        }
        this.updatedOrderStatus(payloadData)

    }
    rejectOrder = async (orderId: any, reason: any) => {
        console.log(orderId);
        let payloadData = {
            id: orderId,
            data: {
                status: "Rejected",
                reason: reason
            }
        }
        this.setState({ RejectedReasonBlock: false })
        this.updatedOrderStatus(payloadData)


    }

    partiallyAcceptedOrder = async (orderId: any) => {
        let array = this.state?.orderConfirmationState?.map((data: any) => {
            return data.item_code;
        })
        let rejectedItemsArray: any = [];
        if (array.length > 0) {
            this.state?.selectedOrderDetails?.order_items.map(async (Allitems: any, index: any) => {
                if (!array.includes(Allitems.id)) {
                    rejectedItemsArray.push(Allitems.id)
                }
            });
        }
        // { Orders FullFilled: 0, New Orders: 1, Accepted: 2, On Hold: 3, Partially Accepted: 4, Rejected: 5, Partially Rejected: 6}
        if (this.state?.selectedOrderDetails?.partner.toLowerCase() == 'phygital') {
            console.log(this.state?.orderConfirmationState);
            console.log(this.state?.variablePriceArray);
            let collectOnlySelectVariablePriceorQuantity: any = [];
            if (this.state?.orderConfirmationState.length > 0 && this.state?.variablePriceArray.length > 0) {
                this.state?.orderConfirmationState?.map((oc: any) => {
                    this.state?.variablePriceArray?.map((vp: any) => {
                        if (oc.item_code == vp.article_code) {
                            collectOnlySelectVariablePriceorQuantity.push(vp);
                        }
                    })
                })
            }
            // console.log(collectOnlySelectVariablePriceorQuantity);


            let reqPayload = {
                id: orderId,
                data: {
                    // status: 'On hold',
                    status: 'Partially accepted',
                    accepted_item_ids: array.length ? array : [],
                    rejected_item_ids: rejectedItemsArray.length > 0 ? rejectedItemsArray : [],
                    // variable_prices: this.state?.variablePriceArray.length > 0 ? this.state?.variablePriceArray : [],
                    variable_prices: collectOnlySelectVariablePriceorQuantity.length > 0 ? collectOnlySelectVariablePriceorQuantity : [],
                }
            }
            console.log(reqPayload);
            this.updatedOrderStatus(reqPayload)
        } else {
            let reqPayload = {
                id: orderId,
                data: {
                    status: 3,
                    accepted_item_ids: array.length ? array : [],
                    rejected_item_ids: rejectedItemsArray.length > 0 ? rejectedItemsArray : [],
                }
            }
            this.updatedOrderStatus(reqPayload)
        }

    }


    handelItemSearch = async (searchedText: any, orderId: any) => {
        console.log(searchedText, orderId);
        this.searchOrdersItems(searchedText, orderId)
    }



    render() {
        let permissions: any;
        let decodedString = atob(localStorage.getItem('permissions') || '{}');

        //@ts-ignore
        JSON.parse(decodedString).filter((d) => {

            if (d.attributes.access_module.name == "Store Dashboard") {
                permissions = d.attributes
                return
            }
        });
        const { variablePriceArray, orderDetails, selectedOrderDetails, selectedRowKeys, newOrderBlock, acceptedOrdersBlock, rejectedOrdersBlock, partiallyAcceptedOrdersBlock, partiallyRejectedOrdersBlock, ordersOnHoldBlock, orderFullfilledBlock, RejectedReasonBlock, orderList } = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
            // getCheckboxProps: (record: any) => ({
            //     disabled: (record.item_name == 'Potato 1 kg'),

            // })

        };
        const totalOrderColumn = (handleVariablePriceChange: any, changeItemQuantity: any) => {

            return [
                {
                    title: 'order Id',
                    dataIndex: 'order_id',
                    key: 'order_id',
                },
                {
                    title: 'Customer name',
                    dataIndex: 'customer_name',
                    key: 'customer_name',
                },
                {
                    title: 'customer Phoneno',
                    dataIndex: 'customer_phoneno',
                    key: 'customer_phoneno',
                },
                {
                    title: 'Store Id',
                    dataIndex: 'store_id',
                    key: 'store_id',
                },
                {
                    title: 'Item code',
                    dataIndex: 'item_code',
                    key: 'item_code',
                },
                {
                    title: 'Item name',
                    dataIndex: 'item_name',
                    key: 'item_name',
                },
                {
                    title: 'Item quantity ',
                    dataIndex: 'item_quantity',
                    key: 'item_quantity',
                    render(text: any, record: any) {
                        // console.log(record)
                        // console.log(text)
                        return {
                            // props: {
                            //     style: { fontWeight: 'bold', lineHeight: '7px' }
                            // },
                            children: <div>{selectedOrderDetails?.partner.toLowerCase() == 'phygital' ?
                                <Input
                                    type="number"
                                    style={{ textAlign: 'center' }}
                                    contentEditable
                                    defaultValue={text}
                                    placeholder={text}
                                    onBlur={(e) => {
                                        changeItemQuantity(e.target.value, record)
                                    }}

                                />
                                :
                                text}</div>
                        };
                    }
                },
                {
                    title: 'Price',
                    dataIndex: 'price',
                    key: 'price',
                    render(text: any, record: any) {
                        // console.log(record)
                        // console.log(text)

                        return {
                            // props: {
                            //     style: { fontWeight: 'bold', lineHeight: '7px' }
                            // },
                            children: <div>{selectedOrderDetails?.partner.toLowerCase() == 'phygital' ?
                                <Select defaultValue={record.price} style={{ width: 120 }} onChange={(value) => { handleVariablePriceChange(value, record) }}>
                                    {record?.v_prices != null && record?.v_prices?.length > 0 ?
                                        record.v_prices.map((items: any, index: any) => {
                                            return <Select.Option
                                                key={`${index}${items.price}`}
                                                value={JSON.stringify(items)}>
                                                {items.price}
                                            </Select.Option>
                                        })


                                        : null
                                    }
                                </Select>
                                :
                                text}</div>
                        };
                    }
                },
                {
                    title: 'discount',
                    dataIndex: 'discount',
                    key: 'discount',
                },
                {
                    title: 'date & time',
                    dataIndex: 'date_time',
                    key: 'date_time',
                    render(text: any, record: any) {
                        let dataData = text.split(',')
                        return {
                            props: {
                                style: { fontWeight: 'bold', lineHeight: '7px' }
                            },
                            children: <div><p>{dataData[0]}</p><p>{dataData[1]}</p></div>
                        };
                    }
                },
            ]
        }
        const variable_prices = [
            {
                article_code: "101244",
                price: '185.0'
            },
            {
                article_code: "101244",
                price: '55'
            }
        ]

        this.totalOrderOrder = Array.isArray(selectedOrderDetails?.order_items) ? selectedOrderDetails?.order_items?.map((data: any, index: any) => {
            return {
                key: index,
                order_id: index + 1,
                customer_name: selectedOrderDetails.customer_name ? selectedOrderDetails.customer_name : '-',
                customer_phoneno: selectedOrderDetails.customer_phoneno ? selectedOrderDetails.customer_phoneno : '-',
                store_id: selectedOrderDetails.store_id ? selectedOrderDetails.store_id : '-',
                item_code: data.id,
                item_name: data.item_name ? data.item_name : '-',
                item_quantity: data.quantity ? data.quantity : '-',
                stock_in_quantity: data.stock_in_qty ? data.stock_in_qty : null,
                v_prices: selectedOrderDetails?.partner?.toLowerCase() == 'phygital' && data.variable_prices?.length > 0 ? data.variable_prices.map((item: any) => ({ ...item, article_code: data.id, quantity: parseInt(data.quantity) })) : null,
                // v_prices: selectedOrderDetails?.partner?.toLowerCase() == 'phygital' ? variable_prices : null,
                price: data?.price ? data?.price : '-',
                discount: data.discount,
                date_time: selectedOrderDetails.created_at ? ` ${moment(selectedOrderDetails.created_at).format(`D MMM YY`)},${moment(selectedOrderDetails.created_at).format(`h:mm A`)}  ` : '-',
            }
        }) : null

        return (


            <div>

                <Modal
                    title="Reason for Rejection"
                    visible={RejectedReasonBlock}
                    centered
                    width="25vw"
                    onCancel={() => {
                        this.setState({ RejectedReasonBlock: false })
                    }}
                    footer={null}
                >
                    <Form
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        ref={this.formRef}
                        name="content-ref"
                        autoComplete="off"
                    >
                        <Row className="modal-rows fieldBox">
                            <Col className="rowCol" span={12} offset={6}>
                                <Form.Item name="reason" rules={[{ required: true, message: "Reason is required" }]}>
                                    <Input type="text" placeholder="Enter Reason for Rejection" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="saveBtnbox">
                            <Col span={12} offset={10}>
                                <Form.Item>
                                    <Button
                                        style={{ background: '#000000' }}
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        className="content-add-btn saveBtn"
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>


                <div className="StoreSection">
                    <div className="store-dashboard-block">
                        {/* New orders Block */}

                        <div>
                            <ArrowLeftOutlined translate={{}} style={{ fontSize: '35px', paddingBottom: 20 }} onClick={() => {
                                //@ts-ignore
                                this.props.seState({
                                    newOrderBlock: false,
                                    acceptedOrdersBlock: false,
                                    rejectedOrdersBlock: false,
                                    partiallyAcceptedOrdersBlock: false,
                                    partiallyRejectedOrdersBlock: false,
                                    ordersOnHoldBlock: false,
                                    orderFullfilledBlock: false,
                                })


                            }} />

                            <Row >
                                <Col>
                                    <Space >
                                        <div>
                                            <p className="layout-order-id">{selectedOrderDetails?.partner_order_id ? selectedOrderDetails?.partner_order_id : ''}</p>
                                        </div>
                                    </Space>
                                </Col>
                                <Col className="SearchPlacement">
                                    <Space >
                                        <Input type="text"
                                            placeholder="Search"
                                            className="inputTpye"
                                            onChange={(e) => { this.handelItemSearch(e.target.value, selectedOrderDetails?.id) }} />
                                    </Space>
                                </Col>
                            </Row>
                            {!selectedOrderDetails.partner_order_id ?
                                <>
                                    {/* <SyncOutlined style={{ fontSize: "25px", display: "flex", justifyContent: "center" }} translate="" spin /> */}
                                    <Skeleton loading={!selectedOrderDetails.partner_order_id} active avatar>
                                        <List.Item.Meta
                                            avatar={<Avatar src={""} />}
                                            title={""}
                                            description=""
                                        />

                                    </Skeleton>
                                </>

                                :
                                <div className="store-order-informations">
                                    <Card className="order-sortinfo">
                                        <div className="order-title-block">
                                            <div className="order-top-block">
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ width: '100%' }}>
                                                    <Col className="gutter-row" span={14} style={{ textAlign: "left" }}>
                                                        <h2 className="ordres-title" style={{ textTransform: 'capitalize' }}>
                                                            <span className="icon">
                                                                <img
                                                                    src={selectedOrderDetails?.catalogue_url ? selectedOrderDetails?.catalogue_url?.url : 'https://icon-library.com/images/order-icon-png/order-icon-png-12.jpg'}
                                                                    className="partner-img2"
                                                                ></img>
                                                            </span>
                                                            {selectedOrderDetails?.partner ? selectedOrderDetails?.partner : '-'}
                                                        </h2>
                                                    </Col>
                                                    <Col className="gutter-row" span={10} style={{ textAlign: "right" }}>
                                                        <div className="ordre-actoions">
                                                            <Button className="btn btn-submit" style={{ pointerEvents: 'none', backgroundColor: '#c3da90' }}>New Order</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="order-bottom-block">
                                                <Row gutter={[16, 48]} className="n-order-row" >
                                                    <Col className="gutter-row n-order-col" span={4} style={{ textAlign: "left" }} >
                                                        <div className="ordre--info">
                                                            <h4>Order Amount</h4>
                                                            <p>Rs.{selectedOrderDetails?.total_price ? selectedOrderDetails?.total_price : '-'}</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="gutter-row n-order-col" span={4} style={{ textAlign: "left" }} >
                                                        <div className="ordre--info">
                                                            <h4>Total Items </h4>
                                                            <p>{selectedOrderDetails?.total_item ? selectedOrderDetails?.total_item : '-'}</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="gutter-row n-order-col" span={4} style={{ textAlign: "left" }} >
                                                        <div className="ordre--info">
                                                            <h4>Total Quantity </h4>
                                                            <p>{selectedOrderDetails?.item_quantity ? selectedOrderDetails?.item_quantity : '-'}</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="gutter-row n-order-col" span={7} style={{ textAlign: "left" }} >
                                                        <div className="ordre--info">
                                                            <h4>Total Discount </h4>
                                                            <p>{selectedOrderDetails?.discount}</p>
                                                        </div>
                                                    </Col>

                                                    <Col className="gutter-row n-order-col" span={5} style={{ textAlign: "left" }}  >
                                                        <div className="order-action  n-order-row">

                                                            {
                                                                selectedOrderDetails?.partner.toLowerCase() == 'phygital' ?
                                                                    selectedRowKeys?.length == this.totalOrderOrder?.length ?
                                                                        <Button
                                                                            className="btn-submit  n-order-col"
                                                                            onClick={() => { this.acceptPhygitalOrder(selectedOrderDetails?.id) }}
                                                                        >
                                                                            Accept
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            className="btn-submit  n-order-col"
                                                                            disabled
                                                                        // onClick={()=>{this.acceptOrder(selectedOrderDetails?.id)}}
                                                                        >
                                                                            Accept
                                                                        </Button>
                                                                    : selectedRowKeys?.length == this.totalOrderOrder?.length ?
                                                                        <Button
                                                                            className="btn-submit"
                                                                            onClick={() => { this.acceptOrder(selectedOrderDetails?.id) }}
                                                                        >
                                                                            Accept
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            className="btn-submit"
                                                                            disabled
                                                                        // onClick={()=>{this.acceptOrder(selectedOrderDetails?.id)}}
                                                                        >
                                                                            Accept
                                                                        </Button>

                                                            }


                                                            <Button className="btn-submit btn-submit1"
                                                                onClick={() => {
                                                                    this.setState({ RejectedReasonBlock: true })
                                                                    // this.rejectOrder(selectedOrderDetails?.id) 
                                                                }}
                                                            >
                                                                Reject
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="deleivery-add-block">
                                                            <div className="ordre--info">
                                                                <h4>Delivery Address</h4>
                                                                <p>{selectedOrderDetails?.address ? `${selectedOrderDetails?.address?.maps_address ? selectedOrderDetails?.address?.maps_address : `${selectedOrderDetails?.address?.address1},${selectedOrderDetails?.address?.city}`}` : '-'}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>


                                            </div>
                                        </div>
                                    </Card>
                                    <div className="KTexport">

                                    </div>



                                    <div className="order-listinfo-block">
                                        <div className="order-filter-block">
                                            <Row gutter={[16, 48]} >
                                                <Col span={12} style={{ textAlign: "left" }}>

                                                </Col>
                                                <Col span={12} style={{
                                                    paddingLeft: '8px',
                                                    paddingRight: '23px',
                                                    paddingBottom: '15px',
                                                    justifyContent: 'flex-end',
                                                    display: 'flex'
                                                }}>
                                                    <a
                                                        className="a-btn"
                                                        // style={{
                                                        //     marginRight: '90%',
                                                        //     marginBottom: '15px'
                                                        // }}
                                                        href={`${baseURL}/bx_block_orderdetailview/orders/order_detail_export.csv?id=${selectedOrderDetails?.id}`}
                                                    >
                                                        <ExportOutlined translate={{}} />KT Export

                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Card className="order-tablelist-block">
                                            <Row gutter={[16, 48]} >
                                                <Col span={24} style={{ textAlign: "left" }}>
                                                    <Table
                                                        rowSelection={rowSelection}
                                                        size={'small'}
                                                        bordered={false}
                                                        pagination={false}
                                                        dataSource={this.totalOrderOrder}
                                                        scroll={{ x: 'max-content' }} // 1100
                                                        columns={totalOrderColumn(this.handleVariablePriceChange, this.changeItemQuantity,)} />
                                                </Col>

                                                {

                                                    // To hide Partially acceptance request in case of Dunzo Partner
                                                    selectedOrderDetails?.partner.toLowerCase() == 'phygital' ?

                                                        selectedRowKeys?.length > 0 && selectedRowKeys?.length !== this.totalOrderOrder?.length ?
                                                            <Button style={{ width: '100%' }}
                                                                onClick={() => this.partiallyAcceptedOrder(selectedOrderDetails?.id)}
                                                            >
                                                                Partially Acceptance Request fulfiled
                                                            </Button>
                                                            :
                                                            <Button style={{ width: '100%' }} disabled>
                                                                Partially Acceptance Request fulfiled
                                                            </Button>

                                                        : null
                                                }
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}
// Customizable Area End
