import React, { Component } from "react";
import InfoPageController, {
    Props,
    configJSON,
} from "../src/InfoPageController.web";

export default class PrivacyPolicyWeb extends InfoPageController {

    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errors: { email: "", password: "" },
            message: "",
            privarcyPolicyData: null,
            termAndConditionData: null,
        };
    }

    render() {
        const { privarcyPolicyData, termAndConditionData } = this.state;
        return (
            <>
                <div className="termConditions">
                    <div
                        dangerouslySetInnerHTML={{ __html: privarcyPolicyData?.content }}>
                    </div>

                </div>

            </>
        );
    }
}
