// Customizable Area Start
import React, { createRef, useState } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Row,
  Col,
  Form,
  Switch,
  Typography,
  TimePicker,
  DatePicker,
  Radio,
  Space,
  Avatar,
  Tabs,
  Anchor,
  Popconfirm,
  Popover,
  Layout,
  Pagination,
  Divider,
  Spin,
} from "antd";
const { Option } = Select;
import { ArrowLeftOutlined, CloseCircleFilled, DeleteFilled, DownCircleFilled, EditFilled, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import {
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
const { TabPane } = Tabs;
import { message as AntMessage } from "antd";
import moment from 'moment';
const { baseURL } = require('../../../../framework/src/config.js')
import "./CatalogueList.css";

/**
 * @author
 * @class @StoreTimings
 **/


import CatalogueController, { Props } from "../CatalogueController";

export default class CatalogueList extends CatalogueController {
  permissions: any = {};
  timer: any = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      arrayHolder: [],
      token: "",
      addCategoryModal: false,
      addSubCategoryModal: false,
      addGroupingModal: false,
      editCategoryModal: false,
      editSubCategoryModal: false,
      editGroupingModal: false,
      selectedTab: 1,
      CategoryList: [],
      SubCategoryList: [],
      GroupingList: [],
      apiLoading: false,
      selectedCategoryData: {},
      selectedSubCategoryData: {},
      selectedGroupingsData: {},
      Errors: {},
      selectedRowKeys: [],
      catalogueArray: [],
      InventoryDataList: [],
      selectedSubCategoryArray: [],
      displayInventoryList: [],
      selectedOfferData: {},
      OfferListData: [],
      buyItem: {},
      freeItems: [],
      offerModal: false,
      editOfferModal: false,
      timingsData: {
        startDate: '',
        EndDate: '',
        StartTime: '',
        EndTime: '',
      },
      selectedOfferDataForUpdate: {},
      searchInventoryItemsBlock: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        Totalcount: 0,
        TotalInventorycount: 0
      },
      itemCode: 'Item Code',
      articleCodeLoader: false,
      selectedCatalogueId: 0,
      DataLoader: false

    }
  }

  async componentDidMount() {
    this.getCatgory();
    this.getSubCategory();
    // this.getAllGroupingsData();
    this.getGroupingsInformationByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize);
    this.getallarticlecodesByPagination(1, 10)
    // this.getallarticlecodes();




  }

  getList(item: any) {

  }
  formRef = createRef<FormInstance>();
  selectRow = (record: any) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys });
  };
  onSelectedRowKeysChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  handelTabChange = (key: any) => {
    if (key == 4) {
      this.getallarticlecodes();
      this.getAllOffers();
    }
    this.setState({ selectedTab: key });

  }

  onFinish = (values: any) => {
    console.log("Success:", values);
    // console.log("Success:", this.state.timingsData);
    let { timingsData, selectedOfferData } = this.state;
    let array = this.state.OfferListData;
    // let array = [...this.state.OfferListData];
    let Inv_Codes: any = [];

    let newItemsForOfferDataList = this.state?.freeItems?.map((freeItem: any, index: any) => {
      Inv_Codes.push(freeItem.id)
    })

    let reqPayload = {}
    let CID = localStorage.getItem('catalogue_id');
    if (!CID) {
      return AntMessage.error('Catalouge Id not found')
    }
    // offer_type: { DISCOUNT: 0, BOGO: 1, BXGY: 2, FREEBIE: 3}
    // discount_type: { PERCENTAGE: 0, FIXED: 1, FLATRATE: 2}
    if (selectedOfferData?.id == 1) {
      reqPayload = {
        catalogue_id: CID,
        offer_type: "BOGO",
        start_date: timingsData?.startDate,
        end_date: timingsData?.EndDate,
        start_time: timingsData?.StartTime,
        end_time: timingsData?.EndTime,
        active: true,
        inventory_ids: Inv_Codes,
        // discount_type: 2,
        // discount_value: 30,
        // min_order_amount: 200,
        // first_order_only: 1,
        // freebie_limit_count: 2
      }

      console.log(reqPayload);
      this.createOffer(reqPayload)

    } else if (selectedOfferData?.id == 2) {
      reqPayload = {
        catalogue_id: CID,
        offer_type: "BXGY",
        start_date: timingsData?.startDate,
        end_date: timingsData?.EndDate,
        start_time: timingsData?.StartTime,
        end_time: timingsData?.EndTime,
        active: true,
        inventory_ids: Inv_Codes,
        // discount_type: 2,
        // discount_value: 30,
        // min_order_amount: 200,
        // first_order_only: 1,
        // freebie_limit_count: 2
      }


      console.log(reqPayload);
      this.createOffer(reqPayload)

    } else if (selectedOfferData?.id == 3) {
      reqPayload = {
        catalogue_id: CID,
        offer_type: "FREEBIE",
        start_date: timingsData?.startDate,
        end_date: timingsData?.EndDate,
        start_time: timingsData?.StartTime,
        end_time: timingsData?.EndTime,
        active: true,
        inventory_ids: Inv_Codes,
        // discount_type: 2,
        // discount_value: 30,
        min_order_amount: values?.minimumAmount,
        // first_order_only: 1,
        // freebie_limit_count: 2
      }
      console.log(reqPayload);
      this.createOffer(reqPayload)

    }

    // this.setState({ OfferListData: [...this.state.OfferListData, ...newItemsForOfferDataList], })
    this.setState({ offerModal: false, buyItem: {}, freeItems: [], }, () => {
      console.log(this.state);
    });

  };

  onFinishUpdate = (values: any) => {
    let { timingsData, selectedOfferData } = this.state;
    let Inv_Codes: any = [];
    let newItemsForOfferDataList = this.state?.freeItems?.map((freeItem: any, index: any) => {
      Inv_Codes.push(freeItem.id)
    })

    let reqPayload = {}
    let CID = localStorage.getItem('catalogue_id');
    if (!CID) {
      return AntMessage.error('Catalouge Id not found')
    }
    // offer_type: { DISCOUNT: 0, BOGO: 1, BXGY: 2, FREEBIE: 3}
    // discount_type: { PERCENTAGE: 0, FIXED: 1, FLATRATE: 2}
    if (selectedOfferData?.id == 1) {
      reqPayload = {
        catalogue_id: CID,
        offer_type: values?.offerType,
        start_date: timingsData?.startDate,
        end_date: timingsData?.EndDate,
        start_time: timingsData?.StartTime,
        end_time: timingsData?.EndTime,
        active: true,
        inventory_ids: Inv_Codes,
        // discount_type: 2,
        // discount_value: 30,
        // min_order_amount: 200,
        // first_order_only: 1,
        // freebie_limit_count: 2
      }

      console.log(reqPayload);
      this.updateOffer(reqPayload)

    } else if (selectedOfferData?.id == 2) {
      reqPayload = {
        catalogue_id: CID,
        offer_type: values?.offerType,
        start_date: timingsData?.startDate,
        end_date: timingsData?.EndDate,
        start_time: timingsData?.StartTime,
        end_time: timingsData?.EndTime,
        active: true,
        inventory_ids: Inv_Codes,
        // discount_type: 2,
        // discount_value: 30,
        // min_order_amount: 200,
        // first_order_only: 1,
        // freebie_limit_count: 2
      }


      console.log(reqPayload);
      this.updateOffer(reqPayload)

    } else if (selectedOfferData?.id == 3) {
      reqPayload = {
        catalogue_id: CID,
        offer_type: values?.offerType,
        start_date: timingsData?.startDate,
        end_date: timingsData?.EndDate,
        start_time: timingsData?.StartTime,
        end_time: timingsData?.EndTime,
        active: true,
        inventory_ids: Inv_Codes,
        // discount_type: 2,
        // discount_value: 30,
        min_order_amount: values?.minimumAmount,
        // first_order_only: 1,
        // freebie_limit_count: 2
      }
      console.log(reqPayload);
      this.updateOffer(reqPayload)

    }

    // this.setState({ OfferListData: [...this.state.OfferListData, ...newItemsForOfferDataList], })
    this.setState({ offerModal: false, buyItem: {}, freeItems: [], }, () => {
      console.log(this.state);
    });

  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  onReset = () => {
    this.formRef.current!.resetFields();
  };

  handleSwitch = (e: any, id: any) => {
    console.log(e, id);
    let reqPayload = {
      data: {
        attributes: {
          status: e
        }
        //*******************************************| Switch Actions |***************************************************/
      }
    }
    this.editInventoryData(reqPayload, id);
  };
  handleCategorySwitch = (e: any, id: any) => {
    console.log(e, id);
    let reqPayload = {
      data: {
        attributes: {
          status: e
        }
      }
    }
    this.editCategoryData(reqPayload, id);
  };

  handleSubCategorySwitch = (e: any, id: any) => {
    console.log(e, id);
    let reqPayload = {
      data: {
        attributes: {
          status: e
        }
      }
    }
    this.editSubCategoryData(reqPayload, id);
  };

  handelEditCategory = (itemId: any) => {
    if (this.permissions?.update_action != true) {
      AntMessage.error('Permission Denied !');
      return;
    }
    this.setState({ editCategoryModal: true, DataLoader: true });
    this.handelGetCategoryDataByID(itemId)
  }
  handelDeleteCategory = (itemId: any) => {
    if (this.permissions?.delete_action != true) {
      AntMessage.error('Permission Denied !');
      return;
    }
    this.handleDeleteCategoryById(itemId);
  }
  handelEditSubCategory = (itemId: any) => {
    if (this.permissions?.update_action != true) {
      AntMessage.error('Permission Denied !');
      return;
    }
    console.log(itemId);
    this.setState({ editSubCategoryModal: true, DataLoader: true })
    this.handelGetSubCategoryDataByID(itemId)
  }
  handelDeleteSubCategory = (itemId: any) => {
    if (this.permissions?.delete_action != true) {
      AntMessage.error('Permission Denied !');
      return;
    }
    this.handleDeleteSubCategoryById(itemId);
  }
  handelEditGroupings = (item: any) => {
    if (this.permissions?.update_action != true) {
      AntMessage.error('Permission Denied !');
      return;
    }
    this.setState({ editGroupingModal: true, DataLoader: true })
    this.getAllInventoryById(item);
  }
  handelDeletegrouping = async (item: any) => {
    if (this.permissions?.delete_action != true) {
      AntMessage.error('Permission Denied !');
      return;
    }
    console.log(item);
    this.deleteGroupingsbyId(item);
  }
  handleUploadChange(info: any) {
    let fileList = [...info.fileList];
    console.log(fileList[0].originFileObj);
    const formData = new FormData();
    formData.append("file", fileList[0].originFileObj as Blob);
    let data = {
      formData: formData,
    };
    // console.log(formData)
  };

  onChangeItemCode(data: any) {
    this.searchInventoryDetails(data?.attributes?.id);
    this.setState({ searchInventoryItemsBlock: false, itemCode: data?.attributes?.item_name })
  }
  onChangeItemCode2(e: any) {
    console.log(e);
    this.searchInventoryDetails(e)
  }

  onItemSearchForOffers(e: any) {
    console.log(e);
    // this.inventoryItemSearchByName(e)
  }


  onSelectItemForOffers(e: any) {
    console.log(e);
    this.setState({ selectedOfferData: JSON.parse(e), offerModal: true });

  }
  onChangeBuyItem(e: any) {
    this.onChangeFreeItem(e)
    // if (this.state.selectedOfferData?.id == 3) {
    //   this.setState({ buyItem: e.target.value })

    // } else {
    //   console.log(JSON.parse(e));
    //   this.setState({ buyItem: JSON.parse(e) })
    // }

  }
  onChangeFreeItem(e: any) {
    console.log(JSON.parse(e));
    // this.setState({ freeItems: [...this.state.freeItems, JSON.parse(e)] })
    let data = [];
    data = this.state.freeItems;
    console.log(data);

    data.push(JSON.parse(e))
    this.setState({ freeItems: data })

  }

  handleTimeChange = (e: any, timeString: any) => {
    // console.log("handleTimeChange", e, timeString);
    // console.log(timeString[0]);
    // console.log(timeString[1]);
    this.setState({
      timingsData:
      {
        ...this.state.timingsData,
        StartTime: timeString[0],
        EndTime: timeString[1],
      }
    })

  };

  handleDateChange = (e: any, DateString: any) => {
    console.log("handleDateChange", e, DateString);
    this.setState({
      timingsData:
      {
        ...this.state.timingsData,
        startDate: DateString[0],
        EndDate: DateString[1],

      }
    })
  };

  onSaveOffers() {
    console.log(this.state?.buyItem);
    console.log(this.state?.freeItems);
    let Array = this.state?.freeItems?.map((freeItem: any, index: any) => {
      return {
        sno: index + 1,
        offerType: this.state?.selectedOfferData?.title,
        itemCode: freeItem.article_code,
        itemName: freeItem.item_name,
        amount: this.state?.selectedOfferData?.id == 3 ? this.state?.buyItem : 'NA',

      }
    })
    this.setState({ OfferListData: Array, buyItem: {}, freeItems: [], offerModal: false });

  }
  editOffer = (data: any) => {
    console.log(data);
    data.offerType == "BOGO" ? this.setState({ selectedOfferData: { id: 1, title: "Buy One Get One Free (BOGO)", code: "BOGO" } }) : null;
    data.offerType == "BXGY" ? this.setState({ selectedOfferData: { id: 2, title: "Buy X Get X Free  (B(X)G(Y))", code: "BXGY" } }) : null;
    data.offerType == "FREEBIE" ? this.setState({ selectedOfferData: { id: 3, title: "FREEBIE", code: "FREEBIE" } }) : null;

    this.setState({
      timingsData:
      {
        ...this.state.timingsData,
        startDate: data.start_date,
        EndDate: data.end_date,
        StartTime: data.start_time,
        EndTime: data.end_time,

      },
      editOfferModal: true,
      selectedOfferDataForUpdate: data,
    }, () => {
      console.log(this.state);
      //@ts-ignore
      this.formRef.current.setFieldsValue({ offerType: this.state?.selectedOfferDataForUpdate?.offerType })
      if (data.offerType == "FREEBIE") {
        //@ts-ignore
        this.formRef.current.setFieldsValue({ minimumAmount: data.amount })
      }

    })
  }

  deleteOffer = (data: any) => {
    console.log(data);
    console.log(data.id);
    this.deleteOfferById(data.id);

  }
  selectUpdateOfferSelectedOffer = (data: any) => {
    console.log(data);
    data == "BOGO" ? this.setState({ selectedOfferData: { id: 1, title: "Buy One Get One Free (BOGO)", code: "BOGO" } }) : null;
    data == "BXGY" ? this.setState({ selectedOfferData: { id: 2, title: "Buy X Get X Free  (B(X)G(Y))", code: "BXGY" } }) : null;
    data == "FREEBIE" ? this.setState({ selectedOfferData: { id: 3, title: "FREEBIE", code: "FREEBIE" } }) : null;

  }
  //===================================================================================

  //call add Category API
  handleAddCategoryData = async (data: any) => {
    this.addCategoryData(data);
  }



  //handelEditCategoryData
  handelEditCategoryData = async (data: any) => {
    console.log(data);
    let dataToBeUpdated = {
      name: data.name,
      display_name: data.display_name,
      description: data.description,
      catalogue_id: data.catalogue_id,
      priority: data.priority,
      status: true

    }
    this.editCategoryData(dataToBeUpdated, this.state.selectedCategoryData.id);

  }


  //handelEditGroupingsData
  handelEditGroupingsData = async (data: any) => {
    console.log(data);
    let subCategorryArray: any = [];
    this.state?.SubCategoryList?.map((d1: any) => {
      data?.subCatego.map((d2: any) => {
        if (d1.name === d2) {
          subCategorryArray.push(d1.id);
        }
      })
    })
    console.log(subCategorryArray);


    let datatobeUpdated = {
      data: {
        attributes: {
          item_code: data.itemCode,
          description: data.item_description,
          net_price: data.netmrp,
          gross_price: data.gmrp,
          item_name: data.itemName,
          display_name: data.itemDisplayName,
          // sub_category_ids: data.subCatego,
          sub_category_ids: subCategorryArray,

        }
      }

    }
    this.editInventoryData(datatobeUpdated, this.state.selectedGroupingsData.data.id);

  }
  handelAddGroupingsData = async (data: any) => {
    console.log(data);
    let reqPayload = {
      data: {
        attributes: {
          item_code: data.itemCode,
          description: data.item_description,
          net_price: data.netmrp,
          gross_price: data.gmrp,
          item_name: data.itemName,
          display_name: data.itemDisplayName,
          sub_category_ids: data.subCatego.map((i: any) => Number(i)),
          status: 'true'
        }
      }
    }
    this.addGroupingsData(reqPayload);
  }

  //handelEditSubCategoryData
  handelEditSubCategoryData = async (data: any) => {
    // console.log(data);
    let dataToBeUpdated = {
      id: data.id,
      data: {
        name: data.name,
        category_id: data.category_id,
        display_name: data.display_name,
        description: data.description,
        catalogue_id: localStorage.getItem("catalogue_id")
      }
    }
    this.editSubCategoryData(dataToBeUpdated, this.state.selectedSubCategoryData.id)

  }

  //handleAddSubCategoryData
  handleAddSubCategoryData = async (data: any) => {
    this.addSubCategoryData(data);
  }

  //handelGetCategoryDataByID
  handelGetCategoryDataByID = async (Id: any) => {
    this.getCategoryById(Id)
  }
  //handelGetOfcDataByID
  handelGetSubCategoryDataByID = async (ofcId: any) => {
    this.getSubCategoryById(ofcId);
  }
  uploadCatalogueCsv = async (e: any) => {
    this.setState({ apiLoading: true })
    const formData = new FormData();
    formData.append("file", e.file);
    this.uploadCatalogueCsvFile(formData)
  }
  uploadVariablePrice = async (e: any) => {
    let isFileCSV = await this.beforeFileUpload(e.file)
    if (isFileCSV) {
      this.setState({ apiLoading: true })
      const formData = new FormData();
      formData.append("file", e.file);
      this.uploadVariablePriceFile(formData);
    }
  }
  uploadCategoryCsv = async (e: any) => {
    let isFileCSV = await this.beforeFileUpload(e.file)
    if (isFileCSV) {
      this.setState({ apiLoading: true })
      const formData = new FormData();
      formData.append("file", e.file);
      this.uploadCategoryCsvFile(formData);
    }
  }
  uploadSubCategories = async (e: any) => {
    let isFileCSV = await this.beforeFileUpload(e.file)
    if (isFileCSV) {
      this.setState({ apiLoading: true })
      const formData = new FormData();
      formData.append("file", e.file);
      this.uploadSubCategoryCsvFile(formData);
    }
  }

  uploadGroupingsCsv = async (e: any) => {
    let isFileCSV = await this.beforeFileUpload(e.file)
    if (isFileCSV) {
      this.setState({ apiLoading: true })
      const formData = new FormData();
      formData.append("file", e.file);
      this.uploadGroupingsCsvFile(formData)
    } else {
      AntMessage.error('You can only upload csv/xls file!');
    }
  }

  beforeFileUpload(file: any) {
    if (file.type == 'text/csv' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return true;
    } else {
      return false
    }
  }





  selectedZoneId(zoneId: any) {
    this.formRef.current?.setFieldsValue({ ofc_id: null })
    // this.getAllOfcsRealtedtoZoneId(zoneId);
  }

  editSelectedZoneId(zoneId: any) {
    this.formRef.current?.setFieldsValue({ ofc_id: null })
    //this.getAllOfcsRealtedtoZoneId(zoneId);
  }

  searchCategories = async (e: any) => {
    clearTimeout(this.timer);
    this.setState({ token: e.target.value, apiLoading: true })
    this.timer = setTimeout(() => {
      this.searchCategoriesDetails(this.state?.token);
    }, 1000)
  }
  searchSubCategories = async (e: any) => {
    clearTimeout(this.timer);
    this.setState({ token: e.target.value, apiLoading: true })
    this.timer = setTimeout(() => {
      this.searchSubCategoriesDetails(this.state?.token)
    }, 1000)
  }
  searchGroupingData = async (e: any) => {
    clearTimeout(this.timer);
    this.setState({ token: e.target.value, apiLoading: true })
    this.timer = setTimeout(() => {
      this.searchGroupingsDetails(this.state?.token)
    }, 1000)
  }
  changeSubCategoryId = async (e: any) => {
    let AlreadySelectedSubCategories = this.state?.selectedGroupingsData?.data?.attributes?.sub_category_ids;
    let updatedSubCategoryArray = AlreadySelectedSubCategories.filter((d: any) => {

    })
  }



  onPageChange = (page: any, pageSize: any) => {
    this.setState({
      apiLoading: true,
      pagination: {
        ... this.state.pagination,
        currentPage: page,
        pageSize: pageSize
      }
    });
    this.getallarticlecodesByPagination(page, pageSize)
  }


  searchArticleCode = (e: any) => {
    let value = e.target.value
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.searchArticleCodeAPI(value)
    }, 1000)

  }
  //=====================================================================
  render() {
    let decodedString = atob(localStorage.getItem('permissions') || '{}');

    //@ts-ignore
    JSON.parse(decodedString).filter((d) => {

      if (d.attributes?.access_module?.name == "Catalogue") {
        this.permissions = d.attributes
        return
      }
    });

    console.log(this.permissions);

    const antIcon = <LoadingOutlined translate={{}} style={{ fontSize: '35px', color: 'black', top: '60% !important' }} spin />;

    const { DataLoader, selectedTab, pagination, editOfferModal, selectedRowKeys, addGroupingModal, selectedGroupingsData, token, selectedOfferData, offerModal, timingsData, selectedOfferDataForUpdate, itemCode, searchInventoryItemsBlock, InventoryDataList, articleCodeLoader } = this.state;

    //@ts-ignore
    const catalogueId = this.props.history?.location?.state?.catalogueId ? this.props.history?.location?.state?.catalogueId : localStorage.getItem('catalogue_id');
    //@ts-ignore

    const catalogueDetails = this.props.history?.location?.state?.catalogueDetails ? this.props.history?.location?.state?.catalogueDetails : null;
    // PAGINATION OPTIONS
    const paginationOptions = {
      onChange: (page: any) => {

        this.setState({ pagination: { ...pagination, currentPage: page } });
        if (selectedTab == 3) {
          this.setState({ apiLoading: true })
          this.getGroupingsInformationByPagination(page, 10)
        }
      },
      total: this.state?.pagination?.Totalcount,
      showSizeChanger: false,
      current: pagination.current,
      pageSize: pagination.pageSize
    };

    // console.log(this.permissions, "permissions");
    const OfferData = [
      {
        id: 1, title: "Buy One Get One Free (BOGO)", code: "BOGO"
      },
      {
        id: 2, title: "Buy X Get X Free  (B(X)G(Y))", code: "BXGY"
      },
      {
        id: 3, title: "FREEBIE", code: "FREEBIE"
      },
    ]
    //Category Columns
    const CategoryColumn = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "Priority Order",
        dataIndex: "priority",
        key: "priority",
        width: "100px"
      },
      {
        title: "Category Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Display Name",
        dataIndex: "display_name",
        key: "display_name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (data: any, record: any, index: any) => {
          return (
            <Space size="large">
              <Switch
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                defaultChecked={data == true ? true : false}
                onChange={(e) => this.handleCategorySwitch(e, record.id)}
                title={data == true ? 'Active' : 'Inactive'}
              />
            </Space>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">

              <span className="icon">
                <EditFilled
                  translate
                  onClick={() => {
                    this.handelEditCategory(item.id);
                  }}
                />
              </span>
              <span>EDIT</span>
            </Space>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">
              <Popconfirm
                title="Are you sure to delete ?"
                onConfirm={() => {
                  this.handelDeleteCategory(item.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled
                  translate
                />
              </Popconfirm>
              {/* <span className="icon">
                <DeleteFilled
                  translate
                  onClick={() => {
                    this.handelDeleteCategory(item.id);
                  }}
                />
              </span> */}
            </Space>
          </div>
        ),
      },
    ];

    //subCategory Columns
    const subCategoryColumns = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "Sub Category Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Display Name",
        dataIndex: "display_name",
        key: "display_name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Category Name",
        dataIndex: "category_name",
        key: "category_name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (data: any, record: any, index: any) => {
          return (
            <Space size="large">
              <Switch
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                defaultChecked={data == true ? true : false}
                onChange={(e) => this.handleSubCategorySwitch(e, record.id)}
                title={data == true ? 'Active' : 'Inactive'}
              />
            </Space>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">
              <span className="icon">
                <EditFilled
                  translate
                  onClick={() => {
                    this.handelEditSubCategory(item.id)
                  }
                  }
                />
              </span>
              <span>Edit</span>
            </Space>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  this.handelDeleteSubCategory(item.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled
                  translate
                />
              </Popconfirm>
              {/* <span className="icon">
                <DeleteFilled
                  translate
                  onClick={() => {
                    this.handelDeleteSubCategory(item.id)
                    // this.setState({ editOfcModal: true })
                  }
                  }
                />
              </span> */}
            </Space>
          </div>
        ),
      }
    ];

    //Groups Columns
    const GroupsColumns = [
      {
        title: "SNo",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "Item Code",
        dataIndex: "articalCode",
        key: "articalCode",
      },
      {
        title: "Item Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Display Name",
        dataIndex: "displayName",
        key: "displayName",
      },
      {
        title: "Item Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Net MRP",
        dataIndex: "netMrp",
        key: "netMrp",
      },
      {
        title: "Gross MRP",
        dataIndex: "grossMrp",
        key: "grossMrp",
      },
      {
        title: "sub Category name",
        dataIndex: "subCategoryId",
        key: "subCategoryId",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (data: any, record: any, index: any) => {
          return (
            <Space size="large">
              <Switch
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                checked={data == true ? true : false}
                onChange={(e) => this.handleSwitch(e, record.id)}
                title={data == true ? 'Active' : 'Inactive'}
              />
            </Space>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">
              <span className="icon">
                <EditFilled
                  translate
                  onClick={() => {
                    this.handelEditGroupings(item.id)
                  }}
                />
              </span>
              <span>Edit</span>
            </Space>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">
              <Popconfirm
                title="Are you sure to delete ?"
                onConfirm={() => {
                  this.handelDeletegrouping(item.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled
                  translate
                />
              </Popconfirm>

              {/* <span className="icon">
                <DeleteFilled
                translate
                onClick={() => {
                  this.handelDeletegrouping(item.id);
                }}
                />
              </span> */}
            </Space>
          </div>
        ),
      },
    ];
    //Offers list 
    const OffersColumns = [
      // {
      //   title: "Sno",
      //   dataIndex: "sno",
      //   key: "sno",
      // },
      {
        title: "Offer Type",
        dataIndex: "offerType",
        key: "offerType",
        sorter: {
          compare: (a: any, b: any) => a?.offerType.localeCompare(b?.offerType),
          multiple: 1,
        },
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
        sorter: {
          compare: (a: any, b: any) => a?.start_date.split('-')[0] - b?.start_date.split('-')[0],
          multiple: 1,
        },

      },
      {
        title: "End Date",
        dataIndex: "end_date",
        key: "end_date",
        sorter: {
          compare: (a: any, b: any) => a?.end_date.split('-')[0] - b.end_date?.split('-')[0],
          multiple: 1,
        },
      }, {
        title: "Start Time",
        dataIndex: "start_time",
        key: "start_time",
        sorter: {
          compare: (a: any, b: any) => a?.start_time.split(':')[0] - b?.start_time.split(':')[0],
          multiple: 1,
        },
      },
      {
        title: "End Time",
        dataIndex: "end_time",
        key: "end_time",
        sorter: {
          compare: (a: any, b: any) => a?.end_time.split(':')[0] - b?.end_time.split(':')[0],
          multiple: 1,
        },
      },
      {
        title: "Item Amount",
        dataIndex: "amount",
        key: "amount",
        width: '55px'
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">
              <EditFilled
                translate
                onClick={() => {
                  this.editOffer(fullObj)
                }}
              />
            </Space>
          </div>
        )
      }, {
        title: "",
        dataIndex: "",
        key: "",
        render: (item: any, fullObj: any) => (
          <div className="editIconBox">
            <Space size="large">
              <Popconfirm
                placement="left"
                title="Are you sure to delete this offer? ?"
                onConfirm={() => {
                  this.deleteOffer(fullObj);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled translate={{}} className="option-btn deleteBtn" />
              </Popconfirm>

            </Space>
          </div>
        )
      }

    ];



    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };



    return (
      <div className="StoreSection">

        {/* ADD Category MODAL */}
        <Modal
          className="addstoreModalBox catelogModalBox"
          visible={this.state.addCategoryModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.setState({ addCategoryModal: false });
            this.onReset();
            // this.componentDidMount()

          }
          }
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Add Category</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">Add Category Details</p>
              </Col>
            </Row>

            {this.state.Errors.addCategoryError !== "" ? <Row style={{ marginLeft: '20px', color: 'red' }}>{this.state.Errors.addCategoryError}</Row> : ''}

            <Form
              onFinish={this.handleAddCategoryData}
              // onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              initialValues={
                {
                  //ofc_id: this.state?.ofcIds[0]?.id,
                }
              }
              name="content-ref"
              autoComplete="off"
              layout="vertical"
            >
              <Row className="modal-rows fieldBox">
                <Col className="rowCol" span={24}>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: "Category name is required" }]} >
                    <Input type="text" placeholder="Enter Category name" />
                  </Form.Item>
                </Col>

              </Row>
              <Row className="modal-rows fieldBox">
                <Col className="rowCol" span={24}>
                  <Form.Item label="Display name" name="display_name" rules={[{ required: true, message: "Display name is required" }]}>
                    <Input type="text" placeholder="Enter display name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="modal-rows fieldBox">
                <Col className="rowCol" span={24}>
                  <Form.Item label="Description" name="description" rules={[{ required: true, message: "Description is required" }]} >
                    <Input type="text" placeholder="Enter description" />
                  </Form.Item>
                </Col>
              </Row>


              <Row className="saveBtnbox">
                <Col span={9}></Col>
                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="content-add-btn saveBtn"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={9}></Col>
              </Row>
            </Form>
          </div>
        </Modal>

        {/* EDIT CATEGORY MODAL */}
        <Modal
          className="addstoreModalBox catelogModalBox"
          visible={this.state.editCategoryModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.setState({ editCategoryModal: false })
            this.onReset();
            // this.componentDidMount()
          }}
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Edit Category</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">
                  Edit Category details
                </p>
              </Col>
            </Row>

            <Form
              onFinish={this.handelEditCategoryData}
              // onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              name="content-ref"
              autoComplete="off"
              layout="vertical"
            >
              <Spin
                spinning={DataLoader}
                indicator={antIcon}
              >
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={24}>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: "Category name is required" }]} >
                      <Input type="text" placeholder="Enter Category name" />
                    </Form.Item>
                    <Form.Item name="catalogue_id" noStyle >
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item label="Priority" name="priority" noStyle >
                      <Input type="hidden" />
                    </Form.Item>
                  </Col>

                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={24}>
                    <Form.Item label="Display name" name="display_name" rules={[{ required: true, message: "Display name is required" }]}>
                      <Input type="text" placeholder="Enter display name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={24}>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: "Description is required" }]} >
                      <Input type="text" placeholder="Enter description" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="saveBtnbox">
                  <Col span={9}></Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="content-add-btn saveBtn"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={9}></Col>
                </Row>
              </Spin>
            </Form>
          </div>
        </Modal>

        {/* ADD SubCategory MODAL */}
        <Modal
          className="addstoreModalBox catelogModalBox"
          visible={this.state.addSubCategoryModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.setState({ addSubCategoryModal: false })
            this.onReset();
            // this.componentDidMount();
          }}
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Add Sub Category</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">Add sub category Details</p>
              </Col>
            </Row>
            {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
            <Form
              onFinish={this.handleAddSubCategoryData}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              name="content-ref"
              autoComplete="off"
              layout="vertical"
            >
              <Row className="modal-rows fieldBox">
                <Col className="rowCol" span={24}>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: "SubCategory name is required" }]} >
                    <Input type="text" placeholder="Enter SubCategory name" />
                  </Form.Item>
                </Col>

              </Row>
              <Row className="modal-rows fieldBox">
                <Col className="rowCol" span={24}>
                  <Form.Item label="Display name" name="display_name" rules={[{ required: true, message: "Display name is required" }]}>
                    <Input type="text" placeholder="Enter display name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="modal-rows fieldBox">
                <Col className="rowCol" span={24}>
                  <Form.Item label="Description" name="description" rules={[{ required: true, message: "Description is required" }]} >
                    <Input type="text" placeholder="Enter description" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="modal-rows fieldBox">
                <Col className="rowCol" span={24}>
                  <Form.Item label="Category" name="category_id" rules={[{ required: false }]}>
                    <Select className="modal-role-text" placeholder="select Category">
                      {/* @ts-ignore */}
                      <Option value="">Select Category</Option>
                      {this.state.CategoryList.map((option: any) => (
                        //@ts-ignore
                        <Option value={option.id}>{option.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                </Col>
              </Row>
              <Row className="saveBtnbox">
                <Col span={9}></Col>
                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="content-add-btn saveBtn"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={9}></Col>
              </Row>
            </Form>
          </div>
        </Modal>

        {/* EDIT SubCategory MODAL */}
        <Modal
          className="addstoreModalBox catelogModalBox"
          visible={this.state.editSubCategoryModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.setState({ editSubCategoryModal: false })
            this.onReset();
            // this.componentDidMount();
          }}
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Edit Sub Category</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">Edit sub category Details</p>
              </Col>
            </Row>
            {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
            <Form
              onFinish={this.handelEditSubCategoryData}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              name="content-ref"
              autoComplete="off"
              layout="vertical"
            >
              <Spin
                spinning={DataLoader}
                indicator={antIcon}
              >
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={24}>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: "SubCategory name is required" }]} >
                      <Input type="text" placeholder="Enter SubCategory name" />
                    </Form.Item>
                  </Col>

                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={24}>
                    <Form.Item label="Display name" name="display_name" rules={[{ required: true, message: "Display name is required" }]}>
                      <Input type="text" placeholder="Enter display name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={24}>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: "Description is required" }]} >
                      <Input type="text" placeholder="Enter description" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="modal-rows fieldBox">
                  <Col className="rowCol" span={24}>
                    <Form.Item label="Category Id" name="category_id" rules={[{ required: false }]}>

                      <Select className="modal-role-text" placeholder="select Category">
                        {/* @ts-ignore */}
                        <Option value="">Select Category</Option>
                        {this.state.CategoryList.map((option: any) => (
                          //@ts-ignore
                          <Option value={option.id}>{option.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>

                  </Col>
                </Row>
                <Row className="saveBtnbox">
                  <Col span={9}></Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="content-add-btn saveBtn"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={9}></Col>
                </Row>
              </Spin>
            </Form>
          </div>
        </Modal>


        {/* ADD Groupings MODAL */}
        <Modal
          className="addstoreModalBox catelogModalBox"
          visible={this.state.addGroupingModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.setState({ addGroupingModal: false })
            this.onReset();
          }
          }
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Add Item Details</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">Add the item details</p>
              </Col>
            </Row>
            {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
            <Form
              onFinish={this.handelAddGroupingsData}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              name="content-ref"
              autoComplete="off"
              layout="vertical"
            >
              <Row className="modal-rows" gutter={[32, 0]}>
                <Col className="rowCol" span={10}>
                  <Form.Item
                    name="itemCode"
                    label="Item Code"
                  >
                    {/* <Select
                      showSearch
                      className="rowCol fieldBox selectdropBox"
                      placeholder="Search/Select Itemcode"
                      // onSearch={this.searchSelectValue}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        //@ts-ignore
                        option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        //@ts-ignore
                        optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                      }
                      onChange={(e) => { this.onChangeItemCode(e) }}
                    >
                      {this.state?.InventoryDataList?.map((data: any) => {
                        return <Option
                          key={`${data?.attributes?.article_code}${data?.attributes?.id}`}
                          value={data?.attributes?.id}
                        >
                          {data?.attributes?.item_name}-{data?.attributes?.article_code}
                        </Option>
                      })
                      }
                    </Select> */}

                    {/* <Input className="modal-role-text" placeholder="Item Code" onChange={(e) => { this.onChangeItemCode(e.target.value) }} /> */}

                    {/* custom search with pagination */}
                    <Input
                      placeholder={`${itemCode}`}
                      className="modal-role-text"
                      style={{ cursor: 'pointer' }}
                      readOnly
                      onClick={() => { this.setState({ searchInventoryItemsBlock: true }) }}
                    />

                    {searchInventoryItemsBlock ?
                      <div className="dropdown"
                      // onBlur={() => { this.setState({ searchInventoryItemsBlock: false }) }}

                      >
                        <div className="dropdown-content">
                          <div className="cancel-button">
                            <label className="cancel-button-text" onClick={() => { this.setState({ searchInventoryItemsBlock: false }) }}>Cancel</label>
                          </div>

                          <div className="search">
                            <Input
                              // autoFocus
                              placeholder="Search"
                              className="modal-role-text"
                              onChange={(e) => { this.searchArticleCode(e) }}
                            />
                          </div>
                          <div
                            className="inv-items"
                          >
                            <Spin
                              style={{ color: '#000' }}
                              className="ar-loader"
                              spinning={articleCodeLoader}
                              indicator={<SyncOutlined translate={{}} className="custom-search-loader" spin />}
                            >

                              {this.state?.InventoryDataList.length > 0 ?
                                this.state?.InventoryDataList?.map((data: any) => {
                                  return <div
                                    className="item"
                                    onClick={() => { this.onChangeItemCode(data) }}
                                    title={`${data?.attributes?.item_name}-${data?.attributes?.article_code}`}
                                  >
                                    {data?.attributes?.item_name}-{data?.attributes?.article_code}
                                  </div>
                                })
                                :
                                <div className="no-data-found">
                                  No Data Found !
                                </div>
                              }
                            </Spin>
                          </div>
                          <div className="pagination">
                            <Divider />
                            {this.state?.InventoryDataList.length > 0 ?
                              <Pagination
                                size="small"
                                showSizeChanger={false}
                                showQuickJumper={false}
                                total={this.state?.pagination?.TotalInventorycount}
                                onChange={this.onPageChange} />
                              : null}
                          </div>
                        </div>
                      </div>
                      : null}


                  </Form.Item>
                </Col>
                <Col className="rowCol" span={10}>
                  <Form.Item
                    name="itemName"
                    label="Item Name"
                    rules={[
                      { required: true, message: "Item name is required." },
                    ]}
                  >
                    <Input placeholder="Item name" className="modal-role-text" />
                  </Form.Item>
                </Col>
                <Col className="rowCol" span={10}>
                  <Form.Item
                    name="itemDisplayName"
                    label="Display name"
                    rules={[
                      { required: true, message: "Item display name is required." },
                    ]}
                  >
                    <Input placeholder="Item display name" className="modal-role-text" />
                  </Form.Item>
                </Col>
                <Col className="rowCol fieldBox selectdropBox" span={10}>
                  <Form.Item
                    name="subCatego"
                    label="Sub Category"
                    rules={[
                      { required: true, message: "subCategory is required." },
                    ]}
                  >
                    <Select
                      // mode={localStorage.getItem('catalogue_name') == 'Dunzoo' ? 'multiple' : undefined}
                      mode="multiple"
                      showSearch
                      showArrow
                      style={{ width: '100%' }}
                      placeholder="select Sub Category"
                      optionFilterProp="children"
                      maxTagCount='responsive'
                      filterOption={(input, option) =>
                        //@ts-ignore
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        this.state?.SubCategoryList.map((data: any) => {
                          return <Option value={data.id}>
                            {data.name}
                          </Option>
                        })
                      }

                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="modal-rows" gutter={[32, 0]}>
                <Col span={10} className="rowCol">
                  {/* <Form.Item
                    name="item_description"
                    rules={[
                      { required: true, message: "item description is required." },
                    ]}
                  >
                    <Input placeholder="Item description" className="modal-role-text" />

                  </Form.Item> */}
                  <Form.Item
                    name="item_description"
                    label="Description"
                    rules={[
                      { required: true, message: "item description is required." },
                    ]}
                  >
                    <Input placeholder="item description" className="modal-role-text" />

                  </Form.Item>
                </Col>
                <Col span={10} className="rowCol">
                  <Form.Item
                    name="gmrp"
                    label="Gross MRP"
                    rules={[
                      { required: true, message: "Gross mrp is required." },
                    ]}
                  >
                    <Input placeholder="Gross Mrp" className="modal-role-text" readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="modal-rows" gutter={[32, 0]}>
                <Col span={10} className="rowCol">
                  <Form.Item
                    name="netmrp"
                    label="Net MRP"
                    rules={[
                      { required: true, message: "Net mrp is required." },
                    ]}
                  >
                    <Input placeholder="Net Mrp" className="modal-role-text" readOnly />
                  </Form.Item>
                </Col>
                <Col span={10} className="rowCol">
                  {/* <Form.Item
                    name="stock"
                    rules={[
                      { required: true, message: "stock is required." },
                    ]}
                  >
                    <Input placeholder="stock" className="modal-role-text" />
                  </Form.Item> */}
                </Col>

              </Row>
              <Row className="saveBtnbox">
                <Col span={9}></Col>
                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="content-add-btn saveBtn"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={9}></Col>
              </Row>
            </Form>
          </div>
        </Modal>




        {/* EDIT Groupings MODAL */}
        <Modal
          className="addstoreModalBox catelogModalBox"
          visible={this.state.editGroupingModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.setState({ editGroupingModal: false })
            this.onReset();
          }
          }
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
            />
          }
          footer={null}
        >
          <div className="AddStoreTimingsSection">
            <Row className="header">
              <Col span={24}>
                <h1 className="mb-0 modal-heading">Edit Item Details</h1>
              </Col>
              <Col span={24}>
                <p className="mb-0 modal-subtext">Edit the item details</p>
              </Col>
            </Row>
            {/* <Row className="imgBoxBlock">
              <div className="imgBox" />
            </Row> */}
            <Form
              onFinish={this.handelEditGroupingsData}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              name="content-ref"
              autoComplete="off"
              layout="vertical"
            >
              <Spin
                spinning={DataLoader}
                indicator={antIcon}
              >
                <Row className="modal-rows" gutter={[32, 0]}>
                  <Col className="rowCol" span={12}>
                    <Form.Item label=" " className="catelog_switch">
                      <div style={{
                        lineHeight: '0px',
                        fontWeight: 'bold',
                        height: '53px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        paddingTop: '13px',
                        paddingLeft: '5px'
                      }}>

                        <Row gutter={[32, 0]}>
                          <Col className="rowCol" span={14}>
                            <p>{selectedGroupingsData?.data?.attributes?.item_code}</p>
                            <p>{selectedGroupingsData?.data?.attributes?.item_name}</p>
                          </Col>
                          <Col className="rowCol" span={10}>
                            <div className="switch-text">
                              {selectedGroupingsData?.data?.attributes ?
                                <Switch
                                  style={{ marginLeft: '14px', width: '98px' }}
                                  checkedChildren={'Available'}
                                  unCheckedChildren={'Unavailable'}
                                  onChange={(e) => this.handleSwitch(e, selectedGroupingsData?.data?.attributes?.id)}
                                  title={selectedGroupingsData && selectedGroupingsData?.data?.attributes?.status ? 'Available' : 'Unavailable'}
                                  defaultChecked={selectedGroupingsData && selectedGroupingsData?.data?.attributes?.status == true ? true : false}
                                />
                                : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col className="rowCol" span={12}>
                    {/* <Form.Item
                    name="itemName"
                    rules={[
                      { required: true, message: "Item name is required." },
                    ]}
                  >
                    <Input placeholder="Item name" className="modal-role-text" />
                  </Form.Item> */}
                    <Form.Item label="Sub Category" name="subCatego" >
                      <Select
                        mode="multiple"
                        className="rowCol fieldBox selectdropBox"
                        maxTagCount='responsive'
                        placeholder="select SubCategory"
                      // defaultValue={this.state?.selectedGroupingsData?.data?.attributes?.sub_category_names}
                      >
                        {this.state.SubCategoryList.map((option: any) => (
                          <Option key={`${option.id}${option.name}`}
                            value={option.name}
                          >{option.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="rowCol" span={12}>
                    <Form.Item name="itemCode" noStyle >
                      <Input type='hidden' />
                    </Form.Item>
                    <Form.Item name="itemName" noStyle >
                      <Input type='hidden' />
                    </Form.Item>
                    <Form.Item
                      name="itemDisplayName"
                      label="Item Display Name"
                      rules={[
                        { required: true, message: "Item display name is required." },
                      ]}
                    >
                      <Input placeholder="Item display name" className="modal-role-text" />
                    </Form.Item>
                  </Col>
                  <Col className="rowCol fieldBox selectdropBox" span={12}>
                    <Form.Item
                      name="item_description"
                      label="Item Description"
                      rules={[
                        { required: true, message: "item description is required." },
                      ]}
                    >
                      <Input placeholder="item description" className="modal-role-text" />

                    </Form.Item>
                  </Col>

                  <Col span={12} className="rowCol">
                    {/* <Form.Item
                    name="item_description"
                    rules={[
                      { required: true, message: "item description is required." },
                    ]}
                  >
                    <Input placeholder="Item description" className="modal-role-text" />

                  </Form.Item> */}
                    <Form.Item
                      name="gmrp"
                      label="Gross MRP"
                    // rules={[
                    //   { required: true, message: "Gross mrp is required." },
                    // ]}
                    >
                      <Input placeholder="Gross Mrp" className="modal-role-text" readOnly />
                    </Form.Item>

                  </Col>
                  <Col span={12} className="rowCol">
                    <Form.Item
                      name="netmrp"
                      label="Net MRP"
                    // rules={[
                    //   { required: true, message: "Net mrp is required." },
                    // ]}
                    >
                      <Input placeholder="Net Mrp" className="modal-role-text" readOnly />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="modal-rows" gutter={[32, 0]}>
                  <Col span={10} className="rowCol">

                  </Col>
                  <Col span={10} className="rowCol">
                    {/* <Form.Item
                    name="stock"
                    rules={[
                      { required: true, message: "stock is required." },
                    ]}
                  >
                    <Input placeholder="stock" className="modal-role-text" />
                  </Form.Item> */}
                  </Col>

                </Row>
                <Row className="saveBtnbox text-center">
                  <Col span={9}></Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="content-add-btn saveBtn"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={9}></Col>
                </Row>
              </Spin>
            </Form>
          </div>
        </Modal>




        <div
          style={{ cursor: 'pointer', width: '60px' }}
          onClick={() => {
            //@ts-ignore
            this.props.history.push('/Catalogue')
          }}>
          <div className="backButton">
            <ArrowLeftOutlined translate={{}} /> Back
          </div>
        </div>
        <div className="StoreTimingsHeaderSection catalouge-row">


          <div className="catalouge-col" style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
            <img src={catalogueDetails?.image} className="catalouge-image-block" />
            <h1 >{catalogueDetails?.name ? catalogueDetails?.name : ''} </h1>
          </div>

          {this.state.selectedTab == 1 ?


            <div className="StoreTimingsActions catalouge-row">
              <div className="searchBox catalouge-col">
                <Input placeholder="Search" onChange={(e) => { this.searchCategories(e) }} />
              </div>
              <Upload className="importBtn catalouge-col"
                accept={".csv,.xls,.xlsx"}
                customRequest={(e) => { this.uploadCategoryCsv(e) }} showUploadList={false}>
                <Button
                  icon={<UploadOutlined translate />}>Import</Button>
              </Upload>
              <Button className="Btn catalouge-col"
              >
                <a
                  href={`${baseURL}/bx_block_categories/categories/csv_export.csv?search=${token}`}
                >Export
                </a>
              </Button>

              <Button className="Btn addstoreBtn catalouge-col " onClick={() => {
                if (this.permissions?.create_action != true) {
                  AntMessage.error('Permission Denied !');
                  return;
                }
                this.setState({ addCategoryModal: true })
              }
              }
              >Add Category</Button>
            </div>
            : null}


          {this.state.selectedTab == 2 ?
            <div className="StoreTimingsActions catalouge-row">
              <div className="searchBox catalouge-col">
                <Input placeholder="Search" onChange={(e) => { this.searchSubCategories(e) }} />
              </div>
              <Upload className="importBtn catalouge-col"
                accept={".csv,.xls,.xlsx"}
                customRequest={(e) => { this.uploadSubCategories(e) }} showUploadList={false}>
                <Button icon={<UploadOutlined translate />}>Import</Button>
              </Upload>
              <Button className="Btn catalouge-col"
              >
                <a
                  href={`${baseURL}/bx_block_categories/sub_categories/csv_export.csv?search=${token}`}
                >Export
                </a>
              </Button>

              <Button className="Btn addstoreBtn catalouge-col" onClick={() => {
                if (this.permissions?.create_action != true) {
                  AntMessage.error('Permission Denied !');
                  return;
                }
                this.setState({ addSubCategoryModal: true })
              }
              }
              >
                Add SubCategory</Button>


            </div>
            : null
          }


          {this.state.selectedTab == 3 ?

            <div className="StoreTimingsActions catalouge-row">
              <div className="searchBox catalouge-col">
                <Input placeholder="Search" onChange={(e) => { this.searchGroupingData(e) }} />
              </div>
              {localStorage.getItem("catalogue_name")?.toLowerCase() == 'phygital' ?
                <Upload className="importBtn"
                  accept={".csv,.xls,.xlsx"}
                  style={{ marginRight: '10px' }}
                  customRequest={(e) => { this.uploadVariablePrice(e) }}
                  showUploadList={false}>
                  <Button
                    style={{ marginRight: '10px' }}
                    icon={<UploadOutlined translate />}>Import Variable Price</Button>
                </Upload>
                : null}
              <Upload className="importBtn catalouge-col"
                accept={".csv,.xls,.xlsx"}
                customRequest={(e) => { this.uploadGroupingsCsv(e) }} showUploadList={false}>
                <Button icon={<UploadOutlined translate />}>Import</Button>
              </Upload>
              <Button className="Btn catalouge-col"
              >
                <a
                  href={`${baseURL}/bx_block_categories/groupings/csv_export.csv?catalogue_id=${catalogueId}${this.state?.token ? `?search=${token}` : ''}`}
                >Export
                </a>
              </Button>

              <Button className="Btn addstoreBtn catalouge-col" onClick={() => {
                if (this.permissions?.create_action != true) {
                  AntMessage.error('Permission Denied !');
                  return;
                }
                this.setState({ addGroupingModal: true })
              }

              }
              >
                Add Groupings</Button>

            </div>
            : null
          }

        </div>
        {
          this.state.selectedTab == 1 || this.state.selectedTab == 2 || this.state.selectedTab == 3 ?
            <div className="sample-file">
              <Popover
                placement="bottom"
                content={
                  <div>
                    <p>
                      {this.state.selectedTab == 1 ? <a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=category`}>Download</a> : null}
                      {this.state.selectedTab == 2 ? <a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=sub_category`}>Download</a> : null}
                      {this.state.selectedTab == 3 ? <a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=grouping`}>Download</a> : null}

                    </p>
                  </div>
                }
                title="Download Sample File"
              >
                <DownCircleFilled translate={{}} style={{ marginRight: 5 }} />Sample file

              </Popover>
            </div>
            : null
        }


        {
          offerModal ?
            <Modal
              className="addstoreModalBox catelogModalBox"
              visible={offerModal}
              title={null}
              centered
              width="45vw"
              onCancel={() => {
                this.setState({ offerModal: false });
              }
              }
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              <div>


                {
                  selectedOfferData?.id == 1 ?
                    <div className="AddStoreTimingsSection">
                      <Row className="header">
                        <Col span={24}>
                          <h1 className="mb-0 modal-heading">{selectedOfferData?.title}</h1>
                        </Col>
                      </Row>
                      <Form
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        ref={this.formRef}
                        name="content-ref"
                        autoComplete="off"
                        layout="vertical"
                      >
                        <div style={{ marginTop: '30px' }}>
                          <Row>
                            <Col span={12}>
                              <Form.Item label="Select Items" name="invItem">
                                <Select
                                  showSearch
                                  style={{ width: '200px' }}
                                  placeholder="Select Itemcode"
                                  loading={this.state?.InventoryDataList.length == 0}
                                  onSearch={this.onItemSearchForOffers}
                                  onChange={(e) => { this.onChangeBuyItem(e) }}
                                >
                                  {this.state?.InventoryDataList?.map((data: any) => {
                                    return <Option
                                      key={`${data?.attributes?.article_code}`}
                                      // value={data?.attributes?.id}
                                      value={JSON.stringify(data.attributes)}

                                    >
                                      <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                    </Option>
                                  })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Select Items" name="invItem2">
                                <Select
                                  showSearch
                                  style={{ width: '200px' }}
                                  placeholder="Select Itemcode"
                                  loading={this.state?.InventoryDataList.length == 0}
                                  // onSearch={this.onItemSearchForOffers}
                                  onChange={(e) => { this.onChangeFreeItem(e) }}
                                >
                                  {this.state?.InventoryDataList?.map((data: any) => {
                                    return <Option
                                      key={`${data?.attributes?.article_code}`}
                                      // value={data?.attributes?.id}
                                      value={JSON.stringify(data.attributes)}
                                    >
                                      <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                    </Option>
                                  })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row >
                            <Col span={12} className="startEndDatePicker">
                              <Form.Item label="Date" name="startEndDate" rules={[{ required: true, message: "Start and End date is required" }]}>
                                <DatePicker.RangePicker
                                  format={'YYYY/MM/DD'}
                                  onChange={this.handleDateChange} />
                              </Form.Item>
                            </Col>
                            <Col span={12} className="startEndTimePicker">
                              <Form.Item label="Time" name="startEndTime" rules={[{ required: true, message: "Start and End time is required" }]}>
                                <TimePicker.RangePicker
                                  onChange={this.handleTimeChange}
                                  use12Hours={true}
                                  format="h:mm a"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="saveBtnbox">
                            <Col span={6}>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  size="large"
                                  htmlType="submit"
                                  className="content-add-btn saveBtn offerSaveBtn"
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>

                        </div>
                      </Form>
                    </div>
                    : null
                }
                {
                  selectedOfferData?.id == 2 ?
                    <div className="AddStoreTimingsSection">
                      <Row className="header">
                        <Col span={24}>
                          <h1 className="mb-0 modal-heading">{selectedOfferData?.title}</h1>
                        </Col>
                      </Row>
                      <Form
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        ref={this.formRef}
                        name="content-ref"
                        autoComplete="off"
                        layout="vertical"
                      >
                        <div style={{ marginTop: '30px' }}>
                          <Row>
                            <Col span={8}>
                              <Select
                                showSearch
                                style={{ width: '200px', marginRight: "20px", marginBottom: '15px' }}
                                placeholder="Select Itemcode"
                                loading={this.state?.InventoryDataList.length == 0}
                                onSearch={this.onItemSearchForOffers}
                                onChange={(e) => { this.onChangeBuyItem(e) }}
                              >
                                {this.state?.InventoryDataList?.map((data: any) => {
                                  return <Option
                                    key={`${data?.attributes?.article_code}`}
                                    // value={data?.attributes?.id}
                                    value={JSON.stringify(data.attributes)}
                                  >
                                    <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                  </Option>
                                })
                                }
                              </Select>
                            </Col>
                            <Col span={8}>
                              <Select
                                showSearch
                                style={{ width: '200px', marginRight: "20px" }}
                                placeholder="Select Itemcode"
                                loading={this.state?.InventoryDataList.length == 0}
                                onSearch={this.onItemSearchForOffers}
                                onChange={(e) => { this.onChangeFreeItem(e) }}
                              >
                                {this.state?.InventoryDataList?.map((data: any) => {
                                  return <Option
                                    key={`${data?.attributes?.article_code}`}
                                    // value={data?.attributes?.id}
                                    value={JSON.stringify(data.attributes)}
                                  >
                                    <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                  </Option>
                                })
                                }
                              </Select>
                            </Col>
                            <Col span={8}>
                              <Select
                                showSearch
                                style={{ width: '200px', marginRight: "20px" }}
                                placeholder="Select Itemcode"
                                loading={this.state?.InventoryDataList.length == 0}
                                // onSearch={this.onItemSearchForOffers}
                                onChange={(e) => { this.onChangeFreeItem(e) }}
                              >
                                {this.state?.InventoryDataList?.map((data: any) => {
                                  return <Option
                                    key={`${data?.attributes?.article_code}`}
                                    // value={data?.attributes?.id}
                                    value={JSON.stringify(data.attributes)}
                                  >
                                    <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                  </Option>
                                })
                                }
                              </Select>
                            </Col>

                          </Row>
                          <Row >
                            <Col span={12} className="startEndDatePicker">
                              <Form.Item label="Date" name="startEndDate" rules={[{ required: true, message: "Start and End date is required" }]}>
                                <DatePicker.RangePicker format={'YYYY/MM/DD'} onChange={this.handleDateChange} />
                              </Form.Item>
                            </Col>
                            <Col span={12} className="startEndTimePicker">
                              <Form.Item label="Time" name="startEndTime" rules={[{ required: true, message: "Start and End time is required" }]} >
                                <TimePicker.RangePicker
                                  onChange={this.handleTimeChange}
                                  use12Hours={true}
                                  format="h:mm a"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="saveBtnbox">
                            <Col span={6}>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  size="large"
                                  htmlType="submit"
                                  className="content-add-btn saveBtn offerSaveBtn"
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>

                        </div>
                      </Form>
                    </div>
                    : null
                }
                {
                  selectedOfferData?.id == 3 ?
                    <div className="AddStoreTimingsSection">
                      <Row className="header">
                        <Col span={24}>
                          <h1 className="mb-0 modal-heading">{selectedOfferData?.title}</h1>
                        </Col>
                      </Row>
                      <Form
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        ref={this.formRef}
                        name="content-ref"
                        autoComplete="off"
                        layout="vertical"
                      >
                        <div style={{ marginTop: '30px' }}>
                          <Row>
                            <Col span={12}>
                              <Form.Item label="Minimum amount" name="minimumAmount">
                                <Input
                                  className="rowCol fieldBox "
                                  style={{ width: '200px' }}
                                  placeholder="Minimum amount"
                                // onChange={(e) => { this.onChangeBuyItem(e) }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Select Item" name="InvItems">
                                <Select
                                  showSearch
                                  style={{ width: '200px', height: '50px' }}
                                  placeholder="Select Itemcode"
                                  loading={this.state?.InventoryDataList.length == 0}
                                  // onSearch={this.onItemSearchForOffers}
                                  onChange={(e) => { this.onChangeFreeItem(e) }}
                                >
                                  {this.state?.InventoryDataList?.map((data: any) => {
                                    return <Option
                                      key={`${data?.attributes?.article_code}`}
                                      // value={data?.attributes?.id}
                                      value={JSON.stringify(data.attributes)}
                                    >
                                      <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                    </Option>
                                  })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row >
                            <Col span={12} className="startEndDatePicker">
                              <Form.Item label="Date" name="startEndDate" rules={[{ required: true, message: "Start and End date is required" }]}>
                                <DatePicker.RangePicker format={'YYYY/MM/DD'} onChange={this.handleDateChange} />
                              </Form.Item>
                            </Col>
                            <Col span={12} className="startEndTimePicker">
                              <Form.Item label="Time" name="startEndTime" rules={[{ required: true, message: "Start and End time is required" }]}>
                                <TimePicker.RangePicker
                                  onChange={this.handleTimeChange}
                                  use12Hours={true}
                                  format="h:mm a"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="saveBtnbox">
                            <Col span={6}>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  size="large"
                                  htmlType="submit"
                                  className="content-add-btn saveBtn offerSaveBtn"
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>

                        </div>
                      </Form>
                    </div>
                    : null
                }

                {/* {selectedOfferData?.id ?
                <Button className="offerSaveBtn" onClick={() => { this.onSaveOffers() }}>Save</Button> : null
              } */}

              </div>
            </Modal>
            : null
        }


        <Modal
          className="addstoreModalBox catelogModalBox"
          visible={editOfferModal}
          title={null}
          centered
          width="45vw"
          onCancel={() => {
            this.setState({ editOfferModal: false });
            this.onReset();
          }
          }
          closeIcon={
            <CloseCircleFilled
              style={{ color: "#000", fontSize: "20px" }}
              translate={{}}
              onClick={() => {
                this.setState({ editOfferModal: false });
                this.onReset();
              }}
            />
          }
          footer={null}
        >
          <div>



            <div className="AddStoreTimingsSection">
              <Row className="header">
                <Col span={24}>
                  <h1 className="mb-0 modal-heading"> Update {selectedOfferData?.title}</h1>
                </Col>
              </Row>
              <Form
                onFinish={this.onFinishUpdate}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
                name="content-ref"
                autoComplete="off"
                layout="vertical"
              >
                <div style={{ marginTop: '30px' }}>
                  <Row >
                    <Col span={6}>
                      <Form.Item label="Offer Type" name="offerType">
                        <Select style={{ width: 160 }}
                          placeholder="Select Offer Type"
                          onChange={(e) => { this.selectUpdateOfferSelectedOffer(e) }}
                        >
                          {OfferData.map((d: any, index: any) => {
                            return (
                              <Select.Option key={`Option${index}`} value={d?.code} ><label style={{ fontSize: 10 }}> {d.title}</label></Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>


                    {selectedOfferData?.id == 1 ?
                      <Row>
                        <Col span={12}>
                          <Form.Item label="Select Items" name="invItem">
                            <Select
                              showSearch
                              style={{ width: '200px' }}
                              placeholder="Select Itemcode"
                              loading={this.state?.InventoryDataList.length == 0}
                              // onSearch={this.onItemSearchForOffers}
                              onChange={(e) => { this.onChangeBuyItem(e) }}
                            >
                              {this.state?.InventoryDataList?.map((data: any) => {
                                return <Option
                                  key={`${data?.attributes?.article_code}`}
                                  // value={data?.attributes?.id}
                                  value={JSON.stringify(data.attributes)}

                                >
                                  <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                </Option>
                              })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Select Items" name="invItem2">
                            <Select
                              showSearch
                              style={{ width: '250px' }}
                              placeholder="Select Itemcode"
                              loading={this.state?.InventoryDataList.length == 0}
                              // onSearch={this.onItemSearchForOffers}
                              onChange={(e) => { this.onChangeFreeItem(e) }}
                            >
                              {this.state?.InventoryDataList?.map((data: any) => {
                                return <Option
                                  key={`${data?.attributes?.article_code}`}
                                  // value={data?.attributes?.id}
                                  value={JSON.stringify(data.attributes)}
                                >
                                  <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                </Option>
                              })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      : null}
                    {selectedOfferData?.id == 2 ?
                      <Row>
                        <Col span={8}>
                          <Form.Item label="Select Items" name="invItem">
                            <Select
                              showSearch
                              style={{ width: '200px' }}
                              placeholder="Select Itemcode"
                              loading={this.state?.InventoryDataList.length == 0}
                              // onSearch={this.onItemSearchForOffers}
                              onChange={(e) => { this.onChangeBuyItem(e) }}
                            >
                              {this.state?.InventoryDataList?.map((data: any) => {
                                return <Option
                                  key={`${data?.attributes?.article_code}`}
                                  // value={data?.attributes?.id}
                                  value={JSON.stringify(data.attributes)}

                                >
                                  <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                </Option>
                              })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8} >
                          <Form.Item label="Select Items" name="invItem2">
                            <Select
                              showSearch
                              style={{ width: '200px', marginRight: '25px' }}
                              placeholder="Select Itemcode"
                              loading={this.state?.InventoryDataList.length == 0}
                              // onSearch={this.onItemSearchForOffers}
                              onChange={(e) => { this.onChangeFreeItem(e) }}
                            >
                              {this.state?.InventoryDataList?.map((data: any) => {
                                return <Option
                                  key={`${data?.attributes?.article_code}`}
                                  // value={data?.attributes?.id}
                                  value={JSON.stringify(data.attributes)}
                                >
                                  <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                </Option>
                              })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Select Items" name="invItem2">
                            <Select
                              showSearch
                              style={{ width: '250px' }}
                              placeholder="Select Itemcode"
                              loading={this.state?.InventoryDataList.length == 0}
                              // onSearch={this.onItemSearchForOffers}
                              onChange={(e) => { this.onChangeFreeItem(e) }}
                            >
                              {this.state?.InventoryDataList?.map((data: any) => {
                                return <Option
                                  key={`${data?.attributes?.article_code}`}
                                  // value={data?.attributes?.id}
                                  value={JSON.stringify(data.attributes)}
                                >
                                  <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                </Option>
                              })
                              }
                            </Select>
                          </Form.Item>
                        </Col>

                      </Row>
                      : null}
                    {selectedOfferData?.id == 3 ?
                      <Row>
                        <Col span={12}>
                          <Form.Item label="Minimum amount" name="minimumAmount">
                            <Input
                              className="rowCol fieldBox "
                              style={{ width: '200px' }}
                              placeholder="Minimum amount"
                            // onChange={(e) => { this.onChangeBuyItem(e) }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Select Items" name="invItem2">
                            <Select
                              showSearch
                              style={{ width: '200px', marginLeft: '25px' }}
                              placeholder="Select Itemcode"
                              loading={this.state?.InventoryDataList.length == 0}
                              // onSearch={this.onItemSearchForOffers}
                              onChange={(e) => { this.onChangeFreeItem(e) }}
                            >
                              {this.state?.InventoryDataList?.map((data: any) => {
                                return <Option
                                  key={`${data?.attributes?.article_code}`}
                                  // value={data?.attributes?.id}
                                  value={JSON.stringify(data.attributes)}
                                >
                                  <label style={{ fontSize: 10 }}> {data?.attributes?.item_name}-{data?.attributes?.article_code}</label>
                                </Option>
                              })
                              }
                            </Select>
                          </Form.Item>
                        </Col>

                      </Row>
                      : null}
                  </Row>
                  <Row >
                    <Col span={12} className="startEndDatePicker">
                      <Form.Item label="Date" name="startEndDate">
                        <DatePicker.RangePicker
                          defaultValue={timingsData.startDate ? [moment(timingsData.startDate, 'DD-MM-YYYY'), moment(timingsData.EndDate, 'DD-MM-YYYY')] : null}
                          format={'DD-MM-YYYY'}
                          onChange={this.handleDateChange} />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="startEndTimePicker">
                      <Form.Item label="Time" name="startEndTime">
                        <TimePicker.RangePicker
                          onChange={this.handleTimeChange}
                          use12Hours={true}
                          defaultValue={timingsData.StartTime ? [moment(timingsData.StartTime, 'h:mm a'), moment(timingsData.EndTime, 'h:mm a')] : null}


                          format="h:mm a"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="saveBtnbox">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn saveBtn offerSaveBtn"
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>

                </div>
              </Form>
            </div>

          </div>
        </Modal>






        <div className="storeTimingTableBlock storeTabSection">
          <Tabs defaultActiveKey="1" onChange={this.handelTabChange}>
            <TabPane tab="Category" key="1">
              <Table
                // rowSelection={rowSelection}
                columns={CategoryColumn}
                dataSource={this.state?.CategoryList}
                loading={this.state.apiLoading}
                scroll={{ x: 1200 }}
                size={'small'}
              // pagination={false}
              />
            </TabPane>
            <TabPane tab="Sub Category" key="2">
              <Table
                // rowSelection={rowSelection}
                columns={subCategoryColumns}
                dataSource={this.state?.SubCategoryList}
                loading={this.state.apiLoading}
                scroll={{ x: 1200 }}
                size={'small'}
              // pagination={false}
              />
            </TabPane>
            <TabPane tab="Groupings" key="3">
              <Table
                // rowSelection={rowSelection}
                columns={GroupsColumns}
                dataSource={this.state?.GroupingList}
                loading={this.state.apiLoading}
                scroll={{ x: 1200 }}
                size={'small'}
                pagination={paginationOptions}
              />
            </TabPane>
            {
              localStorage.getItem("catalogue_name")?.toLowerCase() == "zomato" ?
                <TabPane tab="Offers" key="4">
                  <div className="offersSection">
                    <div className="offer1">
                      <Row className="offer-BOGO">
                        <Col span={6}>
                          <Select style={{ width: 160 }}
                            onChange={(e) => { this.onSelectItemForOffers(e) }}
                            value={'Select Offer Type'}
                            placeholder="Select Offer Type">
                            <Select.Option disabled key={`Option00`} value={`0`}><label style={{ fontSize: 10 }}>Choose the offers</label></Select.Option>
                            {OfferData.map((d: any, index: any) => {
                              return (
                                <Select.Option key={`Option${index}`} value={JSON.stringify(d)}><label style={{ fontSize: 10 }}> {d.title}</label></Select.Option>
                              )
                            })}
                          </Select>
                        </Col>
                      </Row>

                    </div>
                  </div>

                  <Table
                    columns={OffersColumns}
                    dataSource={this.state?.OfferListData}
                    scroll={{ x: 1200 }}
                    size={'small'}
                    loading={this.state?.OfferListData == 0 ? true : false}
                    showSorterTooltip={false}
                  />
                </TabPane>
                : null
            }

          </Tabs>
        </div>
      </div >
    );
  }
}
// Customizable Area End