import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Swal from 'sweetalert2'
import { message as AntMessage } from "antd";
import moment from 'moment';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    selectedRowKeys: any;
    addModal: boolean;
    days: any;
    Errors: any
    repeats: any;
    selectedTab: number;
    editModal: boolean;
    addOfcModal: boolean;
    editOfcModal: boolean;
    editZoneModal: boolean,
    addZoneModal: boolean,
    Storelist: any,
    OfcList: any,
    ZoneList: any,
    ofcIds: any
    zoneIds: any
    selectedStoreData: any,
    selectedOfcData: any,
    selectedZoneData: any,
    Inventorylist: any,
    selectedInventoryData: any,
    apiLoading: boolean,
    storeTimingsData: any,
    selectedStoreTimingData: any,
    closingStoreTimings: any,
    selectedCheckBoxData: any,
    pagination: any,
    InventoryApiLoading: boolean,
    modaLoader: boolean,
    selectedProfilePic: any,
    addItemUnavailable: any,
    getItemUnavailable: any,
    FtpModal: boolean,
    uploadingLoading: boolean,
    searchValue: any,
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CfInventoryManagement10Controller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    base_url = `https://b24sevendashboard-82797-ruby.b82797.dev.us-east-1.aws.svc.builder.cafe/`;
    public token = localStorage.getItem('token')
    public config = {
        headers: {
            'token': this.token || ''//'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NSwiZXhwIjoxNjM3MDQwNjg0fQ.5O46lD5pWKpsslFKx2gKi667-YdKDiKEB937oHoi8-_yHBtuDi2DxrcBUdE3t7VcSalptY73DLHoQtwyZgm0eA'
        }
    }
    getStoreInformation: string;
    getOfcInformation: string;
    getZoneInformation: string;
    addStoreInformation: string;
    addOfcInformation: string
    addZoneInformation: string
    editStoreInformation: string
    editOfcInformation: string
    editZoneInformation: string
    getAllInventoriesInformation: string
    addInventoriesInformation: string
    getInventoryByIdInformation: string
    getStoreByIdInformation: string
    getOfcByIdInformation: string
    getZoneByIdInformation: string
    editInventoryInformation: string
    editStoreTimingsInformation: string
    editMultipleStoreTimingsInformation: string
    getAllOfcRealtedToZoneId: string
    getselectedStoreClosingTime: string
    deleteParticularStoreClosingTime: string
    uploadStoreCsvFileInformation: string
    uploadOfcCsvFileInformation: string
    uploadZoneCsvFileInformation: string
    uploadInventoryCsvFileInformation: string
    storeSearchInformation: string
    inventorySearchInformation: string
    OfcSearchInformation: string
    ZoneSearchInformation: string
    FtpLocationInformation: string
    UpdateFtpLocationInformation: string
    UpdateStoreStatusInformation: string
    UpdateOfcStatusInformation: string
    UpdateZoneStatusInformation: string
    UpdateStoreProfileImage: string
    addItemAvailiblityInformation: string
    getItemAvailiblityInformation: string
    updateItemAvailiblityInformation: string
    getInventoryByStoreIdInformation: string

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            // Customizable Area Start
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            selectedRowKeys: [],
            addModal: false,
            days: [],
            Errors: {},
            repeats: [],
            selectedTab: 1,
            editModal: false,
            addOfcModal: false,
            editOfcModal: false,
            editZoneModal: false,
            addZoneModal: false,
            Storelist: [],
            OfcList: [],
            ZoneList: [],
            ofcIds: [],
            zoneIds: [],
            selectedStoreTimingData: {},
            closingStoreTimings: [],
            selectedStoreData: {},
            selectedOfcData: {},
            selectedZoneData: {},
            Inventorylist: [],
            selectedInventoryData: {},
            apiLoading: false,
            InventoryApiLoading: false,
            storeTimingsData: {
                startDate: '',
                EndDate: '',
                StartTime: '',
                EndTime: '',
                Days: [],
                repeats: [],

            },
            selectedCheckBoxData: [],
            pagination: {
                currentPage: 1,
                pageSize: 10,
                TotalInventoryCount: 0,
                TotalStoreCount: 0,
            },
            modaLoader: false,
            selectedProfilePic: "",
            addItemUnavailable: [],
            getItemUnavailable: {},
            FtpModal: false,
            uploadingLoading: false,
            searchValue: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    toast(msg: any) {
        Swal.fire({
            toast: true,
            position: 'top-right',
            showConfirmButton: false,
            icon: 'success',
            timerProgressBar: false,
            timer: 4000,
            title: msg
        })
    }
    errorToast(msg: any) {
        Swal.fire({
            toast: true,
            position: 'top-right',
            showConfirmButton: false,
            icon: 'error',
            timerProgressBar: false,
            timer: 3000,
            title: msg
        })
    }





    //====================================================================================================
    async componentDidMount() {
        // Customizable Area Start
        // this.ongetStore();
        this.onGetStoreByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize);
        this.ongetOfc();
        this.ongetZone();
        // Customizable Area End
    }

    ongetStore = async () => {
        this.setState({ apiLoading: true });
        this.getStoreInformation = await this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getStoreApiEndPoint,
        });
    };
    onGetStoreByPagination = async (page: any, pagesize: any) => {
        this.setState({ apiLoading: true });
        this.getStoreInformation = await this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getStoreApiEndPoint + `?page=${page}&per_page=${pagesize}`,
        });
    };
    ongetOfc = async () => {
        this.getOfcInformation = await this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getOfcApiEndPoint,
        });
    };
    ongetZone = async () => {
        this.getZoneInformation = await this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getZoneApiEndPoint,
        });
    };



    addStoreData = async (data: any) => {
        if (this.state.selectedProfilePic !== "") {
            //file upload code
            let add_store_data = new FormData();
            add_store_data.append("data[storeId]", data.storeId);
            add_store_data.append("data[store_name]", data.store_name);
            add_store_data.append("data[password]", data.password);
            add_store_data.append("data[address]", data.address);
            add_store_data.append("data[phone_no]", data.phone_no);
            add_store_data.append("data[name]", data.manager);
            add_store_data.append("data[zone_id]", data.zone_id);
            add_store_data.append("data[ofc_id]", data.ofc_id);
            add_store_data.append("data[user_name]", data.username);
            add_store_data.append("data[avatar]", this.state.selectedProfilePic);
            add_store_data.append("data[phygital_store_id]", data.phygital_store_id);
            add_store_data.append("data[dunzo_store_id]", data.dunzo_store_id);
            add_store_data.append("data[zomato_store_id]", data.zomato_store_id);

            console.log('Calling add Store API', add_store_data)
            this.addStoreInformation = await this.apiCall({
                contentType: undefined,// configJSON.ApiContentType,
                method: configJSON.httpPostMethod,
                endPoint: configJSON.addStoreApiEndPoint,
                body: add_store_data
            });


        } else {
            //without file upload code
            let add_store_data = {
                data: {
                    storeId: data.storeId,
                    store_name: data.store_name,
                    password: data.password,
                    address: data.address,
                    phone_no: data.phone_no,
                    name: data.manager,
                    zone_id: parseInt(data.zone_id),
                    ofc_id: parseInt(data.ofc_id),
                    user_name: data.username,
                    phygital_store_id: data.phygital_store_id,
                    dunzo_store_id: data.dunzo_store_id,
                    zomato_store_id: data.zomato_store_id
                }
            }
            console.log('Calling add Store API', add_store_data)
            this.addStoreInformation = await this.apiCall({
                contentType: configJSON.ApiContentType,
                method: configJSON.httpPostMethod,
                endPoint: configJSON.addStoreApiEndPoint,
                body: JSON.stringify(add_store_data),
            });
        }

    }

    addOfcData = async (data: any) => {
        this.addOfcInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.addOfcApiEndPoint,
            body: JSON.stringify(data),
        });

    }

    addZoneData = async (data: any) => {
        this.addZoneInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.addZoneApiEndPoint,
            body: JSON.stringify(data),
        });
    }

    editStoreData = async (data: any) => {
        this.editStoreInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPatchMethod,
            endPoint: configJSON.editStoreApiEndPoint,
            body: JSON.stringify(data),
        });
    }

    editZoneData = async (data: any) => {
        this.editZoneInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.editZoneApiEndPoint,
            body: JSON.stringify(data),
        });
    }

    editOfcData = async (data: any) => {
        this.editOfcInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.editOfcApiEndPoint,
            body: JSON.stringify(data),
        });
    }



    getStoreById = async (id: number) => {
        this.getStoreByIdInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.getStoreByIdApiEndPoint}${id}`,
        });
    }
    getOfcDataByID = async (id: number) => {
        this.getOfcByIdInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.getOfcByIdApiEndPoint}${id}`,
        });
    }

    getZoneDataByID = async (id: number) => {
        this.getZoneByIdInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.getZoneByIdApiEndPoint}${id}`,
        });
    }

    EditStoreTimings = async (data: any) => {
        this.editStoreTimingsInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.EditStoreTimingsApiEndPoint,
            body: JSON.stringify(data),

        });
    }

    EditMultipleStoreTimings = async (data: any) => {
        this.editMultipleStoreTimingsInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.EditMulipleStoreTimingsApiEndPoint,
            body: JSON.stringify(data),

        });
    }


    getAllOfcsRealtedtoZoneId = async (id: any) => {
        this.getAllOfcRealtedToZoneId = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getAllOfcByZoneIdApiEndPoint + id,
            // body: JSON.stringify(data),

        });
    }

    getStoreClosingTimings = async (id: any) => {
        this.getselectedStoreClosingTime = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getClosingStoreTimeApiEndPoint + id,
            // body: JSON.stringify(data),

        });
    }



    deleteClosingTime = async (id: any, store_account_id: any) => {
        this.deleteParticularStoreClosingTime = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpDelMethod,
            endPoint: configJSON.deleteParticularStoreClosingTimeApiEndPoint + id + '&store_id=' + store_account_id, //4&store_id=5
            // body: JSON.stringify(data),

        });
    }






    uploadStoreCsvFile = async (formData: any) => {
        this.uploadStoreCsvFileInformation = await this.apiCall({
            contentType: undefined,//`multipart/form-data;boundary="boundary"`,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.StoreImportCsvApiEndPoint,
            body: formData,

        });
    }
    uploadOfcCsvFile = async (formData: any) => {
        this.uploadOfcCsvFileInformation = await this.apiCall({
            contentType: undefined,//`multipart/form-data;boundary="boundary"`,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.OfcImportCsvApiEndPoint,
            body: formData,

        });
    }
    uploadZoneCsvFile = async (formData: any) => {
        this.uploadZoneCsvFileInformation = await this.apiCall({
            contentType: undefined,//`multipart/form-data;boundary="boundary"`,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.ZoneImportCsvApiEndPoint,
            body: formData,

        });
    }





    searchStoreDetails = async (searchedText: any) => {
        this.storeSearchInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.StoreSearchApiEndPoint + searchedText,
        });
    }


    searchOfcDetails = async (searchedText: any) => {
        this.OfcSearchInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.OfcSearchApiEndPoint + searchedText,
        });
    }
    searchZoneDetails = async (searchedText: any) => {
        this.ZoneSearchInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.ZoneSearchApiEndPoint + searchedText,
        });
    }

    updateStoreStatus = async (data: any) => {
        this.UpdateStoreStatusInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPatchMethod,
            endPoint: configJSON.UpdateStorestatusApiEndPoint,
            body: JSON.stringify(data)
        });
    }
    updateOfcStatus = async (data: any) => {
        this.UpdateOfcStatusInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.UpdateOfcStatusApiEndPoint,
            body: JSON.stringify(data)
        });
    }
    updateZoneStatus = async (data: any) => {
        this.UpdateZoneStatusInformation = await this.apiCall({
            contentType: configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.UpdateZoneStatusApiEndPoint,
            body: JSON.stringify(data)
        });
    }

    uploadStoreProfile = async (data: any) => {
        this.UpdateStoreProfileImage = await this.apiCall({
            contentType: undefined,//configJSON.ApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.StoreProfileChangeApiEndPoint,
            body: data
        });
    }



    //=============== API CALL BLOCK ==============================
    apiCall = async (data: any) => {
        const { contentType, method, endPoint, body } = data;
        const token = localStorage.getItem('token')
        const header = {
            "Content-Type": contentType,
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };


    //========================================= RECEIVE API CALL BLOCK ===================================================

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("responseJson>>>>>>>", responseJson)
            if (responseJson?.errors && responseJson?.errors[0]?.token) {
                if (responseJson?.errors[0]?.token == "Token has Expired" || responseJson?.errors[0]?.token == "Invalid token") {
                    localStorage.clear();
                    window.location.href = '/';
                };
                if (responseJson == undefined) {
                    // AntMessage.error('Something went wrong')
                    return
                }
            }
            if (responseJson && responseJson.data || responseJson?.message) {
                if (apiRequestCallId === this.getStoreInformation) {
                    this.handelGetStoreData(responseJson);
                }
                if (apiRequestCallId === this.getOfcInformation) {
                    this.handelGetOfcData(responseJson);
                }
                if (apiRequestCallId === this.getZoneInformation) {
                    this.handelGetZoneData(responseJson);
                }
                if (apiRequestCallId === this.addStoreInformation) {
                    this._handelAddStoreData(responseJson);
                }
                if (apiRequestCallId === this.addOfcInformation) {
                    this._handelAddOfcData(responseJson);
                }
                if (apiRequestCallId === this.addZoneInformation) {
                    this._handelAddZoneData(responseJson);
                }
                if (apiRequestCallId === this.editStoreInformation) {
                    this._handelEditStoreData(responseJson);
                }
                if (apiRequestCallId === this.editZoneInformation) {
                    this._handelEditZoneData(responseJson);
                }
                if (apiRequestCallId === this.editOfcInformation) {
                    this._handelEditOfcData(responseJson);
                }
                if (apiRequestCallId === this.getStoreByIdInformation) {
                    this._handelGetStoreDataById(responseJson);
                }
                if (apiRequestCallId === this.getOfcByIdInformation) {
                    this._handelGetOfcDataById(responseJson);
                }
                if (apiRequestCallId === this.getZoneByIdInformation) {
                    this._handelGetZoneDataById(responseJson);
                }
                if (apiRequestCallId === this.editStoreTimingsInformation) {
                    this._handelEditStoreTimings(responseJson);
                }
                if (apiRequestCallId === this.editMultipleStoreTimingsInformation) {
                    this._handelMultipleEditStoreTimings(responseJson);
                }
                if (apiRequestCallId === this.getAllOfcRealtedToZoneId) {
                    this._handelgetAllOfcRealtedToZoneSuccessResponse(responseJson);
                }
                if (apiRequestCallId === this.getselectedStoreClosingTime) {
                    this._handelGetStoreClosingTimeSessResponse(responseJson);
                }
                if (apiRequestCallId === this.deleteParticularStoreClosingTime) {
                    this.setState({ selectedCheckBoxData: [] })
                    this._handelDeleteStoreClosingTimeSuccessResponse(responseJson);
                }
                if (apiRequestCallId === this.uploadStoreCsvFileInformation) {
                    this._handelImportStoreCsvSuccessResponse(responseJson);
                }
                if (apiRequestCallId === this.uploadOfcCsvFileInformation) {
                    this._handelImportStoreCsvSuccessResponse(responseJson);
                }
                if (apiRequestCallId === this.uploadZoneCsvFileInformation) {
                    this._handelImportStoreCsvSuccessResponse(responseJson);
                }
                if (apiRequestCallId === this.storeSearchInformation) {
                    this.handelGetStoreData(responseJson);
                }
                if (apiRequestCallId === this.OfcSearchInformation) {
                    this.handelGetOfcData(responseJson);
                }
                if (apiRequestCallId === this.ZoneSearchInformation) {
                    this.handelGetZoneData(responseJson);
                }
                if (apiRequestCallId === this.UpdateStoreStatusInformation) {
                    this._handelStoreStatusSuccessResponse(responseJson)
                }
                if (apiRequestCallId === this.UpdateOfcStatusInformation) {
                    this._handelOfcStatusSuccessResponse(responseJson)
                }
                if (apiRequestCallId === this.UpdateZoneStatusInformation) {
                    this._handelZoneStatusSuccessResponse(responseJson)
                }
                if (apiRequestCallId === this.UpdateStoreProfileImage) {
                    this._handelStoreProfileUpdateSuccessResponse(responseJson)
                }
                if (apiRequestCallId === this.addItemAvailiblityInformation) {
                    this._handeladdItemAvailiblityInformationSuccessResponse(responseJson)
                }
                if (apiRequestCallId === this.updateItemAvailiblityInformation) {
                    this._handelupdateItemUnavailiblityInformationSuccessResponse(responseJson)
                }


















            } else if (responseJson && responseJson.error || responseJson?.errors || responseJson == undefined) {
                if (apiRequestCallId === this.getStoreInformation) {
                    // AntMessage.error('Something Went Wrong')//antd
                }
                if (apiRequestCallId === this.addStoreInformation) {
                    this.handelErrorAddStoreData(responseJson);
                }
                if (apiRequestCallId === this.editStoreInformation) {
                    console.log(responseJson);

                    if (responseJson?.errors) {
                        if (responseJson?.errors.ofc[0] == "must exist") { AntMessage.error("Store must link with Ofc") }
                        if (responseJson?.errors.zone[0] == "must exist") { AntMessage.error("Store must link with Zone") }
                    }


                }
                if (apiRequestCallId === this.editStoreTimingsInformation) {
                    this._handelEditStoreTimingsError(responseJson);
                }
                if (apiRequestCallId === this.editMultipleStoreTimingsInformation) {
                    this._handelMultipleEditStoreTimingsError(responseJson);
                }
                if (apiRequestCallId === this.getselectedStoreClosingTime) {
                    console.log(responseJson);
                }
                if (apiRequestCallId === this.deleteParticularStoreClosingTime) {
                    this.setState({ selectedCheckBoxData: [] })
                    this._handelDeleteStoreClosingTimeErrorResponse(responseJson);
                }
                if (apiRequestCallId === this.storeSearchInformation) {
                    this._handelStoreSearchErrorResponse(responseJson);
                }
                if (apiRequestCallId === this.UpdateStoreProfileImage) {
                    this._handelStoreProfileUpdateErrorResponse(responseJson)
                }
                if (apiRequestCallId === this.addItemAvailiblityInformation) {
                    this._handeladdItemAvailiblityInformationErrorResponse(responseJson)
                }
                if (apiRequestCallId === this.updateItemAvailiblityInformation) {
                    this._handelupdateItemUnavailiblityInformationErrorResponse(responseJson)
                }
                if (apiRequestCallId === this.UpdateStoreStatusInformation) {
                    this._handelStoreStatuserrorResponse(responseJson)
                }
                else {
                    // AntMessage.error('Something went wrong');
                }
            } else if (errorReponse) {

            }
        }
    }
    //===================================================================================================

    //====================================================================================================
    handelGetStoreData = async (data: any) => {

        let storeListDataTobeDisplayed = data.data.map((sd: any,index:any) => {
            return {
                id: sd.id,
        sno: (index + 1) + ((this.state.pagination.currentPage - 1) * 10),
                store_id: sd.storeId ? sd.storeId : '-',
                store_name: sd.store_name ? sd.store_name : '-',
                store_address: sd.address ? sd.address : '-',
                store_manager: sd.name ? sd.name : '-',
                store_contact: sd.phone_no ? sd.phone_no : '-',
                store_username: sd.user_name ? sd.user_name : '-',
                store_status: sd?.status ? sd.status : false,
                store_password: sd.password ? sd.password : '-',
                store_ofc_id: sd.ofc_unnique_number,//sd.ofc_id,
                store_zone_id: sd.zone_unique_id,
                outlet_id: sd?.phygital_store_id || sd?.dunzo_store_id || sd?.zomato_store_id ? [{ name: 'Phygital', id: sd?.phygital_store_id ? sd?.phygital_store_id : 'NA' }, { name: 'Dunzo', id: sd?.dunzo_store_id ? sd?.dunzo_store_id : 'NA' }, { name: 'Zomato', id: sd?.zomato_store_id ? sd?.zomato_store_id : 'NA' }] : [],

            }
        })
        this.setState({ Storelist: storeListDataTobeDisplayed, apiLoading: false, pagination: { ...this.state.pagination, TotalStoreCount: data?.meta?.total_count ? data?.meta?.total_count : 40 } });
    }
    //====================================================================================================
    handelGetOfcData = async (data: any) => {
        let ofcListDataTobeDisplayed = data.data.map((sd: any,index:any) => {
            return {
                id: sd.id,
                sno: (index + 1),   
                ofc_unnique_number: sd.ofc_unnique_number ? sd.ofc_unnique_number : '-',
                ofcName: sd.clusture_name ? sd.clusture_name : '-',
                ofcDescription: sd.clusture_discription ? sd.clusture_discription : '-',
                ofcZoneId: sd.zone_unique_id,//sd.zone_id,
                ofcStatus: sd.status ? true : false
            }
        })
        this.setState({
            OfcList: ofcListDataTobeDisplayed, apiLoading: false
            //  ofcIds: data.data 
        })
    }
    //====================================================================================================
    handelGetZoneData = async (data: any) => {
        let zoneListDataTobeDisplayed = data.data.map((sd: any,index:any) => {
            return {
                zoneId: sd.id,
        sno: (index + 1),
                zone_unique_id: sd.zone_unique_id ? sd.zone_unique_id : '-',
                zoneName: sd.zone_name ? sd.zone_name : '-',
                zoneDescription: sd.zone_description ? sd.zone_description : '-',
                zoneStatus: sd.status ? true : false
            }
        })
        this.setState({
            ZoneList: zoneListDataTobeDisplayed,
            zoneIds: data.data,
            apiLoading: false
        });

    }
    //====================================================================================================
    //call add Store API
    _handelAddStoreData = async (data: any) => {
        console.log(data.data);
        if (data.data) {
            // this.toast(data.message)
            this.setState({ addModal: false })
            AntMessage.success(data.message)//antd
            this.onGetStoreByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize)
            // this.ongetStore();
            // this.componentDidMount();
        }
    }
    //====================================================================================================
    _handelAddOfcData = async (data: any) => {
        console.log(data.data);
        if (data.data) {
            this.setState({ addOfcModal: false })
            AntMessage.success(data.message)//antd
            this.ongetOfc();
            // this.componentDidMount();
        }
    }
    //====================================================================================================
    _handelAddZoneData = async (data: any) => {
        console.log(data.data);
        if (data.data) {
            this.setState({ addZoneModal: false })
            AntMessage.success(data.message)//antd
            this.ongetZone();
            // this.componentDidMount();
        }
    }
    //====================================================================================================
    _handelEditZoneData = async (data: any) => {
        console.log(data.data);
        if (data.data) {
            this.setState({ editZoneModal: false })
            // this.toast(data.message);
            AntMessage.success(data.message)//antd
            this.ongetZone();
            // this.componentDidMount();
        }
    }
    //====================================================================================================
    _handelEditOfcData = async (data: any) => {
        console.log(data.data);
        if (data.data) {
            this.setState({ editOfcModal: false })
            // this.toast(data.message)//swal
            AntMessage.success(data.message)//antd
            this.ongetOfc();
            // this.componentDidMount();
        }
    }
    //====================================================================================================
    _handelEditStoreData = async (data: any) => {
        console.log(data.data);
        if (data.data) {
            this.setState({ editModal: false })
            // this.toast(data.message)
            AntMessage.success(data.message)
            this.onGetStoreByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize)
            // this.ongetStore();
            // this.componentDidMount();
        }

    }
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    _handelGetStoreDataById = async (data: any) => {
        console.log(data);
        let APIResponse = data?.data;
        this.setState({ selectedStoreData: data?.data, modaLoader: false });//editModal: true,
        this.getAllOfcsRealtedtoZoneId(APIResponse?.zone_id);
        // console.log(this.formRef)
        let _data = {
            id: data.data?.id,
            storeId: APIResponse?.storeId,
            store_name: APIResponse?.store_name,
            address: APIResponse?.address,
            phone_no: APIResponse?.phone_no,
            manager: APIResponse?.name,
            username: APIResponse?.user_name,
            password: APIResponse?.password,
            ofc_id: APIResponse?.ofc_id ? APIResponse?.ofc_id : 0,
            zone_id: APIResponse?.zone_id ? APIResponse?.zone_id : 0,
            phygital_store_id: APIResponse?.phygital_store_id ? APIResponse?.phygital_store_id : "",
            dunzo_store_id: APIResponse?.dunzo_store_id ? APIResponse?.dunzo_store_id : "",
            zomato_store_id: APIResponse?.zomato_store_id ? APIResponse?.zomato_store_id : ""
        }
        console.log(_data);

        //@ts-ignore
        this.formRef.current.setFieldsValue(_data);

    }
    //====================================================================================================
    _handelGetOfcDataById = async (data: any) => {
        console.log(data);
        let APIResponse = data?.data;
        this.setState({ selectedOfcData: APIResponse, modaLoader: false });
        let _data = {
            zone_id: APIResponse?.zone_id,
            ofc_id: APIResponse?.id,
            ofc_unnique_number: APIResponse?.ofc_unnique_number,
            ofc_name: APIResponse?.clusture_name,
            ofc_description: APIResponse?.clusture_discription,
        }
        //@ts-ignore
        this.formRef.current.setFieldsValue(_data)
    }

    //====================================================================================================
    _handelGetZoneDataById = async (data: any) => {
        console.log(data);
        let APIResponse = data?.data;
        this.setState({ selectedZoneData: APIResponse, modaLoader: false });
        let _data = {
            zone_id: APIResponse?.id,
            zone_unique_id: APIResponse?.zone_unique_id,
            zone_name: APIResponse?.zone_name,
            zone_description: APIResponse?.zone_description,
        }
        //@ts-ignore
        this.formRef.current.setFieldsValue(_data)
    }
    //====================================================================================================
    // _handelEditStoreTimings
    _handelEditStoreTimings = async (data: any) => {
        console.log(data);
        if (data.data) {
            AntMessage.success('Store Timings updated successfully!')
            this.setState({ editOfcModal: false, selectedCheckBoxData: [] })
        }

    }
    //====================================================================================================
    // _handelMultipleEditStoreTimings
    _handelMultipleEditStoreTimings = async (data: any) => {
        console.log(data);
        if (data.status == 200) {
            AntMessage.success(data.messages)
            this.setState({ addModal: false })
        }

    }
    //====================================================================================================
    // _handelgetAllOfcRealtedToZoneSuccessResponse
    _handelgetAllOfcRealtedToZoneSuccessResponse = async (data: any) => {
        console.log(data);
        this.setState({ ofcIds: data.data })
        //@ts-ignore
        // this.formRef.current?.setFieldsValue({ ofc_id:data.data})
    }
    //====================================================================================================
    //====================================================================================================
    // _handelGetStoreClosingTimeSessResponse
    _handelGetStoreClosingTimeSessResponse = async (data: any) => {
        // console.log(data);
        this.setState({ closingStoreTimings: data.data })
        let date = []
        date.push(moment(data.data[0].start_date, 'YYYY/MM/DD'))
        date.push(moment(data.data[0].end_date, 'YYYY/MM/DD'))
        // console.log(date);

        //@ts-ignore
        this.formRef.current?.setFieldsValue({ datePicker: date })
        this.setState({
            storeTimingsData:
            {
                ...this.state.storeTimingsData,
                StartTime: data.data[0].start_time,
                EndTime: data.data[0].end_time,
            }
        })

        this.setState({
            storeTimingsData:
            {
                ...this.state.storeTimingsData,
                startDate: data.data[0].start_date,
                EndDate: data.data[0].start_date,

            }
        })
        console.log(this.state);




    }
    //====================================================================================================
    // _handelDeleteStoreClosingTimeSuccessResponse
    _handelDeleteStoreClosingTimeSuccessResponse = async (data: any) => {
        console.log(data);;
        AntMessage.success(data.message)
        this.setState({ editModal: false })
        this.ongetStore();
        // this.componentDidMount();

    }
    //====================================================================================================
    // _handelImportStoreCsvSuccessResponse
    _handelImportStoreCsvSuccessResponse = async (data: any) => {
        if (data.data) {
            this.setState({ apiLoading: false })
            AntMessage.success('File Imported Successfully ');
            this.onGetStoreByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize);
            // this.ongetStore();
            // this.componentDidMount();
        }

    }
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //_handelStoreStatusSuccessResponse
    _handelStoreStatusSuccessResponse = async (data: any) => {
        if (data.data) {
            this.setState({ apiLoading: false })
            AntMessage.success("Status Updated Successfully ");
            this.onGetStoreByPagination(this.state?.pagination?.currentPage, this.state?.pagination?.pageSize);
            // this.ongetStore();
            // this.componentDidMount();
        }
    }
    //====================================================================================================
    //_handelOfcStatusSuccessResponse
    _handelOfcStatusSuccessResponse = async (data: any) => {
        if (data) {
            this.setState({ apiLoading: false })
            AntMessage.success(data.message);
            this.ongetOfc();
            // this.componentDidMount();
        }
    }

    //====================================================================================================
    // _handelZoneStatusSuccessResponse
    _handelZoneStatusSuccessResponse = async (data: any) => {
        if (data) {
            this.setState({ apiLoading: false })
            AntMessage.success(data.message);
            this.ongetZone();
            // this.componentDidMount();
        }
    }
    //====================================================================================================
    // _handelStoreProfileUpdateSuccessResponse
    _handelStoreProfileUpdateSuccessResponse = async (data: any) => {
        if (data) {
            AntMessage.success(data.message)
        }
    }
    //====================================================================================================
    // _handeladdItemAvailiblityInformationErrorResponse
    _handeladdItemAvailiblityInformationSuccessResponse = async (data: any) => {
        // console.log(data);
        if (data.data) {
            AntMessage.success('Item updated');
            this.setState({ addOfcModal: false })
        }

    }
    //====================================================================================================
    // _handelupdateItemUnavailiblityInformationErrorResponse
    _handelupdateItemUnavailiblityInformationSuccessResponse = async (data: any) => {
        console.log(data);
        if (data.data) {
            AntMessage.success('Item updated')
        }
        this.setState({ editZoneModal: false })
    }
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================








    //====================================================================================================
    //ERROR BLOCK
    //====================================================================================================
    handelErrorAddStoreData = async (data: any) => {
        this.setState({ Errors: { addStoreError: data.error } });
        console.log(this.state);

    }
    //====================================================================================================
    // _handelEditStoreTimingsError
    _handelEditStoreTimingsError = async (data: any) => {
        AntMessage.error(data.error)

    }
    //====================================================================================================
    // _handelMultipleEditStoreTimingsError
    _handelMultipleEditStoreTimingsError = async (data: any) => {
        AntMessage.error(data.error)
    }
    //====================================================================================================
    //====================================================================================================
    // _handelDeleteStoreClosingTimeErrorResponse
    _handelDeleteStoreClosingTimeErrorResponse = async (data: any) => {
        console.log(data);
    }
    //====================================================================================================
    //====================================================================================================
    // _handelStoreSearchErrorResponse
    _handelStoreSearchErrorResponse = async (data: any) => {
        this.setState({ apiLoading: false });
        AntMessage.error('No data found')
    }
    //====================================================================================================
    // _handelStoreStatusSuccessResponse
    _handelStoreStatusErrorResponse = async (data: any) => {
        this.setState({ apiLoading: false })
        AntMessage.error('Status not Updated Successfully')
    }
    //====================================================================================================
    // _handelOfcStatusSuccessResponse
    _handelOfcStatusErrorResponse = async (data: any) => {
        this.setState({ apiLoading: false })
        AntMessage.error('Status not Updated Successfully')
    }
    //====================================================================================================
    // _handelZoneStatusSuccessResponse
    _handelZoneStatusErrorResponse = async (data: any) => {
        this.setState({ apiLoading: false })
        AntMessage.error('Status not Updated Successfully')
    }
    //====================================================================================================
    _handelStoreProfileUpdateErrorResponse = async (data: any) => {
        AntMessage.error('Something went wrong')
    }
    //====================================================================================================
    // _handeladdItemAvailiblityInformationErrorResponse
    _handeladdItemAvailiblityInformationErrorResponse = async (data: any) => {
        console.log(data);

    }
    //====================================================================================================
    // _handelupdateItemUnavailiblityInformationErrorResponse
    _handelupdateItemUnavailiblityInformationErrorResponse = async (data: any) => {
        console.log(data);
        // if (data.data) {
        //     AntMessage.success('Item updated')
        // }

    }
    //====================================================================================================
    //====================================================================================================
    // _handelStoreStatuserrorResponse
    _handelStoreStatuserrorResponse = async (data: any) => {
        console.log(data.errors.ofc);
        this.setState({ apiLoading: false })
        if (data?.errors.ofc[0] == "must exist") { AntMessage.error("Store must link with Ofc") }
        if (data?.errors.zone[0] == "must exist") { AntMessage.error("Store must link with Zone") }
    }
    //====================================================================================================

    //====================================================================================================
    //====================================================================================================
    //====================================================================================================
    //====================================================================================================


    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        },
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible,
    };

    btnExampleProps = {
        onPress: () => this.doButtonPressed(),
    };

    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }

    // Customizable Area Start
    // Customizable Area End
}
    // //===================================== API CALLING BLOCK ============================================
