// Customizable Area Start
import React, { createRef } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import { Typography, Input, Row, Col, Button, Divider, Checkbox, Switch, Space, Avatar, Dropdown, Menu, Modal, Form, Select, Popconfirm, Spin } from 'antd';
import { EditFilled, DeleteFilled, CloseCircleFilled, MinusCircleOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { FormInstance } from "antd/es/form";
//@ts-ignore
import GlobalLoader from "../../../web/src/GlobalLoader"
import './RolesPermission.css'
const { Title, Paragraph, Text, Link } = Typography;
import RolesPermissionController, { Props, configJSON } from "./RolesPermissions2Controller";
import moment from "moment";
import { message as AntMessage } from "antd";

export default class Roles extends RolesPermissionController {
    timer: any = null;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            editRoleModal: false,
            addRoleModal: false,
            rolePermissionData: [],
            selectedRoleWithPermission: {},
            checkBoxPermissionSelectedValue: [],
            formAttributes: [],
            selectedCheckBoxData: [],
            deletedPermissionArray: [],
            dataLoader: false,
        };
        document.title = `Roles & Permission | 24SevenDashboard`

        // Customizable Area End
    }
    formRef = createRef<FormInstance>();

    onFinish = (values: any) => {
        let permissions_attributes: any = [];
        // if(values.attributes){
        values?.attributes?.map((d: any) => {
            let create_action = false;
            let delete_action = false;
            let update_action = false;
            let read_action = false;
            d.checkPermissions.map((p: string) => {

                if (p === 'create') {
                    create_action = true

                } else if (p === 'update') {
                    update_action = true;

                }
                else if (p === 'read') {
                    read_action = true;

                }
                else if (p === 'delete') {
                    delete_action = true
                }

            }) // 2nd loop end


            let obj = {
                access_module_id: d.access_module_permissions,
                create_action: create_action,
                delete_action: delete_action,
                update_action: update_action,
                read_action: read_action
            }
            // console.log(obj);


            permissions_attributes.push(obj)

        })


        let requestPayload = {
            data: {
                name: values.Role_name,
                status: "active",
                permissions_attributes: permissions_attributes
            }
        }

        // console.log(requestPayload);
        this.addRoleData(requestPayload)
        // }
        // else{
        //     AntMessage.error(`Permission Can't be Blank`)
        // }

    };
    // ====================================
    onUpdateRolesAndPermission = (values: any) => {
        console.log("Success:", values);
        let permissions_attributes: any = [];

        values.attributes.map((d: any, index: any) => {
            let create_action = false;
            let delete_action = false;
            let update_action = false;
            let read_action = false;
            this.state.checkBoxPermissionSelectedValue[index].map((p: string) => {

                if (p === 'create') {
                    create_action = true

                } else if (p === 'update') {
                    update_action = true;
                }
                else if (p === 'read') {
                    read_action = true;

                }
                else if (p === 'delete') {
                    delete_action = true
                }

            }) // 2nd loop end


            if (d.accessModuleId != undefined) {
                let obj = {
                    id: d.accessModuleId,
                    access_module_id: d.access_module_permissions,
                    create_action: create_action,
                    delete_action: delete_action,
                    update_action: update_action,
                    read_action: read_action
                }
                // console.log(obj);
                permissions_attributes.push(obj)
            } else {
                let obj = {
                    access_module_id: d.access_module_permissions,
                    create_action: create_action,
                    delete_action: delete_action,
                    update_action: update_action,
                    read_action: read_action
                }
                // console.log(obj);
                permissions_attributes.push(obj)
            }
        })

        let sortedPermissionAttributesArray = permissions_attributes.sort((a: any, b: any) => {
            return a.access_module_id - b.access_module_id;
        })


        let requestPayload = {
            data: {
                name: values.Role_name,
                status: "active",
                permissions_attributes: sortedPermissionAttributesArray,//permissions_attributes
            }
        }
        // console.log(requestPayload);
        this.editRolesAndPermissions(values.id, requestPayload)

    };
    onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    onReset = () => {
        this.formRef.current!.resetFields();
    };

    handelOnChange(checkedValues: any, index: any) {
        // console.log(checkedValues, index);

        let blankpermissionArray = [];
        blankpermissionArray = this.state.checkBoxPermissionSelectedValue;

        blankpermissionArray[index] = checkedValues
        this.setState({ checkBoxPermissionSelectedValue: blankpermissionArray })
    }

    removeParticularRole = (index: any, field: any) => {
        // console.log(index);
        let accessModuleDBId: any = []
        this.state?.selectedRoleWithPermission?.modules.map((d: any) => {
            accessModuleDBId.push(d.id);
        })

        let blankpermissionArray = [];
        blankpermissionArray = this.state.checkBoxPermissionSelectedValue;
        blankpermissionArray.splice(index, 1);
        this.setState({ checkBoxPermissionSelectedValue: blankpermissionArray, deletedPermissionArray: accessModuleDBId })

        if (accessModuleDBId[field.key] !== undefined) {
            this.deleteParticularRoleById(accessModuleDBId[field.key])
        }
    }


    handelStatusChange = (e: any, itemId: any) => {
        let requestData = {
            data: {
                status: e ? "active" : "inactive"
            }
        }
        this.updateStatus(requestData, itemId)
    };

    getRolesAndPermissionDataById = (itemId: any) => {
        this.setState({ editRoleModal: true })
        this.state.rolePermissionData.filter((d: any) => {
            if (d.id == itemId) {
                this.setState({ selectedRoleWithPermission: d }, () => {
                    console.log("Selected Role", d);
                    this.updateValuesinFormList();
                })
            }
        })

    };


    updateValuesinFormList = () => {
        setTimeout(() => {
            this.formRef.current?.setFieldsValue({ Role_name: this.state?.selectedRoleWithPermission.name });
            this.formRef.current?.setFieldsValue({ id: this.state?.selectedRoleWithPermission.id });

            if (this.state?.selectedRoleWithPermission?.modules.length != 0) {

                let permissionArray: any = [];
                let attributes: any = []
                let accessModuleID: any;
                let accessModuleDBId: any;
                this.state?.selectedRoleWithPermission?.modules.map((d: any) => {
                    let p1 = [];
                    let permissionObj = d.accessPermission;
                    if (d.accessPermission.create === true) {
                        p1.push('create')
                    } if (d.accessPermission.delete === true) {
                        p1.push('delete')
                    } if (d.accessPermission.read === true) {
                        p1.push('read')
                    } if (d.accessPermission.update === true) {
                        p1.push('update')
                    }
                    permissionArray.push(p1);
                    accessModuleDBId = d.id;
                    if (d.name === "Dashboard") { accessModuleID = 1; }
                    if (d.name === "Catalogue") { accessModuleID = 2; }
                    if (d.name === "Roles and Permission") { accessModuleID = 3; }
                    if (d.name === "Store") { accessModuleID = 4; }
                    if (d.name === "People") { accessModuleID = 5; }
                    if (d.name === "Store Timings") { accessModuleID = 6; }
                    if (d.name === "Inventory") { accessModuleID = 7; }
                    if (d.name === "Reporting") { accessModuleID = 8; }
                    if (d.name === "Store Dashboard") { accessModuleID = 9; }

                    attributes.push(
                        {
                            access_module_permissions: accessModuleID,
                            checkPermissions: p1,
                            accessModuleId: accessModuleDBId
                        }
                    )

                })

                this.setState({ checkBoxPermissionSelectedValue: permissionArray })
                this.setState({ formAttributes: attributes })

                this.formRef.current?.setFieldsValue({
                    attributes: attributes
                })



            } else {
                this.formRef.current?.setFieldsValue({ attributes: [] })
                this.setState({ checkBoxPermissionSelectedValue: [] })
            }
        }, 1)
    }

    deleteRoleAndPermission = (itemId: any) => {
        this.deleteRoleById(itemId);
    }
    searchRolesWithPermissions = async (e: any) => {
        clearTimeout(this.timer);
        let value = e.target.value;
        this.timer = setTimeout(() => {
            this.searchRolesWithPermissionDetails(value)
        }, 1000)
    }
    //========================================== RENDER  =======================================================================
    render() {
        // const antIcon = <SyncOutlined translate={{}} style={{ fontSize: '25px', color: 'black', marginTop: '10%' }} spin />;
        const antIcon = <GlobalLoader />;

        let permissions: any;
        let decodedString = atob(localStorage.getItem('permissions') || '{}');
        //@ts-ignore
        JSON.parse(decodedString).filter((d) => {

            if (d.attributes.access_module.name == "Roles and Permission") {
                permissions = d.attributes
                return
            }
        });

        console.log(permissions, "permissions");

        const permissionValues = [
            { name: "Create", value: "create", id: 1 },
            { name: "Update", value: "update", id: 2 },
            { name: "Read", value: "read", id: 3 },
            { name: "Delete", value: "delete", id: 4 }
        ]
        let dropdownValues = [
            { name: "Dashboard", id: 1 },
            { name: "Catalogue", id: 2 },
            { name: "Roles and Permission", id: 3 },
            { name: "Store", id: 4 },
            { name: "People", id: 5 },
            { name: "Store Timings", id: 6 },
            { name: "Inventory", id: 7 },
            { name: "Reporting", id: 8 },
            { name: "Store Dashboard", id: 9 },
        ]

        // let dddValue: any = [
        //      ['create','update'],,[],['create','delete'],
        // ]
        const updateDropDownData = (id: any) => {
            // console.log(id);

            dropdownValues = dropdownValues.filter((d) => {
                if (id !== d.id) {
                    return d
                }
                // else {   
                //      dropdownValues.push(d) 
                // }
            })
            // console.log(dropdownValues);

        }

        const { dataLoader, rolePermissionData, selectedRoleWithPermission, checkBoxPermissionSelectedValue, formAttributes } = this.state
        return (
            <>
                {/*  MAIN CONTENT */}
                <Row className="role-res-main rolePermissionSection">
                    <Col span={24} className="content-container">
                        <Row className="header flex-row">
                            <Col className="flex-col" span={15} style={{ textAlign: 'left' }}>
                                <Title className="title">Roles {"&"} Permission</Title>
                            </Col>

                            <Col className="flex-col" span={9} style={{ textAlign: "end" }}>
                                <Space size="middle">
                                    <Input placeholder="Search" className="search-box" onChange={(e) => { this.searchRolesWithPermissions(e) }} />
                                    {permissions?.create_action ?
                                        <Button type="primary" size="large" className="add-btn" onClick={() => {
                                            this.setState({ addRoleModal: true })
                                        }} >ADD ROLE</Button>
                                        : null}

                                </Space>
                            </Col>
                        </Row>
                        <Col className="rolePermissionTable ">
                            <Row className="rolePermissionTableHead flex-row" >
                                <Col className="flex-col" span={4}>
                                    <Text strong>Role name</Text>
                                </Col>
                                <Col className="flex-col" span={14}>
                                    <Text strong>Module Access</Text>
                                </Col>
                                <Col className="flex-col" span={3}>
                                    <Text strong>Status</Text>
                                </Col>
                                <Col className="flex-col" span={3}>
                                    <Text strong>Action</Text>
                                </Col>
                            </Row>

                            {
                                !dataLoader ?
                                    rolePermissionData.length > 0 ?
                                        rolePermissionData.map((item: any) => (
                                            <>
                                                <Divider style={{ margin: "0px" }} />
                                                <Row key={item.name} style={{ background: "#fff", padding: "10px" }} className="main-content flex-row relative">
                                                    <Col className="flex-col pt role-title" span={4}>
                                                        <Text className="role-title-text" strong >{item.name}</Text>
                                                    </Col>
                                                    <Col className="flex-col" span={14}>

                                                        {item.modules.length > 0 ?
                                                            item.modules.map((val: any) => (
                                                                <Row className="flex-module-row" key={val.name}>
                                                                    <Col className="flex-module-col" span={5}><Text className="module-name">{val.name}</Text></Col>
                                                                    <Col className="flex-module-col" span={4}><Checkbox checked={val.accessPermission.create} className="permission" style={{ pointerEvents: 'none' }}>Create</Checkbox></Col>
                                                                    <Col className="flex-module-col" span={4}><Checkbox checked={val.accessPermission.update} className="permission" style={{ pointerEvents: 'none' }}>Update</Checkbox></Col>
                                                                    <Col className="flex-module-col" span={4}><Checkbox checked={val.accessPermission.read} className="permission" style={{ pointerEvents: 'none' }}>Read</Checkbox></Col>
                                                                    <Col className="flex-module-col" span={4}><Checkbox checked={val.accessPermission.delete} className="permission" style={{ pointerEvents: 'none' }}>Delete</Checkbox></Col>
                                                                </Row>
                                                            )) : <div className="noPermission">No Permissions for this role</div>
                                                        }

                                                    </Col>
                                                    <Col span={6} className="pt actionPos">
                                                        <Row className="flex-row">
                                                            <Col span={12} className="action-col">
                                                                <Space>
                                                                    {/* <Text className="act-status">Active</Text> */}
                                                                    <Switch
                                                                        defaultChecked={item.status}
                                                                        checkedChildren={'Active'}
                                                                        unCheckedChildren={'Inactive'}
                                                                        size="small"
                                                                        onChange={(e) => { this.handelStatusChange(e, item.id) }} className="status-switch" />
                                                                </Space>
                                                            </Col>
                                                            <Col span={10} className="action-col actionBtnBox ">
                                                                <Space size="large">
                                                                    {permissions?.update_action ?
                                                                        <Avatar icon={<EditFilled translate onClick={() => {
                                                                            this.getRolesAndPermissionDataById(item.id)
                                                                            // this.updateValuesinFormList();
                                                                        }} />} size="small" className="option-btn" />
                                                                        : null}
                                                                    {permissions?.delete_action ?
                                                                        <Popconfirm
                                                                            title="Are you sure to delete this role ?"
                                                                            onConfirm={() => {
                                                                                this.deleteRoleAndPermission(item.id);
                                                                            }}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <Avatar icon={<DeleteFilled translate={{}} />} size="small" className="option-btn deleteBtn" />
                                                                        </Popconfirm>

                                                                        : null}
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                        )) : <div className="no-data">No Roles Found !</div>
                                    :
                                    <Spin indicator={antIcon} style={{ display: "flex", justifyContent: "center" }} />
                            }
                        </Col>
                    </Col>
                </Row>

                {/* ADD ROLE MODAL  */}

                <Modal
                    title={null}
                    centered
                    visible={this.state.addRoleModal}
                    //   onOk={() => this.setModal2Visible(false)}
                    width="55vw"
                    onCancel={() => {
                        this.setState({ addRoleModal: false });
                        this.onReset();

                    }}
                    footer={null}
                    closeIcon={
                        <CloseCircleFilled
                            style={{ color: "#000", fontSize: "20px" }}
                            translate={{}}
                        />
                    }

                >
                    <div className="role-add-modal modal-body">
                        <Form
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            ref={this.formRef}
                            name="content-ref"
                            autoComplete="off"
                        >
                            <div className="header" >
                                <h1 className="mb-0 modal-heading">Add Role</h1>
                                <p className="mb-0 modal-subtext">Add role {"&"} permission details</p>
                            </div>
                            <Row gutter={30}>
                                <Col span={15}>
                                    <Form.Item name="Role_name"
                                        label='Role name'
                                        rules={[{ required: true, message: 'Role name is required' }]}>
                                        <Input
                                            placeholder={'Enter Role'}
                                            className="modal-role-text" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>

                                </Col>
                            </Row>

                            <div className="permission-block">
                                <Form.List name="attributes">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Col key={`${index}${field.key}`} span={18} >
                                                    <Space align="start" className="role-row">
                                                        <Form.Item
                                                            key={`${index}${field.key}`}
                                                            noStyle
                                                            shouldUpdate={(prevValues, curValues) =>
                                                                prevValues.area !== curValues.area || prevValues.permissions !== curValues.permissions
                                                            }
                                                        >
                                                            {() => (
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, 'access_module_permissions']}
                                                                    //@ts-ignore
                                                                    fieldKey={[field.fieldKey, 'permissions']}
                                                                    rules={[{ required: true, message: 'Access Module is required' }]}
                                                                >
                                                                    <Select
                                                                        placeholder={'Select Permission'}
                                                                        className="role-col"
                                                                        style={{ width: 230 }} onChange={(value) => {
                                                                            updateDropDownData(value)
                                                                        }} >
                                                                        {
                                                                            dropdownValues.map((item: any, index: any) => (
                                                                                <Select.Option defaultValue={item.name} value={item.id} key={item.id}> {item.name}</Select.Option>
                                                                            ))
                                                                        }


                                                                    </Select>
                                                                </Form.Item>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item
                                                            valuePropName="checked"
                                                            {...field}
                                                            name={[field.name, 'checkPermissions']}
                                                            //@ts-ignore
                                                            fieldKey={[field.fieldKey, 'checkPermissions']}
                                                            rules={[{ required: true, message: 'Permission is required' }]}>
                                                            <Checkbox.Group className="role-col">
                                                                <Col span={16} offset={1} style={{ display: "flex" }}>
                                                                    <Space>
                                                                        {permissionValues.map((item: any, index: any) => (
                                                                            <Col span={6}> <Checkbox name={item.name} value={item.value} >{item.name}</Checkbox></Col>
                                                                        ))}
                                                                        <MinusCircleOutlined span={6} translate={{}} onClick={() => remove(field.name)} />

                                                                    </Space>
                                                                </Col>
                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                    </Space>
                                                </Col>
                                            ))}
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="addPermissionButton"
                                                onClick={() => add()}
                                            >
                                                ADD PERMISSION
                                            </Button>
                                            {/* <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined translate={{}} />}>Add Permissions</Button>
                                        </Form.Item> */}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            {/* ))} */}
                            <Form.Item>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    className="modal-save-btn"
                                >
                                    Save
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </Modal>





                {/* EDIT ROLE MODAL  */}

                <Modal
                    title={null}
                    centered
                    visible={this.state.editRoleModal}
                    //   onOk={() => this.setModal2Visible(false)}
                    width="55vw"
                    onCancel={() => {
                        this.setState({ editRoleModal: false })
                    }}
                    footer={null}
                    closeIcon={
                        <CloseCircleFilled
                            translate={{}}
                            style={{ color: "#000", fontSize: "20px" }} />
                    }

                >
                    <div className="role-edit-modal modal-body">

                        <Spin
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            spinning={dataLoader}
                            indicator={<GlobalLoader />} >

                            <Form
                                onFinish={this.onUpdateRolesAndPermission}
                                onFinishFailed={this.onFinishFailed}
                                ref={this.formRef}
                                name="content-ref"
                                autoComplete="off"
                            >

                                <div className="header" >
                                    <h1 className="mb-0 modal-heading">Edit Role</h1>
                                    <p className="mb-0 modal-subtext">Edit role {"&"} permission details</p>
                                </div>

                                <Row gutter={30} >
                                    <Col span={10}>
                                        <Form.Item name="Role_name" rules={[{ required: true, message: 'Role name is required' }]}>
                                            <Input className="modal-role-text " size="large" />
                                        </Form.Item>
                                        <Form.Item name="id" noStyle>
                                            <Input type="hidden" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>

                                    </Col>
                                </Row>
                                {/* {roles.map((item,index) => ( */}

                                <div className="permission-block">
                                    <Form.List name="attributes" >
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Col key={`${index}${field.key}`} span={18}>
                                                        <Space align="start" className="role-row">
                                                            <Form.Item
                                                                key={`${index}${field.key}`}
                                                                noStyle
                                                                shouldUpdate={(prevValues, curValues) =>
                                                                    prevValues.area !== curValues.area || prevValues.permissions !== curValues.permissions
                                                                }
                                                            >
                                                                {() => (
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, 'access_module_permissions']}
                                                                        //@ts-ignore
                                                                        fieldKey={[field.fieldKey, 'permissions']}
                                                                        rules={[{ required: true, message: 'Access Module is required' }]}
                                                                    >
                                                                        <Select
                                                                            placeholder={'Select Permission'}
                                                                            className="role-col"
                                                                            style={{ width: 230 }} onChange={(value) => {
                                                                                updateDropDownData(value)
                                                                            }} >
                                                                            {
                                                                                dropdownValues.map((item: any, index: any) => (
                                                                                    <Select.Option defaultValue={item.name} value={item.id} key={item.id}> {item.name}</Select.Option>
                                                                                ))
                                                                            }


                                                                        </Select>
                                                                    </Form.Item>
                                                                )}
                                                            </Form.Item>
                                                            <Form.Item
                                                                valuePropName="checked"
                                                                {...field}
                                                                name={[field.name, 'checkPermissions']}
                                                                //@ts-ignore
                                                                fieldKey={[field.fieldKey, 'checkPermissions']}
                                                            >{/*   rules={[{ required: true, message: 'Permission is required' }]} */}
                                                                {/* {console.log(this.state?.checkBoxPermissionSelectedValue[index])} */}
                                                                <Checkbox.Group
                                                                    className="role-col"
                                                                    value={this.state.checkBoxPermissionSelectedValue[index]}
                                                                    onChange={(e) => { this.handelOnChange(e, index) }}>
                                                                    <Col span={16} offset={1} style={{ display: "flex" }}>
                                                                        <Space>
                                                                            {permissionValues.map((item: any, index: any) => (
                                                                                <Col span={6}> <Checkbox name={item.name} value={item.value} >{item.name}</Checkbox></Col>
                                                                            ))}
                                                                            <MinusCircleOutlined span={6} translate={{}} onClick={() => {
                                                                                remove(field.name)
                                                                                this.removeParticularRole(index, field)
                                                                            }} />

                                                                        </Space>
                                                                    </Col>
                                                                </Checkbox.Group>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'accessModuleId']}
                                                                //@ts-ignore
                                                                fieldKey={[field.fieldKey, 'accessModuleId']}
                                                                noStyle
                                                            >
                                                                <Input type="hidden" />
                                                            </Form.Item>
                                                        </Space>
                                                    </Col>
                                                ))}
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    className="addPermissionButton"
                                                    onClick={() => add()}
                                                >
                                                    ADD PERMISSION
                                                </Button>
                                                {/* <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined translate={{}} />}>Add Permissions</Button>
                                        </Form.Item> */}
                                            </>
                                        )}
                                    </Form.List>
                                </div>
                                {/* ))} */}

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        className="modal-save-btn"
                                    >
                                        Save
                                    </Button>
                                </Form.Item>

                            </Form>
                        </Spin>
                    </div>
                </Modal>

            </>

        );
    }
}
