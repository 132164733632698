// Customizable Area Start
import React, { createRef, useState } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  Table,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Form,
  TimePicker,
  DatePicker,
  Radio,
  Space,
  Avatar,
  Tabs,
  Layout,
  Menu,
  Card,
  Row,
  Col,
  Typography,
  Dropdown,
  message,
  Badge
} from 'antd';

import './index.css';
import {
  BellFilled,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  FileDoneOutlined,
  FormOutlined,
  KeyOutlined
} from '@ant-design/icons';
import Settings from '../../../../blocks/Settings2/src/Settings.web';
import NotificationsList from '../../../../blocks/Notifications/src/Notifications.web';
import zIndex from '@material-ui/core/styles/zIndex';
const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const handelLogout = () => {
  message.success(`Logout Successfully`);
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = '/';
  localStorage.removeItem('notification');
};

const onCliccck = ({ key }) => {
  // message.info(`Click on item ${key}`);
  if (key == 5) {
    handelLogout();
  }
};

export const Home = props => {
  const postLoginComponents = [
    ...props.SideBarComponents,
    ...props.postLoginComponents
  ];

  const history = createBrowserHistory();

  const menu = (
    <Menu onClick={onCliccck}>
      <Menu.Item key="1">
        <Avatar
          size={24}
          icon={<UserOutlined />}
          style={{ backgroundColor: '#000000' }}
        >
          Profile
        </Avatar>
        <span
          className="ml-5"
          onClick={() => {
            setModalType('Profile');
            setprofileBioModal(true);
          }}
        >
          Profile Info
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <Avatar
          size={24}
          icon={<FileDoneOutlined />}
          style={{ backgroundColor: '#000000' }}
        >
          T&C
        </Avatar>
        <span className="ml-5">
          <Link to="/term-and-condition" className="linkColor">
            Term & Conditions
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item key="3">
        <Avatar
          size={24}
          icon={<FormOutlined />}
          style={{ backgroundColor: '#000000' }}
        >
          Privarcy
        </Avatar>
        <span className="ml-5">
          <Link to="/privacy-policy" className="linkColor">
            Privarcy Policy
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item key="4">
        <Avatar
          size={24}
          icon={<KeyOutlined />}
          style={{ backgroundColor: '#000000' }}
        >
          Password
        </Avatar>
        <span
          className="ml-5"
          onClick={() => {
            setModalType('ChangePassword');
            setChangePasswordModal(true);
          }}
        >
          Change Password
        </span>
      </Menu.Item>
      <Menu.Item key="5">
        <Avatar
          size={24}
          icon={<LogoutOutlined />}
          style={{ backgroundColor: '#000000' }}
        >
          Logout
        </Avatar>
        <span className="ml-5">Logout</span>
      </Menu.Item>
    </Menu>
  );

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [notificationModal, setnotificationModal] = useState(false);
  const [profileBioModal, setprofileBioModal] = useState(false);
  const [modaltype, setModalType] = useState('');
  const [Image, setImage] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [SideBarComponents, setSideBarComponents] = useState(
    props.SideBarComponents
  );
  const [collapsed, setcollapsed] = useState(false);

  return (
    <BrowserRouter history={history}>
      <Layout className="home">
        <Header className={`home-header ${props.notifyAlert && 'alert-box'}`}>
          <div className="logo">
            <img
              style={{
                width: 'inherit',
                height: 'inherit',
                border: '1px solid #ccc',
                borderRadius: '0%'
              }}
              src="https://www.24-seven.in/img/logo/0001.jpg"
            />
          </div>
          <Menu
            className="nnn"
            theme="light"
            mode="horizontal"
            selectable={false}
            // defaultSelectedKeys={['2']}
          >
            <Menu.Item className="nnnn" key="1">
              <Badge count={notificationCount}>
                <Avatar
                  icon={<BellFilled className="bell-icon" />}
                  size={32}
                  onClick={() => {
                    setModalType('Notification');
                    setnotificationModal(true);
                  }}
                  className="home-notification"
                />
              </Badge>
            </Menu.Item>
            <Menu.Item className="nnnn" key="2">
              {' '}
              {Image !== null ? (
                <Avatar src={Image} size={32} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}
            </Menu.Item>
            <Menu.Item className="nnnn" key="3">
              {' '}
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={e => e.preventDefault()}
                >
                  {localStorage.getItem('fullName') !== 'null'
                    ? localStorage.getItem('fullName')
                    : 'No Name'}{' '}
                  <DownOutlined />
                </a>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </Header>
        {/* <Header className={`home-header ${props.notifyAlert && 'alert-box'}`}>
          <Row>
            <Col key={1} span={10}>
              <div clasdName="logo">
                <img
                  style={{
                    width: '250px',
                    height: ' 50px',
                    border: '1px solid #ccc',
                    borderRadius: '0%'
                  }}
                  src="https://www.24-seven.in/img/logo/0001.jpg"
                />
              </div>
            </Col>

            <Col key={2} span={14} style={{ textAlign: 'end' }}>
              <Space size="middle">
                <Badge count={notificationCount}>
                  <Avatar
                    icon={<BellFilled className="bell-icon" />}
                    size={32}
                    onClick={() => {
                      setModalType('Notification');
                      setnotificationModal(true);
                    }}
                    className="home-notification"
                  />
                </Badge>
                {Image !== null ? (
                  <Avatar src={Image} size={32} />
                ) : (
                  <Avatar icon={<UserOutlined />} />
                )}

                <Dropdown
                  overlay={menu}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={e => e.preventDefault()}
                  >
                    {localStorage.getItem('fullName') !== 'null'
                      ? localStorage.getItem('fullName')
                      : 'No Name'}{' '}
                    <DownOutlined />
                  </a>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header> */}

        <NotificationsList
          openNotificationModal={notificationModal}
          handleCloseNotificationModal={setnotificationModal}
          openModal={props.notifyModal}
          setNotificationCount={setNotificationCount}
        />

        <Settings
          openChangePasswordModal={changePasswordModal}
          handleCloseChangePasswordModal={setChangePasswordModal}
          openProfileBioModal={profileBioModal}
          handleprofileBioModal={setprofileBioModal}
          modalType={modaltype}
          profileImage={setImage}
        />

        <Layout>
          <Sider
            id="custom-sider"
            // collapsible
            // collapsed={collapsed}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
              broken == true ? setcollapsed(true) : setcollapsed(false);
            }}
            onCollapse={(collapsed, type) => {
              // setcollapsed(true)
            }}
            className={`${props.notifyAlert && 'alert-box'}`}
          >
            <Menu
              theme="dark"
              style={{ backgroundColor: '#000000' }}
              mode="inline"
              defaultSelectedKeys={
                localStorage.getItem('dataIndex')
                  ? [localStorage.getItem('dataIndex')]
                  : ['0']
              }
              className="home-nav-list"
            >
              {SideBarComponents &&
                SideBarComponents.map((item, index) => (
                  <Menu.Item
                    key={index}
                    onClick={() => {
                      localStorage.setItem('dataIndex', index);
                    }}
                    style={{
                      borderStyle: 'none solid none none',
                      paddingLeft: '15px'
                    }}
                    className="home-nav-list-items"
                  >
                    <span className="radio-box" />
                    <Link to={item.path} className="home-nav-list-item-text">
                      {' '}
                      {item.name}
                    </Link>
                  </Menu.Item>
                ))}
            </Menu>
          </Sider>
          <Layout className={`site-layout ${props.notifyAlert && 'alert-box'}`}>
            <Content style={{ overflow: 'initial' }}>
              <Switch>
                {postLoginComponents &&
                  postLoginComponents.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.main}
                      history={history}
                    />
                  ))}
              </Switch>
            </Content>
          </Layout>
        </Layout>
        {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
      </Layout>
    </BrowserRouter>
  );
};
// Customizable Area End
