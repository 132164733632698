
import firebase from 'firebase';
import 'firebase/messaging';

export const initializeFirebase = () => {

    firebase.initializeApp({
        apiKey: "AIzaSyDqweJDIuyIEJtb06X25rAA1ZEfmyKuCck",
        authDomain: "seven-63598.firebaseapp.com",
        projectId: "seven-63598",
        storageBucket: "seven-63598.appspot.com",
        messagingSenderId: "246001294430",
        appId: "1:246001294430:web:880be52f95b56b53cee8cf",
        measurementId: "G-92WFRPX01L"
    });
    const messaging = firebase.messaging();
}






