Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions2";
exports.labelBodyText = "RolesPermissions2 Body";
exports.validationApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDelMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.ApiContentType = "application/json";
exports.btnExampleTitle = "CLICK ME";
exports.getRolesApiEndPoint = `/bx_block_roles_permissions/roles/role_list`;
exports.updateStatusRolesApiEndPoint = `/bx_block_roles_permissions/roles?id=`;
exports.AddRolesApiEndPoint = `/bx_block_roles_permissions/roles`;
exports.DeleteRoleByIdApiEndPoint = `bx_block_roles_permissions/roles?id=`;
exports.editRoleByIdApiEndPoint = `/bx_block_roles_permissions/roles?id=`;
exports.SearchRoleApiEndPoint = `bx_block_roles_permissions/roles/role_list?search=`;
exports.deleteRolePermissionApiEndPoint = `/bx_block_roles_permissions/roles/permissions/`;
// Customizable Area End
