Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.httpPutMethod = "PUT";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Notifications";
exports.labelBodyText = "Notifications Body";
exports.fetchNotification = `bx_block_notifications/notifications/get_user_notification`;
exports.termAndConditionAPIEndPoint = `/bx_block_admin/admin/get_terms_and_condition`;
exports.privacyPolicyAPIEndPoint = `/bx_block_admin/admin/get_privacy_policy`;
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
