// Customizable Area Start
import React, { createRef } from "react";
import "antd/dist/antd.css";
import {
    Table,
    Select,
    Button,
    Row,
    Col,
    Form,
    Typography,
    DatePicker,
    Empty,

} from "antd";
import { CloseCircleFilled, LeftOutlined } from "@ant-design/icons";
import "./ReportList.css";
import { FormInstance } from "antd/es/form";
import RequestManagementController, {
    Props,
    configJSON,
} from "../RequestManagementController";
import moment from 'moment'
import { message as AntMessage } from "antd";
const { baseURL } = require('../../../../framework/src/config.js')
const { Title, Paragraph, Text, Link } = Typography;


export default class ReportList extends RequestManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            cardArray: [
                { id: 1, title: "Sales Report", type: 'Orders fulfilled' },
                { id: 2, title: "Order Cancellation Reason Report", type: 'Rejected' },
                { id: 3, title: "Partial Order Acceptance Reason Report", type: 'Partially accepted' },
                { id: 4, title: "Inventory report", type: 'Orders fulfilled' },
                { id: 5, title: "Store Close and Open Logs", type: 'Orders fulfilled' },
                { id: 6, title: "Outlet Wise statistics", type: 'OutletStatistics' },
                { id: 7, title: "Current Stock Report", type: 'currentStock' },
                { id: 8, title: "Daily Grocery sales report ", type: 'DailyGrocery' },
            ],
            selectedRowKeys: [],
            selectedReport: {},
            FiltercardArray: [],
            ReportDataList: [],
            Storelist: [],
            apiLoading: false,
            selectedCategory: null,
            selectedCategoryName: 'Select Category',
            selectedStore: null,
            selectedStoreName: 'Select Store',
            CategoryList: [],
            to_date: null,
            from_date: null,
            pagination: {
                current: 1,
                pageSize: 10,
                Totalcount: 0
            },
            catalogueList: [],
            selectedCatalougeId: null,

        };
        document.title = ` Report List |  24SevenDashboard`

    }
    // Customizable Area End

    onSelectedRowKeysChange = (selectedRowKeys: any) => {
        this.setState({ selectedRowKeys });
    };

    setSelectedStoreId = async (item: any) => {
        let ParsedData = JSON.parse(item)
        this.setState({ selectedStore: ParsedData?.id, selectedStoreName: ParsedData?.store_name }, () => {
            if (this.state?.selectedStore !== null && this.state?.selectedCategory !== null) {
                this.updateCurrentStockReport(this.state?.selectedStore, this.state?.selectedCategory)
            } else {
                AntMessage.warning('Please Select Category')
            }
        })
    }

    getDailyGrocerySalesReportByStoreId = async (item: any) => {
        let ParsedData = JSON.parse(item);
        this.setState({ selectedStore: ParsedData?.id, selectedStoreName: ParsedData?.store_name }, () => {
            this.updateDailyGocercySalesReport(ParsedData?.id);
        })
    }

    getOutletStataictsByStoreId = async (item: any) => {
        let ParsedData = JSON.parse(item);
        this.setState({ selectedStore: ParsedData?.id, selectedStoreName: ParsedData?.store_name }, () => {
            this.updateOutletStataictsByStoreId(ParsedData?.id);
        })
    }

    getstoreCloasingOpeningTimeByStoreId = async (item: any) => {
        let ParsedData = JSON.parse(item);
        this.setState({ selectedStore: ParsedData?.id, selectedStoreName: ParsedData?.store_name }, () => {
            this.updateStoreClosingOpeningTimeByStoreId(ParsedData?.id);
        })
    }

    getInventoryItemUnavalablityByStoreId = async (item: any) => {
        let ParsedData = JSON.parse(item);
        this.setState({ selectedStore: ParsedData?.id, selectedStoreName: ParsedData?.store_name }, () => {
            this.updateInventoryItemUnavalablityByStoreId(ParsedData?.id);
        })
    }

    getSalesReportByStoreId = async (item: any) => {
        let ParsedData = JSON.parse(item);
        this.setState({ selectedStore: ParsedData?.id, selectedStoreName: ParsedData?.store_name }, () => {
            this.updateSalesReportByStoreId(ParsedData?.id);
        })
    }
    getReportByCatalouge = async (CATLOUGE_ID: any, currentReportDetails: any) => {
        this.setState({ apiLoading: true, selectedCatalougeId: CATLOUGE_ID });

        console.log(CATLOUGE_ID, currentReportDetails);

        if (currentReportDetails?.id == 1) {
            if (CATLOUGE_ID == '0') {
                this.setState({ selectedCatalougeId: null })
                this.updateSalesReport();
            } else {
                this.updateSalesReportByCatalogeId(CATLOUGE_ID);
            }
        }
        if (currentReportDetails?.id == 2) {
            if (CATLOUGE_ID == '0') {
                this.setState({ apiLoading: true, selectedCatalougeId: null })
                this.getReportData(currentReportDetails?.type);
            } else {
                this.getReportDataByCatalougeId(currentReportDetails?.type, CATLOUGE_ID)

            }
        }
        if (currentReportDetails?.id == 3) {
            if (CATLOUGE_ID == '0') {
                this.setState({ selectedCatalougeId: null })
                this.getPartiallyAcceptanceReportData(currentReportDetails?.type, 1, 10);
            } else {
                this.getPartiallyAcceptanceReportDataByCatalougeId(currentReportDetails?.type, 1, 10, CATLOUGE_ID)
            }
        }

    }
    setSelectedCategoryId = async (item: any) => {
        let ParsedData = JSON.parse(item)
        this.setState({ selectedCategory: ParsedData?.id, selectedCategoryName: ParsedData?.attributes?.name }, () => {
            if (this.state?.selectedStore !== null && this.state?.selectedCategory !== null) {
                this.updateCurrentStockReport(this.state?.selectedStore, this.state?.selectedCategory)
            } else {
                AntMessage.warning('Please Select Store Id')
            }
        })
    }

    onDateChange = (dates: any, dateStrings: any) => {
        if (this.state?.selectedStore) {
            if (dates && dateStrings) {
                let reqPayload = `?from_date=${moment(dates[0]).format('D/MM/YYYY')}&to_date=${moment(dates[1]).format('D/MM/YYYY')}`;
                this.setState({
                    from_date: dates[0],
                    to_date: dates[1],
                })

                this.updateDailyGocercySalesReportDateFilter(this.state?.selectedStore, reqPayload);
            } else {
                // this.onGetOrders();
            }
        } else {
            AntMessage.error('Please select Store')
        }

    }

    onOutletDateChangeDateChange = (dates: any, dateStrings: any) => {
        if (this.state?.selectedStore) {
            if (dates && dateStrings) {
                let reqPayload = `?from_date=${moment(dates[0]).format('D/MM/YYYY')}&to_date=${moment(dates[1]).format('D/MM/YYYY')}`;
                console.log(reqPayload);
                this.setState({
                    from_date: dates[0],
                    to_date: dates[1],
                })
                this.updateOutletStataictReportDateFilter(this.state?.selectedStore, reqPayload);
            } else {
                // this.onGetOrders();

            }
        } else {
            AntMessage.error('Please select Store')
        }

    }

    clearFilterState = async () => {
        this.setState({ ReportDataList: [], selectedStore: null, selectedStoreName: null, selectedCategoryName: null, to_date: null, from_date: null });
    }

    render() {
        const { selectedCatalougeId, catalogueList, selectedCategoryName, Storelist, selectedRowKeys, to_date, from_date, ReportDataList, pagination, selectedStore, selectedStoreName } = this.state

        //@ts-ignore
        const ReportDetails = this.props.history.location.state.detail;

        // PAGINATION OPTIONS
        const paginationOptions = {
            onChange: (page: any) => {

                this.setState({ pagination: { ...pagination, current: page } });
                if (ReportDetails?.id == 3) {
                    console.log("PAGE", page);
                    this.getPartiallyAcceptanceReportData(ReportDetails?.type, page, 10)
                }
                if (ReportDetails?.id == 4) {
                    console.log("PAGE", page);
                    this.getInventoryReportData(ReportDetails?.type, page, 10)
                }
            },
            total: this.state?.pagination?.Totalcount,
            showSizeChanger: false,
            current: pagination.current,
            pageSize: pagination.pageSize
        };

        const salesReport = [
            {
                title: "Store Id",
                dataIndex: "storeId",
                key: "storeId",
            },
            {
                title: "Site Id",
                dataIndex: "site_id",
                key: "site_id",
            },
            {
                title: "Store Name",
                dataIndex: "storeName",
                key: "storeName",
            },
            {
                title: " Order Date",
                dataIndex: "order_date",
                key: "order_date",

            },
            {
                title: "Order Id",
                dataIndex: "order_id",
                key: "order_id",
            },
            {
                title: "Txn Id",
                dataIndex: "txn_id",
                key: "txn_id",
            },
            {
                title: "Bill No (D365)",
                dataIndex: "bill_no",
                key: "bill_no",
            },
            {
                title: " Amount (With Discount)",
                dataIndex: "amountwithDiscount",
                key: "amountwithDiscount",
            },
            {
                title: " Final Amount Without Discount",
                dataIndex: "amountWithoutDiscount",
                key: "amountWithoutDiscount",
            },
            // {
            //     title: "Order Status",
            //     dataIndex: "order_status",
            //     key: "order_status",
            // },
            {
                title: " Action",
                dataIndex: "action",
                key: " action",
            },
            {
                title: "Product Status",
                dataIndex: "product_status",
                key: "product_status",
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                key: "product_name",
            },
            {
                title: "Product Quantity",
                dataIndex: "product_quantity",
                key: "product_quantity",
            },
            {
                title: "  Packaging Charge",
                dataIndex: "packaging_charge",
                key: " packaging_charge",
            },
            {
                title: " Action Reason",
                dataIndex: "action_reason",
                key: " action_reason",
            },
            {
                title: " Action By",
                dataIndex: "packaging_charge",
                key: " packaging_charge",
            },
            {
                title: " Total TCS",
                dataIndex: "totalTCS ",
                key: " totalTCS  ",
            },
            {
                title: "Total TDS",
                dataIndex: "totalTDS",
                key: " totalTDS",
            },
            {
                title: " Total GST ",
                dataIndex: "total_gst",
                key: " total_gst",
            },
            {
                title: "  Base Amount ",
                dataIndex: "base_amoount",
                key: " base_amoount",
            },
            {
                title: " Product Total Amount",
                dataIndex: "product_total_amount",
                key: " product_total_amount  ",
            },
            {
                title: "Product Amount Per Unit (mrp)",
                dataIndex: "product_amount_per_unit",
                key: "product_amount_per_unit  ",
            },
            {
                title: "Product Discount ",
                dataIndex: "product_discount",
                key: "product_discount",
            },
            {
                title: "Item Base Amount",
                dataIndex: "item_base_amount",
                key: " item_base_amount",
            },

        ];

        const orderCancellationReport = [
            {
                title: "Order Id",
                dataIndex: "orderId",
                key: "orderId",
            },
            {
                title: "Order Partner",
                dataIndex: "orderPartner",
                key: "orderPartner",
            },
            {
                title: "Customer Name",
                dataIndex: "customerName",
                key: "customerName",
            },
            {
                title: "Customer Information ",
                dataIndex: "customerInfo",
                key: "customerInfo",
            },
            {
                title: "Store Id",
                dataIndex: "storeId",
                key: "storeId",
            },
            {
                title: "Store Name",
                dataIndex: "storeName",
                key: "storeName",
            },
            {
                title: "Order Status",
                dataIndex: "orderStatus",
                key: "orderStatus",
            },
            {
                title: "Order Date",
                dataIndex: "orderDate",
                key: "orderDate",
            },
            {
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
            },


        ]

        const partialOrderAcceptReport = [
            {
                title: "Order Id",
                dataIndex: "orderId",
                key: "orderId",
            },
            {
                title: "Order Partner",
                dataIndex: "orderPartner",
                key: "orderPartner",
            },
            {
                title: "Order date",
                dataIndex: "orderDate",
                key: "orderDate",
            },
            {
                title: "Item Code",
                dataIndex: "itemCode",
                key: "itemCode",
            },
            {
                title: "Item Name",
                dataIndex: "itemName",
                key: "itemName",
            },
            {
                title: "Customer Name",
                dataIndex: "customerName",
                key: "customerName",
            },
            {
                title: "Customer Information ",
                dataIndex: "customerInfo",
                key: "customerInfo",
            },
            {
                title: "Store Id",
                dataIndex: "storeId",
                key: "storeId",
            },
            {
                title: "Store Name",
                dataIndex: "storeName",
                key: "storeName",
            },
            {
                title: "Article Status",
                dataIndex: "articleStatus",
                key: "articleStatus",
            },
            {
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
            },


        ]

        const itemUnavailablityReport = [

            {
                title: "Store Id",
                dataIndex: "storeId",
                key: "storeId",
            },
            {
                title: "Store Name",
                dataIndex: "storeName",
                key: "storeName",
            },
            {
                title: "Item Code",
                dataIndex: "itemCode",
                key: "itemCode",
            },
            {
                title: "Item Name",
                dataIndex: "itemName",
                key: "itemName",
            },
            {
                title: "Unavailability Start Date",
                dataIndex: "unStartDate",
                key: "unStartDate",
            },
            {
                title: "Unavailability Start Time ",
                dataIndex: "unStartTime",
                key: "unStartTime",
            },
            {
                title: "Unavailability End Date",
                dataIndex: "unEndDate",
                key: "unEndDate",
            },
            {
                title: "Unavailability End Time",
                dataIndex: "unEndTime",
                key: "unEndTime",
            },
            {
                title: "Repeat",
                dataIndex: "repeat",
                key: "repeat",
            },
            {
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
            },
            {
                title: "Record By",
                dataIndex: "RecordBy",
                key: "RecordBy",
            },


        ]

        const istoreOpenCloseReport = [

            {
                title: "Store Id",
                dataIndex: "storeId",
                key: "storeId",
            },
            {
                title: "Store Name",
                dataIndex: "storeName",
                key: "storeName",
            },
            {
                title: "Close Start Date",
                dataIndex: "CloseStartDate",
                key: "CloseStartDate",
            },
            {
                title: "Close Start Time ",
                dataIndex: "closeStartTime",
                key: "closeStartTime",
            },
            {
                title: "Close End Date",
                dataIndex: "closeEndDate",
                key: "closeEndDate",
            },
            {
                title: "Close End Time",
                dataIndex: "closeEndTime",
                key: "closeEndTime",
            },
            {
                title: "Repeat",
                dataIndex: "repeat",
                key: "repeat",
            },
            {
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
            },
            {
                title: "Record By",
                dataIndex: "RecordBy",
                key: "RecordBy",
            },


        ]

        const currentStockReport = [
            {
                title: " Item Name",
                dataIndex: "itemName",
                key: "itemName",
            },
            {
                title: "Item Quantity",
                dataIndex: "itemQuantity",
                key: "itemQuantity",
            },
            {
                title: "Item Price",
                dataIndex: "itemPrice",
                key: "itemPrice",
            }
        ]

        const dailyGrocerySalesReport = [
            {
                title: "Date",
                dataIndex: "date",
                key: "date",
            },
            {
                title: "Store Code",
                dataIndex: "storeCode",
                key: "storeCode",
            },
            {
                title: "Order No",
                dataIndex: "orderNo",
                key: "orderNo",
            },
            {
                title: "Bill No",
                dataIndex: "BillNo",
                key: "BillNo",
            },
            {
                title: "MRP",
                dataIndex: "mrp",
                key: "mrp",
            },
            // {
            //     title: "Rate",
            //     dataIndex: "rate",
            //     key: "rate",
            // },
            {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
            },
            {
                title: "Payment Mode",
                dataIndex: "paymentMode",
                key: "paymentMode",
            }
        ];

        const outletWiseStatistics = [
            {
                title: "Store Id",
                dataIndex: "storeId",
                key: "storeId",
            },
            {
                title: "Store Name",
                dataIndex: "store_name",
                key: "store_name",
            },
            {
                title: "Store Manager",
                dataIndex: "store_manager",
                key: "store_manager",
            },
            {
                title: "Zone",
                dataIndex: "zone",
                key: "zone",
            },
            {
                title: "Ofc",
                dataIndex: "ofc",
                key: "ofc",
            },
            {
                title: "Address",
                dataIndex: "address",
                key: "address",
            },






        ]

        let columns
        if (ReportDetails?.id == 1) { columns = salesReport }
        if (ReportDetails?.id == 2) { columns = orderCancellationReport }
        if (ReportDetails?.id == 3) { columns = partialOrderAcceptReport }
        if (ReportDetails?.id == 4) { columns = itemUnavailablityReport }
        if (ReportDetails?.id == 5) { columns = istoreOpenCloseReport }
        if (ReportDetails?.id == 6) { columns = outletWiseStatistics }
        if (ReportDetails?.id == 7) { columns = currentStockReport }
        if (ReportDetails?.id == 8) { columns = dailyGrocerySalesReport }

        return (
            <>
                <Row className="ReportList-main">
                    <Col span={24} className="content-container">
                        <Row className="header report-list-row">
                            <Col className="report-list-col" span={24} style={{ textAlign: "left" }}>
                                <Link
                                    //@ts-ignore
                                    onClick={() => { this.props.history.push('/Reporting') }}
                                    className="backLink" ><LeftOutlined translate={{}} />Back
                                </Link>
                            </Col>
                            <Col className="report-list-col"
                                span={7}
                                style={{ textAlign: "left" }}>
                                <Title className="title">{ReportDetails?.title}</Title>
                            </Col>
                            <Col className="report-list-col"
                                span={17}
                                style={{ textAlign: "end" }}>
                                {/* <Space size="middle" className="filterFieldBox">
                                    <Input
                                        placeholder="Filter by StoreID"
                                        className="search-box"
                                    />
                                </Space>*/}

                                <div className="display-flex">
                                    {ReportDetails?.id == 1 || ReportDetails?.id == 2 || ReportDetails?.id == 3 ?
                                        <>
                                            <Select
                                                // value={selectedStoreName}
                                                loading={catalogueList?.length == 0}
                                                defaultValue={'0'}
                                                className="modal-role-select report-list-col-search"
                                                placeholder="Catalouge"
                                                optionFilterProp="children"

                                                onChange={(value) => {
                                                    this.getReportByCatalouge(value, ReportDetails)

                                                }}>
                                                <Select.Option
                                                    value={'0'}
                                                    key={'dfgsdf'}
                                                >

                                                    <span style={{ marginLeft: 10 }}>All Catalogues</span>
                                                </Select.Option>
                                                {catalogueList.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={option?.id}
                                                        key={index}
                                                    >
                                                        <img src={option?.attributes?.avatar?.url}
                                                            height="20px"
                                                            width="20px"
                                                            style={{ borderRadius: 10, marginRight: 5 }}
                                                        />
                                                        {option?.attributes?.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </>
                                        : null
                                    }
                                    {ReportDetails?.id == 1 ?
                                        <>

                                            <Select
                                                value={selectedStoreName}
                                                loading={Storelist?.length == 0}
                                                showSearch
                                                // style={{ width: "150px" }}
                                                className="modal-role-select report-list-col-search"
                                                placeholder="Select Store"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                                    // option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    //@ts-ignore
                                                    optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                                                }
                                                onChange={(value) => {
                                                    this.getSalesReportByStoreId(value)
                                                }}>
                                                {this.state.Storelist.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={JSON.stringify(option)}
                                                        key={index}
                                                    >
                                                        {`${option.store_name}(${option.store_id})`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            {
                                                selectedStore != null ?
                                                    <Button
                                                        className="exportReportBtn report-col-btn"
                                                        onClick={() => {
                                                            this.setState({ apiLoading: true, selectedStoreName: null, selectedStore: null })
                                                            this.updateSalesReport();

                                                        }}>Clear Filters</Button>
                                                    : null
                                            }
                                        </>
                                        : null
                                    }

                                    {ReportDetails?.id == 4 ?
                                        <>
                                            <Select
                                                value={selectedStoreName}
                                                loading={Storelist?.length == 0}
                                                showSearch
                                                style={{ width: "150px" }}
                                                className="modal-role-text report-list-col-search"
                                                placeholder="Select Store"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    //@ts-ignore 
                                                    optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                                                }
                                                onChange={(value) => {
                                                    this.getInventoryItemUnavalablityByStoreId(value)
                                                }}>
                                                {this.state.Storelist.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={JSON.stringify(option)}
                                                        key={index}
                                                    >
                                                        {`${option.store_name}(${option.store_id})`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            {
                                                selectedStore != null ?
                                                    <Button
                                                        className="exportReportBtn report-col-btn"
                                                        onClick={() => {
                                                            this.clearFilterState();
                                                            this.getInventoryReportData(ReportDetails?.TYPE, 1, 10);
                                                        }}>Clear Filters</Button>
                                                    : null
                                            }
                                        </>
                                        : null
                                    }
                                    {ReportDetails?.id == 5 ?
                                        <>
                                            <Select
                                                // value={this.state?.Storelist[0]?.id}
                                                showSearch
                                                loading={Storelist?.length == 0}
                                                value={selectedStoreName}
                                                style={{ width: "150px" }}
                                                className="modal-role-text report-list-col-search"
                                                placeholder="Select Store"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                                    // option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    //@ts-ignore
                                                    optionA?.children[0]?.toLowerCase().localeCompare(optionB?.children[0]?.toLowerCase())
                                                }
                                                onChange={(value) => {
                                                    this.getstoreCloasingOpeningTimeByStoreId(value)
                                                }}>
                                                {this.state.Storelist.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={JSON.stringify(option)}
                                                        key={index}
                                                    >
                                                        {`${option.store_name}(${option.store_id})`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            {
                                                selectedStore != null ?
                                                    <Button
                                                        className="exportReportBtn report-col-btn"
                                                        onClick={() => {
                                                            this.clearFilterState();
                                                            this.getStoreClosingTimeLogReport(ReportDetails?.TYPE)
                                                        }}>Clear Filters</Button>
                                                    : null
                                            }
                                        </>
                                        : null
                                    }
                                    {ReportDetails?.id == 6 ?
                                        <>
                                            <Select
                                                // value={this.state?.Storelist[0]?.id}
                                                showSearch
                                                value={selectedStoreName}
                                                loading={Storelist?.length == 0}
                                                style={{ width: "150px", marginBottom: 10 }}
                                                className="modal-role-text report-list-col-search"
                                                placeholder="Select Store"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                                    // option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    //@ts-ignore
                                                    optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                                                }
                                                onChange={(value) => {
                                                    this.getOutletStataictsByStoreId(value)
                                                }}>
                                                {this.state.Storelist.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={JSON.stringify(option)}
                                                        key={index}
                                                    >
                                                        {`${option.store_name}(${option.store_id})`}
                                                    </Select.Option>
                                                ))}
                                            </Select>

                                            {/* Select Dates */}
                                            <div className="date-picker-placement">
                                                <Row>
                                                    <Col>
                                                        <div className="custome-date-control">
                                                            <DatePicker.RangePicker
                                                                value={to_date != null && from_date != null ? [moment(to_date, 'DD/MM/YYYY'), moment(from_date, 'DD/MM/YYYY')] : null}
                                                                onChange={this.onOutletDateChangeDateChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            {
                                                selectedStore != null ?
                                                    <Button
                                                        className="exportReportBtn report-col-btn"
                                                        onClick={() => {
                                                            this.clearFilterState();
                                                        }}>Clear Filters</Button>
                                                    : null
                                            }
                                        </>
                                        : null
                                    }
                                    {ReportDetails?.id == 7 ?
                                        <>
                                            {/* Select Store  */}
                                            <Select
                                                value={selectedStoreName}
                                                showSearch
                                                loading={Storelist?.length == 0}
                                                className="modal-role-text report-list-col-search"
                                                placeholder="Select Store"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    //@ts-ignore
                                                    optionA?.children[0]?.toLowerCase().localeCompare(optionB?.children[0]?.toLowerCase())
                                                }
                                                onChange={(value) => {
                                                    this.setSelectedStoreId(value)
                                                }}>
                                                {this.state.Storelist.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={JSON.stringify(option)}
                                                        key={index}
                                                    >
                                                        {`${option.store_name}(${option.store_id})`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            {/* Select Category */}
                                            <Select
                                                value={selectedCategoryName}
                                                showSearch
                                                className="modal-role-text report-list-col-search"
                                                placeholder="Select Category"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option?.children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    //@ts-ignore
                                                    optionA?.children[0]?.toLowerCase().localeCompare(optionB?.children[0]?.toLowerCase())
                                                }
                                                onChange={(value) => {
                                                    this.setSelectedCategoryId(value)
                                                }}>
                                                {this.state.CategoryList.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={JSON.stringify(option)}
                                                        key={index}
                                                    >
                                                        {`${option?.attributes?.display_name}`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            {
                                                selectedStore != null ?
                                                    <Button
                                                        className="exportReportBtn report-col-btn"
                                                        onClick={() => {
                                                            this.clearFilterState();
                                                        }}>Clear Filters</Button>
                                                    : null
                                            }

                                        </>
                                        : null
                                    }
                                    {ReportDetails?.id == 8 ?
                                        <>
                                            <Select
                                                value={selectedStoreName}
                                                showSearch
                                                loading={Storelist?.length == 0}
                                                style={{ width: "150px", marginBottom: 10 }}
                                                className="modal-role-text report-list-col-search"
                                                placeholder="Select Store"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                                    // option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    //@ts-ignore
                                                    optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                                                }
                                                onChange={(value) => {
                                                    this.getDailyGrocerySalesReportByStoreId(value)
                                                }}>
                                                {this.state.Storelist.map((option: any, index: any) => (

                                                    <Select.Option
                                                        value={JSON.stringify(option)}
                                                        key={index}
                                                    >
                                                        {`${option.store_name}(${option.store_id})`}
                                                    </Select.Option>
                                                ))}
                                            </Select>

                                            {/* Select Dates */}
                                            <div className="date-picker-placement">
                                                <Row>
                                                    <Col>
                                                        <div className="custome-date-control">
                                                            <DatePicker.RangePicker
                                                                value={to_date != null && from_date != null ? [moment(to_date, 'DD/MM/YYYY'), moment(from_date, 'DD/MM/YYYY')] : null}
                                                                onChange={this.onDateChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            {
                                                selectedStore != null ?
                                                    <Button
                                                        className="exportReportBtn report-col-btn"
                                                        onClick={() => {
                                                            this.clearFilterState();
                                                        }}>Clear Filters</Button>
                                                    : null
                                            }
                                        </>
                                        : null
                                    }
                                    <Button
                                        className="exportReportBtn report-col-btn"
                                    >
                                        {
                                            ReportDetails?.id == 8 && this.state?.selectedStore ?
                                                <a
                                                    href={`${baseURL}/bx_block_adhocreporting/adhoc_reports/csv_export_grocery_report.csv?token=${localStorage.getItem('token')}&store_id=${this.state?.selectedStore}&from_date=${this.state?.from_date}&to_date${this.state?.to_date}`}
                                                >
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                        {
                                            ReportDetails?.id == 7 && this.state?.selectedStore ?
                                                <a
                                                    href={`${baseURL}/account_block/accounts/store_accounts/inventory_csv_report.csv?id=${this.state.selectedStore}&token=${localStorage.getItem('token')}&category_id=${this.state?.selectedCategory}`}
                                                >
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                        {
                                            ReportDetails?.id == 6 && this.state?.selectedStore ?
                                                <a
                                                    href={`${baseURL}/bx_block_adhocreporting/adhoc_reports/csv_export_outlet_statistics.csv?token=${localStorage.getItem('token')}${this.state?.selectedStore ? `&store_id=${this.state?.selectedStore}` : ''}`}
                                                >
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                        {
                                            ReportDetails?.id == 5 ?
                                                <a
                                                    href={`${baseURL}/bx_block_adhocreporting/adhoc_reports/csv_export_store_closing_report.csv?token=${localStorage.getItem('token')}${this.state?.selectedStore ? `&store_id=${this.state?.selectedStore}` : ''}`}
                                                >
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                        {
                                            ReportDetails?.id == 4 ?
                                                <a
                                                    href={`${baseURL}/bx_block_adhocreporting/adhoc_reports/csv_inventory_unavailability.csv?token=${localStorage.getItem('token')}${this.state?.selectedStore ? `&store_id=${this.state?.selectedStore}` : ''}`}
                                                >
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                        {
                                            ReportDetails?.id == 3 ?
                                                <a
                                                    href={`${baseURL}/bx_block_admin/admin/order_partially_accepted_report.csv?token=${localStorage.getItem('token')}&order_type=Partially accepted${selectedCatalougeId ? `&catalogue_id=${selectedCatalougeId}` : ''}`}
                                                >
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                        {
                                            ReportDetails?.id == 2 ?
                                                <a
                                                    href={`${baseURL}/bx_block_admin/admin/order_cancellation_report.csv?token=${localStorage.getItem('token')}&order_type=Rejected${selectedCatalougeId ? `&catalogue_id=${selectedCatalougeId}` : ''}`}
                                                >
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                        {
                                            ReportDetails?.id == 1 ?
                                                <a
                                                    href={`${baseURL}/bx_block_adhocreporting/adhoc_reports/csv_sales_report.csv?token=${localStorage.getItem('token')}${selectedCatalougeId ? `&catalogue_id=${selectedCatalougeId}` : ''}${this.state?.selectedStore ? `&store_id=${this.state?.selectedStore}` : ''}`}>
                                                    Export Report
                                                </a>
                                                : null
                                        }
                                    </Button>
                                </div>

                            </Col>
                        </Row>

                        <div className="reportListTableBlock">
                            <Table
                                title={() => 'Report'}
                                // rowSelection={rowSelection}
                                locale={{
                                    emptyText: <Empty
                                        description={
                                            <span>
                                                {ReportDetails?.id == 4 || ReportDetails?.id == 5 || ReportDetails?.id == 6 || ReportDetails?.id == 7 || ReportDetails?.id == 8 ?
                                                    <p>Please Select Store</p> : `No Data Found`}
                                            </span>
                                        }
                                    />
                                }}
                                columns={columns}
                                dataSource={ReportDataList}
                                size={'small'}
                                scroll={{ x: 'max-content' }}
                                loading={this.state.apiLoading}
                                pagination={paginationOptions}
                            />
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
// Customizable Area End