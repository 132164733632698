
// Customizable Area Start
import PeopleManagement2Controller, {
  Props,
  configJSON
} from "../PeopleManagement2Controller";
import React, { createRef } from "react";
import "./People.css";
import { Alert, FormInstance, Popover, Spin } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { Redirect } from 'react-router-dom'
import "antd/dist/antd.css";
import {
  Table,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Row,
  Col,
  Form,
  Switch,
  Typography,
  TimePicker,
  DatePicker,
  Radio,
  Space,
  Avatar,
  Tabs,
  Divider, Checkbox
} from "antd";
import { CloseCircleFilled, DownCircleFilled, EditOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { Link as LINK } from 'react-router-dom'
const { Title, Paragraph, Text, Link } = Typography;
const { Option } = Select;
import { EditFilled, DeleteFilled, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { ActivityIndicatorBase } from "react-native";
import { message as AntMessage } from "antd";
//@ts-ignore
import GlobalLoader from "../../../../web/src/GlobalLoader"
//@ts-ignore
import { baseURL } from "../../../../framework/src/config.js"
export default class People extends PeopleManagement2Controller {
  timer: any = null;
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      addModal: false,
      editModal: false,
      employee_id: "",
      employee_name: "",
      role: "",
      contact: "",
      username: "",
      password: "",
      ofc_id: "",
      zn_id: "",
      status: "",
      peopleList: [],
      ofcIds: [],
      zoneIds: [],
      StoreIds: [],
      storeUserInfo: {},
      selectedEmployeeData: {},
      selectedzoneId: 0,
      selectedOfcId: 0,
      selectedStoreId: 0,
      Roles: [],
      selectedRoleId: "",
      id: "",
      selectedRowKeys: [],
      errors: { email: "" },
      email: "",
      employeeProfileImage: "",
      selectselectedProfilePic: "",
      disabledOfc: false,
      disabledStore: false,
      roleInfo: {},
      officeMandatory: false,
      storeMandatory: false,
      zoneMandatory: false,
      dataLoader: false,
      tableDataLoader: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        Totalcount: 0,
        TotalPeoplescount: 0
      },
    }
    // Customizable Area End
    document.title = `Peoples | 24SevenDashboard`

  }

  formRef = createRef<FormInstance>();


  onFinish = (values: any) => {
    console.log("Success:", values);
  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  onReset = () => {
    this.formRef.current!.resetFields();

    this.setState({
      username: "",
      password: "",
      selectedRoleId: "",
      errors: { email: "" }
    })
  };

  handleSwitch = (e: any, data: any) => {
    let requestPayload = {
      data: {
        status: e == true ? 'active' : 'inactive'
      }
    }
    this.updatePeopleStatus(requestPayload, data.id)
  };

  handelOnSubmitEmployee = (values: any) => {
    const { username, password, email } = this.state;
    let isValid = this.validateEmail();
    if (this.state.selectedRoleId !== "") {
      if (this.state.username !== "" &&
        this.state.password !== "" && isValid) {
        this.addPeopleData(values, username, password, email);
      }
    } else {
      if (isValid) {
        this.addPeopleData(values, username, password, email);
      }
    }
  };

  handelEditEmployee = (itemId: any) => {
    this.setState({
      id: itemId.id, dataLoader: true
    })
    this.handelGetEmployeeDataByID(itemId.id)
  }
  handelOnUpdateEmployee = (values: any) => {

    let updateEmployeeData = {
      data: {
        full_name: values.full_name,
        employee_id: values.employee_id,
        full_phone_number: values.full_phone_number,
        zone_id: values.zone_id,
        role_id: values.role_id,
        status: "active",
        password: this.state.password,
        user_name: this.state.username,
        ofc_id: values.ofc_id,
        store_account_id: values.store_id,
        email: this.state.email
      }
    }

    if (this.state.selectedRoleId !== "") {
      if (this.state.username !== "" && this.state.password !== "") {
        this.updateEmployeeData(updateEmployeeData, this.state.id)

      } else {
        this.updateEmployeeData(updateEmployeeData, this.state.id)
      }
    } else {
      this.updateEmployeeData(updateEmployeeData, this.state.id);
    }
  }
  selectedZoneId(zoneId: any) {

    // if(this.state.id){
    this.formRef.current?.setFieldsValue({ ofc_id: "", store_id: "" })
    // }
    this.setState({ selectedzoneId: zoneId, ofcIds: [], StoreIds: [] }, () => {
      if (zoneId) {
        this.getAllOfcsRealtedtoZoneId(zoneId);
      }
    })

  }
  selectedOfcId(OfcId: any) {
    this.setState({ selectedOfcId: OfcId })
    this.getAllStoreRealtedtoOfcId(OfcId);
  }
  // selectedStoreId(storeId:any){
  //   this.setState({selectedStoreId:storeId});
  // }
  handleChangeUsername = (e: any) => {
    const { value } = e.target;
    this.setState({
      username: value
    })
  }


  handleChangePassword = (e: any) => {
    const { value } = e.target;
    this.setState({
      password: value
    })
  };

  handleEmailField = (e: any) => {
    const { value } = e.target;
    const { errors } = this.state;
    if (value) {
      errors.email = "";
    }
    this.setState(
      {
        email: value,
      },
      () => this.validateEmail()
    );
  };

  validateEmail = () => {
    let errors = { email: "" };
    const { email } = this.state;
    let isValid = true;
    if (!email) {
      isValid = false;

      errors["email"] = "Email is required.";
    }

    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        isValid = false;

        errors["email"] = "Please enter valid email address.";
      }
    }
    this.setState({
      errors: { email: errors.email },
    });

    return isValid;
  };

  selectedRoleId(roleId: any) {
    this.setState({ selectedRoleId: roleId, password: "", username: "" });
    this.formRef.current?.setFieldsValue({ store_id: "", password: "", user_name: "" }
    )
    this.getRoleInformation(roleId);
  }

  selectRow = (record: any) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys });
  };

  onSelectedRowKeysChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  uploadPeopleCsv = async (e: any) => {
    let isFileCSV = await this.beforePeopleFileUpload(e.file)
    if (isFileCSV) {
      const formData = new FormData();
      formData.append("file", e.file);
      this.uploadPeopleCsvFile(formData)
    } else {
      AntMessage.error('You can only upload csv/xls file!');
    }
  }

  searchPeople = async (e: any) => {
    clearTimeout(this.timer);
    let value = e.target.value
    this.timer = setTimeout(() => {
      this.searchPeoplesDetails(value)
    }, 1000)
  }

  // uploadProfilePicture
  //@ts-ignore
  uploadProfilePicture = async (e: any) => {
    const data = new FormData();
    data.append("data[avatar]", e.file);
    this.uploadProfilePictureFile(data, this.state.id)
  }

  handelSelectProfilePicture = async (e: any) => {
    this.setState({ selectselectedProfilePic: e.file })
  }

  fetchStoreInfo = (id: any) => {
    this.onStoreInfo(id);
  }
  //============================= beforefileUpload =================================
  beforePeopleFileUpload(file: any) {
    if (file.type == 'text/csv' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return true;
    } else {
      return false
    }
  }

  //======================================== RENDER =====================================================
  render() {
    const { dataLoader, pagination, peopleList, selectedRowKeys, selectselectedProfilePic } = this.state;

    // const antIcon = <LoadingOutlined translate={{}} style={{ fontSize: '50px', color: 'black' }} spin />;
    const antIcon = <GlobalLoader />;

    //-------------------------- TO GET PERMISSIONS FROM THE STORAGE -------------------------------------
    let permissions: any;
    let decodedString = atob(localStorage.getItem('permissions') || '{}');

    //@ts-ignore
    JSON.parse(decodedString).filter((d) => {

      if (d.attributes.access_module.name == "People") {
        permissions = d.attributes
        return
      }
    });
    //----------------------------------------------------------------------------------------------------
    // PAGINATION OPTIONS
    const paginationOptions = {
      onChange: (page: any) => {
        this.setState({ pagination: { ...pagination, currentPage: page } });
        this.onGetPeoplesByPagination(page, this.state?.pagination?.pageSize)

      },
      showSizeChanger: false,
      current: pagination.currentPage,
      pageSize: pagination.pageSize,
      total: this.state?.pagination?.TotalPeoplescount,
    };
    //----------------------------------------------------------------------------------------------------


    let TabObj =
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (item: any, fullObj: any) => (
        <div className="editIconBox" onClick={() => {
          this.setState({ editModal: true, dataLoader: true })
          this.handelEditEmployee(item);
        }}>
          <Space size="large">

            <span className="icon">
              <EditFilled
                translate

              />
            </span>
            <span>EDIT</span>
          </Space>
        </div>
      ),
    }

    const columns = [
      {
        title: 'SNo',
        dataIndex: 'sno',
        key: 'sno',
        className: 'role-name-id'
      },
      {
        title: 'Employee ID',
        dataIndex: 'employee_id',
        key: 'employee_id',
        className: 'role-name-id'
      },
      {
        title: 'Employee Name',
        dataIndex: 'employee_name',
        key: 'employee_name',
        className: 'role-name'
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        className: 'role-name'
      },
      {
        title: 'Store ID',
        dataIndex: 'store_id',
        key: 'store_id',
        className: 'role-name'
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        className: 'role-name'
      },
      {
        title: 'Contact detail',
        dataIndex: 'contact',
        key: 'contact',
        className: 'role-name'
      },
      {
        title: 'Password',
        dataIndex: 'password',
        key: 'password',
        className: 'role-name'
      },
      {
        title: 'OFC ID',
        dataIndex: 'ofc_id',
        key: 'ofc_id',
        className: 'role-name'
      },
      {
        title: 'ZN ID',
        dataIndex: 'zn_id',
        key: 'zn_id',
        className: 'role-name'
      },
      {
        title: "Status",
        dataIndex: "store_status",
        key: "store_status",
        render: (data: any, record: any, values: any) => {
          return (
            <Space size="large">
              {/* <Text className="act-status">Open</Text> */}
              <Switch
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                defaultChecked={data == true ? data : false}
                onChange={(e) => {
                  this.handleSwitch(e, record)
                }}
                title="Open"
              />
            </Space>
          );
        },
      }
    ];

    //@ts-ignore
    permissions?.update_action ? columns.splice(11, 0, TabObj) : '';


    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };

    return (
      <section className="peoplemanageSection">
        <Row className="people-main">
          <Col span={24} className="content-container">
            <Row className="header people-heading">
              <Col span={9} style={{ textAlign: 'left' }} className="people-search">
                <Title className="title people-title-text">People Management</Title>
              </Col>

              <Col span={15} style={{ textAlign: "end" }} className="searchBtnFlexBox people-search">
                <Space size="middle" className="people-r">
                  <Input placeholder="Search" className="search-box people-search" onChange={(e) => { this.searchPeople(e) }} />
                  {/* <Button size="large" icon={<UploadOutlined translate />} className="import-btn">IMPORT</Button> */}
                  {/* <Button size="large" icon={<DownloadOutlined translate />} className="export-btn">EXPORT</Button> */}
                  <div className="people-c">
                    <Upload className="importBtn"
                      accept={".csv,.xls,.xlsx"}
                      customRequest={(e) => { this.uploadPeopleCsv(e) }}
                      showUploadList={false}>
                      <Button icon={<UploadOutlined translate />}>Import</Button>
                    </Upload>
                  </div>
                  <Button className="export-btn people-c"
                  ><DownloadOutlined
                      style={{ marginRight: 10 }}
                      translate={''} />
                    <a
                      href={`${baseURL}/bx_block_peoplemanagement2/people_accounts/csv_export.csv`}
                    >Export
                    </a>
                  </Button>

                  {permissions?.create_action ?
                    <Button size="large" className="add-btn people-c" onClick={() => { this.setState({ addModal: true, id: "", }, () => this.onReset()) }}>ADD PEOPLE</Button>
                    : null}

                </Space>
              </Col>
            </Row>

            {/* ADD EMPLOYEE DATA  */}

            <Modal
              className="addEmployeeModalBox"
              visible={this.state.addModal}
              // bodyStyle={{ height: '850px' }}
              title={null}
              centered
              width="45vw"
              onCancel={() => {
                this.setState({ addModal: false })
                this.onReset();
              }
              }
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              <div className="AddStoreTimingsSection">
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">Add Employee</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">Add the employee details</p>
                  </Col>
                </Row>
                <Row className="imgBoxBlock">
                  <div className="imgBox" >
                    <img height='100px' width="100px" src={selectselectedProfilePic !== "" ? selectselectedProfilePic : "https://www.24-seven.in/img/logo/X-mas-Logo.png"} />
                    <Upload
                      customRequest={(e) => { this.handelSelectProfilePicture(e) }}
                      showUploadList={false}
                    >
                      <Button color="transparent" className="edit_btn" ><EditOutlined translate={{}} /></Button>
                    </Upload>


                  </div>
                </Row>

                <Form
                  onFinish={this.handelOnSubmitEmployee}
                  // onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  initialValues={
                    {
                      // zone_id: this.state?.zoneIds[0]?.id,
                      // ofc_id: this.state?.ofcIds[0]?.id,
                      // store_id: this.state?.StoreIds[0]?.id
                    }
                  }
                  ref={this.formRef}
                  name="content-ref"
                  autoComplete="off"
                  size='small'
                  layout="vertical"
                >
                  <Row className="modal-rows fieldBox">
                    <Col className="rowCol" span={6}>
                      <Form.Item name="employee_id" label="Employee Id" rules={[{ required: true, message: "Employee Id  is required" }]}>
                        <Input type="text" placeholder="Employee Id" />
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={6}>
                      <Form.Item name="full_name" label="Name" rules={[{ required: true, message: "Name is required" }]}>
                        <Input type="text" placeholder="Enter name" />
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row className="modal-rows fieldBox">
                    <Col className="rowCol" span={6}>
                      <Form.Item name="email" label="Email">
                        <Input onChange={this.handleEmailField} type="text" placeholder="Enter Email" />
                        <div id="errorField">{this.state.errors.email}</div>
                      </Form.Item>

                    </Col>
                    <Col className="rowCol" span={6}>
                      <Form.Item label="Role" name="role_id" rules={[{ required: false }]}>

                        <Select className="modal-role-text"
                          placeholder="select role"
                          onChange={(value) => {
                            this.selectedRoleId(value)
                          }}
                        >
                          {this.state.Roles.map((option: any, i: any) => {
                            return (
                              //@ts-ignore
                              <Option value={option?.attributes?.id} key={i}>{option?.attributes?.name}</Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modal-rows fieldBox">
                    <Col className="rowCol" span={6}>
                      <Form.Item name="user_name" label="Username" >
                        <Input type="text" value={this.state.username} onChange={this.handleChangeUsername} placeholder="Enter username" />
                        <div id="errorField">{this.state.selectedRoleId !== "" ? this.state.username == "" ? "Username is required" : "" : ""}</div>
                      </Form.Item>

                    </Col>
                    <Col className="rowCol" span={6}>
                      <Form.Item name="password" label="Password" >

                        <Input type="text" value={this.state.password} onChange={this.handleChangePassword} placeholder="Enter password" />
                        <div id="errorField">{this.state.selectedRoleId !== "" ? this.state.password == "" ? "password is required" : "" : ""}</div>
                      </Form.Item>

                    </Col>
                  </Row>
                  <Row className="modal-rows fieldBox">
                    {/* <Col className="rowCol" span={6}>
                      <Row className="modal-rows fieldBox selectdropBox"> */}
                    <Col span={6}>
                      <Form.Item
                        name="zone_id"
                        label="Zone ID"
                        rules={[{ required: false }]}>

                        <Select className="modal-role-text"
                          placeholder="select Zone"
                          onChange={(value) => {
                            this.selectedZoneId(value)
                          }}
                        >
                          <Option value={'null'}>Select Zone</Option>
                          {this.state.zoneIds.map((option: any) => (
                            //@ts-ignore
                            <Option value={option.id}>{`${option.zone_name}(${option.zone_unique_id})`}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="ofc_id"
                        label="Ofc ID"
                        rules={[{ required: false }]}>

                        <Select className="modal-role-text"
                          placeholder="select Ofc"
                          onChange={(value) => {
                            this.selectedOfcId(value)
                          }}
                          disabled={this.state.disabledOfc}
                        >
                          <Option value="">Select OFC</Option>
                          {this.state.ofcIds.map((option: any) => (
                            //@ts-ignore
                            <Option value={option.id}>{`${option.clusture_name}(${option.ofc_unnique_number})`}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* </Row>
                    </Col> */}
                  </Row>
                  <Row className="modal-rows fieldBox" >

                    <Col className="rowCol" span={6}>
                      <Form.Item label="Store Id" name="store_id" rules={[{ required: false }]}>

                        <Select className="modal-role-text"
                          placeholder="select store"
                          // onChange={(value) => {
                          //   this.selectedStoreId(value)
                          // }}
                          disabled={this.state.disabledStore}
                        >
                          <Option value="">Select Store</Option>
                          {this.state.StoreIds.map((option: any) => (
                            //@ts-ignore
                            <Option value={option.id}>{`${option.store_name}(${option.store_name})`}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="rowCol" span={6}>
                      <Form.Item
                        label="Phone number"
                        name="full_phone_number"
                        rules={[
                          {
                            required: true,
                            message: "Phone number is required"
                          },
                          {
                            max: 10,
                            message: "Phone no should be less than 10 digits",
                          },
                          {
                            min: 10,
                            message: "Phone no should consist 10 digits",
                          },

                        ]}>
                        <Input type="number" placeholder="Enter phone number" />
                      </Form.Item>

                    </Col>

                  </Row>
                  <Row className="saveBtnbox">
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="content-add-btn saveBtn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>




            <Modal
              className="addEmployeeModalBox"
              visible={this.state.editModal}
              title={null}
              centered
              width="45vw"
              onCancel={() => {
                this.onReset();
                this.setState({ editModal: false })
              }
              }
              closeIcon={
                <CloseCircleFilled
                  style={{ color: "#000", fontSize: "20px" }}
                  translate={{}}
                />
              }
              footer={null}
            >
              <div className="AddStoreTimingsSection" style={{ padding: '0px' }}>
                <Row className="header">
                  <Col span={24}>
                    <h1 className="mb-0 modal-heading">Edit Employee</h1>
                  </Col>
                  <Col span={24}>
                    <p className="mb-0 modal-subtext">Edit the employee details</p>
                  </Col>
                </Row>
                <Row className="imgBoxBlock">
                  <div className="imgBox" >
                    <img height='100px' width="100px" style={{ borderRadius: 50 }} src={this.state.employeeProfileImage !== "" ? this.state.employeeProfileImage : "https://www.24-seven.in/img/logo/X-mas-Logo.png"} />
                    <Upload
                      customRequest={(e) => { this.uploadProfilePicture(e) }}
                      showUploadList={false}
                    >
                      <Button color="transparent" className="edit_btn" ><EditOutlined translate={{}} /></Button>
                    </Upload>

                  </div>
                </Row>

                <Form
                  onFinish={this.handelOnUpdateEmployee}
                  // onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  initialValues={
                    {
                      // zone_id: this.state?.zoneIds[0]?.id,
                      // ofc_id: this.state?.ofcIds[0]?.id,
                      // store_id: this.state?.StoreIds[0]?.id
                    }
                  }
                  ref={this.formRef}
                  name="content-ref"
                  autoComplete="off"
                  size='large'
                  layout="vertical"
                >
                  <Spin
                    spinning={dataLoader}
                    indicator={antIcon} >
                    <Row className="modal-rows fieldBox">
                      <Col className="rowCol" span={6}>
                        <Form.Item name="employee_id" label="Employee ID" rules={[{ required: true, message: "Employee unique id  is required" }]}>
                          <Input type="text" placeholder="Employee Id" />
                        </Form.Item>
                      </Col>
                      <Col className="rowCol" span={6}>
                        <Form.Item name="full_name" label="Name" rules={[{ required: true, message: "Name is required" }]}>
                          <Input type="text" placeholder="Enter name" />
                        </Form.Item>
                      </Col>

                    </Row>
                    <Row className="modal-rows fieldBox">
                      <Col className="rowCol" span={6}>
                        <Form.Item name="email" label="Email">
                          <div id="errorField">{this.state.errors.email}</div>
                          <Input value={this.state.email} onChange={this.handleEmailField} type="text" placeholder="Enter Email" />

                        </Form.Item>

                      </Col>
                      {/* <Col className="rowCol" span={6}>
                      <Form.Item name="role_id" rules={[{ required: false }]}>

                        <Select className="modal-role-text" 
                        placeholder="select role"  
                        onChange={(value) => {
                          this.selectedRoleId(value)
                        }}
                        >
                          {this.state.Roles.map((option: any) => {
                            return(
                            //@ts-ignore
                            <Option value={option.attributes.id}>{option.attributes.name}</Option>
                          )})}
                        </Select>
                      </Form.Item>
                    </Col> */}


                      <Col className="rowCol" span={6}>
                        <Form.Item name="role_id"
                          label="Role"
                          rules={[{ required: false }]}>
                          <Select className="modal-role-text"
                            placeholder="select role"
                            onChange={(value) => {
                              this.selectedRoleId(value)
                            }}
                          >
                            {this.state.Roles.map((option: any, i: any) => {
                              return (
                                //@ts-ignore
                                <Option key={i} value={option.attributes.id}>{option.attributes.name}</Option>
                              )
                            })}
                          </Select>
                        </Form.Item>

                      </Col>
                    </Row>
                    <Row className="modal-rows fieldBox">
                      <Col className="rowCol" span={6}>
                        <Form.Item name="user_name" label="Username" >
                          <Input type="text" value={this.state.username} onChange={this.handleChangeUsername} placeholder="Enter username" />
                          {/* <div id="errorField">{this.state.selectedRoleId !== "" ? this.state.username == "" ? "Username is required" : "" : ""}</div> */}
                        </Form.Item>

                      </Col>
                      <Col className="rowCol" span={6}>
                        <Form.Item name="password" label="Password">
                          <Input type="text" value={this.state.password} onChange={this.handleChangePassword} placeholder="Enter password" />
                          {/* <div id="errorField">{this.state.selectedRoleId !== "" ? this.state.password == "" ? "Password is required" : "" : ""}</div> */}
                        </Form.Item>

                      </Col>
                    </Row>
                    <Row className="modal-rows fieldBox">
                      {/* <Col className="rowCol" span={6}>
                      <Row className="modal-rows fieldBox selectdropBox"> */}
                      <Col span={6}>
                        <Form.Item label="Zone ID" name="zone_id" rules={[{ required: this.state.zoneMandatory, message: "Zone is required" }]}>
                          <Select className="modal-role-text" placeholder="select zone"
                            onChange={(value) => {
                              this.selectedZoneId(value)
                            }}
                          >
                            <Option value="">Select Zone</Option>
                            {this.state.zoneIds.map((option: any) => (
                              //@ts-ignore

                              <Option value={option.id}>{option.zone_name}</Option>
                            ))}
                          </Select>
                        </Form.Item>

                      </Col>
                      <Col span={6}>
                        <Form.Item label="Ofc ID" name="ofc_id" rules={[{ required: this.state.officeMandatory, message: "Office is required" }]}>

                          <Select className="modal-role-text" placeholder="select ofc"
                            onChange={(value) => {
                              this.selectedOfcId(value)
                            }}
                            disabled={this.state.disabledOfc}
                          >
                            <Option value="">Select OFC</Option>
                            {this.state.ofcIds.map((option: any) => (
                              //@ts-ignore
                              <Option value={option.id}>{option.clusture_name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {/* </Col>
                      </Row> */}
                      </Col>
                    </Row>
                    <Row className="modal-rows fieldBox">
                      <Col className="rowCol" span={6}>
                        <Form.Item
                          label="Store ID"
                          name="store_id"
                        // rules={[{ required: this.state.storeMandatory, message: "Store is required" }]}
                        >
                          <Select disabled={this.state.disabledStore} className="modal-role-text" placeholder="select store" onChange={(value) => this.fetchStoreInfo(value)}>
                            <Option value="">Select Store</Option>
                            {this.state.StoreIds.map((option: any) => (
                              //@ts-ignore
                              <Option value={option.id}>{option.store_name}</Option>
                            ))}
                          </Select>
                        </Form.Item>

                      </Col>
                      <Col className="rowCol" span={6}>
                        <Form.Item label="Phone number" name="full_phone_number" rules={[{ required: true, message: "Phone number is required" }]}>
                          <Input type="text" placeholder="Enter phone number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="saveBtnbox">
                      <Col span={6}>
                        <Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            className="content-add-btn saveBtn"
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Spin>
                </Form>
              </div>
            </Modal>


            <div className="sample-file">
              <Popover
                placement="bottom"
                content={
                  <div>
                    <p><a href={`${baseURL}/bx_block_settings/csv_sample.csv?name=people`}>Download</a></p>
                  </div>
                }
                title="Download Sample File"
              >
                <DownCircleFilled translate={{}} style={{ marginRight: 5 }} />Sample file

              </Popover>
            </div>



            {/* TABLE DATA  */}
            <Table
              title={() => 'Employees'}
              size={'small'}
              columns={columns}
              dataSource={peopleList}
              loading={{ spinning: this.state.tableDataLoader, indicator: antIcon }}
              scroll={{ x: 'max-content' }} //
              pagination={paginationOptions}
            />

          </Col>
        </Row>
      </ section >
    )
  }
}
// Customizable Area End
