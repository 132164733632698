import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Swal from "sweetalert2";
// Customizable Area Start
import { string } from "yup";
import { message as AntMessage } from "antd";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    password: string;
    email: string;
    errors: { email: string; password: string };
    message: string;
    privarcyPolicyData: any;
    termAndConditionData: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class InfoPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailLoginCallId: string = "";
    validationApiCallId: string = "";
    emailReg: RegExp;
    labelTitle: string = "";
    // Customizable Area End
    termAndConditionInformation: String;
    privacyPolicyInformation: String;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            email: "",
            password: "",
            errors: { email: "", password: "" },
            message: "",
            privarcyPolicyData: null,
            termAndConditionData: null,
        };

        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.gettermAndConditionInformation();
        this.getprivacyPolicyInformation();
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    gettermAndConditionInformation = async () => {
        this.termAndConditionInformation = await this.apiCall({
            contentType: configJSON.loginApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.termAndConditionAPIEndPoint,
        });
    };

    getprivacyPolicyInformation = async () => {
        this.privacyPolicyInformation = await this.apiCall({
            contentType: configJSON.loginApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.privacyPolicyAPIEndPoint,
        });
    };



    apiCall = async (data: any) => {
        const { contentType, method, endPoint, body } = data;
        // const token = (await StorageProvider.get("Userdata")) || "";
        const header = {
            "Content-Type": contentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // console.log("responseJson", responseJson);

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.data) {
                if (apiRequestCallId === this.termAndConditionInformation) {
                    this.setState({ termAndConditionData: responseJson?.data })
                }
                if (apiRequestCallId === this.privacyPolicyInformation) {
                    this.setState({ privarcyPolicyData: responseJson?.data })

                }
            } else if (responseJson && responseJson.errors) {
                if (apiRequestCallId === this.termAndConditionInformation) {
                    // console.log('Error', responseJson)
                }
                if (apiRequestCallId === this.privacyPolicyInformation) {
                    // console.log('Error', responseJson)
                }
            } else if (errorReponse) {
                this.setState({
                    message: "",
                    // isFetching: false,
                });
            }
        }
    }

}
