Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDelMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.ApiContentType = "application/json";
exports.getAllOrdersEndPoint = `bx_block_orderdetailview/orders/all_orders`;
exports.searchOrdersApiEndPoint = `bx_block_orderdetailview/orders/all_orders?search=`;
exports.getProfileDataApiEndPoint = `bx_block_peoplemanagement2/people_accounts?id=`;
exports.getOfcApiEndPoint = `account_block/accounts/ofc/get_all_ofcs`;
exports.getZoneApiEndPoint = `account_block/accounts/zone/list_of_zones`;
exports.getStoreApiEndPoint = `account_block/accounts/store_accounts/get_store`;
exports.getOrdersApiEndPoint = `bx_block_orderdetailview/orders/store_dashboard_order`;
exports.getSelectedOrdersDetailsApiEndPoint = `/bx_block_orderdetailview/orders/order_items?id=`;
exports.orderStatusUpdatedApiEndPoint = `bx_block_orderdetailview/orders`; 
exports.dashboardSearchAccordingToOfcOrZoneApiEndPoint = `bx_block_orderdetailview/orders/all_orders?`;
exports.dashboardSearchDropDownIdsApiEndPoint = `/bx_block_orderdetailview/orders/get_zone_ofc_store`;
exports.AcceptedDunzoOrderStatusApiEndPoint = `bx_block_cfdunzoapiintegration/dunzo_order_status`;
// Customizable Area End
