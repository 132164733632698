// Customizable Area Start
import React, { createRef } from "react";
import "antd/dist/antd.css";
import {
  Input,
  Row,
  Col,
  Typography,
  Space,
  Card,
  Empty,
} from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import "./Reports.css";
import { FormInstance } from "antd/es/form";
const { Title, Paragraph, Text, Link } = Typography;
import RequestManagementController, {
  Props,
  configJSON,
} from "../RequestManagementController";
import Avatar from "antd/lib/avatar/avatar";
export default class Reports extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cardArray: [
        { id: 1, title: "Sales Report", type: 'Orders fulfilled' },
        { id: 2, title: "Order Cancellation Reason Report", type: 'Rejected' },
        { id: 3, title: "Partial Order Acceptance Reason Report", type: 'Partially accepted' },
        { id: 4, title: "Out of Stock Articles", type: 'Orders fulfilled' },
        { id: 5, title: "Store Close and Open Logs", type: 'Orders fulfilled' },
        { id: 6, title: "Outlet Wise statistics", type: 'OutletStatistics' },
        { id: 7, title: "In stock article report", type: 'currentStock' },
        // { id: 8, title: "Daily Grocery sales report ", type: 'DailyGrocery' },
      ],
      selectedRowKeys: [],
      selectedReport: {},
      FiltercardArray: [],
      ReportDataList: [],
      Storelist: [],
      apiLoading: false,
      selectedCategory: null,
      selectedStore: null,
      selectedStoreName: 'Select Store',
      selectedCategoryName: 'Select Category',
      CategoryList: [],
      to_date: null,
      from_date: null,
      pagination: {
        current: 1,
        pageSize: 10,
        Totalcount: 0
      },
      catalogueList: [],
      selectedCatalougeId: null,
      // Customizable Area End
    };
    document.title = `Reports | 24SevenDashboard`
  }
  gotoReportListing = (item: any) => {
    //@ts-ignore
    this.props.history.push({
      pathname: '/Reportlist',
      state: { detail: item }
    })
  };


  onserach(e: any) {
    if (e == '') {
      this.setState({ FiltercardArray: this.state.cardArray });
    } else {
      let filterData = this.state?.cardArray.filter((data: any) => {
        if (data.title.toLowerCase().includes(e.toLowerCase())) {
          return data;
        }
      })
      this.setState({ FiltercardArray: filterData });
    }
  }

  render() {
    return (
      <>
        <Row className="Reports-main">
          <Col span={24} className="content-container">
            <Row className="header report-list-row">
              <Col span={9} className="report-list-col" style={{ textAlign: "left" }}>
                <Title className="title">Reports</Title>
              </Col>
              <Col span={15} className="report-list-col-search" style={{ textAlign: "end" }}>
                <Space size="middle">
                  <Input placeholder="Search" className="search-box report-list-col-search" onChange={(e) => { this.onserach(e.target.value) }} />
                </Space>
              </Col>
            </Row>
            <Row className="CardRowSection report-list-row">
              {
                this.state?.FiltercardArray.length > 0
                  ?
                  this.state?.FiltercardArray?.map((item: any, index: any) => {
                    return (
                      <Col key={index} className="cardColSection report-list-col">
                        <Card
                          hoverable
                          onClick={() => { this.gotoReportListing(item) }}
                          className="cardSection"
                          key={index}
                        >


                          <Avatar
                            size="large"
                            style={{ backgroundColor: '#777' }}
                          >
                            {item.title[0]}
                          </Avatar>
                          <p style={{ marginTop: '8px', textTransform: 'capitalize' }} className="data">{item.title}</p>
                        </Card>
                      </Col>
                    );
                  })
                  :
                  <Empty
                    style={{ marginLeft: '40%' }}
                    description={
                      <span>
                        No data found
                      </span>
                    }
                  />
              }
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
// Customizable Area End

